/* eslint-disable jsx-a11y/alt-text */
import { LockOutlined } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import Loader from "../../atoms/loader/Loader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import Backgroundimage from "../../../src/assets/flight.png";
import "../auth/Auth.css";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import axios from "axios";
import SecureLS from "secure-ls";

const TenantLogin = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [username, setUsername] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  const handleOnSubmit = (data) => {
    setData(data);
    setLoading(true);
  
    // Make a POST request to check if data exists
    axios
    .get(
      `${environment.GET_TENANT_ACCESS_DETAILS}/${data.tenant}/Alias/console/type/tenant?email=${data.email}`,
    )
      .then((postRes) => {
        if (postRes.data) {
          if (postRes.data.hasConsoleAccess === true) {
            // 'hasAccess' is true, proceed with the GET request
            axios
            .get(
              `https://${data.tenant}.intracrew.com/assets/Json/tenant_configuration.json`
            )
              .then((getRes) => {
                setLoading(true);
                Amplify.configure({
                  Auth: getRes.data.cognito,
                });
                Auth.signIn(data.email, data.password)
                  .then((user) => {
                    setLoading(false);
                    new SecureLS("aes").set("_secure_user_info", user);
                    new SecureLS("aes").set("_secure_login_state", true);
                    navigate("/home");
                  })
                  .catch((err) => {
                    setLoading(false);
                    setError(err.message);
                  });
              })
              .catch((getErr) => {
                setLoading(false);
                setError("No tenant");
              });
          } else {
            // 'hasAccess' is false
            setLoading(false);
            setError("Console Access Denied");
          }
        } else {
          // No data returned
          setLoading(false);
          setError("Invalid Tenant Details");
        }
      })
      .catch((postErr) => {
        setLoading(false);
        setError("Error during POST request");
      });
  };
  


  const togglePasswordHide = (event, type) => {
    event.preventDefault();

    setShowPassword(!showPassword);
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    tenant: Yup.string().required(),
    email: Yup.string().required(),
    password: Yup.string().required(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { handleSubmit, register, formState, control } = useForm(formOptions);
  const { errors } = formState;

  return (
    <>
      {error ? (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      ) : (
        <></>
      )}
      {loading ? <Loader loading={loading}></Loader> : <></>}
      <Box className="kmc-console-signup-main-box kmc-customheight100">
        <Container component="main" className="kmc-customheight100">
          <Box className="kmc-loginbox">
            <div className="kmc-customdiv">
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Tenant Login
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(handleOnSubmit)}
                sx={{ marginTop: "1rem" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      defaultValue={""}
                      name="tenant"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          {...register("tenant")}
                          label="Tenant Name"
                          InputLabelProps={{ shrink: true }}
                          error={errors?.tenant ? true : false}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      defaultValue={""}
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          {...register("email")}
                          label="Email"
                          InputLabelProps={{ shrink: true }}
                          error={errors?.email ? true : false}
                        />
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          {...register("password")}
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          InputLabelProps={{ shrink: true }}
                          error={errors?.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                {showPassword ? (
                                  <Visibility
                                    className="cursor_pointer"
                                    onClick={togglePasswordHide}
                                  />
                                ) : (
                                  <VisibilityOff onClick={togglePasswordHide} />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    ></Controller>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={loading}
                    >
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Box>
          <Box>
            <div className="kmc-console-login-background-box">
              <img src={Backgroundimage} className="kmc-loginbg"></img>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default TenantLogin;

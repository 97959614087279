/* eslint-disable jsx-a11y/alt-text */
import { LockOutlined } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import Loader from "../../atoms/loader/Loader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import Backgroundimage from "../../../src/assets/flight.png";
import "../auth/Auth.css";

Amplify.configure({
  Auth: environment.cognito,
});

const SignUp = (props) => {

  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [username, setUsername] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setLoading(true);
      Auth.signUp(data.email, data.password)
        .then((user) => {
          setLoading(false);
          setData(null);
          navigate("/confirm", { state: { username: username } });
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
    }
  }, [data, navigate, username]);

  const handleOnSubmit = (data) => {
    setUsername(data.email);
    setData(data);
  };

  const togglePasswordHide = (event, type) => {
    event.preventDefault();
    if(type === 'password') {
      setShowPassword(!showPassword);
    }    
    if(type === 'confirmPassword') {
      setConfirmPassword(!confirmPassword);
    }
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string()
      .required()
      .min(6, "Password must be at least 6 characters long."),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password")], "Passwords must match."),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { handleSubmit, register, formState, control } = useForm(formOptions);
  const { errors } = formState;

  return (
    <>
      {
        error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : <></>
      }
      {
        loading ? <Loader loading={loading}></Loader> : <></>
      }
      <Box className="kmc-console-signup-main-box kmc-customheight100">
        <Container component="main" className="kmc-customheight100">
          <Box className="kmc-loginbox">
            <div className="kmc-customdiv">
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Register
              </Typography>
              <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} sx={{ marginTop: "1rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      defaultValue={''}
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField fullWidth {...field} {...register("email")} label="Email"
                          InputLabelProps={{ shrink: true }} error={errors?.email ? true : false} />
                      )}
                    >
                    </Controller>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="password"
                      defaultValue={''}
                      control={control}
                      render={({ field }) => (
                        <TextField fullWidth {...field} {...register("password")} label="Password"
                          type={showPassword ? "text" : "password"}
                          InputLabelProps={{ shrink: true }} error={errors?.password ? true : false}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                {showPassword ? (
                                  <Visibility
                                    className="cursor_pointer"
                                    onClick={(event) => togglePasswordHide(event, 'password')}
                                  />
                                ) : (
                                  <VisibilityOff onClick={(event) => togglePasswordHide(event, 'password')} />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    >
                    </Controller>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      defaultValue={''}
                      name="confirmPassword"
                      control={control}
                      render={({ field }) => (
                        <TextField type={confirmPassword ? "text" : "password"} fullWidth {...field} {...register("confirmPassword")} label="Confirm Password"
                          InputLabelProps={{ shrink: true }} error={errors?.confirmPassword ? true : false}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                {confirmPassword ? (
                                  <Visibility
                                    className="cursor_pointer"
                                    onClick={(event) => togglePasswordHide(event, 'confirmPassword')}
                                  />
                                ) : (
                                  <VisibilityOff onClick={(event) => togglePasswordHide(event, 'confirmPassword')} />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    >
                    </Controller>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={loading}
                    >
                      Signup
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: "0.5rem" }}>
                  <Grid item xs>
                    <Typography component="h6">
                      Are you an existing user?
                    </Typography>
                    <Link to="/login">Login</Link>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Box>
          <Box>
            <div className="kmc-console-login-background-box">
              <img src={Backgroundimage} className="kmc-loginbg"></img>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SignUp;

import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {
    Button,
    Typography,
    CircularProgress,
    Badge,
    Grid
} from "@mui/material";

import { Notifications, Settings } from "@mui/icons-material";
import "./header.css";
import { Box } from "@mui/material"
import SecureLS from "secure-ls";
import DefaultLogo from "../../assets/flight.png";
import { useNavigate } from "react-router-dom";
import PrivateEncodedImageUrl from "../../misc/PrivateEncodedImageUrl";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { environment } from "../../assets/configurations/configuration";
import PopoverErrorsList from "../../molecules/PopoverMappingsList";

async function isLoginSessionActive() {
    try {
        await Auth.currentAuthenticatedUser();
        console.log("user login")
        return true;
    } catch {
        return false;
    }
}
if (!isLoginSessionActive()) {
    // login session is not active, so try to logout in this place by clearing the storage
    console.log("login failure")
    localStorage.clear();
    window.location.reload();
}

const Header = (props) => {
const [executed, setExecuted] = useState(false);
    const [logo, setlogo] = useState();

    const _secureStore = new SecureLS();
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const email = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];
    const tenant = props.tenant;
   
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(DefaultLogo);

    const [tenantFailures, setTenantFailures] = useState({});
    const [popoverEl, setPopoverEl] = useState();

    useEffect(() => {
        if (!tenant) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        } else {
            setLoading(false);
        }
    }, [tenant]);

    useEffect(() => {
        setLoading(true);
        if (tenant) {
            // get logo of the current tenant
            axiosInstance
                .get(
                    `${environment.BRANDING_LOGO_API}/${tenant}/visibility/private/document/console/id_type/images/id/logo_image`, {
                    responseType: 'blob'
                })
                .then(res => {
                    const f = new File([res.data], 'preview');
                    setPreview(window.URL.createObjectURL(f));
                    setLoading(false);

                }).catch(err => {
                    setLoading(false);
                    console.log(err);
                });
            // get tenant specific errors or integration failures
            axiosInstance.get(`${environment.GET_TENANT_ERRORS}/${tenant}`)
                .then(res => {
                    if (res && res.data) {
                        setTenantFailures(res.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    const url = PrivateEncodedImageUrl(tenant, email);

         axiosInstance
        .get(
            `${url}`, {
            responseType: 'blob'
        })
        .then(res => {
            console.log("222222", res)
            const f = new File([res.data], 'logo');
            setlogo(window.URL.createObjectURL(f));
            setExecuted(true);
           
        }).catch(err => {
           
        });
    }, [tenant]);

    const handleSettingsClick = (event) => {
        event.preventDefault();
        navigate("/settings/company", {
            state: { isSettingsTabOpen: true, activeItemId: 51 }
        });
    };

    const handleClosePopover = () => {
        setPopoverEl(null);
    }

    return (
        <Grid container spacing={1} sx={{ backgroundColor: "#1C243C" }}>
            <Grid item xs={3}>
                <Box className="kmc-console-header-workspace" sx={{ marginLeft: "1rem" }}>
                    <Typography sx={{ color: 'white', fontSize: '15px' }}>
                        Internal Workspace:
                    </Typography>
                    <Button
                        disableRipple
                        sx={{ color: "#ec910e", textTransform: 'none', fontWeight: '550', fontSize: '15px' }}
                        startIcon={
                            loading ? <CircularProgress color="primary" size={20}></CircularProgress>
                                :
                                // <img src={preview} alt={tenant} width={20} height={20}
                                //     style={{ clipPath: "circle()" }}
                                // ></img>
                                <img src={preview} alt={tenant}
                                    //                                width={50} height={20}
                                    height={20}
                                    style={{ maxWidth: "5rem", maxHeight: "5rem", objectFit: 'contain' }}

                                //                                style={{ maxWidth: "2.5rem",maxHeight:"2.5rem",minHeight: "2.5rem" ,minWidth:"2.5rem",objectFit: 'contain'}}
                                ></img>
                        }
                        onClick={() => {
                            navigate('/workspace/register', {
                                state: {
                                    isWorkspaceExpand: true,
                                    activeItemId: 991
                                }
                            });
                        }}
                    >
                        {loading ? '' : (tenant ? tenant : "Create workspace")}
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Button
                    startIcon={<Settings fontSize="medium" sx={{ color: 'aliceblue' }}></Settings>}
                    size="large"
                    disableRipple
                    onClick={handleSettingsClick}
                    className="kmc-console-header-settings-button"
                >
                    Client Settings
                </Button>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "right" }}>
                <Button
                    size="large"
                    disableRipple
                    startIcon={
                        // <img src={profileImageEncodedUrl}
                        //     alt={email} width={40} height={40}
                        //     style={{ clipPath: "circle()", cursor: "pointer" }}
                        // />
                        <img src={logo}
                            alt={email}
                            width={40} height={40}
                            style={{ maxWidth: "10rem", maxHeight: "10rem", cursor: "pointer", clipPath: "circle()" }}
                        />
                    }
                    sx={{ textTransform: 'none', fontWeight: 550, color: "#fff", fontSize: "14px" }}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate("/settings/editprofile", {
                            state: {
                                isSettingsTabOpen: true,
                                activeItemId: 54
                            }
                        });
                    }}
                >
                    {email}
                </Button>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: "right" }}>
                <Box className="kmc-console-header-notifications" sx={{ marginTop: "1rem", marginRight: "1rem", cursor: "pointer" }}>
                    <Badge badgeContent={Object.keys(tenantFailures).length} color={"error"}
                        onClick={(event) => {
                            event.preventDefault();
                            navigate('/tenant/notifications', {
                                state: {
                                    notifications: tenantFailures
                                }
                            });
                        }}
                    >
                        <Notifications
                            fontSize="medium"
                            sx={{ color: "#b5d5e4" }}
                        >
                        </Notifications>
                    </Badge>
                    {
                        Object.keys(tenantFailures).length > 0
                            ?
                            <PopoverErrorsList
                                el={popoverEl}
                                errors={tenantFailures}
                                handleClosePopover={handleClosePopover}
                            />
                            : <></>
                    }
                </Box>
            </Grid>
        </Grid>
    );
};

export default Header;

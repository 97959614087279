import React, { useEffect, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { AssignmentInd, FactCheck, FileCopy } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import "./DocumentManagamentNodeGroup.css";

export default function DocumentManagamentNodeGroup(props) {

    const navigate = useNavigate();
    const state = useLocation();
    const [active, setActive] = useState();
    
    useEffect(() => {
        const activeItemId = state && state.state && state.state.activeItemId;
        if(activeItemId) {
            setActive(activeItemId);
        }
    },[state]);

    return (
        <List component="div" disablePadding>
            <ListItemButton id="6001"
                className={(active === 6001) ? "kmc-console-adminmanagement-litem-active" : ""}
                onClick={() => {
                    navigate('/manage/documents', { state: { isDocumentManagementNodeExpand: true } });
                    setActive(6001);
                }
                }>
                <ListItemIcon>
                    <AssignmentInd fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'User Documents'} />
            </ListItemButton>
            <ListItemButton id="6002"
                className={(active === 6002) ? "kmc-console-adminmanagement-litem-active" : ""}
                onClick={() => {
                    navigate('/manage/common/documents', { state: { isDocumentManagementNodeExpand: true } });
                    setActive(6002);
                }
                }>
                <ListItemIcon>
                    <FileCopy fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Common Documents'} />
            </ListItemButton>
            <ListItemButton id="6003"
                className={(active === 6003) ? "kmc-console-adminmanagement-litem-active" : ""}
                onClick={() => {
                    navigate('/manage/documents/checklist', { state: { isDocumentManagementNodeExpand: true } });
                    setActive(6003);
                }
                }>
                <ListItemIcon>
                    <FactCheck fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Checklist'} />
            </ListItemButton>
        </List>
    )
}
import React, { useEffect, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { PersonAddAlt, PersonRemove, PersonSearch } from '@mui/icons-material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLocation, useNavigate } from 'react-router-dom';
import "./ManagementNodeGroup.css";

const ManageMentNodeGroup = (props) => {

    const navigate = useNavigate();
    const state = useLocation();
    const [active, setActive] = useState();

    useEffect(() => {
        const activeItemId = state && state.state && state.state.activeItemId;
        if(activeItemId) {
            setActive(activeItemId);
        }
    },[state]);

    return (
        <List component="div" disablePadding>
            <ListItemButton id="1001"
                className={(active === 1001) ? "kmc-console-management-litem-active" : ""}
                onClick={() => {
                    navigate('/management/onboard', { state: { isManagementNodeExpand: true } });
                    setActive(1001);
                }
                }>
                <ListItemIcon>
                    <PersonAddAlt fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'On-board'} />
            </ListItemButton>
            <ListItemButton id="1002"
                className={(active === 1002) ? "kmc-console-management-litem-active" : ""}
                onClick={() => {
                    navigate('/management/offboard', { state: { isManagementNodeExpand: true } });
                    setActive(1002);
                }
                }>
                <ListItemIcon>
                    <PersonRemove fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Off-board'} />
            </ListItemButton>
            <ListItemButton id="1003"
                className={(active === 1003) ? "kmc-console-management-litem-active" : ""}
                onClick={() => {
                    navigate('/management/edit/employee', { state: { isManagementNodeExpand: true } });
                    setActive(1003);
                }
                }>
                <ListItemIcon>
                    <PersonSearch fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Edit People'} />
            </ListItemButton>
            <ListItemButton id="1004"
                className={(active === 1004) ? "kmc-console-management-litem-active" : ""}
                onClick={() => {
                    navigate('/management/assign_secondary_manager', { state: { isManagementNodeExpand: true } });
                    setActive(1004);
                }
                }>
                <ListItemIcon>
                    <AssignmentIndIcon fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Assign Secondary Facilitator'} />
            </ListItemButton>
            <ListItemButton id="1005"
                className={(active === 1005) ? "kmc-console-management-litem-active" : ""}
                onClick={() => {
                    navigate('/management/timetracker', { state: { isManagementNodeExpand: true } });
                    setActive(1005);
                }
                }>
                <ListItemIcon>
                    <AccessTimeIcon  fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Time Tracker'} />
            </ListItemButton>
        </List>
    )
}

export default ManageMentNodeGroup;
import { Buffer } from 'buffer';

const PrivateEncodedStringBase64 = (tenant) => {

    const _tenant = tenant;
    const _photos = "/photos/";
    const _str_to_encode = _tenant + _photos;
    return new Buffer.from(_str_to_encode, 'binary').toString('base64');

}

export default PrivateEncodedStringBase64;
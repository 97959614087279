import { Error, NotificationsOff } from "@mui/icons-material";
import { Box, Card, CardContent, Grid, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import './TenantNotifications.css';

const TenantNotifications = () => {

    const location = useLocation();
    const notifications = location && location?.state && location?.state?.notifications;

    return (
        <Box className="kmc-console-tenant-notifications-main-box">
            <Box className="kmc-console-tenant-notifications-content-box">
                <Card className="kmc-console-tenant-notifications-info-card">
                    <Typography variant="h6">Tenant Notifications</Typography>
                </Card>
                <Card className="kmc-console-tenant-notifications-content-card">
                    <CardContent>
                        <Grid container spacing={1}>
                            {
                                notifications && Object.keys(notifications).length > 0
                                    ?
                                    Object.keys(notifications).map((key, index) => {
                                        return (
                                            <Grid item xs={12} key={index} sx={{ cursor: 'pointer' }}>
                                                <ListItem key={index} sx={{ backgroundColor: '#e6e6e5' }}>
                                                    <ListItemIcon>
                                                        <Error sx={{ color: '#d32f2f' }}></Error>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography sx={{ color: '#d32f2f' }}>{notifications[key]}</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            </Grid>
                                        )
                                    })
                                    : <Grid item xs={12} sx={{ cursor: 'pointer' }}>
                                        <NotificationsOff sx={{ fontSize: "4rem", color: "#bfbfbf" }}></NotificationsOff>
                                        <Typography sx={{ fontSize: '14px', fontWeight: '550', color: '#9e9c9c' }}>No notifications.</Typography>
                                    </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}

export default TenantNotifications;
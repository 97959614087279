import './EmployeeDataStore.css';
import AzurePNG from "../../assets/AzurePNG.png";
import GooglePNG from "../../assets//GooglePNG.png";
import CsvPNG from "../../assets/CSVLogo.png";
import ExcelPNG from "../../assets/ExcelPNG.png";
import UploadPNG from "../../assets/UploadPNG.png";
import BoomHRPNG from "../../assets/BoomHRPNG.png";

import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AzureADForm from '../../molecules/AzureADForm';
import GoogleADForm from '../../molecules/GoogleADForm';
import BoomADForm from '../../molecules/BoomADForm';
import BulkUploadForm from '../../molecules/BulkUploadForm';
import SecureLS from 'secure-ls';
import { useNavigate } from 'react-router-dom';
import { PersonAdd, PersonOff, Refresh } from '@mui/icons-material';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import { environment } from '../../assets/configurations/configuration';
import Loader from '../loader/Loader';

const EmployeeDataStore = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const navigate = useNavigate();

    useEffect(() => {
        if (!tenant) {
            navigate('/workspace/register', {
                state: {
                    message: "You must register domain.",
                    isWorkspaceExpand: true,
                    activeItemId: 991
                }
            });
        }
    }, [tenant, navigate]);

    const [azureDialog, setAzureDialog] = useState(false);
    const [googleDialog, setGoogleDialog] = useState(false);
    const [boomhrDialog, setBoomhrDialog] = useState(false);
    const [bulkUploadDialog, setBulkUploadDialog] = useState(false);
    const [isGoogleCardLoading, setIsGoogleCardLoading] = useState(false);
    const [isAzureCardLoading, setIsAzureCardLoading] = useState(false);
    const [isBoomhrCardLoading, setIsBoomhrCardLoading] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const handleClose = (id) => {
        if (id === "azureADCard") {
            setAzureDialog(!azureDialog);
        }
        if (id === "googleWSCard") {
            setGoogleDialog(!googleDialog)
        }
        if (id === "boomHRCard") {
            setBoomhrDialog(!boomhrDialog);
        }
        if (id === "bulkCard") {
            setBulkUploadDialog(!bulkUploadDialog);
        }
    }

    const handleError = (errorMessage) => {
        if (errorMessage) {
            setError(errorMessage);
        }
    }

    const handleSuccess = (message) => {
        if (message) {
            setMessage(message);
        }
        navigate('/workspace/plan', {
            state: {
                isWorkspaceExpand: true,
                activeItemId: 994
            }
        });
    }

    const handleRefresh = (integration) => {
        // call the respective API based on the integration type.
        switch (integration) {
            case "azureADCard": {
                invokeRefreshAPI("azureADCard");
                break;
            }
            case "boomHRCard": {
                invokeRefreshAPI("boomHRCard");
//                alert("BoomHR integration is in process");
                break;
            }
            case "googleWSCard": {
                invokeRefreshAPI("googleWSCard");
                break;
            }
            default: {
                console.error("Invalid integration type.")
            }
        }
    }

    const invokeRefreshAPI = (type) => {
        const data = {
            tenant_name: tenant,
            entitytype: "Employee"
        };
        if (type === 'googleWSCard') {
            data.useruuid = "google_crawler";
            setIsGoogleCardLoading(true);
            axiosInstance.post(`${environment.GOOGLE_CREDENTIALS_CRAWLER}`, data, { headers: { "InvocationType": "Event" } })
                .then(res => {
                    setIsGoogleCardLoading(false);
                    console.log(res);
                })
                .catch(err => {
                    setIsGoogleCardLoading(false);
                    setError(err?.response?.data);
                });
        }

        if (type === 'boomHRCard') {
            data.useruuid = "boomhr_crawler";
            setIsBoomhrCardLoading(true);
            axiosInstance.post(`${environment.BAMBOOHR_CREDENTIALS_CRAWLER}`, data, { headers: { "InvocationType": "Event" } })
                .then(res => {
                    setIsBoomhrCardLoading(false);
                    console.log(res);
                })
                .catch(err => {
                    setIsBoomhrCardLoading(false);
                    setError(err?.response?.data);
                });
        }

        if (type === 'azureADCard') {
            data.useruuid = "azure_crawler";
            setIsAzureCardLoading(true);
            axiosInstance.post(`${environment.AZURE_CREDENTIALS_CRAWLER}`, data, { headers: { "InvocationType": "Event" } })
                .then(res => {
                    setIsAzureCardLoading(false);
                    console.log(res);
                })
                .catch(err => {
                    setIsAzureCardLoading(false);
                    setError(err?.response?.data);
                });
        }
    }

    return (
        <Box className="kmc-console-emp-datastore-main-box">
            {
                error
                    ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>
                    : <></>
            }
            {
                message
                    ? <Alert severity='success' onClose={() => setError(null)}></Alert>
                    : <></>
            }
            <AzureADForm open={azureDialog} onClose={handleClose}></AzureADForm>
            <GoogleADForm open={googleDialog} onClose={handleClose}></GoogleADForm>
            <BoomADForm open={boomhrDialog} onClose={handleClose}></BoomADForm>
            <BulkUploadForm open={bulkUploadDialog} onClose={handleClose}
                onError={handleError}
                onSuccess={handleSuccess}
            >
            </BulkUploadForm>
            <Box className="kmc-console-emp-datastore-content-box">
                <Card className="kmc-console-emp-datastore-info-card">
                    <Typography variant="h6">Manage people creation through different integrations</Typography>
                </Card>
                <Card className="kmc-console-emp-datastore-content-card">
                    <Box className="kmc-console-emp-datastore-content-box">
                        <Grid container spacing={2}>
                            <Grid item xs={6} sx={{ marginTop: "0.5rem", textAlign: "start" }}>
                                <Typography className="kmc-console-emp-datastore-group-title">People</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ marginTop: "0.5rem", textAlign: "end" }}>
                                <Button sx={{textTransform: 'none'}} 
                                    disableRipple
                                    onClick={(event) => {
                                        event.preventDefault();
                                        navigate('/workspace/plan', {
                                            state: {
                                                isWorkspaceExpand: true,
                                                activeItemId: 994
                                            }
                                        });
                                    }}>
                                    Continue
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className="kmc-console-emp-datastore-facade" id="azureADCard"
                                    onClick={() =>
                                        navigate('/management/onboard', {                                            
                                            state: {                                                
                                                isManagementNodeExpand: false,
                                                //activeItemId: 1001,
                                                navigation_from: "employee_data_page"
                                            }
                                        })
                                    }>
                                    <CardHeader title="Onboard">
                                    </CardHeader>
                                    <CardContent>
                                        <PersonAdd sx={{ height: 100, width: 100, color: "#387920bd" }} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className="kmc-console-emp-datastore-facade" id="azureADCard"
                                    onClick={() => 
                                        navigate('/management/offboard', {                                            
                                            state: {                                                
                                                isManagementNodeExpand: false,
                                                //activeItemId: 1002,
                                                navigation_from: "employee_data_page"
                                            }
                                        })
                                    }>
                                    <CardHeader title="Offboard">
                                    </CardHeader>
                                    <CardContent>
                                        <PersonOff sx={{ height: 100, width: 100, color: "#a95d5d" }} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: "0.25rem", textAlign: "start" }}>
                                <Typography className="kmc-console-emp-datastore-group-title">Upload</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className="kmc-console-emp-datastore-facade" id="csvCard"
                                    onClick={() => {
                                        navigate('/workspace/upload/csv',
                                            {
                                                state: { isWorkspaceExpand: true }
                                            }
                                        );
                                    }}>
                                    <CardHeader title="CSV Upload">
                                    </CardHeader>
                                    <CardContent>
                                        <img src={CsvPNG} height={100} width={100} alt="csvupload"></img>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className="kmc-console-emp-datastore-facade" id="excelCard"
                                    onClick={() => {
                                        navigate('/workspace/upload/excel',
                                            {
                                                state: { isWorkspaceExpand: true }
                                            }
                                        );
                                    }}>
                                    <CardHeader title="Excel Upload">
                                    </CardHeader>
                                    <CardContent>
                                        <img src={ExcelPNG} height={100} width={100} alt="excelupload"></img>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: "0.25rem", textAlign: "start" }}>
                                <Typography className="kmc-console-emp-datastore-group-title">Upload Bulk Images(Only zip format is allowed)</Typography>
                                <Typography className="kmc-console-emp-datastore-group-title"></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Card className="kmc-console-emp-datastore-facade" id="bulkCard"
                                    onClick={() => setBulkUploadDialog(!bulkUploadDialog)}>
                                    <CardHeader title="Bulk Image Upload">
                                    </CardHeader>
                                    <CardContent>
                                        <img src={UploadPNG} height={100} width={100} alt="bulkupload"></img>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: "0.25rem", textAlign: "start" }}>
                                <Typography className="kmc-console-emp-datastore-group-title">Link External Sources</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Card className="kmc-console-emp-datastore-facade" id="azureADCard">
                                    <CardHeader title="Azure Active Directory">
                                    </CardHeader>
                                    {
                                        <Loader loading={isAzureCardLoading}></Loader>
                                    }
                                    <CardContent onClick={() => setAzureDialog(!azureDialog)}>
                                        <img src={AzurePNG} height={100} width={100} alt={"azurewsupload"}></img>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: "end" }}>
                                        <Refresh onClick={() => handleRefresh("azureADCard")}
                                            sx={{ color: "#cf9b55e3" }}
                                        ></Refresh>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card className="kmc-console-emp-datastore-facade" id="googleWSCard">
                                    <CardHeader title="Google Workspace">
                                    </CardHeader>
                                    {
                                        <Loader loading={isGoogleCardLoading}></Loader>
                                    }
                                    <CardContent onClick={() => setGoogleDialog(!googleDialog)}>
                                        <img src={GooglePNG} height={100} width={100} alt={"googlewsupload"}></img>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: "end" }}>
                                        <Refresh onClick={() => handleRefresh("googleWSCard")}
                                            sx={{ color: "#cf9b55e3" }}
                                        ></Refresh>
                                    </CardActions>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card className="kmc-console-emp-datastore-facade" id="boomHRCard">
                                    <CardHeader title="Boom HR">
                                    </CardHeader>
                                    {
                                        <Loader loading={isBoomhrCardLoading}></Loader>
                                    }
                                    <CardContent onClick={() => setBoomhrDialog(!boomhrDialog)}>
                                        <img src={BoomHRPNG} height={100} width={100} alt="boomhrupload"></img>
                                    </CardContent>
                                    <CardActions sx={{ justifyContent: "end" }}>
                                        <Refresh onClick={() => handleRefresh("boomHRCard")}
                                            sx={{ color: "#cf9b55e3" }}
                                        ></Refresh>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

export default EmployeeDataStore;
import React, { useEffect, useState } from "react";
import { Typography, Box, TextField, FormGroup, FormControlLabel, Checkbox, Grid, Chip, Avatar, Alert, Card, Button, Stack, CircularProgress } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import SecureLS from "secure-ls";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import './SecondaryManager.css';
import UserPicker from "../../molecules/UserPicker";
import Loader from "../loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from 'react-device-detect';
import { Navigation } from "swiper";
import EmployeeDisplayCard from "../../molecules/EmployeeDisplayCard";
import PrivateEncodedImageUrl from "../../misc/PrivateEncodedImageUrl";
import LocationSearchPlaces from "../../molecules/LocationSearchPlaces";
import MuiPhoneNumber from 'material-ui-phone-number';

const EditEmpProfile = () => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [file, setFile] = useState();
    const [employee, setEmployee] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState(false);
    const [manager, setManager] = useState();
    const [parentId, setParentId] = useState("");
    const [parentName, setParentName] = useState("");
    const [referenceObjectId, setReferenceObjectId] = useState("");
    const [uniqueId, setUniqueId] = useState();
    const [entityDetails, setEntityDetails] = useState([]);
    const [secondaryManger, setSecondaryManger] = useState([]);

    const [newSecondaryManager, setNewSecondaryManager] = useState({});



//    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState, control, setValue, register, reset } =
     useForm( {
        defaultValues: {
            displayName: "",
            contactEmail: "",
        }
    });

    useEffect(() => {
        if (employee && employee.uniqueId) {
            const config = {
                headers: {
                    "requestagentid": loggedInUserEmail
                }
            }
            setLoading(true);
            axiosInstance
                .get(`${environment.GET_EMPLOYEE_RECORD_API}/${employee.uniqueId}`, config)
                .then(res => {
                    if (res && res.data) {
                        setValue("displayName", res.data.displayName);
                        setValue("contactEmail", res.data.contactEmail);
                        setValue("imageUrl", res.data.imageUrl);
//                        setGeoLocation({ address: res.data?.locationName });
                        setParentId(res.data.parentId);
                        setParentName(res.data.parentName);
                        setReferenceObjectId(res.data.referenceObjectGuid);
                        setUniqueId(res.data.uniqueId);
                    }
                    setLoading(false);
                }).catch(err => {
                    setLoading(false);
                    setError(err.message);
                });
            // get the existing reportees if any for the current selected user
            setLoading(true);
            axiosInstance
                .get(`${environment.GET_EMPLOYEE_MANAGERS}/${tenant}/employeeId/${employee.uniqueId}/getEmployee`)
                .then((res) => {
                    setLoading(false);
                    console.log("GET_EMPLOYEE_MANAGERS",res.data.managers)
                    if (res && res.data) {
                        setSecondaryManger(res.data.managers);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError(err.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employee]);




    const onSelectEmployee = (data) => {
        // fire a get request to get user details with unique Id        
        setEmployee(data);
    }



    const handleOnSubmit = (data) => {
        // on edit you need to do invoke the rest entites in the following order
        // 1. If there are reportees added to the selected employee, need to call the
        // update api of each reportee to update the parent with new manager referenceObjectGuid
        // 2. If there is a new manager assigned to the selected user, need to update the
        // parentId with the selected manager referenceObjectGuid.
        // Therefore, the order should be in the following way. Do step 2 first, update employee.
        // Do step 1 to update the reportees. 
        const config = {
            headers: {
                "changeagentid": loggedInUserEmail
            }
        }

        // check if there is a new manager selected for current selection
        setLoading(true);
        axiosInstance.post(`${environment.ASSIGN_SEC_MANAGER}/${tenant}/employeeId/${employee.uniqueId}/AddOrRemoveManager/${newSecondaryManager.uniqueId}`)
            .then(res => {
                setLoading(false);
                setMessage(true);
                reset();
                setManager(null);
                setEmployee({});
                setEntityDetails([]);
                setNewSecondaryManager({});
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            })
    }


    const onSelectNewSecondaryManager = (e) => {
    setNewSecondaryManager(e);
    }



    const onSelectedAsset = (file) => {
        setFile(file);
    }

    const ShowEmployeeDetails = () => {

        return (
            <>
                <Grid container sx={{ paddingTop: "1rem" }}>
                    <Grid item xs>
                        <EmployeeDisplayCard
                            employee={employee}
                            requestToUpdateAsset={true} onSelectAsset={onSelectedAsset} selectedFile={file}></EmployeeDisplayCard>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {
                        loading ? <CircularProgress></CircularProgress> :
                            <>
                                {
                                    secondaryManger && secondaryManger.length > 0 ?
                                        <Chip
                                            size="small"
                                            label={secondaryManger && secondaryManger.length + " Secondary Managers"}
                                            color="primary"
                                            variant="outlined"
                                            sx={{ marginBottom: "1rem" }}>
                                        </Chip>
                                        : <></>
                                }
                                <Swiper
                                    style={{ height: 'auto' }}
                                    slidesPerView={isMobile ? 1 : 4}
                                    spaceBetween={5}
                                    slidesPerGroup={3}
                                    loop={false}
                                    loopFillGroupWithBlank={false}
                                    navigation={true}
                                    modules={[Navigation]}
                                    centeredSlides={false}
                                    className="swiper-wrapper"
                                >
                                    {secondaryManger
                                        ?
                                        secondaryManger.map((item, key) => (

                                            <SwiperSlide className="swiper-slide">
                                                <Card key={key} className="kmc-console-swiperslide-card">
                                                    <EmployeeDisplayCard employee={secondaryManger[item.entityId]} key={key}></EmployeeDisplayCard>
                                                </Card>
                                            </SwiperSlide>
                                        ))
                                        :
                                        <></>
                                    }
                                </Swiper>
                            </>

                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        manager && manager?.entityId ?
                            <>
                                <Chip
                                    size="small"
                                    label="Current Facilitator"
                                    color="primary"
                                    variant="outlined"
                                    sx={{ marginBottom: "1rem" }}>
                                </Chip>
                                <EmployeeDisplayCard employee={[manager?.entityId]}>
                                </EmployeeDisplayCard>
                            </>
                            : <></>

                    }
                </Grid>
                <Grid item xs={12}>
                    <UserPicker label="Assign New Secondary Facilitator"
                        onSelectSearchItem={onSelectNewSecondaryManager}
//                        onClearSearchItem={onRemoveNewManager}
                    >
                    </UserPicker>
                </Grid>
                <Grid item xs={12} sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                    {
                        newSecondaryManager && newSecondaryManager?.uniqueId ?
                            <Card className="kmc-console-swiperslide-card">
                                <EmployeeDisplayCard employee={newSecondaryManager}></EmployeeDisplayCard>
                            </Card>
                            : <></>
                    }
                </Grid>
            </>
        )
    }

    return (
        <Box className="kmc-console-edit-employee-main-box">
            {
                loading ? <Loader loading={loading}></Loader> : <></>
            }
            {
                error ? <Alert color="error" onClose={(e) => setError(null)}>{error}</Alert> : <></>
            }
            {
                message ? <Alert color="primary" onClose={(e) => setMessage(null)}>People updated successfully</Alert> : <></>
            }
            <Box className="kmc-console-edit-employee-content-box">
                <Card className="kmc-console-edit-employee-info-card">
                    <Typography variant="h6">Assign Secondary Facilitator</Typography>
                </Card>
                <Card className="kmc-console-edit-employee-content-card">
                    <Box className="kmc-console-edit-employee-form-box">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <UserPicker label="Search People"
                                    onSelectSearchItem={onSelectEmployee}
                                >
                                </UserPicker>
                            </Grid>
                            {
                                employee && employee.uniqueId
                                    ? <ShowEmployeeDetails></ShowEmployeeDetails>
                                    : <></>
                            }
                            {
                                employee && employee.uniqueId
                                    ? <Grid item xs={12}>
                                        <Button variant="outlined" type="button" size="small"
                                            disabled={loading}
                                            sx={{ marginTop: "1rem" }}
                                            onClick={handleSubmit(handleOnSubmit)}
                                        >
                                            Add Secondary Manager
                                        </Button>
                                    </Grid>
                                    : <></>
                            }
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box >
    )
}
export default EditEmpProfile;
import React, { useEffect, useState } from 'react';
import { Box, Button, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography, Collapse } from '@mui/material';
import logo from '../../assets/logo1.png'
import {
    ExpandMore, ExpandLess, Logout, ManageAccounts, Workspaces, Badge,
    AdminPanelSettings, Lock, Construction, Twitter,
    DataArray,
    Paid,
    Place
} from '@mui/icons-material';
import './SideBar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import WorkspaceNodeGroup from '../workspace/WorkspaceNodeGroup';
import ManageMentNodeGroup from '../management/ManagementNodeGroup';
import BadgeNodeGroup from '../badgemanagement/BadgeNodeGroup';
import AdminManagementNodeGroup from '../adminmanagement/AdminManagementNodeGroup';
import ConfigManagementNodeGroup from '../configmanagement/ConfigManagementNodeGroup';
import SocialManagementNodeGroup from '../socialmanagement/SocialManagementNodeGroup';
import DocumentManagamentNodeGroup from '../documentmanagement/DocumentManagamentNodeGroup';
import TenantSettingsNodegroup from '../tenantsetttings/TenantSettingsNodeGroup';
import BillingManagementNodeGroup from '../billingmanagement/BillingManagementNodeGroup';
import PermissionManagementNodeGroup from '../permissionmanagement/PermissionManagementNodeGroup';
import LocationMangementNodeGroup from '../locationmanagement/LocationManagementNodeGroup';

const SideBar = (props) => {

    const { isPublished } = props;
    const { state } = useLocation();
    const [isWorkspaceExpand, setIsWorkspaceExpand] = useState(false);
    const [isManagementNodeExpand, setIsManagementNodeExapnd] = useState(false);
    const [isBadgeNodeExpand, setIsBadgeNodeExpand] = useState(false);
    const [isAdminManagementNodeExpand, setIsAdminManagementNodeExpand] = useState(false);
    const [isDocumentManagementNodeExpand, setIsDocumentManagementNodeExpand] = useState(false);
    const [isPermissionsManagementNodeExpand, setIsPermissionsManagementNodeExpand] = useState(false);
    const [isConfigManagementNodeExpand, setIsConfigManagementNodeExpand] = useState(false);
    const [isSocialManagementNodeExpand, setIsSocialManagementNodeExpand] = useState(false);
    const [isBillingManagementNodeExpand, setIsBillingManagementNodeExpand] = useState(false);
    const [isLocationManagementNodeExpand, setIsLocationManagementNodeExpand] = useState(false);
    const [showSettingsTab, setShowSettingsTab] = useState(false);

    useEffect(() => {
        if (state && state.isWorkspaceExpand) {
            setIsWorkspaceExpand(true);
        }
        if (state && state.isManagementNodeExpand) {
            setIsManagementNodeExapnd(true);
        }
        if (state && state.isBadgeNodeExpand) {
            setIsBadgeNodeExpand(true);
        }
        if (state && state.isAdminManagementNodeExpand) {
            setIsAdminManagementNodeExpand(true);
        }
        if (state && state.isDocumentManagementNodeExpand) {
            setIsDocumentManagementNodeExpand(true);
        }
        if (state && state.isPermissionsManagementNodeExpand) {
            setIsPermissionsManagementNodeExpand(true);
        }
        if (state && state.isConfigManagementNodeExpand) {
            setIsConfigManagementNodeExpand(true);
        }
        if (state && state.isSocialManagementNodeExpand) {
            setIsSocialManagementNodeExpand(true);
        }
        if (state && state.isBillingManagementNodeExpand) {
            setIsBillingManagementNodeExpand(true);
        }
        if (state && state.isLocationManagementNodeExpand) {
            setIsLocationManagementNodeExpand(true);
        }
        if (state && state.isSettingsTabOpen) {
            setShowSettingsTab(true);
        }
        if (state && Object.keys(state).length === 0) {
            handleInActiveGroups();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const handleWorkSpaceGroup = (event) => {
        event.preventDefault();
        setIsWorkspaceExpand(!isWorkspaceExpand);
        handleInActiveGroups();
    }

    const handleManagementGroup = (event) => {
        event.preventDefault();
        setIsManagementNodeExapnd(!isManagementNodeExpand);
        handleInActiveGroups();
    }

    const handleBadgeGroup = (event) => {
        event.preventDefault();
        setIsBadgeNodeExpand(!isBadgeNodeExpand);
        handleInActiveGroups();
    }

    const handleAdminManagementGroup = (event) => {
        event.preventDefault();
        setIsAdminManagementNodeExpand(!isAdminManagementNodeExpand);
        handleInActiveGroups();
    }

    const handleDocumentManagementGroup = (event) => {
        event.preventDefault();
        setIsDocumentManagementNodeExpand(!isDocumentManagementNodeExpand);
        handleInActiveGroups();
    }

    const handlePermissionsManagementGroup = (event) => {
        event.preventDefault();
        setIsPermissionsManagementNodeExpand(!isPermissionsManagementNodeExpand);
        handleInActiveGroups();
    }

    const handleConfigManagementGroup = (event) => {
        event.preventDefault();
        setIsConfigManagementNodeExpand(!isConfigManagementNodeExpand);
        handleInActiveGroups();
    }

    const handleSocialManagementGroup = (event) => {
        event.preventDefault();
        setIsSocialManagementNodeExpand(!isSocialManagementNodeExpand);
        handleInActiveGroups();
    }

    const handleBillingManagementGroup = (event) => {
        event.preventDefault();
        setIsBillingManagementNodeExpand(!isBillingManagementNodeExpand);
        handleInActiveGroups();
    }

    const handleLocationManagementGroup = (event) => {
        event.preventDefault();
        setIsLocationManagementNodeExpand(!isLocationManagementNodeExpand);
        handleInActiveGroups();
    }

    const handleInActiveGroups = () => {
        let allNodeGroups = [
            [isWorkspaceExpand, setIsWorkspaceExpand],
            [isManagementNodeExpand, setIsManagementNodeExapnd],
            [isBadgeNodeExpand, setIsBadgeNodeExpand],
            [isAdminManagementNodeExpand, setIsAdminManagementNodeExpand],
            [isDocumentManagementNodeExpand, setIsDocumentManagementNodeExpand],
            [isPermissionsManagementNodeExpand, setIsPermissionsManagementNodeExpand],
            [isConfigManagementNodeExpand, setIsConfigManagementNodeExpand],
            [isSocialManagementNodeExpand, setIsSocialManagementNodeExpand],
            [isBillingManagementNodeExpand, setIsBillingManagementNodeExpand],
            [isLocationManagementNodeExpand, setIsLocationManagementNodeExpand]
        ];
        // close the node groups that are currently active or open state
        allNodeGroups = allNodeGroups.filter(group => (group[0] === true));
        allNodeGroups.forEach(group => {
            const method = group[1];
            const prop = group[0];
            method(!prop);
        });
    }

    const handleSettingsTab = (event) => {
        if (!showSettingsTab) {
            setShowSettingsTab(!showSettingsTab);
            navigate("/settings/company", {
                state: { activeItemId: 51 }
            });
        }
    }

    const navigate = useNavigate();

    const handleLogout = (event) => {
        event.preventDefault();
        Auth.signOut().then(() => {
            navigate("/login");
            localStorage.clear();
        }).catch(err => {
            console.err(err);
        });
    }

    return (
        <Box sx={{ display: 'flex', height: "100% !important" }} className='kmc-console-sidebar-box'>
            <Drawer className='kmc-console-sidebar-drawer'
                sx={{
                    backgroundColor: '#fff',
                    width: "20%",
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: "15%",
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Box className='kmc-console-sidebar-main-head'>
                    <Box className='kmc-console-sidebar-main-head-root' onClick={() => {
                        setShowSettingsTab(false);
                        navigate('/home', { state: {} });
                    }}>
                        <img src={logo} alt={logo} 
                        style={{ clipPath: "circle()" }}
                            width={50} height={50}
                        >
                        </img>
                        <Box className='kmc-console-logo-box'>
                            <Typography variant='h6' className='kmc-console-logo-text'>Intra Crew</Typography>
                        </Box>
                    </Box>
                    <Box className='kmc-console-sidebar-buttons'>
                        <Button className={!showSettingsTab ? 'kmc-console-sidebar-edit-btn-active' : 'kmc-console-sidebar-edit-btn'} onClick={() => {
                            if (showSettingsTab) {
                                setShowSettingsTab(false);
                            }
                            navigate('/home');
                        }}
                        >
                            Tenant
                        </Button>
                        <Button className={showSettingsTab ? 'kmc-console-sidebar-settings-btn-active' : 'kmc-console-sidebar-settings-btn'}
                            onClick={handleSettingsTab}
                            disabled={!isPublished}
                        >
                            Settings
                        </Button>
                    </Box>
                </Box>
                <List className='kmc-console-sidebar-group kmc-console-sidebar-tenant-features-list' style={{ display: showSettingsTab ? 'none' : 'initial' }}>
                    <ListItemButton onClick={handleWorkSpaceGroup} className="kmc-console-workspace-group">
                        <ListItemIcon>
                            <Workspaces fontSize='large' className='kmc-console-workspace-group-li-text'></Workspaces>
                        </ListItemIcon>
                        <ListItemText primary={'Workspace'} className="kmc-console-workspace-group-li-text" />
                        {isWorkspaceExpand
                            ? <ExpandLess className='kmc-console-workspace-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-workspace-group-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isWorkspaceExpand} timeout="auto" unmountOnExit>
                        <WorkspaceNodeGroup></WorkspaceNodeGroup>
                    </Collapse>
                    <ListItemButton onClick={handleManagementGroup} className="kmc-console-management-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <ManageAccounts fontSize='large' className='kmc-console-management-group-li-text'></ManageAccounts>
                        </ListItemIcon>
                        <ListItemText primary={'People Management'} className="kmc-console-management-group-li-text" />
                        {isManagementNodeExpand
                            ? <ExpandLess className='kmc-console-management-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-management-group-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isManagementNodeExpand} timeout="auto" unmountOnExit>
                        <ManageMentNodeGroup></ManageMentNodeGroup>
                    </Collapse>

                    <ListItemButton onClick={handleBadgeGroup} className="kmc-console-badge-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <Badge fontSize='large' className='kmc-console-badge-group-li-text'></Badge>
                        </ListItemIcon>
                        <ListItemText primary={'Badge Management'} className="kmc-console-badge-group-li-text" />
                        {isBadgeNodeExpand
                            ? <ExpandLess className='kmc-console-badge-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-badge-group-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isBadgeNodeExpand} timeout="auto" unmountOnExit>
                        <BadgeNodeGroup></BadgeNodeGroup>
                    </Collapse>

                    <ListItemButton onClick={handleAdminManagementGroup} className="kmc-console-admin-management-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <AdminPanelSettings fontSize='large' className='kmc-console-admin-management-group-li-text'></AdminPanelSettings>
                        </ListItemIcon>
                        <ListItemText primary={'Admin Management'} className="kmc-console-admin-management-group-li-text" />
                        {isAdminManagementNodeExpand
                            ? <ExpandLess className='kmc-console-admin-management-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-admin-management-group-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isAdminManagementNodeExpand} timeout="auto" unmountOnExit>
                        <AdminManagementNodeGroup></AdminManagementNodeGroup>
                    </Collapse>

                    <ListItemButton onClick={handleDocumentManagementGroup} className="kmc-console-doc-management-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <DataArray fontSize='large' className='kmc-console-doc-management-group-li-text'></DataArray>
                        </ListItemIcon>
                        <ListItemText primary={'Data Management'} className="kmc-console-doc-management-group-li-text" />
                        {isDocumentManagementNodeExpand
                            ? <ExpandLess className='kmc-console-doc-management-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-doc-management-group-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isDocumentManagementNodeExpand} timeout="auto" unmountOnExit>
                        <DocumentManagamentNodeGroup></DocumentManagamentNodeGroup>
                    </Collapse>

                    <ListItemButton onClick={handlePermissionsManagementGroup} className="kmc-console-perm-management-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <Lock fontSize='large' className='kmc-console-perm-management-group-li-text'></Lock>
                        </ListItemIcon>
                        <ListItemText primary={'Permissions Management'} className="kmc-console-perm-management-group-li-text" />
                        {isPermissionsManagementNodeExpand
                            ? <ExpandLess className='kmc-console-perm-management-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-perm-management-group-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isPermissionsManagementNodeExpand} timeout="auto" unmountOnExit>
                        <PermissionManagementNodeGroup />
                    </Collapse>

                    <ListItemButton onClick={handleConfigManagementGroup} className="kmc-console-config-management-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <Construction fontSize='large' className='kmc-console-config-management-group-li-text'></Construction>
                        </ListItemIcon>
                        <ListItemText primary={'Configurations Management'} className="kmc-console-config-management-group-li-text" />
                        {isConfigManagementNodeExpand
                            ? <ExpandLess className='kmc-console-config-management-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-badge-config-management-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isConfigManagementNodeExpand} timeout="auto" unmountOnExit>
                        <ConfigManagementNodeGroup></ConfigManagementNodeGroup>
                    </Collapse>

                    <ListItemButton onClick={handleSocialManagementGroup} className="kmc-console-social-management-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <Twitter fontSize='large' className='kmc-console-social-management-group-li-text'></Twitter>
                        </ListItemIcon>
                        <ListItemText primary={'Social Management'} className="kmc-console-social-management-group-li-text" />
                        {isSocialManagementNodeExpand
                            ? <ExpandLess className='kmc-console-social-management-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-social-management-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isSocialManagementNodeExpand} timeout="auto" unmountOnExit>
                        <SocialManagementNodeGroup></SocialManagementNodeGroup>
                    </Collapse>

                    <ListItemButton onClick={handleBillingManagementGroup} className="kmc-console-billing-management-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <Paid fontSize='large' className='kmc-console-billing-management-group-li-text'></Paid>
                        </ListItemIcon>
                        <ListItemText primary={'Billing Management'} className="kmc-console-billing-management-group-li-text" />
                        {isBillingManagementNodeExpand
                            ? <ExpandLess className='kmc-console-billing-management-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-billing-management-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isBillingManagementNodeExpand} timeout="auto" unmountOnExit>
                        <BillingManagementNodeGroup></BillingManagementNodeGroup>
                    </Collapse>

                    <ListItemButton onClick={handleLocationManagementGroup} className="kmc-console-billing-management-group" disabled={!isPublished}>
                        <ListItemIcon>
                            <Place fontSize='large' className='kmc-console-location-management-group-li-text'></Place>
                        </ListItemIcon>
                        <ListItemText primary={'Location Management'} className="kmc-console-location-management-group-li-text" />
                        {isLocationManagementNodeExpand
                            ? <ExpandLess className='kmc-console-location-management-group-dropdown-icon' />
                            : <ExpandMore className='kmc-console-location-management-dropdown-icon' />}
                    </ListItemButton>
                    <Collapse in={isLocationManagementNodeExpand} timeout="auto" unmountOnExit>
                        <LocationMangementNodeGroup></LocationMangementNodeGroup>
                    </Collapse>

                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </ListItemButton>
                </List>
                <List className="kmc-console-sidebar-group kmc-console-sidebar-tenant-details-list"
                    sx={{ display: !showSettingsTab ? 'none' : 'initial' }}
                >
                    <TenantSettingsNodegroup></TenantSettingsNodegroup>
                </List>
            </Drawer>
        </Box>
    );
}
export default SideBar;



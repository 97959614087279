import { Code, Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, Container, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import Loader from "../../atoms/loader/Loader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import backgroundLogoImage from "../../../src/assets/flight.png";
import "./Auth.css";

Amplify.configure({
    Auth: environment.cognito
});

const ResetLoginPassword = (props) => {

    const { state } = useLocation();
    const username = state && state.username;
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);

    const handleOnSubmit = (data) => {
        if (data) {
            setLoading(true);
            Auth.forgotPasswordSubmit(username, data.code, data.password)
                .then(data => {
                    setLoading(false);
                    navigate("/login");
                }).catch(err => {
                    setLoading(false);
                    setError(err.message);
                });
        }
    };

    const togglePasswordHide = (event, type) => {
        event.preventDefault();
        if (type === 'password') {
            setShowPassword(!showPassword);
        }
        if (type === 'confirmPassword') {
            setConfirmPassword(!confirmPassword);
        }
    };

    // form validation rules 
    const validationSchema = Yup.object().shape({
        code: Yup.string().required(),
        password: Yup.string().required(),
        confirmPassword: Yup.string()
            .required()
            .oneOf([Yup.ref("password")], "Passwords must match.")
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, register, formState } = useForm(formOptions);
    const { errors } = formState;

    return (
        <>
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : <></>}
            <Box className="kmc-console-signup-main-box kmc-customheight100">
                <Container component="main" className="kmc-customheight100">
                    <Box className="kmc-loginbox">
                        <div className="kmc-customdiv">
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <Code />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Reset Login Password
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            name="email"
                                            value={username}
                                            InputLabelProps={{ shrink: true }}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="code"
                                            InputLabelProps={{ shrink: true }}
                                            label="Verification Code"
                                            name="code"
                                            {...register("code")}
                                            error={errors?.code ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            id="password"
                                            label="New Password"
                                            name="password"
                                            {...register("password")}
                                            error={errors?.password ? true : false}
                                            type={showPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {" "}
                                                        {showPassword ? (
                                                            <Visibility
                                                                className="cursor_pointer"
                                                                onClick={(event) => togglePasswordHide(event, 'password')}
                                                            />
                                                        ) : (
                                                            <VisibilityOff onClick={(event) => togglePasswordHide(event, 'password')} />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            id="confirmPassword"
                                            label="Confirm New Password"
                                            name="confirmPassword"
                                            {...register("confirmPassword")}
                                            error={errors?.confirmPassword ? true : false}
                                            type={confirmPassword ? "text" : "password"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {" "}
                                                        {confirmPassword ? (
                                                            <Visibility
                                                                className="cursor_pointer"
                                                                onClick={(event) => togglePasswordHide(event, 'confirmPassword')}
                                                            />
                                                        ) : (
                                                            <VisibilityOff onClick={(event) => togglePasswordHide(event, 'confirmPassword')} />
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            disabled={loading}
                                        >
                                            Reset Password
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Box>
                    <Box>
                        <div className="kmc-console-login-background-box">
                            <img src={backgroundLogoImage} alt={'bg-logo'} className="kmc-loginbg"></img>
                        </div>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default ResetLoginPassword;
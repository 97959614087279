import { CheckCircle } from '@mui/icons-material';
import { Alert, Button, Card, CardContent, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SecureLS from 'secure-ls';
import { environment } from '../../assets/configurations/configuration';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import HourglassGIF from '../../assets/Hourglass.gif';
import PublishedIcon from '../../assets/Published.png';
import './PublishTenent.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PublishDomainSection from '../../molecules/PublishDomainSection';
import PublishDNSConfigurationSection from '../../molecules/PublishDNSConfigurationSection';
import PublishLinkedSubDomainPaginationTable from '../../molecules/PublishLinkedSubDomainPaginationTable';

const Publish = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const navigate = useNavigate();
    const [progress, setProgress] = useState(10);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [publishUrl, setPublishUrl] = useState(_secureStore.get("tenantPublishUrl"));
    const [publishActionExecuted, setPublishActionExecuted] = useState(_secureStore.get("isPublishActionTriggered"));
    const [lastPublishTime, setPublishTime] = useState(_secureStore.get("tenantLastPublishedTime"));

    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, control, register, setValue } = useForm(formOptions, {
        defaultValues: {
            is_cache_policy: false,
            invalidation: false
        }
    });
    const [cnames, setCNames] = useState([]);
    const [linkStatus, setLinkStatus] = useState({});
    const [status, setStatus] = useState("");

    useEffect(() => {
        if (!tenant) {
            navigate('/workspace/register', {
                state: {
                    message: "You must register domain.",
                    isWorkspaceExpand: true
                }
            });
        }
        if (loading) {
            setProgress(10);
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
            }, 500);
            return () => {
                clearInterval(timer);
            };
        }
        // make the button disabled for 2 minutes and update user
        // with the details
        if (publishActionExecuted) {
            setTimeout(() => {
                const tenantPublishStatus = {
                    tenant_name: tenant,
                    isPublished: true
                }
                _secureStore.set("tenantPublishStatus", tenantPublishStatus);
                _secureStore.set("tenant_name", tenant);
                _secureStore.set("isPublishActionTriggered", false);
                _secureStore.set("tenantPublishUrl", environment.stage === 'prod' ?
                    tenant + ".intracrew.com"
                    : tenant + "." + environment.stage + ".intracrew.com");
                _secureStore.set("tenantLastPublishedTime", new Date().toLocaleString());
                window.location.reload();
            }, 120000); // 2 minutes // 120000
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant, loading, publishUrl]);

    const [mount, setMount] = useState(false);
    useEffect(() => {
        if (!mount) {
            axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/domain_caching`)
                .then(res => {
                    setMount(true);
                    if (res && res.data) {
                        setValue('is_cache_policy', res.data?.is_cache_policy);
                        setValue('invalidation', res.data?.invalidation);
                    }
                })
                .catch(err => {
                    setError(err && err?.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant]);

    const Progress = () => {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex', marginTop: "2rem" }}>
                <CircularProgress variant="determinate" value={progress} size={100} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(progress)}%`}
                    </Typography>
                </Box>
            </Box>
        )
    }

    const handlePublish = (data) => {
        const config = {
            tenant_name: tenant,
            is_cache_policy: data.is_cache_policy,
            invalidation: data.invalidation
        };
        setPublishUrl("");
        setPublishTime("");
        setLoading(true);
        axiosInstance.post(environment.PUBLISH_API, config)
            .then(res => {
                setPublishActionExecuted("true");
                _secureStore.set("isPublishActionTriggered", true);
                _secureStore.set("tenantPublishUrl", "");
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setError("Something went wrong during publish.");
                setLoading(false);
            });
    }

    return (
        <Box className="kmc-console-publish-main-box">
            {
                error ? <Alert color='error' onClose={() => setError(null)}>{error}</Alert> : <></>
            }
            <Box className="kmc-console-publish-content-box">
                <Card className="kmc-console-publish-info-card">
                    <Typography variant="h6" >Manage workspace deployment</Typography>
                    <Typography variant="h6">Publish workspace with current changes</Typography>
                </Card>
                <Card className="kmc-console-publish-content-card">
                    <CardContent>
                        {
                            publishUrl
                                ?
                                <Box className="kmc-console-publish-tenant-success-card">
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <img src={PublishedIcon} alt={"publish"} height={100} width={100}></img>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1} sx={{ textAlign: "left" }}>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontWeight: "550", fontSize: '13px' }}>Workspace Status:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CheckCircle sx={{ color: 'green', fontSize: '15px' }}></CheckCircle>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontWeight: "550", fontSize: '13px' }}>Workspace Link:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <a style={{ color: "cornflowerblue", fontSize: '15px' }} href={"https://" + publishUrl} target="_blank" rel="noreferrer">Go to workspace</a>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontWeight: "550", fontSize: '13px' }}>Last Published Time:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontSize: '14px' }}> {lastPublishTime}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                                : <Typography variant="h6" sx={{ fontSize: "medium" }}>This action takes approximately 15 minutes to publish the workspace.</Typography>
                        }
                        {
                            publishActionExecuted ?
                                <Box sx={{ marginTop: "1rem" }}>
                                    <img src={HourglassGIF} alt={"hourglass"} width={100} height={100}></img>
                                </Box>
                                : <></>
                        }
                        <Box>
                            {
                                loading ?
                                    <>
                                        <Progress variant='determinate' value={progress} fontSize="large" sx={{ marginTop: "1rem" }}></Progress>
                                    </>
                                    : <></>
                            }
                        </Box>
                        <Box component={"form"} onSubmit={handleSubmit(handlePublish)} sx={{ marginTop: "1rem" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name={"is_cache_policy"}
                                        render={({ field: { value, ...field } }) => (
                                            <FormGroup>
                                                <FormControlLabel label={"Enable Server Cache"}
                                                    sx={{ justifyContent: "end" }}
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            {...register("is_cache_policy")}
                                                            checked={!!value}
                                                            color="primary"
                                                            size={"small"}
                                                            disableRipple
                                                        />
                                                    }
                                                >
                                                </FormControlLabel>
                                            </FormGroup>
                                        )}
                                    >
                                    </Controller>
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name={"invalidation"}
                                        render={({ field: { value, ...field } }) => (
                                            <FormGroup>
                                                <FormControlLabel label={"Invalidate Server Cache"}
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            {...register("invalidation")}
                                                            checked={!!value}
                                                            color="primary"
                                                            size={"small"}
                                                            disableRipple
                                                        />
                                                    }
                                                >
                                                </FormControlLabel>
                                            </FormGroup>
                                        )}
                                    >
                                    </Controller>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="outlined" type="submit" size="small"
                                        sx={{ marginTop: "1rem" }}
                                        disabled={publishActionExecuted ? true : false}
                                    >
                                        {publishActionExecuted ? "PUBLISHING" : "PUBLISH"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
                {
                    <>
                        {
                            props?.isPublished
                                ? <Card className='kmc-console-publish-content-card'>
                                    <PublishDomainSection tenant={tenant}
                                        handleGetCNames={(cnames) => {
                                            setCNames(cnames);
                                        }}
                                        handleGetLinkStatus={(status) => {
                                            setLinkStatus(status);
                                        }}
                                        handleDomainStatus={(status) => {
                                            setStatus(status);
                                        }}
                                    />
                                </Card>
                                : null
                        }
                        {
                            Object.keys(linkStatus).length > 0
                                ? <Card className='kmc-console-publish-content-card'>
                                    <PublishDNSConfigurationSection tenant={tenant} linkStatus={linkStatus} status={status} />
                                </Card>
                                : null
                        }
                        {
                            cnames && cnames.length > 0
                                ? <Card className='kmc-console-publish-content-card'>
                                    <PublishLinkedSubDomainPaginationTable tenant={tenant} cnames={cnames} />
                                </Card>
                                : null
                        }
                    </>
                }
            </Box>
        </Box >
    )
}

export default Publish;
import React, { useEffect, useRef, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { GridViewOutlined, CheckCircle, Cancel, HourglassTop, MapSharp, Payment, Public, Key, NextPlan, DocumentScanner, BrandingWatermark } from '@mui/icons-material';
import './WorkspaceNodeGroup.css';
import { useLocation, useNavigate } from 'react-router-dom';
import SecureLS from 'secure-ls';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import { environment } from '../../assets/configurations/configuration';

const WorkspaceNodeGroup = (props) => {

    const navigate = useNavigate();
    const state = useLocation();
    const [active, setActive] = useState(false);

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const email = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const tenantPublishStatus = _secureStore.get("tenantPublishStatus");
    const _ref = useRef();

    const brandingLogoCheckStore = _secureStore.get("isBrandingLogoCompleted");
    const planDetailsCheckStore = _secureStore.get("isPlanDetailsCompleted");
    const credentialCheckStore = _secureStore.get("isCredentialCompleted");
    const paymentCheckStore = _secureStore.get("isPaymentCompleted");
    const employeeDataStore = _secureStore.get("isEmployeeDataCompleted");
    const mappingsCheckStore = _secureStore.get("isMappingsCompleted");

    const [brandingCompleteCheck, setBrandingCompleteCheck] = useState(false);
    const [brandingLoading, setBrandingLoading] = useState(false);

    const [planDetailsCheck, setPlanDetailsCheck] = useState(false);
    const [planLoading, setPlanLoading] = useState(false);

    const [credentialsCheck, setCredentialsCheck] = useState(false);
    const [credentialsLoading, setCredentialsLoading] = useState(false);

    const [paymentCheck, setPaymentCheck] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);

    const [employeeDataLoading, setEmployeeDataLoading] = useState(false);
    const [employeeDataCheck, setEmployeeDataCheck] = useState(false);

    const [mappingsCheck, setMappingsCheck] = useState(false);
    const [mappingsLoading, setMappingsLoading] = useState(false);

    const [tenantPublishLoading, setTenantPublishLoading] = useState(false);
    const [tenantPublishCheck, setTenantPublishCheck] = useState(false);

    useEffect(() => {
        const activeItemId = state && state.state && state.state.activeItemId;
        if (activeItemId) {
            setActive(activeItemId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (brandingLogoCheckStore) {
            setBrandingCompleteCheck(brandingLogoCheckStore);
            setBrandingLoading(brandingLogoCheckStore);
            setTimeout(() => {
                setBrandingLoading(false);
            }, 1500);
        }

        if (planDetailsCheckStore) {
            setPlanDetailsCheck(planDetailsCheckStore);
            setPlanLoading(planDetailsCheckStore);
            setTimeout(() => {
                setPlanLoading(false);
            }, 1500);
        }

        if (credentialCheckStore) {
            setCredentialsCheck(credentialCheckStore);
            setCredentialsLoading(credentialCheckStore);
            setTimeout(() => {
                setCredentialsLoading(false);
            }, 1500);
        }

        if (employeeDataStore) {
            setEmployeeDataCheck(employeeDataStore);
            setEmployeeDataLoading(employeeDataStore);
            setTimeout(() => {
                setEmployeeDataLoading(false);
            }, 1500);
        }

        if (paymentCheckStore) {
            setPaymentCheck(paymentCheckStore);
            setPaymentLoading(paymentCheckStore);
            setTimeout(() => {
                setPaymentLoading(false);
            }, 1500);
        }

        if (mappingsCheckStore) {
            setMappingsCheck(mappingsCheckStore);
            setMappingsLoading(mappingsCheckStore);
            setTimeout(() => {
                setMappingsLoading(false);
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandingLogoCheckStore, planDetailsCheckStore, credentialCheckStore, employeeDataStore, paymentCheckStore, mappingsCheckStore]);

    useEffect(() => {
        if (_ref) {
            sendBrandingLogoGetRequest();
            sendPlansDetailsGetRequest();
            sendPaymentDetailsGetRequest();
            sendCrendentialsGetRequest();
            sendEmployeeDataGetRequest();
            sendMappingsGetRequest();
            getTenantPublishStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_ref])


    const sendBrandingLogoGetRequest = async () => {
        try {
            setBrandingLoading(true);
            const resp = await axiosInstance
                .get(`${environment.BRANDING_LOGO_API}/${tenant}/visibility/private/document/console/id_type/images/id/logo_image`);
            if (resp) {
                setBrandingLoading(false);
                setBrandingCompleteCheck(true);
            }
        } catch (err) {
            setBrandingLoading(false);
            setBrandingCompleteCheck(false);
        }
    }

    const sendPlansDetailsGetRequest = async () => {
        try {
            setPlanLoading(true);
            const resp = await axiosInstance.get(`${environment.PLAN_DETAILS}/${tenant}/configurationGroup/plan_details`);
            if (resp && resp.data) {
                setPlanDetailsCheck(true);
            } else {
                setPlanDetailsCheck(false);
            }
            setPlanLoading(false);
        } catch (err) {
            setPlanLoading(false);
            setPlanDetailsCheck(false);
        }
    }

    const sendPaymentDetailsGetRequest = async () => {
        try {
            setPaymentLoading(true);
            const resp = await axiosInstance.get(`${environment.PAYMENT_DETAILS}/${tenant}/configurationGroup/payment_details`);
            if (resp && resp.data) {
                setPaymentCheck(true);
            } else {
                setPaymentCheck(false);
            }
            setPaymentLoading(false);
        } catch (err) {
            setPaymentLoading(false);
            setPaymentCheck(false);
        }
    }

    const sendCrendentialsGetRequest = async () => {
        try {
            setCredentialsLoading(true);
            const resp = await axiosInstance.get(`${environment.CONSOLE_CREDENTIALS}/${tenant}/configurationGroup/console_credentials`);
            if (resp && resp.data) {
                setCredentialsCheck(true);
            } else {
                setCredentialsCheck(false);
            }
            setCredentialsLoading(false);
        } catch (err) {
            setCredentialsLoading(false);
            setCredentialsCheck(false);
        }
    }

    const sendEmployeeDataGetRequest = async () => {
        try {
            setEmployeeDataLoading(true);
            const config = {
                headers: {
                    "requestagentid": email
                }
            }
            const resp = await axiosInstance.get(environment.GET_EMPLOYEE_COUNT_API, config);
            if (resp && resp.data) {
                setEmployeeDataLoading(false);
                const { employee } = resp.data;
                if (employee > 0) {
                    console.log("Found employee count " + employee + " in current org");
                    setEmployeeDataCheck(true);
                } else {
                    setEmployeeDataCheck(false);
                }
            }
        } catch {
            setEmployeeDataCheck(false);
            setEmployeeDataLoading(false);
        }
    }

    const sendMappingsGetRequest = async () => {
        try {
            setMappingsLoading(true);
            const resp = await axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/mapping_attributes`);
            if (resp && resp.data) {
                setMappingsLoading(false);
                if (resp.data.mapping_attributes && Object.entries(resp.data.mapping_attributes).length > 0) {
                    console.log("Found mappings count " + Object.entries(resp.data.mapping_attributes).length + " in current org");
                    setMappingsCheck(true);
                } else {
                    setMappingsCheck(false);
                }
            } else {
                setMappingsLoading(false);
                setMappingsCheck(false);
            }
        } catch {
            setMappingsCheck(false);
            setMappingsLoading(false);
        }
    }

    const getTenantPublishStatus = () => {
        try {
            setTenantPublishLoading(true);
            setTimeout(() => {
                const isPublished = tenantPublishStatus.isPublished;
                if (isPublished) {
                    setTenantPublishCheck(true);
                } else {
                    setTenantPublishCheck(false);
                }
                setTenantPublishLoading(false);
            }, 1200);
        }
        catch {
            setTenantPublishCheck(false);
            setTenantPublishLoading(false);
        }
    }

    return (
        <List component="div" disablePadding>
            <ListItemButton id="991"
                className={(active === 991) ? "kmc-console-workspace-litem-active" : ""}
                onClick={() => {
                    navigate('/workspace/register', { state: { isWorkspaceExpand: true }, replace: true });
                    setActive(991);
                }
                }>
                <ListItemIcon>
                    <GridViewOutlined fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Tenant'} />
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {
                        tenant ? <CheckCircle style={{ color: "green" }}></CheckCircle>
                            : <Cancel style={{ color: "#ab3e3e" }}></Cancel>
                    }
                </ListItemIcon>
            </ListItemButton>
            <ListItemButton id="992"
                className={(active === 992) ? "kmc-console-workspace-litem-active" : ""}
                onClick={() => {
                    navigate('/workspace/customize', { state: { isWorkspaceExpand: true }, replace: true });
                    setActive(992);
                }
                }>
                <ListItemIcon>
                    <BrandingWatermark />
                </ListItemIcon>
                <ListItemText primary={'Branding'} />
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {
                        brandingLoading
                            ? <HourglassTop style={{ color: "#FFAD00" }}></HourglassTop>
                            : brandingCompleteCheck ?
                                <CheckCircle style={{ color: "green" }}></CheckCircle>
                                : <Cancel style={{ color: "#ab3e3e" }}></Cancel>
                    }
                </ListItemIcon>
            </ListItemButton>
            <ListItemButton id="993"
                className={(active === 993) ? "kmc-console-workspace-litem-active" : ""}
                onClick={() => {
                    navigate('/workspace/upload/employee', { state: { isWorkspaceExpand: true }, replace: true });
                    setActive(993);
                }
                }>
                <ListItemIcon>
                    <DocumentScanner fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'People Data'} />
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {
                        employeeDataLoading
                            ? <HourglassTop style={{ color: "#FFAD00" }}></HourglassTop>
                            : employeeDataCheck ?
                                <CheckCircle style={{ color: "green" }}></CheckCircle>
                                : <Cancel style={{ color: "#ab3e3e" }}></Cancel>
                    }
                </ListItemIcon>
            </ListItemButton>
            <ListItemButton id="994"
                className={(active === 994) ? "kmc-console-workspace-litem-active" : ""}
                onClick={() => {
                    navigate('/workspace/plan', { state: { isWorkspaceExpand: true }, replace: true });
                    setActive(994);
                }
                }>
                <ListItemIcon>
                    <NextPlan fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Plan Details'} />
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {
                        planLoading
                            ? <HourglassTop style={{ color: "#FFAD00" }}></HourglassTop>
                            : planDetailsCheck ?
                                <CheckCircle style={{ color: "green" }}></CheckCircle>
                                : <Cancel style={{ color: "#ab3e3e" }}></Cancel>
                    }
                </ListItemIcon>
            </ListItemButton>
            <ListItemButton id="995"
                className={(active === 995) ? "kmc-console-workspace-litem-active" : ""}
                onClick={() => {
                    navigate('/workspace/credentials', { state: { isWorkspaceExpand: true }, replace: true });
                    setActive(995);
                }
                }>
                <ListItemIcon>
                    <Key fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Credentials'} />
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {
                        credentialsLoading
                            ? <HourglassTop style={{ color: "#FFAD00" }}></HourglassTop>
                            : credentialsCheck ?
                                <CheckCircle style={{ color: "green" }}></CheckCircle>
                                : <Cancel style={{ color: "#ab3e3e" }}></Cancel>
                    }
                </ListItemIcon>
            </ListItemButton>
            <ListItemButton id="996"
                className={(active === 996) ? "kmc-console-workspace-litem-active" : ""}
                onClick={() => {
                    navigate('/workspace/payment', { state: { isWorkspaceExpand: true }, replace: true });
                    setActive(996);
                }
                }>
                <ListItemIcon>
                    <Payment fontSize='medium'></Payment>
                </ListItemIcon>
                <ListItemText primary={'Payment'} />
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {
                        paymentLoading
                            ? <HourglassTop style={{ color: "#FFAD00" }}></HourglassTop>
                            : paymentCheck ?
                                <CheckCircle style={{ color: "green" }}></CheckCircle>
                                : <Cancel style={{ color: "#ab3e3e" }}></Cancel>
                    }
                </ListItemIcon>
            </ListItemButton>
            <ListItemButton id="997"
                className={(active === 997) ? "kmc-console-workspace-litem-active" : ""}
                onClick={() => {
                    navigate('/workspace/mapping', { state: { isWorkspaceExpand: true }, replace: true });
                    setActive(997);
                }
                }>
                <ListItemIcon>
                    <MapSharp fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Mapping'} />
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {
                        mappingsLoading
                            ? <HourglassTop style={{ color: "#FFAD00" }}></HourglassTop>
                            : mappingsCheck ?
                                <CheckCircle style={{ color: "green" }}></CheckCircle>
                                : <Cancel style={{ color: "#ab3e3e" }}></Cancel>
                    }
                </ListItemIcon>
            </ListItemButton>
            <ListItemButton id="998"
                className={(active === 998) ? "kmc-console-workspace-litem-active" : ""}
                onClick={() => {
                    navigate('/workspace/publish', { state: { isWorkspaceExpand: true }, replace: true });
                    setActive(998);
                }
                }>
                <ListItemIcon>
                    <Public fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Publish'} />
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {
                        tenantPublishLoading
                            ? <HourglassTop style={{ color: "#FFAD00" }}></HourglassTop>
                            : tenantPublishCheck ?
                                <CheckCircle style={{ color: "green" }}></CheckCircle>
                                : <Cancel style={{ color: "#ab3e3e" }}></Cancel>
                    }
                </ListItemIcon>
            </ListItemButton>
        </List>
    )
}

export default WorkspaceNodeGroup;
import { Download, Info } from "@mui/icons-material";
import InvoicePaidIcon from "../../assets/InvoicePaid.png";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";

const InvoiceItem = (props) => {

    const { invoice } = props;

    const cardContentFields = [
        {
            top: [
                {
                    id: "orderNumber",
                    name: "Order Number",
                    value: invoice?.id
                },
                {
                    id: "createdTime",
                    name: "Created Time",
                    value: new Date(invoice?.created * 1000).toDateString()
                },
                {
                    id: "renewedOn",
                    name: "Renewed On",
                    value: new Date(invoice?.period_start * 1000).toDateString()
                },
                {
                    id: "expiresOn",
                    name: "Expires On",
                    value: new Date(invoice?.period_end * 1000).toDateString()
                }
            ]
        },
        {
            center: [
                {
                    id: "userEmail",
                    name: "User Email",
                    value: invoice?.customer_email
                },
                {
                    id: "billingReason",
                    name: "Billing Reason",
                    value: invoice?.billing_reason
                },
                {
                    id: "amount",
                    name: "Amount",
                    value: invoice?.amount_paid + " " + (invoice?.currency).toUpperCase()
                },
                {
                    id: "name",
                    name: "Name",
                    value: invoice?.customer_name
                }
            ]
        }
    ];

    const handleInvoiceDownload = (event, invoice) => {
        event.preventDefault();        
        const document_link = invoice?.invoice_pdf;
        const link = document.createElement('a');
        link.href = document_link;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleInvoiceViewDetails = (event, invoice) => {
        event.preventDefault();
        const document_details_link = document.createElement('a');
        document_details_link.href = invoice?.hosted_invoice_url;
        document_details_link.target = "_blank";
        document_details_link.ref = "noreferrer";
        document.body.appendChild(document_details_link);
        document_details_link.click();
        document.body.removeChild(document_details_link);
    }

    return (
        <Card className="kmc-console-invoice-item">
            <CardContent sx={{ backgroundColor: "#f1f0f0" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {
                                cardContentFields[0]['top'].map((header) => {
                                    return (
                                        <Grid item xs={3} key={header?.id} sx={{ textAlign: "start" }}>
                                            <Typography sx={{ color: "#5A6DA7", fontWeight: "600", fontSize: "14px" }}>{header.name}</Typography>
                                            <Typography sx={{ color: "#8b8787", fontWeight: "550", fontSize: "12px" }}>{header.value}</Typography>
                                        </Grid>
                                    )
                                })

                            }
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={3} sx={{ textAlign: "start" }}>
                        <img src={InvoicePaidIcon} alt={"invoice_paid"}></img>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            {
                                cardContentFields[1]['center'].map((header) => {
                                    return (
                                        <Grid item xs={6} key={header?.id} sx={{ textAlign: "start" }}>
                                            <Typography sx={{ color: "#5A6DA7", fontWeight: "600", fontSize: "13px" }}>{header.name}</Typography>
                                            <Typography sx={{ color: "#716464", fontSize: "13px" }}>{header.value}</Typography>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: "start"}}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Button                                    
                                    fullWidth
                                    sx={{textTransform: 'none'}}
                                    variant="outlined"
                                    color="primary"
                                    size={"small"}
                                    startIcon={<Info fontSize="large"></Info>}
                                    onClick={(event) => handleInvoiceViewDetails(event, invoice)}
                                >
                                    View Details
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    sx={{textTransform: 'none'}}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Download fontSize="large"></Download>}
                                    size={"small"}
                                    onClick={(event) => handleInvoiceDownload(event, invoice)}
                                >
                                    Download
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default InvoiceItem;
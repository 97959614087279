import { UploadFileRounded } from '@mui/icons-material';
import {
    Alert,
    Button, Card, CardContent, Chip,
    Grid, MenuItem, TextField, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useRef, useState } from 'react';
import './CsvUpload.css';
import { Controller, useForm } from "react-hook-form";
import { axiosInstance } from '../interceptors/axios/AxiosInstance';
import { environment } from '../assets/configurations/configuration';
import SecureLS from 'secure-ls';
import Loader from '../atoms/loader/Loader';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { mappings } from './CsvUploadMappingItems';
import * as XLSX from "xlsx";
import { useNavigate } from 'react-router-dom';

const ExcelUpload = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const [file, setFile] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const navigate = useNavigate();
    const fileRef = useRef();

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        contactEmail: Yup.string().required(),
        //latitude: Yup.string().required(),
        //longitude: Yup.string().required(),
        locationName: Yup.string().required(),
        parentId: Yup.string().required(),
        referenceObjectGuid: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { handleSubmit, register, reset, control, formState } = useForm(formOptions, {
        defaultValues: {
            firstName: "",
            lastName: "",
            company: "",
            contactEmail: "",
            latitude: "",
            longitude: "",
            locationName: "",
            parentId: "",
            referenceObjectGuid: ""
        }
    });

    const { errors } = formState;

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = (event) => {
                const result = event.target?.result;
                const workbook = XLSX.read(result, { type: "binary" });
                const sheetsList = workbook.SheetNames;
                const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetsList[0]], { header: 1, defval: "" });
                // first row will be treated as a header.
                const headers = worksheet[0];
                setHeaders(headers);
            };
        }
    }

    const handleOnSubmit = (data) => {
        if (data) {
            let config = {
                headers: {
                    filename: file.name,
                    'Content-Type': file.type
                }
            }
            setLoading(true);
            axiosInstance.post(
                `${environment.EMPLOYEE_CSV_FILE_UPLOAD_API}/${tenant}/visibility/private/document/console/id_type/xlsx/id/employee`,
                file, config).then(res => {
                    // continue to call the crawl api
                    // for storing the unique data
                    setLoading(false);
                    if (res) {
                        const crawl_data = {
                            entitytype: "Employee",
                            useruuid: _secure_user_info && _secure_user_info.attributes['email'],
                            mappings: data
                        };
                        setLoading(true);
                        axiosInstance.post(environment.CRAWL_EXCEL, crawl_data)
                            .then(res => {
                                setLoading(false);
                                setFile(null);
                                setHeaders([]);
                                reset();
                                setMessage("Excel file has been uploaded successfully.");
                                updateCompletionCheck(true);
                                navigate('/workspace/plan', {
                                    state: {
                                        isWorkspaceExpand: true,
                                        activeItemId: 994
                                    }
                                });
                            }).catch(err => {
                                console.log(err);
                                setError(err && err.message);
                                setLoading(false);
                            });
                    }
                }).catch(err => {
                    setError(err && err.message);
                });
        }
    }

    const updateCompletionCheck = (flag) => {
        _secureStore.set("isEmployeeDataCompleted", flag);
    }

    const MappingFields = () => {
        return (
            <>
                <Grid item xs={12}>
                    <Chip label={"Map auto populated headers with attributes"}
                        color="primary"
                        variant='outlined'
                    >
                    </Chip>
                </Grid>
                {
                    mappings ? mappings.map((mapping, key) => {
                        const { label, name } = mapping;
                        return (
                            <Grid item xs={12} sx={{ textAlign: "start" }} key={key}>
                                <Controller
                                    key={name}
                                    control={control}
                                    name={name}
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            fullWidth
                                            {...field}
                                            {...register(name)}
                                            label={label}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                            error={errors && errors[name] ? true : false}>
                                            {headers.map((option, key) => (
                                                <MenuItem key={key} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                        )
                    })
                        : null
                }
            </>
        )
    }

    return (
        <Box className="kmc-console-csvupload-main-box">
            <Loader loading={loading} />
            {error ? <Alert severity='error'>{error}</Alert> : null}
            {message ? <Alert severity='success'>{message}</Alert> : null}
            <Box className="kmc-console-csvupload-content-box">
                <Card className="kmc-console-csvupload-info-card">
                    <Typography variant="h6">Manage people creation through Excel</Typography>
                </Card>
                <Card className="kmc-console-csvupload-content-card">
                    <Box component="form" className="kmc-console-csvupload-content-box" onSubmit={handleSubmit(handleOnSubmit)}>
                    <Alert severity="info">
                                    There are some special keys like role, lat, long which will enable some special features in tenant application <a href="https://www.intracrew.com/spreadsheet.html" target="_blank">Click here</a>
                    </Alert> 
                    <br/>  
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: "start" }}>
                                <Chip label='Select Excel File'
                                    variant='outlined'
                                    color='primary'
                                    icon={<UploadFileRounded></UploadFileRounded>}
                                    onClick={() => {
                                        fileRef.current.click();
                                    }}
                                >
                                </Chip>
                                <input type="file" accept=".xls,.xlsx" ref={fileRef} hidden onChange={handleFileUpload} />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    file
                                        ?
                                        <Box>
                                            <Card className="kmc-console-csvupload-file-card-facade">
                                                <CardContent sx={{ backgroundColor: "aliceblue" }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography sx={{ fontSize: "15px" }}>Filename: {file.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography sx={{ fontSize: "14px" }}>Filetype: {file.type}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography sx={{ fontSize: "14px" }}> Size: {file.size}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                        : <></>
                                }
                            </Grid>
                            {
                                file ? <MappingFields /> : null
                            }
                            <Grid item xs={12}>
                                {
                                    file ?
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            size='small'
                                            disabled={loading}
                                        >
                                            {loading ? "In progress" : "Finish upload"}
                                        </Button>
                                        : <></>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )

}

export default ExcelUpload;
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { credentialsTableHeadCells } from './CredentialsTableHeadCells';
import { visuallyHidden } from '@mui/utils';
import { Key, Visibility } from "@mui/icons-material";

export default function CredentialsTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead sx={{ backgroundColor: "#cdd1dc" }}>
            <TableRow>
                {credentialsTableHeadCells.map((credentialsTableHeadCell) => (
                    <TableCell
                        key={credentialsTableHeadCell.id}
                        align={credentialsTableHeadCell.numeric ? 'right' : 'left'}
                        //padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === credentialsTableHeadCell.id ? order : false}
                    >
                        {
                            credentialsTableHeadCell.sortable ?
                                <TableSortLabel
                                    active={orderBy === credentialsTableHeadCell.id}
                                    direction={orderBy === credentialsTableHeadCell.id ? order : 'asc'}
                                    onClick={createSortHandler(credentialsTableHeadCell.id)}
                                >
                                    {credentialsTableHeadCell.id === "configKey" ? <Key></Key> : null}
                                    {credentialsTableHeadCell.label}
                                    {orderBy === credentialsTableHeadCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                                : <TableSortLabel hideSortIcon>
                                    {credentialsTableHeadCell.id === "isSensitive" ? <Visibility></Visibility> : null}
                                    {credentialsTableHeadCell.label}
                                </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
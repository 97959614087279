import { Table, Box, Paper, TableBody, TableCell, TableContainer, TablePagination, TableRow, styled, tableCellClasses } from "@mui/material";
import { useState } from "react";
import './ConfigurationPaginationTable.css';
import PublishLinkedSubDomainTableHeader from "./PublishLinkedSubDomainTableHeader";

export default function PublishLinkedSubDomainPaginationTable(props) {

    const cnames = props.cnames;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - cnames.length) : 0;


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#e6e9ed",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <Box sx={{ width: '100%', margin: "1.5rem 1.5rem 0 0" }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        aria-label="customized table"
                        size={'medium'}
                    >
                        <PublishLinkedSubDomainTableHeader
                        />
                        <TableBody>
                            {
                                cnames
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const domain = Object.keys(row)[0];
                                        const properties = Object.values(row)[0];
                                        return (
                                            <StyledTableRow
                                                hover
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <StyledTableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                >
                                                    {domain}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {properties?.type}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {properties?.key}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {properties?.value}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={cnames.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}
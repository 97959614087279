import {
  Table,
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Alert,
  Chip,
  CircularProgress,
  TableSortLabel,
  TableHead,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SecureLS from "secure-ls";
import "./ConfigurationPaginationTable.css";
import { Add, Delete, Edit, Preview } from "@mui/icons-material";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import Loader from "../atoms/loader/Loader";
import PermissionTableHead from "./PermissionTableHead";
import PrivateEncodedImageUrl from "../misc/PrivateEncodedImageUrl";
import PopoverUserPicker from "./PopoverUserPicker";
import PopoverUsersList from "./PopoverUsersList";
import { visuallyHidden } from "@mui/utils";

export default function PermissionPaginationTable(props) {
  const {
    onEditPermissionRow,
    initialPermissions,
    onDemandRefreshPermissions,
  } = props;

  const _secureStore = new SecureLS();
  const tenant = _secureStore.get("tenant_name");
  const _secure_user_info = _secureStore.get("_secure_user_info");
  const loggedInUserEmail =
    _secure_user_info &&
    _secure_user_info.attributes &&
    _secure_user_info.attributes["email"];

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [logo, setLogo] = useState([]);
  const [showRecipientsTable, setShowRecipientsTable] = useState({
    visible: false,
    name: "",
    permissionId: "",
  });
  const ref = useRef();

  useEffect(() => {
    setPermissions(initialPermissions);
    getImage(permissions);
  }, [ref, initialPermissions, permissions]);

  const getImage = (users) => {
    const avatarPromises = users.map((user) => {
      const url = user.logo_url;
      return axiosInstance
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = res.data;
          const imageUrl = URL.createObjectURL(blob);
          return imageUrl;
        });
    });

    Promise.all(avatarPromises).then((avatarUrls) => {
      setLogo(avatarUrls);
    });
  };

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleDeleteRow = (event, row) => {
    // make a call to update the permissions in the attribute service
    setLoading(true);
    axiosInstance
      .delete(
        `${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_type/${row.associate_type}`
      )
      .then((res) => {
        setPermissions(
          permissions.filter((permission) => permission.name !== row.name)
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err && err.message);
      });
  };

  const handleEditRow = (event, permissionName) => {
    event.preventDefault();
    const editablePermRow = permissions.filter(
      (permission) => permission.name === permissionName
    );
    if (editablePermRow && editablePermRow.length > 0) {
      onEditPermissionRow(editablePermRow[0]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [el, setEl] = useState(null);
  const [elRecipients, setElRecipients] = useState(null);
  const [elAdmin, setElAdmin] = useState(null);
  const [elAdmins, setElAdmins] = useState(null);
  const [addRecipientForPermission, setAddRecipeintForPermission] = useState(
    {}
  );
  const [addAdminForPermission, setAdminForPermission] = useState({});
  const [admins, setAdmins] = useState([]);
  const [refreshLabelCount, setRefreshLabelCount] = useState(false);

  const handleRecipientPopover = (event, permissionRow) => {
    setEl(event.target);
    setAddRecipeintForPermission(permissionRow);
  };

  const handleAdminPopover = (event, permissionRow) => {
    setElAdmin(event.target);
    setAdminForPermission(permissionRow);
  };

  const handleAdminsPopover = (event, permissionRow) => {
    setElAdmins(event.target);
    setAdmins(permissionRow.permission_admins);
  };

  const handleClosePopover = () => {
    setEl(null);
    setElAdmin(null);
    setElRecipients(null);
    setElAdmins(null);
    setAdminForPermission({});
    setAddRecipeintForPermission({});
    setAdmins([]);
  };

  const handleSelectedAdmin = (user) => {
    handleClosePopover();
    // check existance of the admin user
    const isPermissionAdminExists =
      addAdminForPermission?.permission_admins?.filter(
        (admins) => admins.uniqueId === user.uniqueId
      );
    if (isPermissionAdminExists && isPermissionAdminExists.length > 0) {
      return;
    }
    // handle to update permission admins
    const config = {
      name: addAdminForPermission.name,
      description: addAdminForPermission.description,
      permission_admins: [
        ...(addAdminForPermission?.permission_admins || []),
        user,
      ],
      logo_url: addAdminForPermission.logo_url,
    };
    if (addAdminForPermission) {
      axiosInstance
        .post(
          `${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_type/permission_${addAdminForPermission.name}`,
          config
        )
        .then((res) => {
          onDemandRefreshPermissions();
          setAdminForPermission({});
        })
        .catch((err) => {
          setError(err && err?.message);
        });
    }
  };

  const handleSelectedRecipient = (user) => {
    handleClosePopover();
    // handle to update user count
    const config = {
      headers: {
        company: tenant,
        tenant: tenant,
        source: tenant,
        changeagentid: loggedInUserEmail,
      },
    };
    if (addRecipientForPermission) {
      setRefreshLabelCount(true);
      axiosInstance
        .post(
          `${environment.ASSIGN_ATTRIBUTE_TO_EMPLOYEE_API}/${user.uniqueId}/Attributetype/permissions/AttributeId/${addRecipientForPermission.uuid}`,
          {},
          config
        )
        .then((res) => {
          setAddRecipeintForPermission({});
          onDemandRefreshPermissions();
          setRefreshLabelCount(false);
        })
        .catch((err) => {
          setError(err && err?.message);
          setRefreshLabelCount(false);
        });
    }
  };

  const handleRecipientsList = (event, permissionId, permissionName, flag) => {
    if (showRecipientsTable.visible) {
      setShowRecipientsTable({ visible: false, name: "", permissionId: "" });
      return;
    }
    setShowRecipientsTable({
      visible: flag,
      name: permissionName,
      permissionId: permissionId,
    });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - permissions.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Loader loading={loading}></Loader>
      {error ? (
        <Alert
          severity="error"
          onClose={(e) => {
            setError("");
          }}
        >
          {error}
        </Alert>
      ) : null}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <PermissionTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(permissions, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  //const url = PrivateEncodedImageUrl(tenant, row.name);
                  return row && row.name ? (
                    <TableRow hover tabIndex={-1} key={row.name}>
                      <TableCell component="th" id={labelId} scope="row">
                        <img
                          src={logo[index]}
                          height={50}
                          width={50}
                          style={{ clipPath: "circle()" }}
                          alt={"permission logo"}
                        ></img>
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <LabelCount
                          permissionId={row.uuid}
                          permissionName={row.name}
                          tenant={tenant}
                          onClickRecipientsList={handleRecipientsList}
                          onDemandRefreshPermissions={
                            onDemandRefreshPermissions
                          }
                          onDemandRefreshCount={refreshLabelCount}
                        />
                        <Chip
                          label={<Add sx={{ display: "block" }}></Add>}
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={(event) => {
                            handleRecipientPopover(event, row);
                          }}
                        ></Chip>
                        <PopoverUserPicker
                          el={el}
                          handleSelectedItem={handleSelectedRecipient}
                          handleClosePopover={handleClosePopover}
                        ></PopoverUserPicker>
                        <PopoverUsersList
                          el={elRecipients}
                          users={row.recipient_list}
                          tenant={tenant}
                          handleClosePopover={handleClosePopover}
                        ></PopoverUsersList>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={row?.permission_admins?.length || 0}
                          color="secondary"
                          variant="outlined"
                          size="small"
                          onClick={(event) => {
                            handleAdminsPopover(event, row);
                          }}
                          sx={{ marginRight: "3px" }}
                        ></Chip>
                        <Chip
                          label={<Add sx={{ display: "block" }}></Add>}
                          color="primary"
                          variant="outlined"
                          size="small"
                          onClick={(event) => {
                            handleAdminPopover(event, row);
                          }}
                        ></Chip>
                        <PopoverUserPicker
                          el={elAdmin}
                          title={"Select Administrator"}
                          handleSelectedItem={handleSelectedAdmin}
                          handleClosePopover={handleClosePopover}
                        ></PopoverUserPicker>
                        <PopoverUsersList
                          el={elAdmins}
                          title={"Administrators"}
                          users={admins}
                          tenant={tenant}
                          handleClosePopover={handleClosePopover}
                        ></PopoverUsersList>
                      </TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>
                        <Edit
                          fontSize="medium"
                          sx={{
                            marginRight: "0.5rem",
                            color: "grey",
                            cursor: "pointer",
                          }}
                          onClick={(event) => handleEditRow(event, row.name)}
                        ></Edit>
                        <Delete
                          fontSize="medium"
                          sx={{ color: "#a45d5d", cursor: "pointer" }}
                          onClick={(event) => handleDeleteRow(event, row)}
                        ></Delete>
                      </TableCell>
                    </TableRow>
                  ) : null;
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={permissions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {showRecipientsTable.visible ? (
        <>
          <Chip
            sx={{ marginBottom: "1rem", fontWeight: "525" }}
            label={
              "Recipients with " + showRecipientsTable.name + " permission"
            }
            variant="outlined"
            color="primary"
            size="small"
            onDelete={() =>
              setShowRecipientsTable({
                visible: false,
                name: "",
                permissionId: "",
              })
            }
          ></Chip>
          <RecipeintsTable
            permissionId={showRecipientsTable.permissionId}
            permissionName={showRecipientsTable.name}
            tenant={tenant}
            loggedInUserEmail={loggedInUserEmail}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

const LabelCount = (props) => {
  const {
    permissionId,
    permissionName,
    tenant,
    onClickRecipientsList,
    onDemandRefreshCount,
  } = props;
  const labelRef = useRef();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (permissionName) {
      setLoading(true);
      axiosInstance
        .get(
          `${environment.ATTRIBUTE_SERVICE_EMPLOYEE_COUNT}/${tenant}/associate_type/${permissionId}/count`
        )
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            setCount(res.data.count);
          }
        })
        .catch((err) => {
          // do not report error
          setLoading(false);
        });
    }
  }, [onDemandRefreshCount, labelRef, permissionId, permissionName, tenant]);

  return (
    <>
      {loading ? (
        <Chip
          variant="outlined"
          sx={{ border: "none" }}
          size="small"
          label={<CircularProgress size={25} />}
        ></Chip>
      ) : (
        <Chip
          label={count}
          color="secondary"
          variant="outlined"
          size="small"
          onClick={(event) => {
            onClickRecipientsList(event, permissionId, permissionName, true);
          }}
          sx={{ marginRight: "3px" }}
        ></Chip>
      )}
    </>
  );
};

const RecipeintsTable = (props) => {
  const { permissionId, permissionName, tenant, loggedInUserEmail } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [recepients, setRecepients] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setLoading(true);
    if (permissionId) {
      axiosInstance
        .get(
          `${
            environment.ATTRIBUTE_SERVICE_EMPLOYEE_LIST
          }/${tenant}/associate_type/${permissionId}/list?page_size=100&date_utc_max=${new Date().getTime()}`
        )
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.length > 0) {
              const config = {
                headers: {
                  requestagentid: loggedInUserEmail,
                },
              };
              Array.isArray(res.data) &&
                res.data.forEach((rec) => {
                  setLoading(true);
                  if (rec.id) {
                    axiosInstance
                      .get(
                        `${environment.GET_EMPLOYEE_RECORD_API}/${rec.id}`,
                        config
                      )
                      .then((res) => {
                        setLoading(false);
                        setRecepients((recepients) => [
                          ...recepients,
                          res.data,
                        ]);
                      })
                      .catch((err) => {
                        setLoading(false);
                        setError(err && err?.message);
                      });
                  }
                });
            } else {
              setRecepients([]);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err && err.message);
        });
    }
  }, [tenant, permissionId, permissionName, loggedInUserEmail]);

  const headCells = [
    {
      id: "preview",
      numeric: false,
      disablePadding: true,
      label: "Profile",
      sortable: false,
    },
    {
      id: "givenName",
      numeric: false,
      disablePadding: true,
      label: "Name",
      sortable: true,
    },
    {
      id: "departments",
      numeric: false,
      disablePadding: true,
      label: "Departments",
      sortable: false,
    },
    {
      id: "manager",
      numeric: false,
      disablePadding: true,
      label: "Facilitator",
      sortable: false,
    },
  ];
  const RecepientsTableHead = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead sx={{ backgroundColor: "#cdd1dc" }}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              //padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <TableSortLabel hideSortIcon>
                  {headCell.id === "preview" ? (
                    <Preview sx={{ paddingRight: "3px" }} />
                  ) : null}
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - recepients.length) : 0;

  const [logo, setLogo] = useState([]);
  useEffect(() => {
    console.log(recepients);
    getImage(recepients);
  }, [recepients]);

  const getImage = (users) => {
    const avatarPromises = users.map((user) => {
      const url = PrivateEncodedImageUrl(tenant, user.image_name);
      return axiosInstance
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = res.data;
          const imageUrl = URL.createObjectURL(blob);
          console.log(imageUrl);
          return imageUrl;
        });
    });

    Promise.all(avatarPromises).then((avatarUrls) => {
      setLogo(avatarUrls);
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Loader loading={loading}></Loader>
      {error ? <Alert severity="error"></Alert> : null}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <RecepientsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {recepients.length > 0 ? (
              <TableBody>
                {stableSort(recepients, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={row.givenName}>
                        <TableCell component="th" id={labelId} scope="row">
                          <img
                            src={logo[index]}
                            style={{ clipPath: "circle()" }}
                            height={40}
                            width={40}
                            alt={row.givenName}
                          ></img>
                        </TableCell>
                        <TableCell>{row.givenName}</TableCell>
                        <TableCell>{row?.departments.toString()}</TableCell>
                        <TableCell>{row.parentName}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={recepients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

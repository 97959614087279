import './ManagePermission.css';
import { Alert, Box, Card, Chip, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import CreatePermission from '../../molecules/CreatePermission';
import PermissionPaginationTable from '../../molecules/PermissionPaginationTable';
import SecureLS from 'secure-ls';
import Loader from '../loader/Loader';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import { environment } from '../../assets/configurations/configuration';
const ManagePermission = (props) => {
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const [addPermissionChipDisabled, setAddPermissionChipDisabled] = useState(false);
    const [isPermissionRowEnabled, setIsPermissionRowEnabled] = useState(false);
    const [permissionRow, setPermissionRow] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [refreshPermissions, setRefreshPermissions] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const permissionRef = useRef();
    useEffect(() => {
        setLoading(true);
        axiosInstance.get(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_types/permission?max_items=100`)
            .then(res => {
                setLoading(false);
                setRefreshPermissions(false);
                if (res && res.data) {
                    setPermissions(res.data.Items);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
    }, [permissionRef, refreshPermissions, tenant])
    const handleAddPermissionClick = (e) => {
        setAddPermissionChipDisabled(true);
        setIsPermissionRowEnabled(true);
    }
    const handlePermissionSuccess = () => {
        setRefreshPermissions(true);
        setIsPermissionRowEnabled(false);
        setAddPermissionChipDisabled(false);
    }
    const handleEditPermission = (permissionRow) => {
        handleClosePermission();
        setTimeout(()=>{
            setEditMode(true);
            setAddPermissionChipDisabled(true);
            setIsPermissionRowEnabled(true);
            setPermissionRow(permissionRow);
        },1000);
    }
    const handleClosePermission = () => {
        setEditMode(false);
        setPermissionRow(null);
        setAddPermissionChipDisabled(false);
        setIsPermissionRowEnabled(false);
    }
    const handleDemandRefreshPermissions = () => {
        setRefreshPermissions(true);
    }
    return (
        <Box className="kmc-console-manage-permission-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error" onClose={(e) => { setError("") }}>{error}</Alert> : null}
            <Box className="kmc-console-manage-permission-content-box">
                <Card className="kmc-console-manage-permission-info-card">
                    <Typography variant="h6">Manage Permissions</Typography>
                </Card>
                <Card className="kmc-console-manage-permission-content-card">
                    <Box className="kmc-console-manage-permission-form-box">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: "start" }}>
                                <Chip
                                    label="Create Permission"
                                    icon={<Add></Add>}
                                    color="primary"
                                    variant='outlined'
                                    onClick={handleAddPermissionClick}
                                    disabled={addPermissionChipDisabled}
                                >
                                </Chip>
                            </Grid>
                            {
                                isPermissionRowEnabled ?
                                    <Grid item xs={12}>
                                        <CreatePermission
                                            onPermissionSuccess={handlePermissionSuccess}
                                            onClosePermission={handleClosePermission}
                                            permissionRow={permissionRow}
                                            editMode={editMode}
                                        ></CreatePermission>
                                    </Grid> : null
                            }
                            <Grid item xs={12}>
                                {
                                    permissions
                                        ? <PermissionPaginationTable
                                            initialPermissions={permissions}
                                            refreshPermissions={refreshPermissions}
                                            onDemandRefreshPermissions = {handleDemandRefreshPermissions}
                                            onEditPermissionRow={handleEditPermission}
                                        />
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}
export default ManagePermission;
import { Box, Grid, Typography } from "@mui/material";
import { useRef, useState } from "react";
import BadgeMgmt from "../assets/BadgeMgmt.png";
import LocationMgmt from "../assets/LocationMgmt.png";
import PermissionMgmt from "../assets/PermissionMgmt.png";
import UploadPhoto from "../assets/UploadPhoto.png";
const AttributePreview = (props) => {
    const { name,
        requestToUpdateBadge, onSelectAsset, isPermission, isBadge, isLocation, editMode, imageUrl } = props;
    const inputFile = useRef(null);
    const [defaultLogo, setDefaultLogo] = useState(
        isBadge ? imageUrl ? imageUrl :BadgeMgmt
            : isPermission ? imageUrl ? imageUrl :PermissionMgmt
                : isLocation ? LocationMgmt : null);
    const handleSelectedAsset = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        // show preview
        setDefaultLogo(null);
        setDefaultLogo(window.URL.createObjectURL(file));
        // pass the selected file to the caller
        onSelectAsset(file);
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {
                    <Box onClick={(e) => {
                        if (requestToUpdateBadge) {
                            inputFile.current.click();
                        }
                    }}>
                        <input type="file" hidden accept=".png,.jpg,.jpeg" ref={inputFile} onChange={handleSelectedAsset} />
                        {
                            <img src={defaultLogo}
                                // height={100}
                                // width={100}
                                alt={"preview"}
                                // style={{ cursor: "pointer", clipPath: "circle()" }}
                                style={{ cursor: "pointer", maxWidth: "10rem",maxHeight:"10rem"}}
                                onError={(event) => {
                                    event.target.src = UploadPhoto;
                                }}
                            />
                        }
                    </Box>
                }
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: "small", color: "#99651de0", fontWeight: "bold" }}>{name}</Typography>
            </Grid>
        </Grid>
    )
}
export default AttributePreview;
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Typography, Alert } from '@mui/material';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/Circle';
import './PlanDetails.css';
import { Box } from "@mui/system";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { environment } from "../../assets/configurations/configuration";
import SecureLS from "secure-ls";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";
import team from '../../assets/team_logo.png';
import logo from '../../assets/icon-logo-new.png';

const PlanDetails = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const navigate = useNavigate();
    const _ref = useRef();

    useEffect(() => {
        if (!tenant) {
            navigate('/workspace/register', {
                state: {
                    message: "You must register domain.",
                    isWorkspaceExpand: true,
                    activeItemId: 991
                }
            });
            return;
        }
        if (_ref) {
            setLoading(true);
            axiosInstance.get(`${environment.PLAN_DETAILS}/${tenant}/configurationGroup/plan_details`)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        const current_plan = {
                            billing_type: res.data.billing_type,
                            plan_type: res.data.plan_type
                        }
                        const id = current_plan.billing_type + '_' + current_plan.plan_type;
                        const _ele = document.getElementById(id);
                        _ele.classList.add('selected-plan');
                        setCurrentPlan(current_plan);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    console.log(err);
                });
        }
    }, [tenant, _ref, navigate]);

    const [currentPlan, setCurrentPlan] = useState();
    const [message, setMessage] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const handlePlanSelect = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setMessage(false);
        if (value) {
            const plan = value.split("-");
            const plan_value = {
                billing_type: plan[0],
                plan_type: plan[1]
            }
            // remove the current plan
            if (currentPlan) {
                const id = currentPlan.billing_type + '_' + currentPlan.plan_type;
                const _ele = document.getElementById(id);
                _ele.classList.remove('selected-plan');
            }
            // set the current plan
            const id = plan_value.billing_type + '_' + plan_value.plan_type;
            const _ele = document.getElementById(id);
            _ele.classList.add('selected-plan');
            setCurrentPlan(plan_value);
        }
    }

    const updateCompletionCheck = (flag) => {
        _secureStore.set("isPlanDetailsCompleted", flag);
    }

    const handlePlanClick = (event) => {
        event.preventDefault();
        if (!currentPlan) {
            setMessage(true);
            return;
        }
        setLoading(true);
        axiosInstance.post(`${environment.PLAN_DETAILS}/${tenant}/configurationGroup/plan_details`, currentPlan)
            .then(res => {
                setLoading(false);
                updateCompletionCheck(true);
                navigate('/workspace/credentials', {
                    state: {
                        isWorkspaceExpand: true,
                        activeItemId: 995
                    }
                });
            }).catch(err => {
                console.log(err);
                updateCompletionCheck(false);
                setError(err && err.message);
                setLoading(false);
            })
    }

    return (
        <Box className="kmc-console-plandetails-main-box">
            <Loader loading={loading}></Loader>
            {message ? <Alert severity="warning">Please select plan to continue</Alert> : <></>}
            {error ? <Alert severity="error">{error}</Alert> : <></>}
            <Box className="kmc-console-plandetails-box">
                <Card className="kmc-console-plandetails-info-card">
                    <Typography variant="h6">Manage billing plan</Typography>
                    <Typography variant="h6">You can also change the plan after publish</Typography>
                </Card>

                <Card className="kmc-console-plandetails-content-card">
                    <Typography variant="h6" className="mothly-text" sx={{marginBottom: "1rem",  marginTop: "1rem"}}>Monthly Billing</Typography>
                    <Box className="kmc-console-plandetails-billing-content-box">
                    <div className="row">
                    <Box className="content-box" id="monthly_team">
                    <Typography variant="h1" className="billing-text-title"> Team </Typography>
                    <div className="row">
                        <div className="col-6">
                <div className="image">
                    <img src={team} alt="Description of the image" className="image"/>
                    </div></div>
                <div className="col-6">
                            <Typography variant="h3" className="billing-text-descr">Share IntraCrew with a small team. </Typography>
                            <Typography variant="h3" className="billing-text-descr">Pay Monthly</Typography>
                            <Typography variant="h3" className="billing-text-descr">50 Users</Typography>
                            </div>
                            </div>
                            <hr></hr>
                            <div className="select-btn-flex">
                                <Button size="large" className="select-btn" value={"monthly-team-110"} onClick={handlePlanSelect}>Select</Button>
                            </div>
                            <Typography variant="h3" className="amount">$110 /mo.</Typography>
                        </Box>
                        </div>
                        <Box className="content-box" id="monthly_company">
                        <Typography variant="h3" className="billing-text-title">Company</Typography>
                        <div className="row">
                        <div className="col-6">
                <div className="image">
                <img src={logo} alt="Description of the image" className="image"/>
                    </div></div>
                <div className="col-6">
                            <Typography variant="h3" className="billing-text-descr">Share IntraCrew  with a small team. </Typography>
                            <Typography variant="h3" className="billing-text-descr">Pay Monthly</Typography>
                            <Typography variant="h3" className="billing-text-descr">Free 100 users<br></br>(add more $1.75/ea.)</Typography>
                            </div>
                            </div>
                            <hr></hr>
                            <div className="select-btn-flex">
                                <Button size="large" className="select-btn" value={"monthly-company-240"} onClick={handlePlanSelect}>Select</Button>
                            </div>
                            <Typography variant="h3" className="amount">$300 /mo.</Typography>
                        </Box>

                    </Box>
                    <Typography variant="h6" className="mothly-text" sx={{marginBottom: "1rem",  marginTop: "1rem"}}>Yearly Billing</Typography>
                    <Box className="kmc-console-plandetails-billing-content-box">
                    <div className="row">
                        <Box className="content-box" id="yearly_team">
                        <Typography variant="h3" className="billing-text-title">Team</Typography>
                        <div className="row">
                        <div className="col-6">
                <div className="image">
                <img src={team} alt="Description of the image" className="image"/>
                    </div></div>
                <div className="col-6">
                            <Typography variant="h3" className="billing-text-descr">Share IntraCrew with a small team. </Typography>
                            <Typography variant="h3" className="billing-text-descr">Pay Yearly</Typography>
                            <Typography variant="h3" className="billing-text-descr">50 Users</Typography>
                            </div>
                            </div>
                            <hr></hr>
                            <div className="select-btn-flex">
                                <Button size="large" className="select-btn" value={"yearly-team-99"} onClick={handlePlanSelect}>Select</Button>
                            </div>
                            <Typography variant="h3" className="amount">$99 /mo.</Typography>
                        </Box>
                        </div>
                        <Box className="content-box" id="yearly_company">
                        <Typography variant="h3" className="billing-text-title">Company</Typography>
                        <div className="row">
                        <div className="col-6">
                <div className="image">
                <img src={logo} alt="Description of the image" className="image"/>
                    </div></div>
                <div className="col-6">
                            <Typography variant="h3" className="billing-text-descr">Share IntraCrew with a small team. </Typography>
                            <Typography variant="h3" className="billing-text-descr">Pay Yearly</Typography>
                            <Typography variant="h3" className="billing-text-descr">Free 100 users<br></br>(add more $1.75/ea.)</Typography>
                            </div>
                            </div>
                            <hr></hr>
                            <div className="select-btn-flex">
                                <Button size="large" className="select-btn" value={"yearly-company-199"} onClick={handlePlanSelect}>Select</Button>
                            </div>
                            <Typography variant="h3" className="amount">$260 /mo.</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Button 
                            size={"small"}
                            variant="outlined" 
                            onClick={handlePlanClick}>
                            Continue
                        </Button>
                    </Box>
                </Card>
            </Box>
        </Box>
    )

}

export default PlanDetails;
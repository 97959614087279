/* eslint-disable jsx-a11y/alt-text */
import { LockOutlined } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import { Controller, useForm } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import Loader from "../../atoms/loader/Loader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import flightImage from "../../../src/assets/flight.png";
import "../auth/Auth.css";

Amplify.configure({
  Auth: environment.cognito,
});

const Login = (props) => {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const isAuthenticated = new SecureLS().get("_secure_login_state");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
    if (data) {
      setLoading(true);
      Auth.signIn(data.email, data.password)
        .then((user) => {
          setLoading(false);
          setData(null);
          new SecureLS("aes").set("_secure_user_info", user);
          new SecureLS("aes").set("_secure_login_state", true);
          // Record the login time in local storage
          localStorage.setItem('loginTime', new Date().getTime());

          // Start the logout timer
          startLogoutTimer();

          navigate("/home");
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
    }
  }, [data, isAuthenticated, navigate]);

  const handleOnSubmit = (data) => {
    setData(data);
  };

  const togglePasswordHide = () => {
    setShowPassword(!showPassword);
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    password: Yup.string().required()
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { handleSubmit, register, formState, control } = useForm(formOptions);
  const { errors } = formState;
  const startLogoutTimer = () => {
    const loginTime = localStorage.getItem('loginTime'); // Retrieve the login time
    const currentTime = new Date().getTime(); // Current time in milliseconds
    const twentyFourHours = 24 * 60 * 60 * 1000;
    const timeUntilLogout = twentyFourHours - (currentTime - loginTime);

    // Set a timeout to log the user out after 24 hours
    setTimeout(() => {
      // Perform the logout action (e.g., clear user session)
      logout();
    }, timeUntilLogout);
  };

  const logout = () => {
    // Clear user session (e.g., remove tokens, clear local storage)
    // localStorage.removeItem('loginTime');
    localStorage.clear();
    // Redirect the user to the login page
    window.location.href = '/login';
  };


  return (
    <>
      {
        error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : <></>
      }
      {
        loading ? <Loader loading={loading}></Loader> : <></>
      }
      <Container component="main" className="kmc-customheight100">
        <Box className="kmc-loginbox">
          <div className="kmc-customdiv">
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlined />
            </Avatar>
            <Typography variant="h6">
              Login
            </Typography>
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} sx={{ marginTop: "1rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField fullWidth {...field} {...register("email")} label="Email"
                        InputLabelProps={{ shrink: true }} error={errors?.email} />
                    )}
                  >
                  </Controller>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField fullWidth {...field} {...register("password")} label="Password"
                        type={showPassword ? "text" : "password"}
                        InputLabelProps={{ shrink: true }} error={errors?.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {" "}
                              {showPassword ? (
                                <Visibility
                                  className="cursor_pointer"
                                  onClick={togglePasswordHide}
                                />
                              ) : (
                                <VisibilityOff onClick={togglePasswordHide} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  >
                  </Controller>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={loading}>
                    Login
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: "0.5rem" }}>
                <Grid item xs sx={{ textAlign: "left" }}>
                  <Typography component="h6" fontSize={"14px"} style={{ width: 'max-content' }}>
                    Are you a new user?
                  </Typography>
                  <Typography component="h6" fontSize={"14px"}>
                    <Link to="/signup" className="kmc-registerbtn">
                      Register
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs sx={{ textAlign: "left" }}>
                  <Typography component="h6" fontSize={"14px"} style={{ width: 'max-content' }}>
                    Tenant?
                  </Typography>
                  <Typography component="h6" fontSize={"14px"}>
                    <Link to="/TenantLogin" className="kmc-registerbtn">
                      Login
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs sx={{ textAlign: "right" }}>
                  <Typography component="h6" fontSize={"14px"} style={{ width: 'max-content' }}>
                    Forgot Password?
                  </Typography>
                  <Typography component="h6" fontSize={"14px"}>
                    <Link to="/forgot/password" className="kmc-registerbtn">
                      Reset
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Box>
        <Box className="kmc-console-login-background-box">
          <img src={flightImage} className="kmc-loginbg"></img>
        </Box>
      </Container>
    </>
  )
};

export default Login;

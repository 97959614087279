import './ManageBadge.css';
import { Alert, Box, Card, Chip, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import BadgePaginationTable from '../../molecules/BadgePaginationTable';
import CreateBadge from '../../molecules/CreateBadge';
import Loader from '../loader/Loader';
import SecureLS from 'secure-ls';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import { environment } from '../../assets/configurations/configuration';
const ManageBadge = (props) => {
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const [addBadgeChipDisabled, setAddBadgeChipDisabled] = useState(false);
    const [isBadgeRowEnabled, setIsBadgeRowEnabled] = useState(false);
    const [badgeRow, setBadgeRow] = useState({});
    const [refreshBadges, setRefreshBadges] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [badges, setBadges] = useState([]);
    const badgeRef = useRef();
    useEffect(() => {
        setLoading(true);
        axiosInstance.get(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_types/badge?max_items=100`)
            .then(res => {
                setLoading(false);
                setRefreshBadges(false);
                if (res && res.data) {
                    setBadges(res.data.Items);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
    }, [badgeRef, refreshBadges, tenant]);
    const handleAddBadgeClick = (e) => {
        setAddBadgeChipDisabled(true);
        setIsBadgeRowEnabled(true);
    }
    const handleBadgeSuccess = () => {
        setRefreshBadges(true);
        setIsBadgeRowEnabled(false);
        setAddBadgeChipDisabled(false);
    }
    const handleEditBadge = (badgeRow) => {
        handleCloseBadge();
        setTimeout(()=> {
            setEditMode(true);
            setAddBadgeChipDisabled(true);
            setIsBadgeRowEnabled(true);
            setBadgeRow(badgeRow);
        },1000);
    }
    const handleCloseBadge = () => {
        setEditMode(false);
        setBadgeRow(null);
        setAddBadgeChipDisabled(false);
        setIsBadgeRowEnabled(false);
    }
    const handleDemandRefreshBadges = () => {
        setRefreshBadges(true);
    }
    return (
        <Box className="kmc-console-manage-badge-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error" onClose={(e) => { setError("") }}>{error}</Alert> : null}
            <Box className="kmc-console-manage-badge-content-box">
                <Card className="kmc-console-manage-badge-info-card">
                    <Typography variant="h6">Manage Badges</Typography>
                </Card>
                <Card className="kmc-console-manage-badge-content-card">
                    <Box className="kmc-console-manage-badge-form-box">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: "start" }}>
                                <Chip
                                    label="Create Badge"
                                    icon={<Add></Add>}
                                    color="primary"
                                    variant='outlined'
                                    onClick={handleAddBadgeClick}
                                    disabled={addBadgeChipDisabled}
                                >
                                </Chip>
                            </Grid>
                            {
                                isBadgeRowEnabled ?
                                    <Grid item xs={12}>
                                        <CreateBadge
                                            onBadgeSuccess={handleBadgeSuccess}
                                            onCloseBadge={handleCloseBadge}
                                            badgeRow={badgeRow}
                                            editMode={editMode}
                                        ></CreateBadge>
                                    </Grid> : null
                            }
                            <Grid item xs={12}>
                                {
                                    badges ?
                                        <BadgePaginationTable
                                            initialBadges={badges}
                                            refreshBadges={refreshBadges}
                                            onDemandRefreshBadges = {handleDemandRefreshBadges}
                                            onEditBadgeRow={handleEditBadge}
                                        />
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}
export default ManageBadge;
export const badgeHeadCells = [
    {
        id: "preview",
        numeric: false,
        disablePadding: true,
        label: "Badge Logo",
        sortable: false
    },
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Badge Name",
        sortable: true
    },
    {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Badge Description",
        sortable: false
    },
    {
        id: "recipients",
        numeric: false,
        disablePadding: true,
        label: "Badge Recipients",
        sortable: false
    },
    {
        id: "badgeAdmins",
        numeric: false,
        disablePadding: true,
        label: "Badge Admins",
        sortable: false
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: true,
        label: "Actions",
        sortable: false
    }
]

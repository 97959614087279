import {
    Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Chip,
    CircularProgress, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody,
    TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SecureLS from 'secure-ls';
import { environment } from '../../assets/configurations/configuration';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import Loader from '../loader/Loader';
import { Add, CheckCircle, Delete, Preview } from '@mui/icons-material';
import './ManageDocumentCheckList.css';
import CreateCheckListGroup from './CreateCheckListGroup';
import { isMobile } from 'react-device-detect';
import PopoverUserPicker from '../../molecules/PopoverUserPicker';
import PopoverUsersList from '../../molecules/PopoverUsersList';
import { visuallyHidden } from '@mui/utils';
import PrivateEncodedImageUrl from '../../misc/PrivateEncodedImageUrl';

const ManageDocumentCheckList = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [loading, setLoading] = React.useState();
    const [error, setError] = React.useState();
    const [checkList, setCheckList] = useState([]);
    const [checkItem, setCheckItem] = useState();
    const [checkItemRowEnabled, setCheckItemRowEnabled] = useState({});
    const [reloadCheckList, setReloadCheckList] = useState(false);
    const [showRecipientsTable, setShowRecipientsTable] = useState({ visible: false, name: "" });

    const ref = useRef();

    const handleCheckGroupSuccess = () => {
        setReloadCheckList(true);
    }

    React.useEffect(() => {
        setLoading(true);
        axiosInstance.get(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_types/checklist?max_items=1000`)
            .then(res => {
                setLoading(false);
                if (res && res.data) {
                    setCheckList(res.data.Items);
                    setReloadCheckList(false);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, reloadCheckList]);

    const handleAddCheck = (event, groupId) => {
        setCheckItemRowEnabled({ [groupId]: true });
    }

    const handleChangeItem = (e) => {
        const value = e.target.value;
        setCheckItem(value);
    }

    const handleCloseItem = (e) => {
        setCheckItemRowEnabled(false);
    }

    const handleCreateItem = (event, name) => {
        // update with current check list 
        const filteredCheckList = checkList.filter(lst => lst.name === name);
        const existingItems = filteredCheckList[0].items;
        const newItems = [...existingItems, checkItem];
        const admins = filteredCheckList[0]?.checkgroup_admins;
        setLoading(true);
        const config = {
            name: name,
            items: newItems,
            checkgroup_admins: admins
        }
        axiosInstance
            .post(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_type/checklist_${name}`, config)
            .then((res) => {
                setLoading(false);
                setReloadCheckList(true);
                setCheckItemRowEnabled(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err && err?.message);
            })
    }

    const handleDeleteCheckGroup = (event, checkGroup) => {
        setLoading(true);
        axiosInstance.delete(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_type/${checkGroup.associate_type}`)
            .then(res => {
                setCheckList(checkList.filter(checkList => checkList.name !== checkGroup.name));
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
    }

    const [el, setEl] = useState(null);
    const [elAdmin, setElAdmin] = useState(null);
    const [elAdmins, setElAdmins] = useState(null);
    const [addRecipientForCheckgroup, setAddRecipeintForCheckgroup] = useState({});
    const [addAdminForCheckgroup, setAdminForCheckgroup] = useState({});
    const [admins, setAdmins] = useState([]);

    const handleRecipientPopover = (event, checkGroup) => {
        setEl(event.target);
        setAddRecipeintForCheckgroup(checkGroup);
    }

    const handleAdminPopover = (event, checkGroup) => {
        setElAdmin(event.target);
        setAdminForCheckgroup(checkGroup);
    }

    const handleAdminsPopover = (event, checkGroup) => {
        setElAdmins(event.target);
        setAdmins(checkGroup.checkgroup_admins);
    }

    const handleClosePopover = () => {
        setEl(null);
        setElAdmin(null);
        setElAdmins(null);
        setAdminForCheckgroup({});
        setAddRecipeintForCheckgroup({});
        setAdmins([]);
    };

    const handleSelectedAdmin = (user) => {
        handleClosePopover();
        // check existance of the admin user
        const isAdminExists = addAdminForCheckgroup?.checkgroup_admins?.filter(admins => admins.uniqueId === user.uniqueId);
        if (isAdminExists && isAdminExists.length > 0) {
            setError(user?.givenName + " is already an admin.");
            return;
        }
        // handle to update checkgroup admins
        const config = {
            name: addAdminForCheckgroup.name,
            items: addAdminForCheckgroup.items,
            checkgroup_admins: [...addAdminForCheckgroup?.checkgroup_admins || [], user]
        }
        if (addAdminForCheckgroup) {
            axiosInstance.post(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_type/checklist_${addAdminForCheckgroup.name}`, config)
                .then(res => {
                    setLoading(false);
                    setReloadCheckList(true);
                    setAdminForCheckgroup({});
                })
                .catch(err => {
                    setLoading(false);
                    setError(err && err?.message);
                });
        }
    }

    const handleSelectedRecipient = (user) => {
        handleClosePopover();
        // handle to update user count
        const config = {
            headers: {
                company: tenant,
                tenant: tenant,
                source: tenant,
                changeagentid: loggedInUserEmail
            }
        }
        if (addRecipientForCheckgroup) {
            axiosInstance.post(`${environment.ASSIGN_ATTRIBUTE_TO_EMPLOYEE_API}/${user.uniqueId}/Attributetype/checklist/AttributeId/${addRecipientForCheckgroup.name}`,
                {}, config)
                .then(res => {
                    setAddRecipeintForCheckgroup({});
                    setReloadCheckList(true);
                })
                .catch(err => {
                    setError(err && err?.message);
                });
        }
    }

    const handleRecipientsList = (event, checkGroupName, flag) => {
        if (showRecipientsTable.visible) {
            setShowRecipientsTable({ visible: false, name: "" });
            return;
        }
        setShowRecipientsTable({ visible: flag, name: checkGroupName });
    }

    return (
        <Box className="kmc-console-managedocumentschecklist-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity='error' onClose={() => setError(null)}>{error}</Alert> : null}
            <Box className="kmc-console-managedocumentschecklist-content-box">
                <Card className="kmc-console-managedocumentschecklist-info-card">
                    <Typography variant="h6">Manage Documents Checklist</Typography>
                </Card>
                <Card className="kmc-console-managedocumentschecklist-content-card">
                    <Box component="form" className="kmc-console-managedocumentschecklist-form-box">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CreateCheckListGroup onCheckGroupSuccess={handleCheckGroupSuccess}></CreateCheckListGroup>
                            </Grid>
                            {
                                checkList ? checkList.map((checkGroup, key) => {
                                    return (
                                        <Grid item xs={isMobile ? 12 : 6} key={key}>
                                            <Card>
                                                <CardHeader
                                                    title={
                                                        <>
                                                            {checkGroup?.name}
                                                            <IconButton
                                                                aria-label="help"
                                                                color="primary"
                                                                onClick={(e) => handleAddCheck(e, checkGroup?.name)}
                                                                disableRipple
                                                            >
                                                                <Chip
                                                                    sx={{ cursor: 'pointer' }}
                                                                    //key={checkGroup?.name}
                                                                    size='small'
                                                                    label="Add Check"
                                                                    icon={<Add></Add>}
                                                                    color="primary"
                                                                    variant='outlined'
                                                                    disabled={loading}
                                                                >
                                                                </Chip>
                                                            </IconButton>
                                                        </>
                                                    }
                                                    className={"kmc-console-managedocumentschecklist-group-header-title"}
                                                >
                                                </CardHeader>
                                                <CardContent>
                                                    <Grid container sx={{ alignItems: "center" }}>
                                                        <Grid item xs={12}>
                                                            {
                                                                checkItemRowEnabled && checkItemRowEnabled[checkGroup?.name] ?
                                                                    <>
                                                                        <Grid container spacing={1}>
                                                                            <Grid item xs={12}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    size='small'
                                                                                    variant='standard'
                                                                                    onChange={(e) => { handleChangeItem(e) }}
                                                                                >
                                                                                </TextField>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Button
                                                                                    size="small"
                                                                                    onClick={(e) => handleCreateItem(e, checkGroup?.name)}
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                                <Button
                                                                                    size="small"
                                                                                    onClick={(e) => handleCloseItem(e, checkGroup?.name)}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </> : null
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {
                                                                checkGroup && checkGroup.items ?
                                                                    checkGroup.items.map((v, k) => {
                                                                        return (
                                                                            <ListItem key={k}>
                                                                                <ListItemIcon>
                                                                                    <CheckCircle fontSize={'small'} sx={{ color: 'green' }} />
                                                                                </ListItemIcon>
                                                                                <ListItemText>
                                                                                    {v}
                                                                                </ListItemText>
                                                                            </ListItem>
                                                                        )
                                                                    })
                                                                    : null
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                                <CardActions sx={{ borderTop: "1px solid #f0e9e9e6" }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={4}>
                                                            <Chip sx={{ marginRight: "0.25rem" }}
                                                                label={checkGroup?.checkgroup_admins?.length || 0}
                                                                color="primary"
                                                                variant='outlined'
                                                                size='small'
                                                                onClick={(e) => handleAdminsPopover(e, checkGroup)}
                                                                disabled={loading}
                                                            >
                                                            </Chip>
                                                            <Chip
                                                                size='small'
                                                                label={"Add Admin"}
                                                                icon={<Add></Add>}
                                                                color="primary"
                                                                variant='outlined'
                                                                onClick={(e) => handleAdminPopover(e, checkGroup)}
                                                                disabled={loading}
                                                            >
                                                            </Chip>
                                                            <PopoverUserPicker el={elAdmin}
                                                                title={"Select Administrator"}
                                                                handleSelectedItem={handleSelectedAdmin}
                                                                handleClosePopover={handleClosePopover}
                                                            ></PopoverUserPicker>
                                                            <PopoverUsersList el={elAdmins}
                                                                title={"Administrators"}
                                                                users={admins}
                                                                tenant={tenant}
                                                                handleClosePopover={handleClosePopover}
                                                            >
                                                            </PopoverUsersList>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelCount
                                                                checkGroupName={checkGroup.name}
                                                                tenant={tenant}
                                                                onClickRecipientsList={handleRecipientsList}
                                                                //onDemandRefreshBadges={onDemandRefreshBadges}
                                                                onDemandRefreshCount={reloadCheckList}
                                                            />
                                                            <Chip
                                                                size='small'
                                                                label="Add User"
                                                                icon={<Add></Add>}
                                                                color="primary"
                                                                variant='outlined'
                                                                onClick={(e) => handleRecipientPopover(e, checkGroup)}
                                                                disabled={loading}
                                                            >
                                                            </Chip>
                                                            <PopoverUserPicker el={el}
                                                                title={"Select Receipient"}
                                                                handleSelectedItem={handleSelectedRecipient}
                                                                handleClosePopover={handleClosePopover}
                                                            >
                                                            </PopoverUserPicker>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Chip
                                                                size='small'
                                                                label="Delete"
                                                                icon={<Delete></Delete>}
                                                                color="error"
                                                                variant='outlined'
                                                                onClick={(e) => handleDeleteCheckGroup(e, checkGroup)}
                                                                disabled={loading}
                                                            >
                                                            </Chip>
                                                        </Grid>
                                                    </Grid>


                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    )
                                }) : null
                            }
                        </Grid>
                    </Box>
                    {
                        showRecipientsTable.visible ?
                            <RecipeintsTable checkGroupName={showRecipientsTable.name} tenant={tenant} loggedInUserEmail={loggedInUserEmail} />
                            : <></>
                    }
                </Card>
            </Box>
        </Box>
    )
}

export default ManageDocumentCheckList;

export const LabelCount = (props) => {

    const { checkGroupName, tenant, onClickRecipientsList, onDemandRefreshCount } = props;
    const labelRef = useRef();
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        if (checkGroupName) {
            axiosInstance.get(`${environment.ATTRIBUTE_SERVICE_EMPLOYEE_COUNT}/${tenant}/associate_type/${checkGroupName}/count`)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        setCount(res.data.count);
                    }
                })
                .catch(err => {
                    // do not report error
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onDemandRefreshCount, labelRef]);

    return (
        <>
            {
                loading ?
                    <Chip
                        variant="outlined"
                        sx={{ border: 'none' }}
                        label={<CircularProgress size={20} />}>
                    </Chip>
                    : <Chip
                        label={count}
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={(event) => { onClickRecipientsList(event, checkGroupName, true) }}
                        sx={{ marginRight: "3px" }}
                    >
                    </Chip>
            }
        </>
    )
}

export const RecipeintsTable = (props) => {

    const { checkGroupName, tenant, loggedInUserEmail } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [recepients, setRecepients] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);    

    useEffect(() => {
        setLoading(true);
        if (checkGroupName) {
            axiosInstance.get(`${environment.ATTRIBUTE_SERVICE_EMPLOYEE_LIST}/${tenant}/associate_type/${checkGroupName}/list?page_size=1000&date_utc_max=${new Date().getTime()}`)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        if (res.data.length > 0) {
                            const config = {
                                headers: {
                                    requestagentid: loggedInUserEmail
                                },
                            };
                            Array.isArray(res.data) && res.data.forEach(rec => {
                                setLoading(true);
                                if (rec.id) {
                                    axiosInstance.get(`${environment.GET_EMPLOYEE_RECORD_API}/${rec.id}`, config)
                                        .then(res => {
                                            setLoading(false);
                                            setRecepients(recepients => [...recepients, res.data]);
                                        })
                                        .catch(err => {
                                            setLoading(false);
                                            setError(err && err?.message);
                                        });
                                }
                            })
                        } else {
                            setRecepients([]);
                        }
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setError(err && err.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant, checkGroupName]);

    const headCells = [
        {
            id: "preview",
            numeric: false,
            disablePadding: true,
            label: "Profile",
            sortable: false
        },
        {
            id: "givenName",
            numeric: false,
            disablePadding: true,
            label: "Name",
            sortable: true
        },
        {
            id: "title",
            numeric: false,
            disablePadding: true,
            label: "Position",
            sortable: false
        },
        {
            id: "manager",
            numeric: false,
            disablePadding: true,
            label: "Facilitator",
            sortable: false
        }
    ]
    const RecepientsTableHead = (props) => {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        return (
            <TableHead sx={{ backgroundColor: "#cdd1dc" }}>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            //padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {
                                headCell.sortable ?
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    : <TableSortLabel hideSortIcon>
                                        {headCell.id === "preview" ? <Preview sx={{ paddingRight: "3px" }} /> : null}
                                        {headCell.label}
                                    </TableSortLabel>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - recepients.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error"></Alert> : null}
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <RecepientsTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        {
                            recepients.length > 0
                                ? <TableBody>
                                    {stableSort(recepients, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.givenName}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                    >
                                                        <img src={PrivateEncodedImageUrl(tenant, row.image_name)}
                                                            style={{ clipPath: "circle()" }}
                                                            height={40}
                                                            width={40}
                                                            alt={row?.givenName}
                                                        ></img>
                                                    </TableCell>
                                                    <TableCell
                                                    >
                                                        {row.givenName}
                                                    </TableCell>
                                                    <TableCell
                                                    >
                                                        {row.title}
                                                    </TableCell>
                                                    <TableCell
                                                    >
                                                        {row.parentName}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                : null
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={recepients.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}
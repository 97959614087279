import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { settingsListItems } from './TenantSettingsListItems';

export default function TenantSettingsNodegroup(props) {

    const [active, setActive] = useState();
    const navigate = useNavigate();
    const state = useLocation();    

    useEffect(() => {        
        const activeItemId = state && state.state && state.state.activeItemId;
        if(activeItemId) {
            setActive(activeItemId);
        }
    },[state])

    return (
        <React.Fragment>
            {
                settingsListItems ?
                    settingsListItems.map((item, key) => {
                        return (
                            <ListItemButton
                                key={key}
                                id={item.id}
                                className={(active === item.id) ? "kmc-console-tenant-details-litem-active" : ""}
                                onClick={() => {
                                    navigate(item.navigateTo);
                                    setActive(item.id);
                                }}
                            >
                                <ListItemIcon>
                                    <item.icon fontSize={item.iconSize}></item.icon>
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        )
                    })
                    : null
            }
        </React.Fragment>
    )
}
import { Code } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, Container, TextField, Typography } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import Loader from "../../atoms/loader/Loader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Backgroundimage from "../../../src/assets/flight.png";
import "./Auth.css";

Amplify.configure({
    Auth: environment.cognito
});

const VerifyEmail = (props) => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const username = state && state.username;
        if (!username) {
            navigate('/signup');
        }
        if (data) {
            setLoading(true);
            Auth.confirmSignUp(username, data.code)
                .then(data => {
                    setData(null);
                    setLoading(false);
                    navigate('/login');
                }).catch(err => {
                    setLoading(false);
                    setError(err.message);
                });
        }
    }, [data, state, navigate]);

    const handleOnSubmit = (data) => {
        setData(data);
    };

    // form validation rules 
    const validationSchema = Yup.object().shape({
        code: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, register, formState } = useForm(formOptions);
    const { errors } = formState;

    return (
        <>
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error">{error}</Alert> : <></>}
            <Box className="kmc-console-signup-main-box kmc-customheight100">
                <Container component="main" className="kmc-customheight100">
                    <Box className="kmc-loginbox">
                        <div className="kmc-customdiv">
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <Code />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Enter the code sent to your email
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="code"
                                    label="Enter Code"
                                    name="code"
                                    autoComplete="code"
                                    autoFocus
                                    {...register("code")}
                                    error={errors?.code}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={loading}
                                >
                                    Proceed
                                </Button>
                            </Box>
                        </div>
                    </Box>
                    <Box>
                            <div className="kmc-console-login-background-box">
                                <img src={Backgroundimage} alt={"bg-logo"} className="kmc-loginbg"></img>
                            </div>
                        </Box>
                </Container>
            </Box>
        </>
    )
}

export default VerifyEmail;
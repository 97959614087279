import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { Alert, Button, Card, Chip, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SecureLS from "secure-ls";
import './RegisterDomain.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { environment } from "../../assets/configurations/configuration";

const RegisterDomain = (props) => {

    const { state } = useLocation();
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const tenantPublishStatus = _secureStore.get("tenantPublishStatus");
    const isPublished = tenantPublishStatus && tenantPublishStatus.isPublished;
    const extensions = { domain: 'intracrew.com', zone_id: 'Z2FDTNDATAQYW2' };
    const [domainExits, setDomainExists] = useState(false);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const reserved_domains = ["www", "console"];
    const navigate = useNavigate();

    const _ref = useRef();
    useEffect(() => {
        if (_ref && tenant) {
            setValue("domain", tenant);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant, _ref])

    const handleOnSubmit = (data) => {
        // check if there is an existing domain
        setDomainExists(false);
        setLoading(true);
        axiosInstance.get(`${environment.TENANT_API_KEY_API}/tenant/${data.domain}`)
            .then(res => {
                setLoading(false);
                if (res && res.data && res.data?.APIkey) {
                    setDomainExists(true);
                    return;
                }
            })
            .catch(function (err) {
                setLoading(false);
                if (err.response && err.response.status === 503) {
                    setDomainExists(false);
                    handlePostTenantDomain(data.domain);
                    handleSetDomainAndContinue(data.domain);
                }
            });
    };

    const handlePostTenantDomain = (tenant) => {
        setLoading(true);
        axiosInstance.post(`${environment.POST_TENANT_DOMAIN}/${tenant}`)
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setError(err && err?.message);
            });
    }

    const handleSetDomainAndContinue = (domain) => {
        _secureStore.set("tenant_name", domain);
        _secureStore.set("domain_extensions", extensions);
        navigate("/workspace/customize",
            {
                state:
                {
                    isWorkspaceExpand: true,
                    activeItemId: 992
                }
            }
        );
    }

    const $regex = "^[a-z-0-9]*$";

    // form validation rules 
    const validationSchema = Yup.object().shape({
        domain: Yup.string()
            .min(2, "Minimum 2 characters required.")
            .matches($regex, "Only lower or numeric characters are allowed.")
            .test('reserved_domain_test', "Reserved domain. Try different one to proceed.", function (value) {
                if (reserved_domains.indexOf(value) === -1) {
                    return true;
                } else {
                    return false;
                }
            })
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, register, formState, control, setValue } = useForm(formOptions, {
        defaultValues: {
            domain: ""
        }
    });
    const { errors } = formState;

    return (
        <Box className="kmc-console-register-domain-box">
            {state && state.message ? <Alert severity="error">{state.message}</Alert> : <></>}
            {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : <></>}
            <Box className="kmc-console-register-domain-main-content-box">
                <Card className="kmc-console-register-domain-info-card">
                    <Typography variant="h6" >Choose a domain to publish your site on</Typography>
                    <Typography variant="h6">The domain you choose will be your site's address</Typography>
                </Card>
                <Card className="kmc-console-register-domain-content-card">
                    <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-register-domain-content-box">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Controller
                                    name="domain"
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <TextField
                                            label="Enter domain"
                                            autoFocus
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            {...field}
                                            {...register("domain")}
                                            disabled={isPublished ? true : false}
                                            error={errors?.domain}
                                            helperText={errors?.domain?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            {
                                domainExits
                                    ? <Grid item xs={12}>
                                        <Chip
                                            size="small"
                                            icon={<Cancel sx={{ color: "rgb(171, 62, 62) !important" }}></Cancel>}
                                            sx={{ color: "rgb(171, 62, 62) !important", border: "1px solid rgb(171, 62, 62)" }}
                                            label={"Domain is already registered. Try different one to proceed."}
                                        >
                                        </Chip>
                                    </Grid>
                                    : <></>
                            }
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    size={"small"}
                                    disabled={(isPublished || loading) ? true : false}
                                    startIcon={loading ? <CircularProgress size={20}></CircularProgress> : <></>}
                                >
                                    {isPublished ? "Tenant is registered" : "Continue"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box >
        </Box >
    )

}

export default RegisterDomain;
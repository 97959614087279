import { Table, Box, Paper, TableBody, TableCell, TableContainer, TablePagination, TableRow, Alert } from "@mui/material";
import { useEffect, useRef, useState } from "react"
import SecureLS from "secure-ls";
import './ConfigurationPaginationTable.css';
import { Cancel, CheckCircle, Delete, Edit } from "@mui/icons-material";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import Loader from "../atoms/loader/Loader";
import ConfigurationTableHead from "./ConfigurationTableHead";

export default function ConfigurationPaginationTable(props) {

    const { onEditConfigRow, initialConfigs } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [configs, setConfigs] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const ref = useRef();

    useEffect(() => {
        setConfigs(initialConfigs);
    }, [ref, initialConfigs]);

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDeleteRow = (event, configKey) => {
        event.preventDefault();
        // make a call to update the mappings in the private configuration
        delete configs[configKey];
        setLoading(true);
        const config = {
            application_configuration: configs
        }
        axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/application_configuration`, config)
            .then(res => {
                setLoading(false);
                setConfigs(configs);
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });

    }

    const handleEditRow = (event, configKey) => {
        event.preventDefault();
        const editableConfig = configs[configKey];
        if (editableConfig) {
            onEditConfigRow(editableConfig);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - configs.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error"></Alert> : null}
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <ConfigurationTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(Object.values(configs), getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                            >
                                                {row.configKey}
                                            </TableCell>
                                            <TableCell>{row.isSensitive ? "xxxx-xxxx-xxxx" : row.configValue}</TableCell>
                                            <TableCell>{row.isSensitive
                                                ? <CheckCircle fontSize="small" sx={{ color: "lightsalmon" }}></CheckCircle>
                                                : <Cancel fontSize="small" sx={{ color: "#963137" }}></Cancel>}</TableCell>
                                            <TableCell>
                                                <Edit fontSize="medium" sx={{ marginRight: "0.5rem", color: "grey", cursor: "pointer" }}
                                                    onClick={(event) => handleEditRow(event, row.configKey)}
                                                >
                                                </Edit>
                                                <Delete fontSize="medium" sx={{ color: "#a45d5d", cursor: "pointer" }}
                                                    onClick={(event) => handleDeleteRow(event, row.configKey)}
                                                >
                                                </Delete>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={Object.entries(configs).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}
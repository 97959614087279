import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import SecureLS from "secure-ls";
import { environment } from "../../../assets/configurations/configuration";
import { axiosInstance } from "../../../interceptors/axios/AxiosInstance";
import uploadIcon from "../../../assets/color-logo.png";
import '../Branding.css';

const BrandingLogo = (props) => {
    // check for existing logo
    const [files, setFiles] = useState();
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const [btnState, setBtnState] = useState(true);
    const [imageLoading, setImageLoading] = useState(false);
    const { onComponentChange } = props;
    const ref = useRef();
    useEffect(() => {
        if (ref) {
            onComponentChange(true, "");
            setImageLoading(true);
            axiosInstance
                .get(
                    `${environment.BRANDING_LOGO_API}/${tenant}/visibility/private/document/console/id_type/images/id/logo_image`, {
                    responseType: 'blob'
                })
                .then(res => {
                    setBtnState(!btnState);
                    const f = new File([res.data], 'preview');
                    setPreview(window.URL.createObjectURL(f));
                    onComponentChange(false, "");
                    setImageLoading(false);
                    updateCompletionCheck(true);
                }).catch(err => {
                    updateCompletionCheck(false);
                    onComponentChange(false, "");
                    setBtnState(!btnState);
                    setImageLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    const updateCompletionCheck = (flag) => {
        _secureStore.set("isBrandingLogoCompleted", flag);
    }

    const [isFileUploadSuccess, setIsFileUploadSuccess] = useState(false);
    const [preview, setPreview] = useState();

    const handleFileChange = (event) => {
        if (event.target.files) {
            const file = event.target.files[0];
            if (file) {
                setFiles(file);
                setPreview(window.URL.createObjectURL(file));
                setBtnState(!btnState);
            }
        }
        return;
    }

    const handleFileSubmit = (event) => {
        event.preventDefault();
        if (files) {
            var r = new FileReader();
            r.onload = function () { };
            r.readAsBinaryString(files);
            let config = {
                headers: {
                    filename: files.name,
                    'Content-Type': files.type,
                    'visibility': 'private',
                }
            }
            onComponentChange(true, "");
            axiosInstance.post(`${environment.BRANDING_LOGO_API}/${tenant}/visibility/private/document/console/id_type/images/id/logo_image`,
                files, config)
                .then(res => {
                    onComponentChange(false, "");
                    setIsFileUploadSuccess(!isFileUploadSuccess);
                    updateCompletionCheck(true);
                    setBtnState(!btnState);
                })
                .catch(err => {
                    onComponentChange(false, err.message);
                    updateCompletionCheck(true);
                });
        }
    }

    return (
        <Card className="kmc-console-branding-content-card">
            <CardHeader title="Choose logo"></CardHeader>
            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                <Box component="form" className="kmc-console-branding-logo-preview-box">
                    {
                        files || preview || !imageLoading
                            ?
                            <Card variant="outlined" sx={{ width: "100%", backgroundColor: "whitesmoke" }}>
                                <CardHeader title={
                                    isFileUploadSuccess ? "Logo uploaded successfully" :
                                        btnState ? "Selected logo" : "Current logo"}
                                    className="kmc-console-branding-logo-card-header">
                                </CardHeader>
                                <CardContent>
                                    {
                                        preview ?
                                            // <img src={preview} alt="logo" width={"100"} height={"100"} className="kmc-console-branding-img-preview"></img>
                                            <img src={preview} alt="logo" style={{ maxWidth: "15rem", maxHeight:"15rem" }} className="kmc-console-branding-img-preview"></img>

                                            : <Typography variant="h6">No preview</Typography>
                                    }
                                </CardContent>
                                <CardActions sx={{ justifyContent: "center" }}>
                                    {
                                        btnState
                                            ? <>
                                                <Button size="small" color="primary" variant="outlined" onClick={handleFileSubmit}>Upload</Button>
                                            </>
                                            : <></>
                                    }
                                </CardActions>
                            </Card>
                            : <CircularProgress></CircularProgress>
                    }
                </Box>
                <Box className="kmc-console-branding-logo-selection-box">
                    <Button
                        component="label"
                        variant="outlined"
                        sx={{ marginRight: "1rem", marginTop: "1rem", marginBottom: "1rem", border: "0px" }}
                    >
                        <img src={uploadIcon} alt={'logo'} width="100%" height="auto"></img>
                        <input type="file" hidden accept=".png, .jpg, .jpeg" onChange={handleFileChange} className="branding-logo-preview" />
                    </Button>
                </Box>
            </Box>
        </Card>
    )
};

export default BrandingLogo;
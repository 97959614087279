import './APIKey.css';
import { Alert, Box, Card, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { axiosInstance } from '../../../interceptors/axios/AxiosInstance';
import { environment } from '../../../assets/configurations/configuration';
import SecureLS from 'secure-ls';
import Loader from '../../loader/Loader';
import { Refresh } from '@mui/icons-material';

const APIKey = (props) => {

    const ref = useRef();
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");    

    const [key, setKey] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (ref) {
            // get current profile details
            setLoading(true);
            axiosInstance.get(`${environment.TENANT_API_KEY_API}/tenant/${tenant}`)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        if (res.data.APIkey) {
                            setKey(res.data?.APIkey);
                        }
                    }
                }).catch(err => {
                    setLoading(false);
                    setError(err & err.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    const handleRefreshKey = (e) => {
        const config = {
            headers: {
                api_key: key
            }
        }
        setLoading(true);
            axiosInstance.post(`${environment.TENANT_API_KEY_API}/refresh_APIkey/tenant/${tenant}`, {}, config)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        if (res.data.APIkey) {
                            setKey(res.data?.APIkey);
                        }
                    }
                }).catch(err => {
                    setLoading(false);
                    setError(err & err.message);
                });
    }

    return (
        <Box className="kmc-console-apikey-main-box">
            <Loader loading={loading}></Loader>
            {
                error ? <Alert severity='error'>{error}</Alert> : null
            }
            <Box className="kmc-console-apikey-content-box">
                <Card className="kmc-console-apikey-info-card">
                    <Typography variant='h6'>Tenant Private API Key</Typography>                    
                </Card>
                <Card className="kmc-console-apikey-content-card">
                    <Box className="kmc-console-apikey-form-box">
                        <Grid container sx={{justifyContent: "center"}}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    name="apikey"
                                    value={key}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    label="Tenant API Key"
                                    InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                              <Refresh
                                                sx={{cursor: "pointer", color: "lightcoral"}}
                                                onClick={handleRefreshKey}
                                              />
                                          </InputAdornment>
                                        ),
                                      }}
                                >
                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

export default APIKey;
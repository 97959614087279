import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import "./Layout.css";
import { environment } from "../../assets/configurations/configuration";
import SecureLS from "secure-ls";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";

const Layout = (props) => {

  const open = useState(false);
  const [toggleSideBar, setToggleSidebar] = useState(false);
  const { component } = props;

  const ChildComponent = component;
  const _secureStore = new SecureLS();
  const _secure_user_info = _secureStore.get("_secure_user_info");
  const email = _secure_user_info && _secure_user_info.attributes
    && _secure_user_info.attributes['email'];
  const tenantPublishStatus = _secureStore.get("tenantPublishStatus");

  const [tenant, setTenant] = useState(_secureStore.get("tenant_name"));
  const [isPublished, setIsPublished] = useState(tenantPublishStatus && tenantPublishStatus.isPublished);
  // This is the parent layout component that displays the header,
  // sidebar and child component. Check the tenant status before the 
  // child component renders.
  const _ref = useRef();
  useEffect(() => {
    if (_ref && ChildComponent) {
      axiosInstance.get(environment.GET_TENET_BY_EMAILID + "/" + email)
        .then(res => {
          if (res && res.data) {
            const tenantPublishStatus = {
              tenant_name: res.data,
              isPublished: true
            }
            _secureStore.set("tenantPublishStatus", tenantPublishStatus);
            _secureStore.set("tenant_name", res.data);
            setTenant(res.data);
            setIsPublished(true);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_ref, ChildComponent]);

  const hanldeToggleSideBar = (event) => {
    event.preventDefault();
    setToggleSidebar(!toggleSideBar);
  }

  const handleDrawer = (flag) => {
    setToggleSidebar((flag ? open : !open));
  };

  return (
    <Box className="kmc-console-app-main">
      {
        open ?
          <IconButton
            color="#fff"
            aria-label="open drawer"
            edge="end"
            onClick={hanldeToggleSideBar}
            className="menu-bar-btn"
          >
            <Close />
          </IconButton>
          :
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={hanldeToggleSideBar}
            className="menu-bar-btn"
          >
            <Menu className="list-icon" />
          </IconButton>
      }
      {
        toggleSideBar ?
          <Box className="kmc-console-app-main-sidebar-mobile">
            <SideBar onHandleDrawer={handleDrawer} tenant={tenant} isPublished={isPublished} />
          </Box>
          : <></>
      }
      <Box className="kmc-console-app-main-sidebar">
        <SideBar onHandleDrawer={handleDrawer} tenant={tenant} isPublished={isPublished} />
      </Box>
      <Box className="kmc-console-app-main-container">
        <Header tenant={tenant} isPublished={isPublished} />
        <ChildComponent tenant={tenant} isPublished={isPublished}></ChildComponent>
      </Box>
    </Box>
  );
};

export default Layout;

import { environment } from "../assets/configurations/configuration";
import PrivateEncodedStringBase64 from "./PrivateEncodedStringBase64";

const PrivateEncodedImageUrl = (tenant, image_name) => {

    const encode_image_url = PrivateEncodedStringBase64(tenant);
    const rootUrl = `${environment.EMPLOYEE_VIEW_IMAGE}/${tenant}`;
    const urlPath = "/root/document-manager-resource/source_path/";
    const defaultUrl = "?default=https://www.intracrew.com/images/no_image.png";

    return rootUrl + urlPath + encode_image_url + "/" + image_name + defaultUrl;
}

export default PrivateEncodedImageUrl;
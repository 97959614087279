import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CircularProgress, Dialog, DialogTitle, Grid, TextField } from "@mui/material";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SecureLS from "secure-ls";
import * as Yup from 'yup';
import { environment } from "../assets/configurations/configuration";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import './AzureADForm.css';

const AzureADForm = (props) => {

    const { onClose, open } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        ad_tenant_id: Yup.string().required(),
        ad_client_id: Yup.string().required(),
        ad_client_secret: Yup.string().required()
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    
    const { handleSubmit, formState, control, register, reset, setValue } = useForm(formOptions, {
        defaultValues: {
            ad_tenant_id: "",
            ad_client_id: "",
            ad_client_secret: ""
        }
    });
    const { errors } = formState;
    
    useEffect(() => {
        // get existing AD configuration
        if(open) {
            setLoading(true);
            axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/azure_ad_credentials`)
                .then(res => {
                    setLoading(false);
                    setValue("ad_tenant_id", res.data.ad_tenant_id);
                    setValue("ad_client_id", res.data.ad_client_id);
                    setValue("ad_client_secret", res.data.ad_client_secret);
                })
                .catch(res => {
                    setLoading(false);
                });
        }

    },[open, setValue, tenant]);

    const handleOnSubmit = (data) => {
        // store the Azure AD configuration to private map.
        if (data) {
            setLoading(true);
            axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/azure_ad_credentials`, data)
                .then(res => {
                    setLoading(false);
                    reset();
                    onClose("azureADCard");                    
                })
                .catch(err => {
                    setLoading(false);
                });
        }
    }

    const handleClose = () => {
        reset();
        onClose("azureADCard");
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Azure Active Directory Configuration</DialogTitle>
            <Box className="kmc-console-azure-content-box" component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="ad_tenant_id"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("ad_tenant_id")} label="AD Tenant Id" autoFocus
                                    InputLabelProps={{ shrink: true }} error={errors?.ad_tenant_id ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="ad_client_id"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("ad_client_id")} label="AD Client Id"
                                    InputLabelProps={{ shrink: true }} error={errors?.ad_client_id ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="ad_client_secret"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("ad_client_secret")} label="AD Client Secret"
                                    InputLabelProps={{ shrink: true }} error={errors?.ad_client_secret ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20}></CircularProgress> : <></>}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )

}

export default AzureADForm;
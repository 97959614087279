import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    Alert,
    Card,
    CardHeader,
    CardContent,
    Button,
} from "@mui/material";
import "../../atoms/timetracker/TimeTrackerMgmt.css";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { environment } from "../../assets/configurations/configuration";
import SecureLS from "secure-ls";
import Loader from "../loader/Loader";
import HourglassGIF from "../../assets/Hourglass.gif";
import Details from "./details";
import InputBase from "@mui/material/InputBase";
import UserPicker from "../../molecules/UserPicker";

function TimeTrackerMgmt(props) {
    const secureLs = new SecureLS();
    const tenant = secureLs.get("tenant_name");
    const _secure_user_info = secureLs.get("_secure_user_info");
    const loggedInUserEmail =
        _secure_user_info &&
        _secure_user_info.attributes &&
        _secure_user_info.attributes["email"];
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [userDetails, setUserDetails] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [data, setData] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedRange, setSelectedRange] = useState("month");
    const [filteredUserDetails, setFilteredUserDetails] = useState(userDetails);
    const [searchTerm, setSearchTerm] = useState("");
    const [dateInfo, setDateInfo] = useState({
        currentDate: new Date(),
        currentWeek: Math.ceil(new Date().getDate() / 7),
        currentMonth: new Date().getMonth() + 1,
        currentYear: new Date().getFullYear(),
    });
    const [backupUserDetails, setBackupUserDetails] = useState([]);

    const [uniqueEvents, setUniqueEvents] = useState([]);

    useEffect(() => {
        setSelectedRange("month");
        const fetchEventData = async () => {
            try {
                const searchOptions = {
                    prefix: "*",
                    pageSize: 30,
                    sortByField: "displayName",
                    sortBy: "asc",
                };
                const config = {
                    headers: {
                        company: tenant,
                        requestagentid: loggedInUserEmail,
                    },
                };
                setLoading(true);

                const employeeResponse = await axiosInstance.post(
                    environment.EMPLOYEE_SEARCH_ENTITY,
                    searchOptions,
                    config
                );

                setUserDetails([]);
                setBackupUserDetails([]);
                const uniqueEventsSet = new Set();

                await Promise.all(
                    employeeResponse.data.entityList.map(async (employeeData) => {
                        try {
                            const eventsResponse = await axiosInstance.get(
                                `${environment.GET_USER_EVENTS_BY_MONTH_YEAR}/tenant/${tenant}/user/${employeeData.uniqueId}/month/${dateInfo.currentMonth}/year/${dateInfo.currentYear}`
                            );

                            if (eventsResponse.data.details.length > 0) {
                                const newDetail = {
                                    employeeData: employeeData,
                                    events: eventsResponse.data.Aggregate,
                                };

                                setUserDetails((prevUserDetails) => [
                                    ...prevUserDetails,
                                    newDetail,
                                ]);
                                setBackupUserDetails((prevUserDetails) => [
                                    ...prevUserDetails,
                                    newDetail,
                                ]);

                                Object.keys(eventsResponse.data.Aggregate).forEach((event) => {
                                    uniqueEventsSet.add(event);
                                });
                            }
                        } catch (error) {
                            console.error(error);
                            setError(error.message);
                        }
                    })
                );

                setUniqueEvents(Array.from(uniqueEventsSet));
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        console.log("called here");

        console.log(dateInfo);

        fetchEventData();
    }, [loggedInUserEmail, tenant]);

    // useEffect(() => {
    //   setFilteredUserDetails(userDetails);
    // }, [userDetails]);

    const handleButtonClick = (range) => {
        setSelectedRange(range);
    };

    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value);

        // Filter userDetails based on the search term
        const filteredResults = backupUserDetails.filter((item) => {
            if (item?.employeeData?.displayName) {
                return item?.employeeData?.displayName
                    .toLowerCase()
                    .includes(value.toLowerCase());
            }
        });

        setUserDetails(filteredResults);
    };

    const eventCall = async (filter) => {
        setSelectedRange(filter);
        setUniqueEvents([]);

        const searchOptions = {
            prefix: "*",
            pageSize: 30,
            sortByField: "displayName",
            sortBy: "asc",
        };

        const config = {
            headers: {
                company: tenant,
                requestagentid: loggedInUserEmail,
            },
        };

        setLoading(true);
        axiosInstance
            .post(environment.EMPLOYEE_SEARCH_ENTITY, searchOptions, config)
            .then(async (employee_res) => {
                setLoading(true);
                setUserDetails([]);
                setBackupUserDetails([]);
                const eventsSet = new Set();

                for (let employeeData of employee_res.data.entityList) {
                    try {
                        let res;

                        if (filter === "currentDate") {
                            res = await axiosInstance.get(
                                `${environment.GET_USER_EVENTS_BY_DATE_MONTH_YEAR}/tenant/${tenant}/user/${employeeData.uniqueId}/date/${dateInfo.currentDate.getDate()}/month/${dateInfo.currentMonth}/year/${dateInfo.currentYear}`
                            );
                        } else if (filter === "week") {
                            res = await axiosInstance.get(
                                `${environment.GET_USER_EVENTS_BY_WEEK_MONTH_YEAR}/tenant/${tenant}/user/${employeeData.uniqueId}/week/${dateInfo.currentWeek}/month/${dateInfo.currentMonth}/year/${dateInfo.currentYear}`
                            );
                        } else if (filter === "month") {
                            res = await axiosInstance.get(
                                `${environment.GET_USER_EVENTS_BY_MONTH_YEAR}/tenant/${tenant}/user/${employeeData.uniqueId}/month/${dateInfo.currentMonth}/year/${dateInfo.currentYear}`
                            );
                        }

                        if (res && res.data.details.length > 0) {
                            const newDetail = {
                                employeeData: employeeData,
                                events: res.data.Aggregate,
                            };

                            setUserDetails((prevUserDetails) => [
                                ...prevUserDetails,
                                newDetail,
                            ]);
                            setBackupUserDetails((prevUserDetails) => [
                                ...prevUserDetails,
                                newDetail,
                            ]);

                            Object.keys(res.data.Aggregate).forEach((event) => {
                                eventsSet.add(event);
                            });
                        }
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        setError(error.message);
                    }
                }
                setUniqueEvents(Array.from(eventsSet));
            })
            .catch((err) => {
                setLoading(false);
                setError(err && err.message);
                console.error(err);
            });
    };

    return (
        <Box className="kmc-console-edit-employee-content-box">
            <Loader loading={loading}></Loader>
            <Card className="kmc-console-edit-employee-info-card">
                <Typography variant="h6">Time Tracker</Typography>
            </Card>
            <Card className="kmc-console-edit-employee-content-card card-body">
                {/* <Calendar showDetailsHandle={showDetailsHandle} />
                                    <br />
                                    {showDetails && <Details data={data} />} */}
                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
                <div class="row" style={{ marginTop: '20px' }}>
                    <div class="col col-md-6">
                        <input
                            type="text"
                            placeholder="Search by employee"
                            value={searchTerm}
                            onChange={handleSearch}
                            class="custom-input"
                        />
                    </div>
                    <Box>
                        <Button
                            variant={
                                selectedRange === "currentDate" ? "contained" : "outlined"
                            }
                            color="primary"
                            onClick={() => eventCall("currentDate")}
                        >
                            Current Date
                        </Button>
                        <Button
                            variant={selectedRange === "week" ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => eventCall("week")}
                        >
                            Week
                        </Button>
                        <Button
                            variant={selectedRange === "month" ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => eventCall("month")}
                        >
                            Month
                        </Button>
                    </Box>
                </div>
                <div>
                    <h2 className="h2CustomColor">
                        {currentDate.getDate()}{" "}
                        {new Intl.DateTimeFormat("en-US", { month: "long" }).format(
                            currentDate
                        )}
                        , {currentDate.getFullYear()}
                    </h2>
                </div>
                <Box className="kmc-console-edit-employee-form-box">
                    {loading ? (
                        <p>Loading...</p>
                    ) : userDetails.length > 0 && uniqueEvents.length > 0 ? (
                        <table className="time-tracker-table">
                            <thead>
                                <tr>
                                    <th>People</th>
                                    {uniqueEvents.map((event) => (
                                        <th key={event}>{event}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {userDetails.map((userDetail, index) => (
                                    <tr key={index}>
                                        <td>{userDetail.employeeData.displayName}</td>
                                        {uniqueEvents.map((event) => (
                                            <td key={event}>
                                                {userDetail.events[event]?.total_time_in_hours || 0}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : userDetails.length === 0 && uniqueEvents.length === 0 ? (
                        <p>No data available.</p>
                    ) : (
                        <img
                            src={HourglassGIF}
                            alt={"hourglass"}
                            width={100}
                            height={100}
                        ></img>
                    )}
                </Box>
            </Card>
        </Box>
    );
}

export default TimeTrackerMgmt;

import './GoogleMapContainer.css';
import React, { useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import MarkerClusterer from '@google/markerclusterer';

function GoogleMapContainer({ initialLocations, onEditLocationRow }) {
  // setup map
  const mapRef = useRef();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLocations(initialLocations);
  }, [initialLocations]);

  const setGoogleMap = (map, maps) => {
    mapRef.current = { map, maps };
    const markers = locations && locations.map((location) => {
      return new maps.Marker({
        position: {
          lat: parseFloat(location.location_details.lat),
          lng: parseFloat(location.location_details.lon)
        },
        key: location.locationName
      })
    });
    const markerCluster = new MarkerClusterer(map, markers, {
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      gridSize: 10,
      minimumClusterSize: 2
    });
    console.log(markerCluster);
  }

  const handleMarkerClick = (key) => {
    const locationRow = locations.filter(x => parseFloat(x.location_details.lon) === key.lng);
    if (locationRow && locationRow.length > 0) {
      onEditLocationRow(locationRow);
    }
  }

  return (
    <Container className="kmc-console-map-container">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={{ lat: 37.814305, lng: -122.051773 }}
        defaultZoom={1}
        zoom={1}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => setGoogleMap(map, maps)}
        options={{ streetViewControl: true }}
        onClick={handleMarkerClick}
      >
      </GoogleMapReact>
    </Container>
  )
};

export default GoogleMapContainer;
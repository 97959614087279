import { Box, Card, ListItem, ListItemText, TextField } from '@mui/material';
import { useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Loader from '../atoms/loader/Loader';

export default function LocationSearchPlaces(props) {

    const { searchType } = props;
    const [address, setAddress] = useState(props?.address);

    const handleChange = address => {
        setAddress(address);
    };

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => {
                const record = results[0];
                // get latitude and longitude
                getLatLng(record)
                    .then(latLng => {
                        // get locality for employees
                        let long_name;
                        const address_components = record?.address_components;
                        if (searchType === 'employee') {
                            address_components.forEach(address_component => {
                                if (address_component.types.indexOf('locality') !== -1) {
                                    long_name = address_component.long_name;
                                }
                            });
                        }
                        let locality = [];
                        if (searchType === 'office') {
                            address_components.forEach(address_component => {
                                let obj = {
                                    [address_component.types[0]]: address_component.long_name
                                };
                                locality.push(obj);
                            })
                        }
                        props.onSelectAddress({ address: record?.formatted_address, latLng: latLng, locality: (searchType === 'employee') ? long_name : locality });
                        setAddress(record?.formatted_address);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
            .catch(error => {
                console.error('Error', error);
            });
    };

    return (
        <PlacesAutocomplete
            value={address ? address : ''}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <Box>
                    <TextField
                        fullWidth
                        label={"Select Location"}
                        value={address}
                        InputLabelProps={{ shrink: true }}
                        {...getInputProps({
                            className: 'location-search-input',
                        })}
                    />
                    <Card className="autocomplete-dropdown-container">
                        {loading && <Loader loading={loading}></Loader>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: 'aliceblue', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <ListItem
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <ListItemText>{suggestion.description}</ListItemText>
                                </ListItem>
                            );
                        })}
                    </Card>
                </Box>
            )}
        </PlacesAutocomplete>
    )
}
import { Table, Box, Paper, TableBody, TableCell, TableContainer, TablePagination, TableRow, Alert, Button } from "@mui/material";
import { useEffect, useRef, useState } from "react"
import SecureLS from "secure-ls";
import './MappingPaginationTable.css';
import { Cancel, CheckCircle, Delete, Edit, Info } from "@mui/icons-material";
import MappingTableHead from "./MappingTableHead";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import Loader from "../atoms/loader/Loader";
import PopoverMappingsList from "./PopoverMappingsList";

export default function MappingPaginationTable(props) {

    const { onEditMappingRow, intialMappings } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [mappings, setMappings] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [popoverEl, setPopoverEl] = useState();
    const [attributeList, setAttributesList] = useState([]);
    const ref = useRef();

    useEffect(() => {
        setMappings(intialMappings);
    }, [ref, intialMappings]);

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDeleteRow = (event, name) => {
        event.preventDefault();
        delete mappings[name];
        // make a call to update the mappings in the private configuration
        setLoading(true);
        const config = {
            mapping_attributes: mappings
        }
        axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/mapping_attributes`, config)
            .then(res => {
                setLoading(false);
                setMappings(mappings);
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
    }

    const handleEditRow = (event, name) => {
        event.preventDefault();
        const editableRow = mappings[name];
        if (editableRow) {
            onEditMappingRow(editableRow);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mappings.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error">{error}</Alert> : null}
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <MappingTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>

                            {stableSort(Object.values(mappings), getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.name}
                                        >
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                            >
                                                {row.name}
                                            </TableCell>
                                            {
                                                row.type === 'Date'
                                                    ? row.isStatic
                                                        ? <TableCell>{row.type + " [" + row?.staticValue + "]"}</TableCell>
                                                        : <TableCell>{row.type + " [" + row.dateFormat + "]"}</TableCell>
                                                    : (row.type !== 'List' && row.isStatic)
                                                        ? <TableCell>{row.type + (row?.staticValue ? " [" + row?.staticValue + "]" : "")}</TableCell>
                                                        : row.type === 'List'
                                                            ? <>
                                                                <TableCell>
                                                                    <Button
                                                                        type="button"
                                                                        size="medium"
                                                                        sx={{ textTransform: 'none', color: 'inherit', ":hover": { backgroundColor: 'inherit' } }}
                                                                        disableRipple
                                                                        endIcon={
                                                                            <Info fontSize="small"
                                                                                sx={{ color: "#4189d1" }}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setPopoverEl(e.target);
                                                                                    setAttributesList(row.attributesList);
                                                                                }}
                                                                            ></Info>
                                                                        }
                                                                    >
                                                                        {row.type}
                                                                    </Button>
                                                                </TableCell>
                                                                <PopoverMappingsList el={popoverEl} mappingsList={attributeList} handleClosePopover={() => setPopoverEl(null)} />
                                                            </>
                                                            : <TableCell>{row.type}</TableCell>
                                            }
                                            <TableCell>{row.isStatic
                                                ? <CheckCircle fontSize="small" sx={{ color: "lightsalmon" }}></CheckCircle>
                                                : <Cancel fontSize="small" sx={{ color: "#a45d5d" }}></Cancel>}</TableCell>
                                            <TableCell>{row.isFilterEnabled
                                                ? <CheckCircle fontSize="small" sx={{ color: "lightseagreen" }}></CheckCircle>
                                                : <Cancel fontSize="small" sx={{ color: "#a45d5d" }}></Cancel>}</TableCell>
                                            <TableCell>
                                                {
                                                    (row.type === 'List' && row.isAdminAccessOnly)
                                                        ? <Cancel fontSize="small" sx={{ color: "#a45d5d" }}></Cancel>
                                                        : (row.type !== 'List' && row.isStatic)
                                                            ? <Cancel fontSize="small" sx={{ color: "#a45d5d" }}></Cancel>
                                                            : (row.isAdminAccessOnly)
                                                                ? <Cancel fontSize="small" sx={{ color: "#a45d5d" }}></Cancel>
                                                                : <CheckCircle fontSize="small" sx={{ color: "lightskyblue" }}></CheckCircle>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Edit fontSize="medium" sx={{ marginRight: "0.5rem", color: "grey" }}
                                                    onClick={(event) => handleEditRow(event, row.name)}
                                                >
                                                </Edit>
                                                <Delete fontSize="medium" sx={{ color: "#a45d5d" }}
                                                    onClick={(event) => handleDeleteRow(event, row.name)}
                                                >
                                                </Delete>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={Object.entries(mappings).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}
import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Chip,
  Avatar,
  Alert,
  Card,
  CardHeader,
  CardContent,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import SecureLS from "secure-ls";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../atoms/editempprofile/EditEmpProfile.css";
import UserPicker from "../../molecules/UserPicker";
import Loader from "../../atoms/loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from "react-device-detect";
import { Navigation } from "swiper";
import EmployeeDisplayCard from "../../molecules/EmployeeDisplayCard";
import PrivateEncodedImageUrl from "../../misc/PrivateEncodedImageUrl";
import LocationSearchPlaces from "../../molecules/LocationSearchPlaces";
import MuiPhoneNumber from "material-ui-phone-number";

const AssignSecondaryManager = () => {
  const _secureStore = new SecureLS();
  const tenant = _secureStore.get("tenant_name");
  const _secure_user_info = _secureStore.get("_secure_user_info");
  const loggedInUserEmail =
    _secure_user_info &&
    _secure_user_info.attributes &&
    _secure_user_info.attributes["email"];

  const [file, setFile] = useState();
  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(false);
  const [manager, setManager] = useState();
  const [parentId, setParentId] = useState("");
  const [parentName, setParentName] = useState("");
  const [referenceObjectId, setReferenceObjectId] = useState("");
  const [uniqueId, setUniqueId] = useState();
  const [entityDetails, setEntityDetails] = useState([]);
  const [numberOfReportees, setNumberOfReportees] = useState(0);
  const [currentReportees, setCurrentReportees] = useState([]);
  const [children, setChildren] = useState([]);
  const [geoLocation, setGeoLocation] = useState({});
  const [secmanager, setsecManager] = useState();
  const [newSecManager, setNewSecManager] = useState([]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    displayName: Yup.string().required(),
    contactEmail: Yup.string().required(),
    //role: Yup.string().required(),
    departments: Yup.string().required(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { handleSubmit, formState, control, setValue, register, reset } =
    useForm(formOptions, {
      defaultValues: {
        firstName: "",
        lastName: "",
        displayName: "",
        contactEmail: "",
        contactPhone: "",
        role: "",
        departments: "",
      },
    });

  useEffect(() => {
    if (employee && employee.uniqueId) {
      const config = {
        headers: {
          requestagentid: loggedInUserEmail,
        },
      };
      setLoading(true);
      axiosInstance
        .get(
          `${environment.GET_EMPLOYEE_RECORD_API}/${employee.uniqueId}`,
          config
        )
        .then((res) => {
          if (res && res.data) {
            setValue("firstName", res.data.firstName);
            setValue("lastName", res.data.lastName);
            setValue("displayName", res.data.displayName);
            setValue("contactEmail", res.data.contactEmail);
            setValue("contactPhone", res.data.contactPhone);
            setValue("departments", res.data.departments.toString());
            setValue("imageUrl", res.data.imageUrl);
            setValue("role", res.data.role);
            setValue("isFullTimeEmployee", res.data?.isFullTimeEmployee);
            setValue("isRemoteWorking", res.data?.isRemoteWorking);
            setGeoLocation({ address: res.data?.locationName });
            setParentId(res.data.parentId);
            setParentName(res.data.parentName);
            setReferenceObjectId(res.data.referenceObjectGuid);
            setUniqueId(res.data.uniqueId);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
      // get the existing reportees if any for the current selected user
      setLoading(true);
      axiosInstance
        .get(`${environment.GET_CHILD_RECORD_API}/${employee.uniqueId}`, config)
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            setCurrentReportees(res.data.entityDetails);
            setManager(res.data.parent);
            setChildren(res.data.children);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
      setLoading(true);
      console.log(employee,"employee")
console.log(employee.uniqueId,"uniqueId")

      axiosInstance
        .get(
          `${environment.GET_EMPLOYEE_MANAGERS}/${tenant}/employeeId/${employee.uniqueId}/getEmployee`
        )
        .then(async (res) => {
          setLoading(false);
          if (res && res.data) {
            console.log(res.data, "res");

            const managers = res.data.managers;
            const secmanagers = [];

            // Iterate through each manager and make a GET request for each
            for (const manager of managers) {
              try {
                const managerResponse = await axiosInstance.get(
                  `${environment.GET_EMPLOYEE_RECORD_API}/${manager}`,
                  config
                );
                secmanagers.push(managerResponse.data);
              } catch (error) {
                console.error(`Error fetching manager data: ${error.message}`);
              }
            }

            // Update the state with secmanagers array
            setNewSecManager(secmanagers);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
      console.log(newSecManager, "newaaaa");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

 

  const { errors } = formState;

  const handleProfilePicture = (email) => {
    if (email && file) {
      const encodedPostImageUrl = PrivateEncodedImageUrl(
        tenant,
        email + ".png"
      );
      axiosInstance
        .post(encodedPostImageUrl, file)
        .then((res) => {
          // do nothing already taken care by the employee creation call.
          onSelectedAsset(null);
          setFile(null);
        })
        .catch((err) => {
          // report error here if picture fails to upload
          setError(err && err.message);
        });
    }
  };

  const onSelectEmployee = (data) => {
    // fire a get request to get user details with unique Id
    setEmployee(data);
  };

  const onRemoveEmployee = () => {
    setChildren([]);
    setEntityDetails([]);
    setManager(null);
    setEmployee({});
    setFile(null);
    reset();
  };

  const updateEachReporteeWithNewManager = () => {
    const currentUserReferenceObjectId = referenceObjectId;

    const config = {
      headers: {
        changeagentid: loggedInUserEmail,
      },
    };
    const data = {
      entityType: "Employee",
      isActive: true,
      company: tenant,
    };
    setLoading(true);
    if (entityDetails && entityDetails.length > 0) {
      entityDetails.forEach((entity) => {
        data.referenceObjectGuid = entity.referenceObjectGuid;
        data.uniqueId = entity.uniqueId;
        data.parentId = currentUserReferenceObjectId;
        axiosInstance
          .post(environment.CREATE_EMPLOYEE_RECORD_API, data, config)
          .then((res) => {
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setError(err && err.message);
          });
      });
    }
  };

  const handleOnSubmit = (data) => {
    console.log(employee.uniqueId,"unique")
    console.log(secmanager,
      "sec")
    console.log(newSecManager,"newsec")
    var url = `${environment.ASSIGN_SEC_MANAGER}/${tenant}/employeeId/${employee.uniqueId}/AddOrRemoveManager/${secmanager.uniqueId}`;
    console.log(url, "url");
    setLoading(true);
    axiosInstance
      .post(
        `${environment.ASSIGN_SEC_MANAGER}/${tenant}/employeeId/${employee.uniqueId}/AddOrRemoveManager/${secmanager.uniqueId}`
      )
      .then((res) => {
        console.log(res, "res");
        setLoading(false);
        setMessage(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  const onSelectReportee = (reportee) => {
    if (numberOfReportees < 10) {
      setNumberOfReportees(numberOfReportees + 1);
      setEntityDetails((current) => [...current, reportee]);
    }
  };

  const onSelectNewSecManager = (e) => {
    console.log(e,"selected manager")
    setsecManager(e);

  };

  const onRemoveNewSecManager = (e) => {
    setsecManager(null);
  };

  const handleDelete = (reporteeToDelete) => () => {
    setEntityDetails((reportees) =>
      reportees.filter(
        (reportee) => reportee.uniqueId !== reporteeToDelete.uniqueId
      )
    );
    setNumberOfReportees(numberOfReportees - 1);
  };

  const Reportees = entityDetails.map((reportee, index) => (
    <Chip
      size="small"
      avatar={<Avatar alt={reportee.givenName} src="./broken.png" />}
      label={reportee.givenName}
      key={index}
      onDelete={handleDelete(reportee)}
      color="primary"
      variant="outlined"
    />
  ));

  const onSelectedAsset = (file) => {
    setFile(file);
  };

  const handleLocation = (location) => {
    setGeoLocation({
      address: location?.address,
      latLng: location?.latLng,
      locality: location?.locality,
    });
  };

  const ShowEmployeeDetails = () => {
    return (
      <>
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Grid item xs={6} sx={{ marginRight: "1rem" }}>
            <Card>
              <CardHeader
                title="Profile Details"
                sx={{
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  "& .MuiCardHeader-title": { fontSize: "1.25rem" },
                }}
              ></CardHeader>
              <CardContent>
                <Grid>
                  <EmployeeDisplayCard
                    employee={employee}
                    requestToUpdateAsset={true}
                    onSelectAsset={onSelectedAsset}
                    selectedFile={file}
                  ></EmployeeDisplayCard>
                </Grid>

                <Grid
                  sx={{ paddingTop: "1rem", display: "flex", flexWrap: "wrap" }}
                >
                  <Grid
                    xs={6}
                    sx={{ textAlign: "right", paddingRight: "1rem" }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      fontWeight="bold"
                    >
                      First Name
                    </Typography>
                  </Grid>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <Grid
                        xs={6}
                        sx={{ textAlign: "left", paddingLeft: "1rem" }}
                      >
                        <Typography variant="body1" component="div">
                          {field.value ? field.value : "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  />
                  <Grid
                    xs={6}
                    sx={{ textAlign: "right", paddingRight: "1rem" }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      fontWeight="bold"
                    >
                      Last Name
                    </Typography>
                  </Grid>
                  <Controller
                    control={control}
                    name="lastName"
                    render={({ field }) => (
                      <Grid
                        xs={6}
                        sx={{ textAlign: "left", paddingLeft: "1rem" }}
                      >
                        <Typography variant="body1" component="div">
                          {field.value ? field.value : "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  />
                  <Grid
                    xs={6}
                    sx={{ textAlign: "right", paddingRight: "1rem" }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      fontWeight="bold"
                    >
                      Display Name
                    </Typography>
                  </Grid>
                  <Controller
                    control={control}
                    name="displayName"
                    render={({ field }) => (
                      <Grid
                        xs={6}
                        sx={{ textAlign: "left", paddingLeft: "1rem" }}
                      >
                        <Typography variant="body1" component="div">
                          {field.value ? field.value : "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  />
                  <Grid
                    xs={6}
                    sx={{ textAlign: "right", paddingRight: "1rem" }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      fontWeight="bold"
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Controller
                    control={control}
                    name="contactEmail"
                    render={({ field }) => (
                      <Grid
                        xs={6}
                        sx={{ textAlign: "left", paddingLeft: "1rem" }}
                      >
                        <Typography variant="body1" component="div">
                          {field.value ? field.value : "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  />
                  <Grid
                    xs={6}
                    sx={{ textAlign: "right", paddingRight: "1rem" }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      fontWeight="bold"
                    >
                      Phone
                    </Typography>
                  </Grid>
                  <Controller
                    control={control}
                    name="contactPhone"
                    render={({ field }) => (
                      <Grid
                        xs={6}
                        sx={{ textAlign: "left", paddingLeft: "1rem" }}
                      >
                        <Typography variant="body1" component="div">
                          {field.value ? field.value : "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  />
                  <Grid
                    xs={6}
                    sx={{ textAlign: "right", paddingRight: "1rem" }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      fontWeight="bold"
                    >
                      Role
                    </Typography>
                  </Grid>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field }) => (
                      <Grid
                        xs={6}
                        sx={{ textAlign: "left", paddingLeft: "1rem" }}
                      >
                        <Typography variant="body1" component="div">
                          {field.value ? field.value : "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  />
                  <Grid
                    xs={6}
                    sx={{ textAlign: "right", paddingRight: "1rem" }}
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      fontWeight="bold"
                    >
                      Departments
                    </Typography>
                  </Grid>
                  <Controller
                    control={control}
                    name="departments"
                    render={({ field }) => (
                      <Grid
                        xs={6}
                        sx={{ textAlign: "left", paddingLeft: "1rem" }}
                      >
                        <Typography variant="body1" component="div">
                          {field.value ? field.value : "N/A"}
                        </Typography>
                      </Grid>
                    )}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardHeader
                title="Facilitator"
                sx={{
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  "& .MuiCardHeader-title": { fontSize: "1.25rem" },
                }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12}>
                  {manager && manager?.entityId ? (
                    <>
                      <Chip
                        size="small"
                        label="Current Facilitator"
                        color="primary"
                        variant="outlined"
                        sx={{ marginBottom: "1rem" }}
                      ></Chip>
                      <EmployeeDisplayCard
                        employee={currentReportees[manager?.entityId]}
                      ></EmployeeDisplayCard>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </CardContent>
            </Card>

            <Card sx={{ marginTop: "1rem" }}>
              <CardHeader
                title="Secondary Facilitator"
                sx={{
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  "& .MuiCardHeader-title": { fontSize: "1.25rem" },
                }}
              ></CardHeader>
              <CardContent>
                <Grid item xs={12}>
                  <UserPicker
                    label="Assign Secondary Facilitator"
                    onSelectSearchItem={onSelectNewSecManager}
                    onClearSearchItem={onRemoveNewSecManager}
                  ></UserPicker>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  className="p-2"
                >
                  {secmanager && secmanager?.uniqueId ? (
                    <Card className="kmc-console-swiperslide-card">
                      <EmployeeDisplayCard
                        employee={secmanager}
                      ></EmployeeDisplayCard>
                    </Card>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={12} sx={{ display: "flex",flexWrap: "wrap"  }}>
                  {newSecManager ? (
                    newSecManager.map((item, key) => (
                     <Card xs={6}
                     style={{width:"40%",padding:"1rem"}}> <EmployeeDisplayCard
                     
                      employee={item}
                        key={key}
                      ></EmployeeDisplayCard>
                      </Card>
                    ))
                  ) : (
                    <></>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Box className="kmc-console-edit-employee-main-box">
      {loading ? <Loader loading={loading}></Loader> : <></>}
      {error ? (
        <Alert color="error" onClose={(e) => setError(null)}>
          {error}
        </Alert>
      ) : (
        <></>
      )}
      {message ? (
        <Alert color="primary" onClose={(e) => setMessage(null)}>
          People updated successfully
        </Alert>
      ) : (
        <></>
      )}
      <Box className="kmc-console-edit-employee-content-box">
        <Card className="kmc-console-edit-employee-info-card">
          <Typography variant="h6">Assign Secondary Facilitator</Typography>
        </Card>
        <Card className="kmc-console-edit-employee-content-card">
          <Box className="kmc-console-edit-employee-form-box">
            <Grid container spacing={3}>
              <Grid item xs={11.5}>
                <UserPicker
                  label="Search People"
                  onSelectSearchItem={onSelectEmployee}
                  onClearSearchItem={onRemoveEmployee}
                ></UserPicker>
              </Grid>
              {employee && employee.uniqueId ? (
                <ShowEmployeeDetails></ShowEmployeeDetails>
              ) : (
                <></>
              )}
              {employee && employee.uniqueId ? (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    type="button"
                    size="small"
                    disabled={loading}
                    sx={{ marginTop: "1rem" }}
                    onClick={handleOnSubmit}
                  >
                    Update People
                  </Button>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
export default AssignSecondaryManager;

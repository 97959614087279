export const environment = {
    "stage": "prod",
    "production": false,
    "cognito": {
        "userPoolId": "us-east-1_YITiSdBsn",
        "userPoolWebClientId": "ijdmsirs2phboeg8dbarg3qn5"
    },
    "GET_TENET_BY_EMAILID": "https://e8lvdeawkc.execute-api.us-east-1.amazonaws.com/prod/Iam/tenant/useremail",
    "GET_COGNITO_DETAILS_BY_TENANT":"https://4466mtp5g4.execute-api.us-east-1.amazonaws.com/prod/tenant_registration/tenant",
    "GET_TENANT_ACCESS_DETAILS":"https://7uhsre6dn6.execute-api.us-east-1.amazonaws.com/prod/Iam/tenant",
    "EMPLOYEE_BULK_IMAGE_UPLOAD_API": "https://2xcv2cron4.execute-api.us-east-1.amazonaws.com/prod/large_file_upload_document/tenant",
    "EMPLOYEE_CSV_FILE_UPLOAD_API": "https://2xcv2cron4.execute-api.us-east-1.amazonaws.com/prod/upload_or_get_document/tenant",
    "BRANDING_LOGO_API": "https://2xcv2cron4.execute-api.us-east-1.amazonaws.com/prod/upload_or_get_document/tenant",
    "EMPLOYEE_DATA_EXTRACTION_API": " https://pesicg5z9d.execute-api.us-east-1.amazonaws.com/async/prod/zip/extract_files",
    "BRANDING_COLOR_API": "https://h36ktscvh8.execute-api.us-east-1.amazonaws.com/prod/configuration/public_configuration/configurationName",
    "PAYMENT_DETAILS": "https://h36ktscvh8.execute-api.us-east-1.amazonaws.com/prod/configuration/private_configuration/configurationName",
    "CONSOLE_CREDENTIALS": "https://h36ktscvh8.execute-api.us-east-1.amazonaws.com/prod/configuration/private_configuration/configurationName",
    "MAPPING_ATTRIBUTES_API": "https://h36ktscvh8.execute-api.us-east-1.amazonaws.com/prod/configuration/private_configuration/configurationName",
    "PLAN_DETAILS": "https://h36ktscvh8.execute-api.us-east-1.amazonaws.com/prod/configuration/public_configuration/configurationName",
    "PUBLISH_API": "https://4466mtp5g4.execute-api.us-east-1.amazonaws.com/prod/tenant_registration/publishing_tenant",
//    "CRAWL_CSV": "https://crawler.prod.intracrew.com/prod/crawl_csv",
      "CRAWL_CSV" : "https://nfagma5my4.execute-api.us-east-1.amazonaws.com/prod/serverless_crawler/task/container_name/prod_crawler_service/crawl_type/csv",
 
//    "CRAWL_EXCEL": "https://crawler.prod.intracrew.com/prod/crawl_spreadsheet",
     "CRAWL_EXCEL" : "https://nfagma5my4.execute-api.us-east-1.amazonaws.com/prod/serverless_crawler/task/container_name/prod_crawler_service/crawl_type/spreadsheet",
// elastic search
//   "AUTO_COMPLETE_USER_API": "https://dt9k3x3e4f.execute-api.us-east-1.amazonaws.com/read_entity_search/prod/tenant",
//     "GET_EMPLOYEE_COUNT_API": "https://dt9k3x3e4f.execute-api.us-east-1.amazonaws.com/read_entity_search/prod/get_entity_count",
//     "GET_EMPLOYEE_RECORD_API": "https://dt9k3x3e4f.execute-api.us-east-1.amazonaws.com/read_entity_search/prod/entityType/Employee/get_entity",
//     "GET_CHILD_RECORD_API": "https://dt9k3x3e4f.execute-api.us-east-1.amazonaws.com/read_entity_search/prod/get_children",
//    "DELETE_EMPLOYEE_RECORD_API": "https://dt9k3x3e4f.execute-api.us-east-1.amazonaws.com/read_entity_search/prod/entityType/Employee/delete_entity",
//   "SEARCH_OFFICE_LOCATIONS_API": "https://dt9k3x3e4f.execute-api.us-east-1.amazonaws.com/read_entity_search/prod/entityType/OfficeLocation/search_entity",
//    "GET_USER_INFO_BY_EMAIL": "https://dt9k3x3e4f.execute-api.us-east-1.amazonaws.com/read_entity_search/prod/entityType/Employee/get_entity_by_email",
//    "EMPLOYEE_SEARCH_ENTITY" : "https://dt9k3x3e4f.execute-api.us-east-1.amazonaws.com/read_entity_search/prod/entityType/Employee/search_entity",
//
 
   // "AUTO_COMPLETE_USER_API": "https://g8g5hlq4p4.execute-api.us-east-1.amazonaws.com/read_entity_proxy_service/prod/tenant",
      // "GET_EMPLOYEE_COUNT_API": "https://g8g5hlq4p4.execute-api.us-east-1.amazonaws.com/read_entity_proxy_service/prod/get_entity_count",
    //    "SEARCH_OFFICE_LOCATIONS_API": "https://g8g5hlq4p4.execute-api.us-east-1.amazonaws.com/read_entity_proxy_service/prod/entityType/OfficeLocation/search_entity",
 
//   sql
     "AUTO_COMPLETE_USER_API": "https://g1a5xfl4la.execute-api.us-east-1.amazonaws.com/read_entity_schema_service/prod/tenant",
      "GET_EMPLOYEE_COUNT_API": "https://g1a5xfl4la.execute-api.us-east-1.amazonaws.com/read_entity_schema_service/prod/get_entity_count",
   "GET_EMPLOYEE_RECORD_API": "https://g1a5xfl4la.execute-api.us-east-1.amazonaws.com/read_entity_schema_service/prod/entityType/Employee/get_entity",
   "DELETE_EMPLOYEE_RECORD_API": "https://g1a5xfl4la.execute-api.us-east-1.amazonaws.com/read_entity_schema_service/prod/entityType/Employee/delete_entity",
 "SEARCH_OFFICE_LOCATIONS_API": "https://g1a5xfl4la.execute-api.us-east-1.amazonaws.com/read_entity_schema_service/prod/entityType/OfficeLocation/search_entity",
 "GET_USER_INFO_BY_EMAIL": "https://g1a5xfl4la.execute-api.us-east-1.amazonaws.com/read_entity_schema_service/prod/entityType/Employee/get_entity_by_email",
 "EMPLOYEE_SEARCH_ENTITY" : "https://g1a5xfl4la.execute-api.us-east-1.amazonaws.com/read_entity_schema_service/prod/entityType/Employee/search_entity",
 
 
    "CREATE_EMPLOYEE_RECORD_API": "https://7nh5g65j13.execute-api.us-east-1.amazonaws.com/writeentityservice/prod/entityType/Employee/create_entity",
 
    "REPLACE_MANAGER_API": "https://7nh5g65j13.execute-api.us-east-1.amazonaws.com/writeentityservice/prod/replace_manager/tenant",
 
    "CREATE_OFFICE_RECORD_API": "https://7nh5g65j13.execute-api.us-east-1.amazonaws.com/writeentityservice/prod/entityType/OfficeLocation/create_entity",
 
    "EMPLOYEE_VIEW_IMAGE": "https://2xcv2cron4.execute-api.us-east-1.amazonaws.com/prod/put_get_document/tenant",
    "IAM_DETAILS_API": "https://e8lvdeawkc.execute-api.us-east-1.amazonaws.com/prod/Iam/tenant",
    "ATTRIBUTE_SERVICE_API": "https://1kk7ztnfz5.execute-api.us-east-1.amazonaws.com/prod/associated_parameter_service/tenant_name",
    "ASSIGN_ATTRIBUTE_TO_EMPLOYEE_API": "https://1kk7ztnfz5.execute-api.us-east-1.amazonaws.com/prod/associated_parameter_service/Entity/Employee/id",
    "ATTRIBUTE_SERVICE_EMPLOYEE_COUNT": "https://1kk7ztnfz5.execute-api.us-east-1.amazonaws.com/prod/associated_parameter_service/tenant_name",
    "ATTRIBUTE_SERVICE_EMPLOYEE_LIST": "https://1kk7ztnfz5.execute-api.us-east-1.amazonaws.com/prod/associated_parameter_service/tenant_name",
    "DOCUMENT_DOWNLOAD_LINK": "https://2xcv2cron4.execute-api.us-east-1.amazonaws.com/prod/get_document_link/tenant",
    "TENANT_API_KEY_API": "https://lorzqmmcf0.execute-api.us-east-1.amazonaws.com/prod/APIkey",
    "BAMBOOHR_CREDENTIALS_CRAWLER":"https://nfagma5my4.execute-api.us-east-1.amazonaws.com/prod/serverless_crawler/task/container_name/prod_crawler_service/crawl_type/bamboohr",
     "GOOGLE_CREDENTIALS_CRAWLER": "https://nfagma5my4.execute-api.us-east-1.amazonaws.com/prod/serverless_crawler/task/container_name/prod_crawler_service/crawl_type/google",
//    "GOOGLE_CREDENTIALS_CRAWLER": "https://ds2uam4jof.execute-api.us-east-1.amazonaws.com/async_tenant_registration/prod/invoke_crawler/crawler_name/google_ws_crawler_url/credentials/google_credentials",
//    "AZURE_CREDENTIALS_CRAWLER": "https://ds2uam4jof.execute-api.us-east-1.amazonaws.com/async_tenant_registration/prod/invoke_crawler/crawler_name/azure_ad_crawler_url/credentials/azure_ad_credentials",
     "AZURE_CREDENTIALS_CRAWLER": "https://nfagma5my4.execute-api.us-east-1.amazonaws.com/prod/serverless_crawler/task/container_name/prod_crawler_service/crawl_type/active_directory",
    "TENANT_USERS_POST_API": "https://u505nlr5xl.execute-api.us-east-1.amazonaws.com/prod/registration_authentication/add_user/tenant",
    "TENANT_USERS_GET_DELETE_API": "https://u505nlr5xl.execute-api.us-east-1.amazonaws.com/prod/registration_authentication/tenant",
    "SOCIAL_ACTIVITY_POST_API": "https://m7vg8ids21.execute-api.us-east-1.amazonaws.com/activity_service/prod/tenant_announcement",
 
 
 
    "GET_INVOICES_BY_DATE_API": "https://4k5qz76u4k.execute-api.us-east-1.amazonaws.com/prod/tenant",
    "GET_TENANT_ERRORS": "https://4466mtp5g4.execute-api.us-east-1.amazonaws.com/prod/tenant_registration/toaster_message/tenant",
    "POST_TENANT_DOMAIN": "https://4466mtp5g4.execute-api.us-east-1.amazonaws.com/prod/tenant_registration/domain_creation/tenant",
    "POST_TENANT_DOMAIN": "https://4466mtp5g4.execute-api.us-east-1.amazonaws.com/prod/tenant_registration/domain_creation/tenant",
    "STRIPE_CARD_VALIDATION_API": "https://4k5qz76u4k.execute-api.us-east-1.amazonaws.com/prod/card_verification",
    "TENANT_REMOVE_OR_UNSUBSCRIBE": "https://ztcfh6dobc.execute-api.us-east-1.amazonaws.com/prod/tenant_remove/tenant",
    "DOMAIN_LINK_UNLINK_GET_API": "https://4k5qz76u4k.execute-api.us-east-1.amazonaws.com/prod/custom_domain/tenant",
    "ASSIGN_SEC_MANAGER" : "https://tpwfnkqcm5.execute-api.us-east-1.amazonaws.com/prod/secondary_manager_service/tenant",
    "GET_EMPLOYEE_MANAGERS":"https://tpwfnkqcm5.execute-api.us-east-1.amazonaws.com/prod/secondary_manager_service/tenant",
 
    "GET_EVENTS_BY_TENANT": "https://eody2w7fw5.execute-api.us-east-1.amazonaws.com/prod/work_time_management/tenant",
    "GET_USER_EVENTS_BY_MONTH_YEAR" : "https://eody2w7fw5.execute-api.us-east-1.amazonaws.com/prod/work_time_management",
    "GET_USER_EVENTS_BY_WEEK_MONTH_YEAR" : "https://eody2w7fw5.execute-api.us-east-1.amazonaws.com/prod/work_time_management",
    "GET_USER_EVENTS_BY_DATE_MONTH_YEAR" : "https://eody2w7fw5.execute-api.us-east-1.amazonaws.com/prod/work_time_management",
    }
 
import './ManageDocuments.css';
import { Alert, Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material';
import UserPicker from '../../molecules/UserPicker';
import React, { useRef } from 'react';
import SecureLS from 'secure-ls';
import { environment } from '../../assets/configurations/configuration';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import EmployeeDisplayCard from '../../molecules/EmployeeDisplayCard';
import Loader from '../loader/Loader';
import { Add } from '@mui/icons-material';
import DocumentPaginationTable from '../../molecules/DocumentPaginationTable';

const ManageDocuments = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const inputFile = useRef(null);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [selectedUserDetails, setSelectedUserDetails] = React.useState({});
    const [loading, setLoading] = React.useState();
    const [error, setError] = React.useState();
    const [employeeDocuments, setEmployeeDocuments] = React.useState({});

    React.useEffect(() => {
        if (selectedUser && selectedUser.uniqueId) {
            const config = {
                headers: {
                    "requestagentid": loggedInUserEmail
                }
            }
            setLoading(true);
            axiosInstance
                .get(`${environment.GET_EMPLOYEE_RECORD_API}/${selectedUser.uniqueId}`, config)
                .then(res => {
                    if (res && res.data) {
                        setSelectedUserDetails(res.data);
                        // get the documents associated to the current user from the 
                        // private configuration hashmap
                        const id = selectedUser.uniqueId + "_user_documents";
                        axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/${id}`)
                            .then(res => {
                                setLoading(false);
                                if (res && res.data) {
                                    setEmployeeDocuments(res.data.documents ? res.data.documents : {});
                                }
                            })
                            .catch(err => {
                                setLoading(false);
                                setError(err && err.message);
                                console.log(err && err.msg);
                            })
                    }
                }).catch(err => {
                    setLoading(false);
                    setError(err.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant, selectedUser]);

    function handleSelectedFile(event) {
        event.preventDefault();
        setLoading(true);
        const file = event.target.files[0];
        if (file) {
            // hack to replace the spaces in filenames with '_'.
            let fileName = file.name;
            fileName = fileName.replace(/ /g, "_");
            // start uploading the documents to the bulk upload and upon successful
            // the corresponding data should be stored into the private configuration
            let config = {
                headers: {
                    filename: fileName
                }
            }
            let documentInformation = {};
            const id = selectedUser.uniqueId + "_user_documents";
            //let source_path = "";
            axiosInstance.post(`${environment.EMPLOYEE_BULK_IMAGE_UPLOAD_API}/${tenant}/visibility/private/document/console/id_type/documents/id/${id}`,
                {}, config)
                .then(res => {
                    console.log(res);
                    if (res && res.data) {
                        // needed to save into the private configuration
                        documentInformation.id = res.data.filename;
                        documentInformation.filename = res.data.filename;
                        //documentInformation.created_time = res.data.created_time;
                        documentInformation.file_path = res.data.file_path;

                        // other information if needed
                        documentInformation.filetype = file.type;
                        documentInformation.filesize = file.size;
                        documentInformation.created_time = new Date();

                        const { url } = res.data.presigned_url_details;
                        const { fields } = res.data.presigned_url_details;
                        //source_path = res.data.file_path;
                        const formData = new FormData();
                        formData.append("key", fields['key']);
                        formData.append("AWSAccessKeyId", fields['AWSAccessKeyId']);
                        formData.append("x-amz-security-token", fields['x-amz-security-token']);
                        formData.append("policy", fields['policy']);
                        formData.append("signature", fields['signature']);
                        formData.append("file", file);

                        const preSignedHeaders = new Headers();
                        preSignedHeaders.append("Access-Control-Allow-Credentials", "true");
                        preSignedHeaders.append("Content-Type", ' ');
                        const requestOptions = {
                            method: 'POST',
                            headers: preSignedHeaders,
                            body: formData,
                            redirect: 'follow',
                            mode: 'no-cors',
                        };
                        fetch(url, requestOptions)
                            .then(res => {
                                // save the file metadata information to the private configuration
                                // add new file to current documents
                                employeeDocuments[documentInformation.id] = documentInformation;
                                const config = {
                                    documents: employeeDocuments
                                }
                                axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/${id}`, config)
                                    .then(res => {
                                        setLoading(false);
                                        // update table
                                    })
                                    .catch(err => {
                                        setLoading(false);
                                        setError(err && err.message);
                                        console.log(err && err.msg);
                                    })

                            })
                            .catch(err => {
                                setError(err && err.message);
                                setLoading(false);
                                console.log(err);
                            })
                    }
                })
                .catch(err => {
                    setError(err && err.message);
                    setLoading(false);
                    console.log(err);
                })
        }
    }

    function handleSelectUser(user) {
        // reset the current selected user.
        handleClearSearchItem();        
        setSelectedUser(user);
    }

    function handleClearSearchItem() {
        setSelectedUser(null);
        setEmployeeDocuments({});
    }

    return (
        <Box className="kmc-console-managedocuments-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity='error'>{error}</Alert> : null}
            <Box className="kmc-console-managedocuments-content-box">
                <Card className="kmc-console-managedocuments-info-card">
                    <Typography variant="h6">Manage People & Common Documents</Typography>
                </Card>
                <Card className="kmc-console-managedocuments-content-card">
                    <Box component="form" className="kmc-console-managedocuments-form-box">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <UserPicker onSelectSearchItem={handleSelectUser} onClearSearchItem={handleClearSearchItem}
                                    label={"Search User"}
                                ></UserPicker>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    selectedUser && !loading ?
                                        <Grid item xs={12}>
                                            <Card className="kmc-console-managedocuments-selecteduser-facade">
                                                <CardContent>
                                                    <Grid container sx={{ alignItems: "center" }}>
                                                        <Grid item xs={6}>
                                                            {
                                                                selectedUser
                                                                    ? <EmployeeDisplayCard employee={selectedUser} requestToUpdateAsset={false}></EmployeeDisplayCard>
                                                                    : <></>
                                                            }
                                                        </Grid>                                                       
                                                        <Grid item xs={6} sx={{ backgroundColor: "aliceblue", padding: "0.5rem" }}>
                                                            <Typography fontSize={"13px"}>{selectedUserDetails?.title}</Typography>
                                                            <Typography fontSize={"13px"}>{selectedUserDetails?.contactEmail}</Typography>
                                                            <Typography fontSize={"13px"}>
                                                                {selectedUserDetails?.businessPhones && selectedUserDetails.businessPhones.length > 0 ? selectedUserDetails?.businessPhones[0] : ""}
                                                            </Typography>
                                                            <Typography fontSize={"13px"}>{selectedUserDetails?.locationName}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <input type="file" hidden ref={inputFile} onChange={handleSelectedFile}></input>
                                                            <Chip
                                                                label="Add Document"
                                                                icon={<Add></Add>}
                                                                color="secondary"
                                                                variant='outlined'
                                                                onClick={(e) => { inputFile.current.click() }}
                                                                disabled={loading}
                                                            >
                                                            </Chip>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    selectedUser && !loading
                                        ? <DocumentPaginationTable
                                            documents={employeeDocuments}
                                            selected_user_id={selectedUser.uniqueId}
                                        >
                                        </DocumentPaginationTable>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

export default ManageDocuments;
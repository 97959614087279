import { Navigate } from "react-router-dom";
import SecureLS from "secure-ls";
const ProtectedRoute = ({ children }) => {
    const loginState = new SecureLS().get("_secure_login_state");
    if (loginState) {
      return children;
    }
    return <Navigate to="/login" replace />;
  };

  export default ProtectedRoute;
export const headerCells = [
    {
        id: "domain",
        numeric: false,
        disablePadding: true,
        label: "Domain",
        sortable: false
    },
    {
        id: "type",
        numeric: false,
        disablePadding: true,
        label: "Type",
        sortable: false
    },
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
        sortable: false
    },
    {
        id: "data",
        numeric: false,
        disablePadding: true,
        label: "Data",
        sortable: false
    },
];
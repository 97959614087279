import { Route, Routes } from "react-router-dom";
import "./App.css";
import TenantLogin from "./components/auth/TenantLogin";
import RegisterDomain from "./atoms/domain/RegisterDomain";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import VerifyEmail from "./components/auth/VerifyEmail";
import HomeComponent from "./components/home/Home";
import ProtectedRoute from "./misc/ProtectedRoute";
import PlanDetails from "./atoms/plandetails/PlanDetails";
import Payment from './atoms/payment/Payment';
import Credentials from './atoms/credentials/Credentials';
import Branding from "./atoms/customization/Branding";
import Layout from "./components/layout/Layout";
import Publish from "./atoms/publish/PublishTenent";
import OnBoard from "./atoms/onboard/OnBoard";
import OffBoard from "./atoms/offBoard/OffBoard";
import EditEmpProfile from "./atoms/editempprofile/EditEmpProfile";
import SecondaryManager from "./atoms/secondarymanager/SecondaryManager";

import EmployeeDataStore from "./atoms/bulkupload/EmployeeDataStore";
import EditProfile from "./atoms/setttings/editprofile/EditProfile";
import Mapping from "./atoms/mapping/Mapping";
import ManageAdmin from "./atoms/manageadmin/ManageAdmin";
import ManageConfigurations from "./atoms/configmgmt/ManageConfigurations";
import ManageDocuments from "./atoms/documentmgmt/ManageDocuments";
import APIKey from "./atoms/setttings/apikey/APIKey";
import CompanyDetails from "./atoms/setttings/companydetails/CompanyDetails";
import ResetPassword from "./atoms/setttings/resetpassword/ResetPassword";
import ManageBilling from "./atoms/billing/ManageBilling";
import ManageBadge from "./atoms/badgemgmt/ManageBadge";
import ManageCommonDocuments from "./atoms/documentmgmt/ManageCommonDocuments";
import ManagePermission from "./atoms/permissionsmgmt/ManagePermission";
import CsvUpload from "./molecules/CsvUpload";
import ExcelUpload from "./molecules/ExcelUpload";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetLoginPassword from "./components/auth/ResetLoginPassword";
import ManageDocumentCheckList from "./atoms/documentmgmt/ManageDocumentCheckList";
import ManageLocation from "./atoms/locationmgmt/ManageLocation";
import TimeTracker from "./atoms/timetracker/TimeTrackerMgmt";
import ManageSocialAnnouncements from "./atoms/socialmgmt/ManageSocialAnnouncements";
import TenantNotifications from "./molecules/TenantNotifications";
import { useEffect, useState } from "react";
import AssignSecondaryManager from "./components/assignsecondarymanager/AssignSecondaryManager";
import EventConfiguration from "./atoms/configmgmt/EventConfiguration";

function App() {
  useEffect(() => {
if(localStorage.getItem('loginTime'))
{
  startLogoutTimer();
}
  })

  const startLogoutTimer = () => {
    const loginTime = localStorage.getItem('loginTime'); // Retrieve the login time
    const currentTime = new Date().getTime(); // Current time in milliseconds
    const twentyFourHours = 24 * 60 * 60 * 1000; 
    const timeUntilLogout = twentyFourHours - (currentTime - loginTime);

    // Set a timeout to log the user out after 24 hours
    setTimeout(() => {
      // Perform the logout action (e.g., clear user session)
      logout();
    }, timeUntilLogout);
  };

  const logout = () => {
    // Clear user session (e.g., remove tokens, clear local storage)
    // localStorage.removeItem('loginTime');
    localStorage.clear();
    // Redirect the user to the login page
    window.location.href = '/login';
  };

  return (
    <Routes>
      <Route index element={<Login />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/TenantLogin" element={<TenantLogin />}></Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/confirm" element={<VerifyEmail />}></Route>
      <Route path="/forgot/password" element={<ForgotPassword />}></Route>
      <Route path="/reset/password" element={<ResetLoginPassword />}></Route>
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Layout component={HomeComponent}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/workspace/register"
        element={
          <ProtectedRoute>
            <Layout component={RegisterDomain}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/workspace/customize"
        element={
          <ProtectedRoute>
            <Layout component={Branding}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/workspace/plan"
        element={
          <ProtectedRoute>
            <Layout component={PlanDetails}></Layout>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/workspace/upload/employee"
        element={
          <ProtectedRoute>
            <Layout component={EmployeeDataStore}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/workspace/upload/csv"
        element={
          <ProtectedRoute>
            <Layout component={CsvUpload}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/workspace/upload/excel"
        element={
          <ProtectedRoute>
            <Layout component={ExcelUpload}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/workspace/credentials"
        element={
          <ProtectedRoute>
            <Layout component={Credentials}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/workspace/payment"
        element={
          <ProtectedRoute>
            <Layout component={Payment}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/workspace/mapping"
        element={
          <ProtectedRoute>
            <Layout component={Mapping}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/workspace/publish"
        element={
          <ProtectedRoute>
            <Layout component={Publish}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/management/onboard"
        element={
          <ProtectedRoute>
            <Layout component={OnBoard}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/management/offboard"
        element={
          <ProtectedRoute>
            <Layout component={OffBoard}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/management/edit/employee"
        element={
          <ProtectedRoute>
            <Layout component={EditEmpProfile}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/management/Assign_secondary_manager"
        element={
          <ProtectedRoute>
            <Layout component={AssignSecondaryManager}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/badges"
        element={
          <ProtectedRoute>
            <Layout component={ManageBadge}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/admins"
        element={
          <ProtectedRoute>
            <Layout component={ManageAdmin}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/configuration"
        element={
          <ProtectedRoute>
            <Layout component={ManageConfigurations}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/event_configuration"
        element={
          <ProtectedRoute>
            <Layout component={EventConfiguration}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/announcements"
        element={
          <ProtectedRoute>
            <Layout component={ManageSocialAnnouncements}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/documents"
        element={
          <ProtectedRoute>
            <Layout component={ManageDocuments}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/common/documents"
        element={
          <ProtectedRoute>
            <Layout component={ManageCommonDocuments}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/documents/checklist"
        element={
          <ProtectedRoute>
            <Layout component={ManageDocumentCheckList}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/permissions"
        element={
          <ProtectedRoute>
            <Layout component={ManagePermission}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>      
      <Route
        path="/manage/billing"
        element={
          <ProtectedRoute>
            <Layout component={ManageBilling}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/manage/locations"
        element={
          <ProtectedRoute>
            <Layout component={ManageLocation}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/settings/apikey"
        element={
          <ProtectedRoute>
            <Layout component={APIKey}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>

      <Route
        path="/settings/company"
        element={
          <ProtectedRoute>
            <Layout component={CompanyDetails}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>

      <Route
        path="/settings/editprofile"
        element={
          <ProtectedRoute>
            <Layout component={EditProfile}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/settings/reset/password"
        element={
          <ProtectedRoute>
            <Layout component={ResetPassword}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/tenant/notifications"
        element={
          <ProtectedRoute>
            <Layout component={TenantNotifications}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
       <Route
        path="/management/secondary_manager"
        element={
          <ProtectedRoute>
            <Layout component={SecondaryManager}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
      <Route
        path="/management/timetracker"
        element={
          <ProtectedRoute>
            <Layout component={TimeTracker}></Layout>
          </ProtectedRoute>
        }
      >
      </Route>
    </Routes>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import SecureLS from "secure-ls";
import {
  Alert,
  Avatar,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Close, SearchRounded } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import PrivateEncodedImageUrl from "../misc/PrivateEncodedImageUrl";
import Loader from "../atoms/loader/Loader";

const UserPicker = (props) => {
  const secureLs = new SecureLS();
  const tenant = secureLs.get("tenant_name");
  const _secure_user_info = secureLs.get("_secure_user_info");
  const loggedInUserEmail =
    _secure_user_info &&
    _secure_user_info.attributes &&
    _secure_user_info.attributes["email"];
  const { label } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState([]);

  const { control, setValue, register, watch } = useForm({
    defaultValues: {
      searchItem: "",
    },
  });

  const searchItem = watch("searchItem");
  useEffect(() => {
    if (searchItem && searchItem.length > 1) {
      setLoading(true);
      axiosInstance
        .get(
          `${environment.AUTO_COMPLETE_USER_API}/${tenant}/prefix/${searchItem}`,
          {
            headers: {
              requestagentid: loggedInUserEmail,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            setData(res.data);
            getImage(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [searchItem, tenant, loggedInUserEmail]);

  const getImage = (users) => {
    const avatarPromises = users.map((user) => {
      const url = PrivateEncodedImageUrl(tenant, user.image_name);
      return axiosInstance
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = res.data;
          const imageUrl = URL.createObjectURL(blob);
          return imageUrl;
        });
    });

    Promise.all(avatarPromises).then((avatarUrls) => {
      setLogo(avatarUrls);
    });
  };

  return (
    <Box className="kmc-console-userpicker-box">
      <Controller
        position="fixed"
        control={control}
        name="searchItem"
        render={({ field }) => (
          <TextField
            {...field}
            {...register("searchItem")}
            fullWidth
            size="medium"
            InputLabelProps={{ shrink: true }}
            label={label}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded fontSize="medium" sx={{ color: "#1C243C" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Close
                    fontSize="small"
                    sx={{ color: "#1C243C" }}
                    onClick={(e) => {
                      setValue("searchItem", "");
                      setData([]);
                      props && props.onClearSearchItem([]);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      ></Controller>
      {!loading && data && data.length > 0 ? (
        <List
          component="div"
          disablePadding
          //                        style={{ backgroundColor: "aliceblue", zIndex: 999 }}>
          style={{
            backgroundColor: "aliceblue",
            zIndex: 999,
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          {data.map((user, key) => {
            return (
              <ListItemButton
                key={key}
                onClick={(e) => {
                  e.preventDefault();
                  props.onSelectSearchItem(user);
                  setValue("searchItem", "");
                  setData([]);
                }}
              >
                <ListItemIcon>
                  <Avatar src={logo[key]} alt={user.givenName} color="primary" />
                </ListItemIcon>
                <ListItemText primary={user.givenName} />
              </ListItemButton>
            );
          })}
        </List>
      ) : (
        <Loader loading={loading} />
      )}
      {searchItem.length !== 0 && data.length <= 0 ? (
        <Alert severity="warning">
          No records found for given name {searchItem}
        </Alert>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default UserPicker;

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CircularProgress, Dialog, DialogTitle, Grid, TextField} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SecureLS from "secure-ls";
import * as Yup from 'yup';
import { environment } from "../assets/configurations/configuration";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import './BoomADForm.css';

const BoomADForm = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        bamboohr_api_key: Yup.string().required(),
        bamboohr_sub_domain: Yup.string().required()
    });


    const formOptions = { resolver: yupResolver(validationSchema) };

    const { handleSubmit, formState, control, register, reset,setValue } = useForm(formOptions, {
        defaultValues: {
            bamboohr_api_key: "",
            bamboohr_sub_domain: ""
        }
    });


    const { errors } = formState;

    const handleOnSubmit = (data) => {
        if (data) {
            setLoading(true);
            axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/bamboohr_credentials`, data)
                .then(res => {
                    setLoading(false);
                    reset();
                    onClose("boomHRCard");
                })
                .catch(err => {
                    setLoading(false);
                });
        }
    }

    const { onClose, open } = props;

    useEffect(() => {
        // get existing AD configuration
        if(open) {
            setLoading(true);
            axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/bamboohr_credentials`)
                .then(res => {
                    setLoading(false);
                    setValue("bamboohr_sub_domain", res.data.bamboohr_sub_domain);

                    setValue("bamboohr_api_key", res.data.bamboohr_api_key);

                })
                .catch(res => {
                    setLoading(false);
                });
        }

    },[open, setValue, tenant]);

    const handleClose = () => {
        reset();
        setLoading(false);
        onClose("boomHRCard");
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>BoomHR Active Directory Configuration</DialogTitle>
            <Box className="kmc-console-boomhr-content-box" component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="bamboohr_sub_domain"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("bamboohr_sub_domain")} label="BoomHR AD Subdomain Name"
                                    InputLabelProps={{ shrink: true }} error={errors?.bamboohr_sub_domain ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="bamboohr_api_key"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("bamboohr_api_key")} label="BoomHR AD API Key"
                                    InputLabelProps={{ shrink: true }} error={errors?.bamboohr_api_key ? true : false} />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20}></CircularProgress> : <></>}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )

}

export default BoomADForm;
import React, { useEffect, useState } from "react";
import { Button, Card, Typography, Box, InputAdornment, TextField, Alert, Grid } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import './ResetPassword.css';
import SecureLS from "secure-ls";
import Loader from "../../loader/Loader";
import { Amplify, Auth } from "aws-amplify";
import { environment } from "../../../assets/configurations/configuration";

Amplify.configure({
    Auth: environment.cognito,
});

const ResetPassword = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = new SecureLS().get("_secure_user_info");
    const email = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        if (data && data.newpassword) {
            setLoading(true);
            Auth.currentAuthenticatedUser()
                .then(user => {
                    Auth.changePassword(user, data.oldpassword, data.newpassword)
                        .then((res) => {
                            setLoading(false);
                            setData(null);
                            reset();
                        })
                        .catch((err) => {
                            setLoading(false);
                            setError(err.message);
                        });
                }).catch(err => {
                    setLoading(false);
                    setError(err.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant, data]);

    const handleOnSubmit = (data) => {
        setData(data);
    };

    const togglePasswordHide = (type) => {
        if (type === 'old_password') {
            setShowPassword(!showPassword);
        }
        if (type === 'new_password') {
            setShowNewPassword(!showNewPassword);
        }
    };

    const validationSchema = Yup.object().shape({
        oldpassword: Yup.string().required('Old password is required.'),
        newpassword: Yup.string()
            .required('New password is required.')            
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
                "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character."
            ),

    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, register, formState, reset } = useForm(formOptions);
    const { errors } = formState;
    return (
        <Box className="kmc-console-reset-password-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error" onClose={() => setError(null)}>
                {error}
            </Alert> : <></>}
            <Box className="kmc-console-reset-password-main-content-box">
                <Card className="kmc-console-reset-password-info-card">
                    <Typography variant="h6">Reset Profile Password</Typography>
                </Card>
                <Card className="kmc-console-reset-password-content-card">
                    <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-reset-password-form-box">
                        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                            <Grid item sm={7} xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    placeholder="Email"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    value={email}
                                />
                            </Grid>
                            <Grid item sm={7} xs={12}>
                                <TextField
                                    fullWidth
                                    type={showPassword ? "text" : "password"}
                                    id="oldpassword"
                                    label="Old Password"
                                    name="oldpassword"
                                    autoComplete="oldpassword"
                                    InputLabelProps={{ shrink: true }}
                                    error={errors?.oldpassword}
                                    InputProps={
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end"> {
                                                    showPassword ? (
                                                        <Visibility className="cursor_pointer"
                                                            onClick={() => {
                                                                togglePasswordHide('old_password')
                                                            }}
                                                        />
                                                    ) : (
                                                        <VisibilityOff
                                                            onClick={() => {
                                                                togglePasswordHide('old_password')
                                                            }}
                                                        />
                                                    )
                                                }
                                                </InputAdornment>
                                            ),
                                        }
                                    }
                                    {...register("oldpassword")}
                                    helperText={errors?.oldpassword?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <TextField
                                    fullWidth
                                    type={showNewPassword ? "text" : "password"}
                                    id="newpassword"
                                    label="New Password"
                                    name="newpassword"
                                    autoComplete="newpassword"
                                    error={errors?.newpassword}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end"> {
                                                    showNewPassword ? (
                                                        <Visibility className="cursor_pointer"
                                                            onClick={() => {
                                                                togglePasswordHide('new_password')
                                                            }}
                                                        />
                                                    ) : (
                                                        <VisibilityOff
                                                            onClick={() => {
                                                                togglePasswordHide('new_password')
                                                            }}
                                                        />
                                                    )
                                                }
                                                </InputAdornment>
                                            ),
                                        }
                                    }
                                    {...register("newpassword")}
                                    helperText={errors?.newpassword?.message}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outlined" color="primary"
                                    size="small" type="submit"
                                    disabled={loading}
                                >Reset Password</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    );
}

export default ResetPassword;
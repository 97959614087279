import './ManageBilling.css';
import { Alert, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import InvoiceItem from './InvoiceItem';
import { useEffect, useState } from 'react';
import SecureLS from 'secure-ls';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import { environment } from '../../assets/configurations/configuration';
import Loader from '../loader/Loader';
import { FilterAlt, Subscriptions } from '@mui/icons-material';

const ManageBilling = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [selectedDate, setSelectedDate] = useState('current_year');
    const [cancelSubscriptionDialog, setCancelSubscriptionDialog] = useState(false);

    useEffect(() => {
        // by default get current year invoice
        const min_date_timestamp = getMinDateTimeStamp(selectedDate);
        const max_date_timestamp = new Date().getTime();

        setLoading(true);
        axiosInstance.get(`${environment.GET_INVOICES_BY_DATE_API}/${tenant}/download_invoices_by_month/min_date/${min_date_timestamp / 1000}/max_date/${max_date_timestamp}`)
            .then(res => {
                setLoading(false);
                if (res && res.data) {
                    setInvoices(res.data?.data);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err?.message);
            });
    }, [tenant, selectedDate]);

    const getMinDateTimeStamp = (selected_date) => {
        switch (selected_date) {
            case 'current_year': {
                const current_year = new Date().getFullYear();
                const current_year_start_date = new Date(current_year, 0, 1);
                return new Date(current_year_start_date).getTime();
            }
            case 'last_one_year': {
                const last_one_year = new Date().getFullYear() - 1;
                const last_one_year_start_date = new Date(last_one_year, 0, 1);
                return new Date(last_one_year_start_date).getTime();
            }
            case 'last_two_year': {
                const last_two_year = new Date().getFullYear() - 2;
                const last_two_year_start_date = new Date(last_two_year, 0, 1);
                return new Date(last_two_year_start_date).getTime();
            }
            case 'no_date_archived': {
                return 0;
            }
            default: {
                console.error("Invalid selection.");
            }
        }
    }

    const handleSelectedDate = (event, selectedDate) => {
        event.preventDefault();
        setSelectedDate(selectedDate);
    }

    return (
        <Box className="kmc-console-manage-billing-main-box">
            <Loader loading={loading}></Loader>
            {
                error ? <Alert severity='error' onClose={() => setError(null)}>{error}</Alert> : <></>
            }
            {
                message ? <Alert severity='info' onClose={() => setMessage(null)}>{message}</Alert> : <></>
            }
            <Box className="kmc-console-manage-billing-content-box">
                <Card className="kmc-console-manage-billing-info-card">
                    <Typography variant="h6">Your Orders</Typography>
                </Card>
                <Card className="kmc-console-manage-billing-content-card">
                    <Grid container spacing={1.5} sx={{ paddingTop: "1rem" }}>
                        <Grid item xs={6} sx={{ textAlign: "start" }}>
                            <Button variant={selectedDate === 'current_year' ? "contained" : "outlined"} size={"small"}
                                startIcon={selectedDate === 'current_year' ? <FilterAlt></FilterAlt> : <></>}
                                sx={{ textTransform: 'none', marginRight: "0.25rem" }}
                                onClick={(event) => handleSelectedDate(event, 'current_year')}
                            >
                                Current Year
                            </Button>
                            <Button variant={selectedDate === 'last_one_year' ? "contained" : "outlined"} size={"small"}
                                startIcon={selectedDate === 'last_one_year' ? <FilterAlt></FilterAlt> : <></>}
                                sx={{ textTransform: 'none', marginRight: "0.25rem" }}
                                onClick={(event) => handleSelectedDate(event, 'last_one_year')}>
                                Last 1 Year
                            </Button>
                            <Button variant={selectedDate === 'last_two_year' ? "contained" : "outlined"} size={"small"}
                                startIcon={selectedDate === 'last_two_year' ? <FilterAlt></FilterAlt> : <></>}
                                sx={{ textTransform: 'none', marginRight: "0.25rem" }}
                                onClick={(event) => handleSelectedDate(event, 'last_two_year')}>
                                Last 2 Year
                            </Button>
                            <Button variant={selectedDate === 'no_date_archived' ? "contained" : "outlined"} size={"small"}
                                startIcon={selectedDate === 'no_date_archived' ? <FilterAlt></FilterAlt> : <></>}
                                sx={{ textTransform: 'none', marginRight: "0.25rem" }}
                                onClick={(event) => handleSelectedDate(event, 'no_date_archived')}>
                                Archived Orders
                            </Button>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "end" }}>
                            <Button
                                color='error'
                                startIcon={<Subscriptions />}
                                variant='contained'
                                onClick={(event) => {
                                    event.preventDefault();
                                    setCancelSubscriptionDialog(!cancelSubscriptionDialog);
                                }}
                                sx={{ textTransform: 'none', backgroundColor: "#c23a3a" }}
                            >
                                Cancel Subscription
                            </Button>
                            <CancelSubscriptionDialog open={cancelSubscriptionDialog}
                                onClose={() => setCancelSubscriptionDialog(!cancelSubscriptionDialog)}
                                tenant={tenant}
                                onSuccess={(success) => setMessage(success?.message)}
                            >
                            </CancelSubscriptionDialog>
                        </Grid>
                        {
                            invoices && invoices.length > 0
                                ? invoices.map((invoice, key) => {
                                    return (
                                        <Grid item xs={12} key={key}>
                                            <InvoiceItem invoice={invoice} key={key}></InvoiceItem>
                                        </Grid>
                                    )
                                })
                                : <Grid item xs={12}>
                                    {
                                        !loading ? <Typography sx={{ fontSize: "15px", color: "darkgoldenrod" }}>No records found</Typography> : <></>
                                    }
                                </Grid>
                        }
                    </Grid>
                </Card>
            </Box>
        </Box>
    )
}

export default ManageBilling;

export const CancelSubscriptionDialog = (props) => {

    const { open, onClose, tenant, onSuccess } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    return (
        <Dialog open={open} onClose={onClose}>
            <Loader loading={loading}></Loader>
            {
                error ? <Alert severity='error' onClose={() => setError(null)}>{error}</Alert> : <></>
            }
            <DialogTitle sx={{ color: "#c23a3a" }}>Do you wish to cancel subscription?</DialogTitle>
            <DialogContent>
                <Typography>
                    The current domain accounts and other resources assosiated with it will be removed from the system if the subscription is cancelled.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ textTransform: 'none' }}
                    variant="outlined"
                    color={"error"}
                    onClick={() => {
                        // call the subscription api   
                        setLoading(true);
                        axiosInstance.post(`${environment.TENANT_REMOVE_OR_UNSUBSCRIBE}/${tenant}/cancel_subscription`)
                            .then(res => {
                                setLoading(false);
                                onClose();
                                onSuccess({ message: "Your subscription has been cancelled successfully." })
                            })
                            .catch(err => {
                                setLoading(false);
                                setError(err && err?.message);
                            });
                    }}
                >
                    Continue
                </Button>
                <Button
                    sx={{ textTransform: 'none' }}
                    variant="outlined"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
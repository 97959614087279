import {
    Alert, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Grid,
    TextField
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import "./MappingRow.css";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import SecureLS from "secure-ls";
import { useEffect, useState } from "react";
import Loader from "../atoms/loader/Loader";
import { CloseRounded } from "@mui/icons-material";
import AttributePreview from "./AttributePreview";
import LocationSearchPlaces from "./LocationSearchPlaces";
import PrivateEncodedImageUrl from "../misc/PrivateEncodedImageUrl";
import MuiPhoneNumber from 'material-ui-phone-number';

export default function RegisterOfficeLocation(props,{ onChildDataChange }) {

    const { onLocationSuccess, onCloseLocation, editMode, locationRow, locationName } = props;
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [file, setFile] = useState();
    const [geoLocation, setGeoLocation] = useState({});

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        contactEmail: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { formState, control, register, handleSubmit, watch, setValue, reset } = useForm(formOptions, {
        defaultValues: {
            name: "",
            contactPhone: "",
            contactEmail: "",
            isHeadQuarter: false,
            //isSezLocation: false
        }
    });

    const nameWatch = watch("type");
    const { errors } = formState;

    useEffect(() => {
        if (editMode && locationRow) {
            const { name, contactPhone, contactEmail, isHeadQuarter } = locationRow;
            setValue("name", name);
            setValue("contactPhone", contactPhone);
            setValue("contactEmail", contactEmail);
            setValue("isHeadQuarter", isHeadQuarter);
            //setValue('isSezLocation', isSezLocation);
            setGeoLocation({
                address: locationName,
                latLng: {
                    lat: locationRow.location_details?.lat,
                    lng: locationRow.location_details?.lon
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationRow, editMode, setValue]);


    const handleLocationCardClose = (e) => {
        onCloseLocation();
    }

    const handleSelectedOfficeLogo = (file) => {
        setFile(file);
    }

    const handleSelectedLocation = (location) => {
        setGeoLocation({
            address: location?.address,
            latLng: location?.latLng,
            locality: location?.locality
        });
    }

    const handleOnSubmit = (data) => {
        if (data) {
            data.entityType = "OfficeLocation";
            data.isActive = true;
            data.company = tenant;
            data.locationName = geoLocation?.address;
            data.latitude = (geoLocation?.latLng?.lat)?.toString();
            data.longitude = (geoLocation?.latLng?.lng)?.toString();
            data.officeLocalityJson = geoLocation?.locality;
            data.image_name = data.contactEmail + data.locationName;
            data.image_name = data.image_name.split(' ').join('');
            const config = {
                headers: {
                    "changeagentid": loggedInUserEmail
                }
            }
            const locationEncodedUrl = PrivateEncodedImageUrl(tenant, data?.image_name);
            setLoading(true);
            axiosInstance.post(environment.CREATE_OFFICE_RECORD_API, data, config)
                .then(res => {
                    setLoading(false);
                    reset();
                    onLocationSuccess(`Office ${data?.name} registered successfully.`);
                    setFile(null);
                    props.onChildDataChange(); // Call the callback function to send data to the parent
                })
                .catch(err => {
                    setLoading(false);
                    setError(err.message);
                })

            // update location logo using the image encoder utility
            if (file) {
                setLoading(true);
                axiosInstance.post(locationEncodedUrl, file)
                    .then(res => {
                        setLoading(false);
                        reset();
                        setFile(null);
                    })
                    .catch(err => {
                        setLoading(false);
                        setError(err & err?.message);
                    });
            }
        }
    }

    return (
        <Card component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-mapping-row-facade">
            <Loader loading={loading}></Loader>
            {
                error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : null
            }
            <CardHeader
                title={<CloseRounded sx={{ color: "darkgray" }} onClick={handleLocationCardClose}></CloseRounded>}
                sx={{ textAlign: "right", padding: "8px" }}
            >
            </CardHeader>
            <CardContent>
                <Grid container sx={{ alignItems: "center" }}>
                    <Grid item xs>
                        <AttributePreview
                            isPermission={false}
                            isBadge={false}
                            isLocation={true}
                            name={nameWatch}
                            requestToUpdateBadge={true}
                            onSelectAsset={handleSelectedOfficeLogo}
                        >
                        </AttributePreview>
                    </Grid>
                    <Grid container xs={12} sm spacing={2} sx={{ textAlign: "left" }}>
                        <Grid item xs={12}>
                            <LocationSearchPlaces
                                onSelectAddress={handleSelectedLocation}
                                address={geoLocation?.address}
                                searchType={'office'}
                            >
                            </LocationSearchPlaces>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='name'
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        {...register("name")}
                                        label="Name"
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        error={errors?.name ? true : false}
                                    />
                                )}
                            >
                            </Controller>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                defaultValue={''}
                                name="contactPhone"
                                render={({ field }) => (
                                    <MuiPhoneNumber
                                        fullWidth
                                        defaultCountry={'us'}
                                        {...field}
                                        {...register("contactPhone")}
                                        label="Mobile"
                                        name="contactPhone"
                                        onChange={(value) => setValue("contactPhone", value)}
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        error={errors?.contactPhone ? true : false}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='contactEmail'
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        {...register("contactEmail")}
                                        label="Email"
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        error={errors?.contactEmail ? true : false}
                                    />
                                )}
                            >
                            </Controller>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                defaultValue={false}
                                name="isHeadQuarter"
                                render={({ field: { value, ...field } }) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            {...field}
                                            name="isHeadQuarter"
                                            {...register("isHeadQuarter")}
                                            checked={!!value}
                                            control={<Checkbox size="small" />}
                                            label="Head Quarters"
                                        />
                                    </FormGroup>
                                )}
                            >
                            </Controller>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="isSezLocation"
                                defaultValue={false}
                                render={({ field: { value, ...field } }) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            {...field}
                                            name="isSezLocation"
                                            {...register("isSezLocation")}
                                            checked={!!value}
                                            control={<Checkbox size="small" />}
                                            label="SEZ Location"
                                        />
                                    </FormGroup>
                                )}
                            >
                            </Controller>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}>
                            Register Location
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { configHeadCells } from './ConfigurationTableHeadCells';
import { visuallyHidden } from '@mui/utils';
import { Key, Visibility } from "@mui/icons-material";

export default function ConfigurationTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead sx={{ backgroundColor: "#cdd1dc" }}>
            <TableRow>
                {configHeadCells.map((configHeadCell) => (
                    <TableCell
                        key={configHeadCell.id}
                        align={configHeadCell.numeric ? 'right' : 'left'}
                        //padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === configHeadCell.id ? order : false}
                    >
                        {
                            configHeadCell.sortable ?
                                <TableSortLabel
                                    active={orderBy === configHeadCell.id}
                                    direction={orderBy === configHeadCell.id ? order : 'asc'}
                                    onClick={createSortHandler(configHeadCell.id)}
                                >
                                    {configHeadCell.id === "configKey" ? <Key></Key> : null}
                                    {configHeadCell.label}
                                    {orderBy === configHeadCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                                : <TableSortLabel hideSortIcon>
                                    {configHeadCell.id === "isSensitive" ? <Visibility></Visibility> : null}
                                    {configHeadCell.label}
                                </TableSortLabel>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export const configHeadCells = [
    {
        id: "configKey",
        numeric: false,
        disablePadding: true,
        label: "Configuration Key",
        sortable: true
    },
    {
        id: "configValue",
        numeric: false,
        disablePadding: true,
        label: "Configuration Value",
        sortable: false
    },
    {
        id: "isSensitive",
        numeric: false,
        disablePadding: true,
        label: "Sensitive",
        sortable: false
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: true,
        label: "Actions",
        sortable: false
    }
]

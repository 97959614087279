import React, { useState, useRef, useEffect } from "react";
import { Box, Card, Grid, TextField, Button, Typography, Alert } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { Cancel, EditSharp } from "@mui/icons-material";
import SecureLS from "secure-ls";
import './EditProfile.css';
import EmployeeDisplayCard from "../../../molecules/EmployeeDisplayCard";
import { axiosInstance } from "../../../interceptors/axios/AxiosInstance";
import { environment } from "../../../assets/configurations/configuration";
import Loader from "../../loader/Loader";
import PrivateEncodedImageUrl from "../../../misc/PrivateEncodedImageUrl";
import MuiPhoneNumber from 'material-ui-phone-number';

const EditProfile = () => {

    const secureLs = new SecureLS();
    const tenant = secureLs.get("tenant_name");
    const _secure_user_info = secureLs.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [file, setFile] = useState();
    const [profile, setProfile] = useState({});
    const [editMode, setEditMode] = useState(true);
    const [editImgMode, setEditImgMode] = useState(true);
    const ref = useRef(null);

    useEffect(() => {
        if (ref) {
            // get current profile details
            setLoading(true);
            axiosInstance.post(
                `${environment.IAM_DETAILS_API}/${tenant}/Alias/console/type/tenant_details`,
                { email: loggedInUserEmail })
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        setValue("contactEmail", loggedInUserEmail);
                        if (res.data.profile) {
                            setProfile(res.data.profile);
                            setValue("email", res.data.profile?.email);
                            setValue("firstName", res.data.profile?.firstName);
                            setValue("lastName", res.data.profile?.lastName);
                            setValue("displayName", res.data.profile?.displayName);
                            setValue("phone", res.data.profile?.phone);
                        }
                    }
                }).catch(err => {
                    setLoading(false);
                    setError(err & err.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        displayName: Yup.string().required(),
        phone: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState, control, register, setValue } = useForm(formOptions, {
        defaultValues: {
            firstName: "",
            lastName: "",
            displayName: "",
            contactEmail: "",
            phone: ""
        }
    });
    const { errors } = formState;

    const handleSelectedPicture = (file) => {
        if (file) {
            setEditImgMode(false);
            setFile(file);
        }
    }

    const handleOnSubmit = (data) => {
        const config = {
            email: loggedInUserEmail,
            profile: data
        }
        setLoading(true);
        axiosInstance.post(
            `${environment.IAM_DETAILS_API}/${tenant}/Alias/console/type/tenant_details`, config)
            .then(res => {
                setLoading(false);
                if (res && res.data) {
                    if (res.data.profile) {
                        setEditMode(true);
                        setProfile(res.data.profile);
                    }
                }
            }).catch(err => {
                setLoading(false);
                setError(err & err.message);
            });

        // update profile picture
        if (file) {
            const profileEncodedUrl = PrivateEncodedImageUrl(tenant, loggedInUserEmail);
            axiosInstance.post(profileEncodedUrl, file)
                .then(res => {
                    setLoading(false);
                    setEditImgMode(true);
                    setFile(null);
                }).catch(err => {
                    setLoading(false);
                    setError(err & err.message);
                });
        }
    }
    return (
        <Box className="kmc-console-component-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : <></>}
            <Box className="kmc-console-component-content-box">
                <Card className="kmc-console-edit-profile-info-card">
                    <Typography variant='h6'>Manage Profile</Typography>
                </Card>
                <Card className="kmc-console-edit-profile-content-card">
                    <Box className="kmc-console-edit-profile-cc-form-box">
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid item xs>
                                <EmployeeDisplayCard employee={{ givenName: profile.displayName, image_name: loggedInUserEmail }}
                                    requestToUpdateAsset={true}
                                    onSelectAsset={handleSelectedPicture}
                                >
                                </EmployeeDisplayCard>
                            </Grid>
                            <Grid item sm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="firstName"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <TextField fullWidth {...field} {...register("firstName")} label="Firstname"
                                                    InputLabelProps={{ shrink: true }} variant="standard" error={errors?.firstName ? true : false}
                                                    disabled={editMode}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="lastName"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <TextField fullWidth {...field} {...register("lastName")} label="Lastname"
                                                    InputLabelProps={{ shrink: true }} variant="standard" error={errors?.lastName ? true : false}
                                                    disabled={editMode}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="displayName"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <TextField fullWidth {...field} {...register("displayName")} label="Display Name"
                                                    InputLabelProps={{ shrink: true }} variant="standard" error={errors?.displayName ? true : false}
                                                    disabled={editMode}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="contactEmail"
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <TextField disabled fullWidth {...field} {...register("contactEmail")} label="Email"
                                                InputLabelProps={{ shrink: true }} variant="standard" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="phone"
                                        defaultValue={''}
                                        render={({ field }) => (
                                            <MuiPhoneNumber
                                                fullWidth
                                                disabled={editMode}
                                                defaultCountry={'us'}
                                                {...field}
                                                {...register("phone")}
                                                label="Mobile"
                                                name="phone"
                                                onChange={(value) => setValue("phone", value)}
                                                InputLabelProps={{ shrink: true }}
                                                variant="standard"
                                                error={errors?.phone ? true : false}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sx={{ paddingTop: "1rem", textAlign: 'end' }}>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    size="small"
                                    disabled={loading || (editMode && editImgMode)}
                                    sx={{ textTransform: 'none' }}
                                    onClick={handleSubmit(handleOnSubmit)}
                                >Save Profile</Button>
                            </Grid>
                            <Grid item xs={6} sx={{ paddingTop: "1rem", paddingLeft: "1rem", textAlign: 'start' }}>
                                <Button
                                    type="button"
                                    size="small"
                                    color="secondary"
                                    disabled={loading}
                                    sx={{ textTransform: 'none' }}
                                    endIcon={editMode ? <EditSharp></EditSharp> : <Cancel></Cancel>}
                                    onClick={() => setEditMode(!editMode)}
                                    disableRipple
                                >
                                    {editMode ? 'Edit' : 'Cancel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}
export default EditProfile;
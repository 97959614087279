import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogTitle, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import GooglePNG from "../assets//GooglePNG.png";
import FacebookPNG from "../assets//FacebookPNG.png";
import MicrosoftPNG from "../assets//MicrosoftPNG.png";
import oAuthPNG from "../assets//oAuthPNG.png";
import { yupResolver } from "@hookform/resolvers/yup";
import SecureLS from "secure-ls";
import * as Yup from 'yup';
import { Controller, useForm } from "react-hook-form";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import Loader from "../atoms/loader/Loader";
import { CopyAll, UploadFile } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CredentialsExternalSources = (props) => {

    const [microsoftDialog, setMicrosoftDialog] = useState(false);
    const [googleDialog, setGoogleDialog] = useState(false);
    const [facebookDialog, setFacebookDialog] = useState(false);
    const [oauthDialog, setOauthDialog] = useState(false);
    const [error, setError] = useState();


    const navigate = useNavigate();

    const handleClose = (id) => {
        if (id === "googleSSOCard") {
            setGoogleDialog(!googleDialog);
        }
        if (id === "facebookSSOCard") {
            setFacebookDialog(!facebookDialog)
        }
        if (id === "microsoftSSOCard") {
            setMicrosoftDialog(!microsoftDialog);
        }
        if (id === "oAuthSSOCard") {
            setOauthDialog(!oauthDialog);
        }
    }

    return (
        <Grid container spacing={1}>
            <GoogleSSOIntegrationDialog open={googleDialog} onClose={handleClose}></GoogleSSOIntegrationDialog>
            <FacebookSSOIntegrationDialog open={facebookDialog} onClose={handleClose}></FacebookSSOIntegrationDialog>
            <OAuthSSOIntegrationDialog open={oauthDialog} onClose={handleClose}></OAuthSSOIntegrationDialog>
            <MicrosoftSSOIntegrationDialog open={microsoftDialog} onClose={handleClose}></MicrosoftSSOIntegrationDialog>
            <Grid item xs={8} sx={{ marginTop: "0.25rem", textAlign: "end" }}>
                <Typography className="kmc-console-emp-datastore-group-title">External SSO Providers</Typography>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'end' }}>
                <Button
                    sx={{ textTransform: 'none' }}
                    disableRipple
                    onClick={(event) => {
                        event.preventDefault();
                        navigate('/workspace/payment', {
                            state: {
                                isWorkspaceExpand: true,
                                activeItemId: 996
                            }
                        });
                    }}>
                    Continue
                </Button>
            </Grid>
            <Alert severity="info">
                Neither admin nor user cannot edit the static field. To update the value, it is only possible through console.
            </Alert>
            <Grid item xs={12}>
                {
                    error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : <></>
                }
            </Grid>
            <Grid item xs={3}>
                <Card className="kmc-console-emp-datastore-facade" id="googleSSOCard">
                    <CardHeader title="Google SSO Integration">
                    </CardHeader>
                    <CardContent onClick={() => setGoogleDialog(!googleDialog)}>
                        <img src={GooglePNG} height={100} width={100} alt={"googlewsupload"}></img>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card className="kmc-console-emp-datastore-facade" id="facebookSSOCard">
                    <CardHeader title="Facebook SSO Integration">
                    </CardHeader>
                    <CardContent onClick={() => setFacebookDialog(!googleDialog)}>
                        <img src={FacebookPNG} height={100} width={100} alt={"googlewsupload"}></img>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card className="kmc-console-emp-datastore-facade" id="microsoftSSOCard">
                    <CardHeader title="SAML SSO Integration">
                    </CardHeader>
                    <CardContent onClick={() => {
                        setMicrosoftDialog(!googleDialog);
                    }}>
                        <img src={MicrosoftPNG} height={100} width={100} alt="boomhrupload"></img>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={3}>
                <Card className="kmc-console-emp-datastore-facade" id="oAuthSSOCard">
                    <CardHeader title="OAuth SSO Integration">
                    </CardHeader>
                    <CardContent onClick={() => setOauthDialog(!googleDialog)}>
                        <img src={oAuthPNG} height={100} width={100} alt="boomhrupload"></img>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default CredentialsExternalSources;

export const GoogleSSOIntegrationDialog = (props) => {

    const { onClose, open } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        google_client_id: Yup.string().required(),
        google_client_secret: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { handleSubmit, formState, control, register, reset, setValue, watch } = useForm(formOptions, {
        defaultValues: {
            google_client_id: "",
            google_client_secret: "",
            authorized_domain: "",
            authorized_javascript_origins: "",
            authorized_redirect_urls: "",
            scopes: ""
        }
    });
    const { errors } = formState;

    useEffect(() => {
        // get existing SSO configuration
        if (open) {
            setLoading(true);
            axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/auth_google_credentials`)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        setValue("google_client_id", res.data?.google_client_id);
                        setValue("google_client_secret", res.data?.google_client_secret);
                        setValue("authorized_domain", res.data?.authorized_domain);
                        setValue("authorized_javascript_origins", res.data?.authorized_javascript_origins);
                        setValue("authorized_redirect_urls", res.data?.authorized_redirect_urls);
                        setValue("scopes", res.data?.scopes?.toString());
                    }
                })
                .catch(res => {
                    setLoading(false);
                });
        }

    }, [open, setValue, tenant]);

    const authDomainWatch = watch("authorized_domain");
    const authJavaScriptOriginsWatch = watch("authorized_javascript_origins");
    const authRedirectUrlsWatch = watch("authorized_redirect_urls");
    const scopesWatch = watch("scopes");

    const handleClose = () => {
        reset();
        onClose("googleSSOCard");
    };

    const handleOnSubmit = (data) => {
        if (data) {
            setLoading(true);
            axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/auth_google_credentials`, data)
                .then(res => {
                    setLoading(false);
                    reset();
                    onClose("googleSSOCard");
                })
                .catch(err => {
                    setLoading(false);
                });
        }
    }
    const [authDomaincopying, setAuthDomainCopying] = useState(false);
    const handleAuthDomainCopy = () => {
        setAuthDomainCopying(true);
        setTimeout(() => setAuthDomainCopying(false), 2000);
    }
    const [authJavascriptOrigincopying, setAuthJavascriptOriginCopying] = useState(false);
    const handleAuthJavascriptOriginCopy = () => {
        setAuthJavascriptOriginCopying(true);
        setTimeout(() => setAuthJavascriptOriginCopying(false), 2000);
    }
    const [authRedirectUrlscopying, setAuthRedirectUrlsCopying] = useState(false);
    const handleAuthRedirectUrlsCopy = () => {
        setAuthRedirectUrlsCopying(true);
        setTimeout(() => setAuthRedirectUrlsCopying(false), 2000);
    }
    const [authScopescopying, setAuthScopesCopying] = useState(false);
    const handleAuthScopesCopy = () => {
        setAuthScopesCopying(true);
        setTimeout(() => setAuthScopesCopying(false), 2000);
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Google SSO Configuration</DialogTitle>
            <Box className="kmc-console-google-sso-content-box" component="form" onSubmit={handleSubmit(handleOnSubmit)}
                sx={{ margin: "1.5rem", textAlign: "center" }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="google_client_id"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("google_client_id")} label="Google Client Id" autoFocus
                                    InputLabelProps={{ shrink: true }} error={errors?.google_client_id ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="google_client_secret"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("google_client_secret")} label="Google Client Secret"
                                    InputLabelProps={{ shrink: true }} error={errors?.google_client_secret ? true : false} />
                            )}
                        />
                    </Grid>
                    {
                        authDomainWatch
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="authorized_domain"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("authorized_domain")} label="Authorized Domain"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end"  >
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthDomainCopy()
                                                                navigator.clipboard.writeText(authDomainWatch);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authDomaincopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>

                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        authJavaScriptOriginsWatch
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="authorized_javascript_origins"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("authorized_javascript_origins")} label="Authorized Javascript Origins"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthJavascriptOriginCopy()
                                                                navigator.clipboard.writeText(authJavaScriptOriginsWatch);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authJavascriptOrigincopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        authRedirectUrlsWatch
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="authorized_redirect_urls"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("authorized_redirect_urls")} label="Authorized Redirect Url"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthRedirectUrlsCopy()
                                                                navigator.clipboard.writeText(authRedirectUrlsWatch);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authRedirectUrlscopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        scopesWatch
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="scopes"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("scopes")} label="Scopes"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthScopesCopy()
                                                                navigator.clipboard.writeText(scopesWatch);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authScopescopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20}></CircularProgress> : <></>}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}

export const FacebookSSOIntegrationDialog = (props) => {

    const { onClose, open } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        fb_app_id: Yup.string().required(),
        fb_app_secret: Yup.string().required(),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { handleSubmit, formState, control, register, reset, setValue, watch } = useForm(formOptions, {
        defaultValues: {
            fb_app_id: "",
            fb_app_secret: "",
            app_domain: "",
            oauth_redirect_url: "",
            site_url: "",
            deep_link: ""
        }
    });
    const { errors } = formState;

    useEffect(() => {
        // get existing SSO configuration
        if (open) {
            setLoading(true);
            axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/auth_facebook_credentials`)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        setValue("fb_app_id", res.data?.fb_app_id);
                        setValue("fb_app_secret", res.data?.fb_app_secret);
                        setValue("app_domain", res.data?.app_domain);
                        setValue("oauth_redirect_url", res.data?.oauth_redirect_url);
                        setValue("site_url", res.data?.site_url);
                        setValue("deep_link", res.data?.deep_link);
                    }
                })
                .catch(res => {
                    setLoading(false);
                });
        }

    }, [open, setValue, tenant]);

    const appDomainWatcher = watch("app_domain");
    const oauthRedirectUrlWatcher = watch("oauth_redirect_url");
    const siteUrlWatcher = watch("site_url");
    const deepLinkWatcher = watch("deep_link");

    const handleClose = () => {
        reset();
        onClose("facebookSSOCard");
    };

    const handleOnSubmit = (data) => {
        if (data) {
            data.fb_version = "v12.0";
            setLoading(true);
            axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/auth_facebook_credentials`, data)
                .then(res => {
                    setLoading(false);
                    reset();
                    onClose("facebookSSOCard");
                })
                .catch(err => {
                    setLoading(false);
                });
        }
    }

    const [authDomaincopyingFb, setAuthDomainCopyingFb] = useState(false);
    const handleAuthDomainCopyFb = () => {
        setAuthDomainCopyingFb(true);
        setTimeout(() => setAuthDomainCopyingFb(false), 2000);
    }
    const [authSiteUrlcopyingFb, setAuthSiteUrlCopyingFb] = useState(false);
    const handleSiteUrlCopyFb = () => {
        setAuthSiteUrlCopyingFb(true);
        setTimeout(() => setAuthSiteUrlCopyingFb(false), 2000);
    }
    const [authRedirectUrlscopyingFb, setAuthRedirectUrlsCopyingFb] = useState(false);
    const handleAuthRedirectUrlsCopyFb = () => {
        setAuthRedirectUrlsCopyingFb(true);
        setTimeout(() => setAuthRedirectUrlsCopyingFb(false), 2000);
    }
    const [authDeeplcopyingFb, setAuthDeepLinkCopyingFb] = useState(false);
    const handleAuthDeepLinkCopyFb = () => {
        setAuthDeepLinkCopyingFb(true);
        setTimeout(() => setAuthDeepLinkCopyingFb(false), 2000);
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Facebook SSO Configuration</DialogTitle>
            <Box className="kmc-console-google-sso-content-box" component="form" onSubmit={handleSubmit(handleOnSubmit)}
                sx={{ margin: "1.5rem", textAlign: "center" }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="fb_app_id"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("fb_app_id")} label="Facebook Application Id" autoFocus
                                    InputLabelProps={{ shrink: true }} error={errors?.fb_app_id ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="fb_app_secret"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("fb_app_secret")} label="Facebook Application Secret"
                                    InputLabelProps={{ shrink: true }} error={errors?.fb_app_secret ? true : false} />
                            )}
                        />
                    </Grid>
                    {
                        appDomainWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="app_domain"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("app_domain")} label="App Domain"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthDomainCopyFb()
                                                                navigator.clipboard.writeText(appDomainWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authDomaincopyingFb ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        oauthRedirectUrlWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="oauth_redirect_url"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("oauth_redirect_url")} label="oAuth Redirect Url"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthRedirectUrlsCopyFb()
                                                                navigator.clipboard.writeText(oauthRedirectUrlWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authRedirectUrlscopyingFb ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        siteUrlWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="site_url"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("site_url")} label="Site Url"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleSiteUrlCopyFb()
                                                                navigator.clipboard.writeText(siteUrlWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authSiteUrlcopyingFb ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        deepLinkWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="deep_link"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("deep_link")} label="Deep Link"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthDeepLinkCopyFb()
                                                                navigator.clipboard.writeText(deepLinkWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authDeeplcopyingFb ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20}></CircularProgress> : <></>}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}

export const OAuthSSOIntegrationDialog = (props) => {

    const { onClose, open } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        oidc_client_id: Yup.string().required(),
        oidc_client_secret: Yup.string().required(),
        oidc_domain_name: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { handleSubmit, formState, control, register, reset, setValue, watch } = useForm(formOptions, {
        defaultValues: {
            oidc_client_id: "",
            oidc_client_secret: "",
            oidc_domain_name: "",
            call_back_urls: "",
            deep_link: ""
        }
    });
    const { errors } = formState;
    const callBackUrlsWatcher = watch('call_back_urls');
    const deepLinkWatcher = watch('deep_link');

    useEffect(() => {
        // get existing SSO configuration
        if (open) {
            setLoading(true);
            axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/auth_oidc_credentials`)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        setValue("oidc_client_id", res.data?.oidc_client_id);
                        setValue("oidc_client_secret", res.data?.oidc_client_secret);
                        setValue("oidc_domain_name", res.data?.oidc_domain_name);
                        setValue("call_back_urls", res.data?.call_back_urls);
                        setValue("deep_link", res.data?.deep_link);
                    }
                })
                .catch(res => {
                    setLoading(false);
                });
        }

    }, [open, setValue, tenant]);

    const handleClose = () => {
        reset();
        onClose("oAuthSSOCard");
    };

    const handleOnSubmit = (data) => {
        if (data) {
            data.oidc_provider_name = "oauth";
            setLoading(true);
            axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/auth_oidc_credentials`, data)
                .then(res => {
                    setLoading(false);
                    reset();
                    onClose("oAuthSSOCard");
                })
                .catch(err => {
                    setLoading(false);
                });
        }
    }

    const [OAuthCallBackUrl, setOAuthCallBackUrlCopying] = useState(false);
    const handleOAuthCallBackUrl = () => {
        setOAuthCallBackUrlCopying(true);
        setTimeout(() => setOAuthCallBackUrlCopying(false), 2000);
    }
    const [OAuthDeepLink, setOAuthDeepLinkCopying] = useState(false);
    const handleOAuthDeepLink = () => {
        setOAuthDeepLinkCopying(true);
        setTimeout(() => setOAuthDeepLinkCopying(false), 2000);
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>OAuth SSO Configuration</DialogTitle>
            <Box className="kmc-console-google-sso-content-box" component="form" onSubmit={handleSubmit(handleOnSubmit)}
                sx={{ margin: "1.5rem", textAlign: "center" }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="oidc_client_id"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("oidc_client_id")} label="Client Id"
                                    InputLabelProps={{ shrink: true }} error={errors?.oidc_client_id ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="oidc_client_secret"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("oidc_client_secret")} label="Client Secret" autoFocus
                                    InputLabelProps={{ shrink: true }} error={errors?.oidc_client_secret ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="oidc_domain_name"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("oidc_domain_name")} label="Domain Name" autoFocus
                                    InputLabelProps={{ shrink: true }} error={errors?.oidc_domain_name ? true : false} />
                            )}
                        />
                    </Grid>
                    {
                        callBackUrlsWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="call_back_urls"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("call_back_urls")} label="Callback Url" autoFocus
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleOAuthCallBackUrl()
                                                                navigator.clipboard.writeText(callBackUrlsWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {OAuthCallBackUrl ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        deepLinkWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="deeplink"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("deeplink")} label="Deep Link" autoFocus
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleOAuthDeepLink()
                                                                navigator.clipboard.writeText(deepLinkWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {OAuthDeepLink ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20}></CircularProgress> : <></>}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}

export const MicrosoftSSOIntegrationDialog = (props) => {

    const { onClose, open } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const fileRef = useRef(null);

    const validationSchema = Yup.object().shape({
        saml_metadata_path: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { handleSubmit, formState, control, register, reset, setValue, watch } = useForm(formOptions, {
        defaultValues: {
            saml_metadata_path: "",
            identifier: "",
            replay_url: "",
            name: "",
            name_space: "",
            source_attribute: ""
        }
    });
    const { errors } = formState;

    useEffect(() => {
        // get existing SSO configuration
        if (open) {
            setLoading(true);
            axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/auth_microsoft_saml_credentials`)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        //setValue("saml_provider_name", res.data?.saml_provider_name);
                        setValue("saml_metadata_path", res.data?.saml_metadata_path);
                        setValue("identifier", res.data?.identifier);
                        setValue("replay_url", res.data?.replay_url);
                        setValue("name", res.data?.attributes_and_claims?.name);
                        setValue("name_space", res.data?.attributes_and_claims?.name_space);
                        setValue("source_attribute", res.data?.attributes_and_claims?.source_attribute);
                    }
                })
                .catch(res => {
                    setLoading(false);
                });
        }

    }, [open, setValue, tenant]);

    const identifierWatcher = watch("identifier");
    const replayUrlWatcher = watch("replay_url");
    const nameWatcher = watch("name");
    const nameSpaceWatcher = watch("name_space");
    const sourceAttributeWatcher = watch("source_attribute");

    const handleClose = () => {
        reset();
        onClose("microsoftSSOCard");
    };

    const handleXMLFileUpload = (file) => {
        if (file) {
            const config = {
                headers: {
                    filename: file.name
                }
            }
            setLoading(true);
            axiosInstance.post(`${environment.EMPLOYEE_CSV_FILE_UPLOAD_API}/${tenant}/visibility/private/document/SSO/id_type/ACTIVE_DIRECTORY_METADATA/id/SSO_ACTIVE_CIRECTORY`,
                file, config)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        setValue("saml_metadata_path", res.data?.file_path);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setError(err && err?.message);
                })
        }
    }

    const handleOnSubmit = (data) => {
        if (data) {
            data.saml_provider_name = "office";
            setLoading(true);
            axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/auth_microsoft_saml_credentials`, data)
                .then(res => {
                    setLoading(false);
                    reset();
                    onClose("microsoftSSOCard");
                })
                .catch(err => {
                    setLoading(false);
                });
        }
    }

    const [authIdentifiercopying, setIdentifierCopying] = useState(false);
    const handleauthIdentifierCopy = () => {
        setIdentifierCopying(true);
        setTimeout(() => setIdentifierCopying(false), 2000);
    }
    const [authReplaycopying, setAuthReplayCopying] = useState(false);
    const handleAuthReplayCopy = () => {
        setAuthReplayCopying(true);
        setTimeout(() => setAuthReplayCopying(false), 2000);
    }
    const [authNamecopying, setAuthNameCopying] = useState(false);
    const handleAuthNameCopy = () => {
        setAuthNameCopying(true);
        setTimeout(() => setAuthNameCopying(false), 2000);
    }
    const [authNameSpacecopying, setAuthNameSpaceCopying] = useState(false);
    const handleAuthNameSpaceCopy = () => {
        setAuthNameSpaceCopying(true);
        setTimeout(() => setAuthNameSpaceCopying(false), 2000);
    }
    const [authSourceAttcopying, setAuthSourceCopying] = useState(false);
    const handleAuthSourceCopy = () => {
        setAuthSourceCopying(true);
        setTimeout(() => setAuthSourceCopying(false), 2000);
    }


    return (
        <Dialog onClose={handleClose} open={open}>
            {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : <></>}
            {loading ? <Loader loading={loading}></Loader> : <></>}
            <DialogTitle>Microsoft SSO Configuration</DialogTitle>
            <Box className="kmc-console-google-sso-content-box" component="form" onSubmit={handleSubmit(handleOnSubmit)}
                sx={{ margin: "1.5rem", textAlign: "center" }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button size="medium"
                            disableRipple
                            startIcon={<UploadFile></UploadFile>}
                            onClick={(event) => {
                                event.preventDefault();
                                fileRef.current?.click();
                            }}
                        >
                            Upload SAML XML
                        </Button>
                        <input type={"file"} hidden accept=".xml" ref={fileRef}
                            onChange={(event) => {
                                event.preventDefault();
                                const file = event.target.files[0];
                                if (file) {
                                    handleXMLFileUpload(file);
                                }
                            }}>
                        </input>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            defaultValue={''}
                            name="saml_metadata_path"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("saml_metadata_path")} label="Metadata Path"
                                    InputLabelProps={{ shrink: true }} error={errors?.saml_metadata_path ? true : false} disabled />
                            )}
                        />
                    </Grid>
                    {
                        identifierWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name="identifier"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("identifier")} label="Identifier"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleauthIdentifierCopy()
                                                                navigator.clipboard.writeText(identifierWatcher);
                                                            }
                                                            }
                                                        >
                                                        </CopyAll>
                                                        {authIdentifiercopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        replayUrlWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name="replay_url"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("replay_url")} label="Replay Url"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthReplayCopy()
                                                                navigator.clipboard.writeText(replayUrlWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authReplaycopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        (nameWatcher || nameSpaceWatcher || sourceAttributeWatcher)
                            ? <Grid item xs={12}>
                                <Typography className="kmc-console-emp-datastore-group-title">Attributes and Claims</Typography>
                            </Grid>
                            : null

                    }
                    {
                        nameWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name="name"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("name")} label="Name"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthNameCopy()
                                                                navigator.clipboard.writeText(nameWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authNamecopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        nameSpaceWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name="name_space"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("name_space")} label="Name Space"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthNameSpaceCopy()
                                                                navigator.clipboard.writeText(nameSpaceWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authNameSpacecopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    {
                        sourceAttributeWatcher
                            ? <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name="source_attribute"
                                    render={({ field }) => (
                                        <TextField disabled fullWidth {...field} {...register("source_attribute")} label="Source Attribute"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CopyAll
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                handleAuthSourceCopy()
                                                                navigator.clipboard.writeText(sourceAttributeWatcher);
                                                            }}
                                                        >
                                                        </CopyAll>
                                                        {authSourceAttcopying ? <span >Copied!</span> : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20}></CircularProgress> : <></>}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}


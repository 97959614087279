import {
    Alert, Button, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Chip,
    TextField, InputAdornment, Stack, Switch
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import "./MappingRow.css";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import SecureLS from "secure-ls";
import { useEffect, useRef, useState } from "react";
import Loader from "../atoms/loader/Loader";
import { CloseRounded, Add } from "@mui/icons-material";
import moment from "moment";
const MappingRow = (props) => {
    const { onMappingSuccess, onCloseMapping, mappingRow, editMode } = props;
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const ref = useRef();
    const [mappings, setMappings] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [attributesList, setAttributesList] = useState([]);
    useEffect(() => {
        axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/mapping_attributes`)
            .then(res => {
                if (res && res.data) {
                    setMappings(res.data.mapping_attributes ? res.data.mapping_attributes : {});
                }
            })
            .catch(err => {
                setError(err && err.message);
                console.log(err && err.msg);
            })
    }, [ref, tenant])
    const types = [
        "String", "Number", "Date", "Boolean", "List"
    ]
    const date_formats = [
        "dd/mm/yyyy", "yyyy/mm/dd", "mm/dd/yyyy", "dd/mm"
    ]; // timezone of the current client when saving
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required(),
        staticValue: Yup.string().test('test static value', 'Invalid value', function (value) {
            if (staticWatch) {
                switch (typeWatch) {
                    case 'Number': {
                        if (value.match("^[0-9]*$")) {
                            return true;
                        }
                        return false;
                    }
                    case 'Date': {
                        if (moment(value, dateFormatWatch.toUpperCase(), true).isValid()) {
                            return true;
                        }
                        return false;
                    }
                    case 'String': {
                        return true;
                    }
                    case 'Boolean': {
                        if (value === "true" || value === "false") {
                            return true;
                        }
                        return false;
                    }
                    case 'List': {
                        return true;
                    }
                    default: {
                        return false;
                    }
                }
            } else {
                return true;
            }
        })
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { formState, control, register, handleSubmit, watch, setValue, reset } = useForm(formOptions, {
        defaultValues: {
            name: "",
            type: "",
            isStatic: false,
            isFilterEnabled: false,
            isAdminAccessOnly: false,
            dateFormat: "",
            attributes: "",
            staticValue: ""
        }
    });
    const typeWatch = watch("type");
    const dateFormatWatch = watch("dateFormat");
    const staticWatch = watch("isStatic");
    const { errors } = formState;
    useEffect(() => {
        if (editMode && mappingRow) {
            const { name, type, isStatic, isFilterEnabled, isAdminAccessOnly, attributesList, staticValue, dateFormat } = mappingRow;
            setValue("name", name);
            setValue("type", type);
            setValue("dateFormat", dateFormat);
            setValue("isStatic", isStatic);
            setValue("isFilterEnabled", isFilterEnabled);
            setValue("isAdminAccessOnly", isAdminAccessOnly);
            setValue("attributesList", isStatic ? setAttributesList(attributesList) : []);
            setValue("staticValue", isStatic ? staticValue : "");
        }
    }, [mappingRow, editMode, setValue]);
    const handleMappingCardClose = (e) => {
        onCloseMapping();
    }
    const handleAttributeDelete = item => () => {
        setAttributesList(attributesList.filter(attribute => attribute !== item));
    };
    const AttributeChip = attributesList.map((item, index) => (
        <Chip
            size="small"
            label={item}
            key={index}
            onDelete={handleAttributeDelete(item)}
            color="primary"
            variant="outlined"
            style={{ maxWidth: "30%", marginRight: "12px", marginLeft: "0", marginBottom: "8px" }}
        />
    ));
    const updateCompletionCheck = (flag) => {
        _secureStore.set("isMappingsCompleted", flag);
    }
    const handleOnSubmit = (data) => {
        if (editMode && mappingRow) {
            data.modifiedDate = new Date();
            mappings[mappingRow.name] = data;
        } else {
            const isExists = Object.keys(mappings).some(key => key === data.name);
            if (isExists) {
                setError("Mapping for the attribute " + data.name + " is already defined.")
                return;
            }
        }
        // add creation time
        data.creationDate = new Date();
        // add new config to current configs
        delete data.newItem
        data.attributesList = attributesList;
        // If static, neither admin nor user cannot edit
        if (data.isStatic && typeWatch !== 'List') {
            data.isAdminAccessOnly = false;
        }
        mappings[data.name] = data;
        const config = {
            mapping_attributes: mappings
        };
        setLoading(true);
        axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/mapping_attributes`, config)
            .then(res => {
                setLoading(false);
                onMappingSuccess();
                updateCompletionCheck(true);
                reset();
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
        handleMappingCardClose();
    }
    return (
        <Card component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-mapping-row-facade">
            <Loader loading={loading}></Loader>
            {
                error ? <Alert severity="warning">{error}</Alert> : null
            }
            <CardHeader
                title={<CloseRounded sx={{ color: "darkgray" }} onClick={handleMappingCardClose}></CloseRounded>}
                sx={{ textAlign: "right", padding: "8px" }}
            >
            </CardHeader>
            <CardContent>
                <Alert severity="info">
                    There are some special mappings like "role",.... which will enable some special features <a href="https://www.intracrew.com/mappings.html" target="_blank">Click here</a>
                </Alert>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={typeWatch === 'Date' ? 4 : 6}>
                        <Controller
                            control={control}
                            defaultValue={''}
                            name="name"
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    {...field}
                                    {...register("name")}
                                    label="Name"
                                    size="small"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ textAlign: "start" }}
                                    error={errors?.name ? true : false}
                                    disabled={editMode}
                                >
                                </TextField>
                            )}
                        >
                        </Controller>
                    </Grid>
                    <Grid item xs={typeWatch === 'Date' ? 4 : 6}>
                        <Controller
                            control={control}
                            defaultValue={''}
                            name="type"
                            render={({ field }) => (
                                <TextField
                                    select
                                    fullWidth
                                    {...field}
                                    {...register("type")}
                                    label="Type"
                                    size="small"
                                    variant="standard"
                                    sx={{ textAlign: "start" }}
                                    InputLabelProps={{ shrink: true }}
                                    error={errors?.type ? true : false}
                                    value={typeWatch ? typeWatch : ''}
                                    disabled={editMode}
                                >
                                    {types.map((option, key) => (
                                        <MenuItem key={key} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        >
                        </Controller>
                    </Grid>
                    {
                        typeWatch === 'Date'
                            ? <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name="dateFormat"
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            fullWidth
                                            {...field}
                                            {...register("dateFormat")}
                                            label="Date Format"
                                            size="small"
                                            variant="standard"
                                            sx={{ textAlign: "start" }}
                                            InputLabelProps={{ shrink: true }}
                                            error={errors?.dateFormat ? true : false}
                                            value={dateFormatWatch ? dateFormatWatch : ''}
                                            disabled={editMode}
                                        >
                                            {date_formats.map((option, key) => (
                                                <MenuItem key={key} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                >
                                </Controller>
                            </Grid>
                            : <></>
                    }
                    <Grid item xs={12} sx={{ justifyContent: "start" }}>
                        <Controller
                            control={control}
                            defaultValue={false}
                            name="isStatic"
                            render={({ field: { value, ...field } }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        {...field}
                                        name="isStatic"
                                        {...register("isStatic")}
                                        checked={!!value}
                                        control={<Checkbox size="small" />}
                                        label="Is Static?"
                                    />
                                </FormGroup>
                            )}
                        >
                        </Controller>
                    </Grid>
                    {
                        typeWatch !== 'Date'
                            ? <Grid item xs={12} sx={{ justifyContent: "start" }}>
                                <Controller
                                    control={control}
                                    defaultValue={false}
                                    name="isFilterEnabled"
                                    render={({ field: { value, ...field } }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                {...field}
                                                name="isFilterEnabled"
                                                {...register("isFilterEnabled")}
                                                checked={!!value}
                                                control={<Checkbox size="small" />}
                                                label="Is Filter Enabled?"
                                            />
                                        </FormGroup>
                                    )}
                                >
                                </Controller>
                            </Grid>
                            : null
                    }
                    {
                        typeWatch === 'List' && staticWatch === true
                            ? <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    defaultValue={[]}
                                    name="attributes"
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            {...field}
                                            {...register("attributes")}
                                            label="Attributes"
                                            size="small"
                                            variant="standard"
                                            inputRef={ref}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Add fontSize='small' sx={{ color: "#1C243C", cursor: "pointer" }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                const value = ref.current.value;
                                                                if (value) {
                                                                    // check if exists
                                                                    if (attributesList.indexOf(value) === -1) {
                                                                        setAttributesList(attributesList => [...attributesList, ref.current.value]);
                                                                    }
                                                                    // clear current text field
                                                                    setValue("attributes", "");
                                                                }
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                )
                                            }}
                                            sx={{ textAlign: "start" }}
                                            error={errors.attributes ? true : false}
                                        >
                                        </TextField>
                                    )}
                                >
                                </Controller>
                            </Grid>
                            : <></>
                    }
                    {
                        staticWatch === true && (typeWatch && typeWatch !== 'List')
                            ? <Grid item xs={4}>
                                <Controller
                                    control={control}
                                    defaultValue={''}
                                    name="staticValue"
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            {...field}
                                            {...register("staticValue")}
                                            label="Value"
                                            size="small"
                                            variant="standard"
                                            inputRef={ref}
                                            InputLabelProps={{ shrink: true }}
                                            sx={{ textAlign: "start" }}
                                            helperText={errors.staticValue?.message}
                                            error={errors.staticValue ? true : false}
                                        >
                                        </TextField>
                                    )}
                                >
                                </Controller>
                            </Grid>
                            : null
                    }
                    <Grid item xs={12}>
                        {
                            typeWatch === 'List' && staticWatch === true && attributesList.length > 0
                                ?
                                <Stack direction={"row"} spacing={1} sx={{ flexWrap: 'wrap' }}>
                                    {
                                        attributesList.length > 0 ? AttributeChip : ""
                                    }
                                </Stack>
                                : <></>
                        }
                    </Grid>
                    {
                        staticWatch && typeWatch !== 'List'
                            ? <Grid item xs={12}>
                                <Alert severity="warning">
                                    Neither admin nor user cannot edit the static field. To update the value, it is only possible through console.
                                </Alert>
                            </Grid>
                            : <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    defaultValue={false}
                                    name="isAdminAccessOnly"
                                    render={({ field: { value, ...field } }) => (
                                        <FormGroup>
                                            <FormControlLabel
                                                sx={{ justifyContent: "center" }}
                                                control={<Switch size="medium" />}
                                                checked={!!value}
                                                label="Admin Access Only"
                                                {...field}
                                                name="isAdminAccessOnly"
                                                {...register("isAdminAccessOnly")}
                                            />
                                        </FormGroup>
                                    )}
                                >
                                </Controller>
                            </Grid>
                    }
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small" disabled={loading}>
                            Save Mapping
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
export default MappingRow;
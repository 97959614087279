import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CircularProgress, Dialog, DialogTitle, Grid, TextField} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import SecureLS from "secure-ls";
import * as Yup from 'yup';
import { environment } from "../assets/configurations/configuration";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import './GoogleADForm.css';

const GoogleADForm = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);    

    const validationSchema = Yup.object().shape({
        google_project_id: Yup.string().required(),
        google_client_id: Yup.string().required(),
        google_client_secret: Yup.string().required(),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { handleSubmit, formState, control, register, reset, setValue } = useForm(formOptions, {
        defaultValues: {
            google_project_id: "",
            google_client_id: "",
            google_client_secret: ""
        }
    });

    const { errors } = formState;

    const handleOnSubmit = (data) => {
        if (data) {
            setLoading(true);
            axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/google_ws_credentials`, data)
                .then(res => {
                    setLoading(false);
                    reset();
                    onClose("googleWSCard");                    
                })
                .catch(err => {
                    setLoading(false);
                });
        }
    }

    const { onClose, open } = props;

    useEffect(() => {
        // get existing AD configuration
        if(open) {
            setLoading(true);
            axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/google_ws_credentials`)
                .then(res => {
                    setLoading(false);
                    setValue("google_project_id", res.data.google_project_id);
                    setValue("google_client_id", res.data.google_client_id);
                    setValue("google_client_secret", res.data.google_client_secret);
                })
                .catch(res => {
                    setLoading(false);
                });
        }

    },[open, setValue, tenant]);
    
    const handleClose = () => {
        reset();
        setLoading(false);
        onClose("googleWSCard");
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Google Workspace Configuration</DialogTitle>
            <Box className="kmc-console-googlews-content-box" component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="google_project_id"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("google_project_id")} label="Google Project Id"
                                    InputLabelProps={{ shrink: true }} error={errors?.google_project_id ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="google_client_id"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("google_client_id")} label="Google Client Id"
                                    InputLabelProps={{ shrink: true }} error={errors?.google_client_id ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="google_client_secret"
                            render={({ field }) => (
                                <TextField fullWidth {...field} {...register("google_client_secret")} label="Google Client Secret"
                                    InputLabelProps={{ shrink: true }} error={errors?.google_client_secret ? true : false} />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}
                            startIcon={loading ? <CircularProgress size={20}></CircularProgress> : <></>}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )

}

export default GoogleADForm;
import { Button, Card, CardContent, CardHeader, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import SecureLS from "secure-ls";
import { UploadFileRounded } from '@mui/icons-material';
import './FileUpload.css';
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { environment } from "../../assets/configurations/configuration";
import Loader from "../loader/Loader";

const FileUpload = (props) => {

    const { onError, onClose, onSuccess } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const [files, setFiles] = useState();
    const [loading, setLoading] = useState(false);

    const handleFileUpload = (event) => {
        event.preventDefault();
        if (event.target.files) {
            const file = event.target.files[0];
            setFiles(file);
        }
        return;
    }

    const handleFileSubmit = (event) => {
        event.preventDefault();
        if (files) {
            let config = {
                headers: {
                    filename: files.name
                }
            }
            setLoading(true);
            let source_path = "";
            axiosInstance.post(`${environment.EMPLOYEE_BULK_IMAGE_UPLOAD_API}/${tenant}/visibility/private/document/console/id_type/bulk_image_upload/id/photos`,
                '', config)
                .then(res => {
                    if (res && res.data) {
                        const { url } = res.data.presigned_url_details;
                        const { fields } = res.data.presigned_url_details;
                        source_path = res.data.file_path;
                        const formData = new FormData();
                        formData.append("key", fields['key']);
                        formData.append("AWSAccessKeyId", fields['AWSAccessKeyId']);
                        formData.append("x-amz-security-token", fields['x-amz-security-token']);
                        formData.append("policy", fields['policy']);
                        formData.append("signature", fields['signature']);
                        formData.append("file", files);

                        const preSignedHeaders = new Headers();
                        preSignedHeaders.append("Access-Control-Allow-Credentials", "true");
                        preSignedHeaders.append("Content-Type", ' ');
                        const requestOptions = {
                            method: 'POST',
                            headers: preSignedHeaders,
                            body: formData,
                            redirect: 'follow',
                            mode: 'no-cors',
                        };
                        fetch(url, requestOptions).then(res => {
                            const config = {
                                headers: {
                                    "InvocationType": "Event"
                                }
                            }
                            const data = {
                                tenant_name: tenant,
                                source_path: source_path,
                                source_root: "document-manager-resource",
                                destination_root: "document-manager-resource",
                                destination_path: tenant + "/photos",
                                keep_folder: false
                            }
                            setLoading(true);
                            axiosInstance.post(environment.EMPLOYEE_DATA_EXTRACTION_API, data, config)
                                .then(res => {
                                    updateCompletionCheck(true);
                                    setLoading(false);
                                    setFiles(null);
                                    onClose();
                                    onSuccess("File is uploaded successfully.");
                                })
                                .catch(err => {
                                    updateCompletionCheck(false);
                                    setLoading(false);
                                    onError(err && err.message);
                                });
                        }).catch(err => {
                            onError(err && err.message);
                            setLoading(false);
                        });
                    }
                })
                .catch(err => {
                    onError(err && err.message);
                    setLoading(false);
                });
        }
    }

    const updateCompletionCheck = (flag) => {
        _secureStore.set("isEmployeeDataCompleted", flag);
    }

    return (
        <Box className="kmc-console-employeeupload-box">
            <Loader loading={loading}></Loader>
            <Card className="kmc-console-employeeupload-content-card">
                <Box component="form" className="kmc-console-employeeupload-content-box">
                    <Button
                        component="label"
                        variant="outlined"
                        startIcon={<UploadFileRounded />}
                        sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                    >
                        Upload Files
                        <input type="file" hidden accept=".zip" onChange={handleFileUpload} />
                    </Button>
                    {
                        files
                            ?
                            <Box>
                                <Card variant="outlined" sx={{ backgroundColor: "whitesmoke" }}>
                                    <CardHeader title={"Selected File"} sx={{ backgroundColor: "lightblue" }}>
                                    </CardHeader>
                                    <CardContent>
                                        <ListItemText>
                                            Filename: {files.name}
                                        </ListItemText>
                                        <ListItemText>
                                            Filetype: {files.type}
                                        </ListItemText>
                                        <ListItemText>
                                            Size: {files.size + " KB"}
                                        </ListItemText>
                                    </CardContent>
                                </Card>
                            </Box>
                            : <></>
                    }
                    {
                        files ?
                            <Button
                                type="submit"
                                variant="outlined"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleFileSubmit}
                                disabled={loading}
                            >
                                Upload & Extract
                            </Button>
                            : <></>
                    }
                </Box>
            </Card>
        </Box>
    )
}

export default FileUpload;
import { Add } from "@mui/icons-material";
import { Alert, Button, Chip, Grid, TextField } from "@mui/material"
import { useState } from "react";
import SecureLS from "secure-ls";
import { environment } from "../../assets/configurations/configuration";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";

export default function CreateCheckListGroup(props) {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [checkGroupRowEnabled, setCheckGroupRowEnabled] = useState();
    const [checkGroupRowName, setCheckGroupName] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const handleChangeGroup = (e) => {
        const value = e.target.value;
        setCheckGroupName(value);
    }

    const handleAddCheckGroup = (e) => {
        setCheckGroupRowEnabled(!checkGroupRowEnabled);
    }

    const handleCancelCreateCheckGroup = (e) => {
        setCheckGroupRowEnabled(!checkGroupRowEnabled);
    }

    const handleCreateCheckGroup = (e) => {
        const config = {
            name: checkGroupRowName,
            items: []
        }
        axiosInstance.post(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_type/checklist_${checkGroupRowName}`, config)
            .then(res => {
                setLoading(false);
                if (res && res.data) {
                    setCheckGroupRowEnabled(!checkGroupRowEnabled);
                    props.onCheckGroupSuccess();
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {
                    error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>
                        : <></>
                }
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "left" }}>
                <Chip
                    size='small'
                    label="Add Check Group"
                    icon={<Add></Add>}
                    color="primary"
                    variant='outlined'
                    onClick={(e) => handleAddCheckGroup(e)}
                    disabled={loading}
                />
            </Grid>
            {
                checkGroupRowEnabled ?
                    <Grid item xs={12} sx={{ textAlign: "left" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    onChange={(e) => { handleChangeGroup(e) }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    size="small"
                                    type="button"
                                    onClick={(e) => handleCreateCheckGroup(e)}
                                >
                                    Save
                                </Button>
                                <Button
                                    size="small"
                                    type="button"
                                    onClick={(e) => handleCancelCreateCheckGroup(e)}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    : null
            }
        </Grid>
    )
}
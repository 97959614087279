import { Alert, Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import SecureLS from "secure-ls";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { environment } from "../../assets/configurations/configuration";
import { useEffect, useRef, useState } from "react";
import Loader from "../../atoms/loader/Loader";
import { isMobile } from "react-device-detect";
import { homeTiles } from "./HomeTiles";

const HomeComponent = (props) => {

  const { isPublished, tenant } = props;
  const _secureStore = new SecureLS();
  //const tenant = _secureStore.get("tenant_name");
  const _secure_user_info = _secureStore.get("_secure_user_info");
  const email = _secure_user_info && _secure_user_info.attributes
    && _secure_user_info.attributes['email'];

  const navigate = useNavigate();

  const ref = useRef();
  const [displayName, setDisplayName] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const date = new Date();
  const hours = date.getHours();

  useEffect(() => {
    if (tenant) {
      // get current profile details
      setLoading(true);
      axiosInstance.post(
        `${environment.IAM_DETAILS_API}/${tenant}/Alias/console/type/tenant_details`,
        { email: email })
        .then(res => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.profile) {
              setDisplayName(res.data.profile?.displayName);
            }
          }
        }).catch(err => {
          setLoading(false);
          setError(err & err.message);
        });
    }
  }, [ref, tenant, email]);

  return (
    <Box className="kmc-console-home-main-box">
      <Loader loading={loading}></Loader>
      {error ? <Alert severity="error">{error}</Alert> : null}
      <Box className="kmc-console-home-content-box">
        <Card className="kmc-console-home-info-card">
          <Typography variant="h6">{hours >= 12 ? "Good Evening," : "Good Morning,"} {displayName ? displayName : email}</Typography>
          <Typography variant="h6">Enjoy the benefits of INTRA CREW</Typography>
        </Card>
        <Card className="kmc-console-home-content-card">
          <CardContent>
            <Grid container spacing={1}>
              {
                !isPublished ? <Grid item xs={isMobile ? 6 : 3}>
                  <Card className="kmc-console-emp-homestore-facade"
                    key={homeTiles[0].title}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(homeTiles[0].navigateTo, { state: homeTiles[0].state });
                    }}
                  >
                    <CardHeader title={homeTiles[0].title}></CardHeader>
                    <CardContent>
                      <img src={homeTiles[0].image} height={100} width={100} alt={homeTiles[0].imageAlt}></img>
                    </CardContent>
                  </Card>
                </Grid>
                  : homeTiles ?
                    homeTiles.map((tile, key) => {
                      return (
                        <Grid item xs={isMobile ? 6 : 3} key={key}>
                          <Card className="kmc-console-emp-homestore-facade"
                            key={tile.title}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(tile.navigateTo, { state: tile.state });
                            }}
                          >
                            <CardHeader title={tile.title}></CardHeader>
                            <CardContent>
                              <img src={tile.image} height={100} width={100} alt={tile.imageAlt}></img>
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                    })
                    : null

              }
              {

              }
            </Grid>
          </CardContent>
        </Card>
        <Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeComponent;

import { AddBusiness, EditSharp, Key, Password } from "@mui/icons-material";

export const settingsListItems = [
    {
        "id": 51,
        "name": "Company Details",
        "icon": AddBusiness,
        "iconSize": "large",
        "navigateTo": "/settings/company"
    },
    {
        "id": 52,
        "name": "API Key",
        "icon": Key,
        "iconSize": "large",
        "navigateTo": "/settings/apikey"
    },
    {
        "id": 54,
        "name": "Edit Profile",
        "icon": EditSharp,
        "iconSize": "large",
        "navigateTo": "/settings/editprofile"
    },
    {
        "id": 55,
        "name": "Update Password",
        "icon": Password,
        "iconSize": "large",
        "navigateTo": "/settings/reset/password"
    }
]
import { Box, Grid, Typography } from "@mui/material";
import { useRef, useState ,useEffect} from "react";
import SecureLS from "secure-ls";
import ImageUrlEncoder from "../misc/PrivateEncodedImageUrl";
import UploadPhoto from "../assets/UploadPhoto.png";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";

const EmployeeDisplayCard = (props) => {

    const inputFile = useRef(null);
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const { employee, requestToUpdateAsset, onSelectAsset, selectedFile } = props;
    const encodedImageUrl = ImageUrlEncoder(tenant, employee?.image_name);
    const [imgSource, setImgSource] = useState(requestToUpdateAsset && selectedFile ? window.URL.createObjectURL(selectedFile) : null);
    
    useEffect(() => {
//       console.log(employee,"employye")
      if (!imgSource) {
        getImage(encodedImageUrl);
      }
    }, [imgSource, encodedImageUrl]);
    
    const getImage = (encodedImageUrl) => {
      const url = encodedImageUrl;
      return axiosInstance
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = res.data;
          const imageUrl = URL.createObjectURL(blob);
          setImgSource(imageUrl);
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
          // You can handle the error as needed
        });
    };
    
    const handleSelectedAsset = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const file = e.target.files[0];
        // clear existing and show preview       
        setImgSource(null);
        setImgSource(window.URL.createObjectURL(file));
        // pass the selected file to the caller
        onSelectAsset(file);
    }
 
    return (
        <Grid container spacing={0.5}>
            <Grid item xs={12}>
                <Box onClick={() => {
                    if (requestToUpdateAsset) {
                        inputFile.current.click();
                    }
                }}>
                    <input type="file" hidden accept=".png,.jpg,.jpeg" ref={inputFile} onChange={handleSelectedAsset} />
                    {
                        <img src={imgSource}
                        
                            // height={50}
                            // width={50}
                            alt={"Preview"}
                            onError={(event) => {
                                event.target.src = UploadPhoto
                            }}
                            style={{ cursor: "pointer", maxWidth: "7rem", maxHeight:"7rem" }}
                            // style={{ cursor: "pointer", clipPath: "circle()" }}
                        />
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: "small" }}>{employee?.givenName}</Typography>
            </Grid>
            <Grid item xs={12}>
                {
                    employee?.parentName
                        ? <Typography sx={{ fontSize: "small" }}>{"Facilitator: " + employee?.parentName}</Typography>
                        : <></>
                }
            </Grid>
            <Grid item xs={12}>
                {
                    employee?.locationName
                        ? <Typography sx={{ fontSize: "small" }}>{"Location: " + employee?.locationName}</Typography>
                        : <></>
                }
            </Grid>
        </Grid>
    )
}

export default EmployeeDisplayCard;
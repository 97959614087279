import './CreateBadge.css';
import * as Yup from 'yup';
import { Alert, Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import Loader from '../atoms/loader/Loader';
import { CloseRounded } from '@mui/icons-material';
import BadgeMgmt from "../assets/BadgeMgmt.png";
import SecureLS from 'secure-ls';
import PrivateEncodedImageUrl from '../misc/PrivateEncodedImageUrl';
import { axiosInstance } from '../interceptors/axios/AxiosInstance';
import { environment } from '../assets/configurations/configuration';
import AttributePreview from './AttributePreview';
const CreateBadge = (props) => {
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const { onBadgeSuccess, onCloseBadge, badgeRow, editMode } = props;
    const [loading, setLoading] = useState();
    const [error, setError] = useState("");
    const [file, setFile] = useState();
    const [admins, setAdmins] = useState([]);
    const [imageUrl, setImageUrl] = useState(editMode ? badgeRow.logo_url : BadgeMgmt);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required()
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState, control, register, watch, reset, setValue } = useForm(formOptions, {
        defaultValues: {
            name: ""
        }
    });
    const { errors } = formState;
    const nameWatch = watch("name");
    useEffect(() => {
        if (editMode && badgeRow) {
            setLoading(true);
            setImageUrl(null);
            const { name, description, badge_admins, logo_url } = badgeRow;
            setImageUrl(logo_url);
            setValue("name", name);
            setValue("description", description);
            setAdmins(badge_admins);
            setFile("@");
            setLoading(false);
        }
    }, [badgeRow, editMode, setValue, imageUrl]);
    const handleOnSubmit = (data) => {
        // handle rest call for creation
        // create badge using the attribute service
        setLoading(true);
        let badge_image = data.name;
        badge_image = "badge_"+badge_image.replace(/ /g,"_");
        const badgeEncodedUrl = PrivateEncodedImageUrl(tenant,badge_image);
        const config = {
            name: data.name,
            description: data.description,
            badge_admins: admins,
            logo_url: badgeEncodedUrl
        }
        if (! file) {
            setLoading(false);
            setError("Please upload Badge image.");
            return;
        };
        axiosInstance.post(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_type/badge_${data.name}`, config)
            .then(res => {
                setLoading(false);
                onBadgeSuccess();
            })
            .catch(err => {
                setLoading(false);
                setError(err && err?.message);
            });
        // update badge logo using the image encoder utility
        if (file && file!=="@") {
            console.log("coming");
            setLoading(true);
            axiosInstance.post(badgeEncodedUrl, file)
                .then(res => {
                    setLoading(false);
                    reset();
                    setFile(null);
                })
                .catch(err => {
                    setLoading(false);
                    setError(err & err?.message);
                });
        };
        handleBadgeCardClose();
    }
    const handleBadgeCardClose = () => {
        onCloseBadge();
    }
    const handleSelectedBadgeLogo = (file) => {
        if (file) {
            setFile(file);
        }
    }
    return (
        <Card component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-mapping-row-facade">
            <Loader loading={loading}></Loader>
            {
                error ? <Alert severity="warning">{error}</Alert> : null
            }
            <CardHeader
                title={<CloseRounded sx={{ color: "darkgray" }} onClick={handleBadgeCardClose}></CloseRounded>}
                sx={{ textAlign: "right", padding: "8px" }}
            >
            </CardHeader>
            <CardContent>
                <Grid container sx={{ alignItems: "center" }}>
                    <Grid item xs>
                        <AttributePreview isPermission={false}
                            isBadge={true}
                            name={nameWatch}
                            requestToUpdateBadge={true}
                            onSelectAsset={handleSelectedBadgeLogo}
                            editMode={editMode}
                            imageUrl={imageUrl}
                        >
                        </AttributePreview>
                    </Grid>
                    <Grid container xs={12} sm spacing={2} sx={{ textAlign: "left" }}>
                        <Grid item xs={12}>
                            <Controller
                                name='name'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        {...register("name")}
                                        label="Accomplishment"
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        error={errors?.name ? true : false}
                                        disabled={editMode}
                                    />
                                )}
                            >
                            </Controller>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='description'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        {...register("description")}
                                        label="Short Description"
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        error={errors?.date ? true : false}
                                    />
                                )}
                            >
                            </Controller>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}>
                            {editMode ? 'Update badge' : 'Create badge'}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
export default CreateBadge;



import BadgeMgmt from "../../assets/BadgeMgmt.png";
import WorkspaceMgmt from "../../assets/WorkspaceMgmt.png";
import DocumentMgmt from "../../assets/DocumentMgmt.png";
import PermissonMgmt from "../../assets/PermissionMgmt.png";
import AdminMgmt from "../../assets/AdminMgmt.png";
import SocialMgmt from "../../assets/SocialMgmt.png";
import LocationMgmt from "../../assets/LocationMgmt.png";
import BillMgmt from "../../assets/BillMgmt.png";
import EmployeeMgmt from "../../assets/EmployeeMgmt.png";
import ConfigurationMgmt from "../../assets/ConfigurationMgmt.png";

export const homeTiles = [
    {
        title: "Workspace Management",
        image: WorkspaceMgmt,
        imageAlt: "workspace",
        navigateTo: "/workspace/register",
        state: {
            isWorkspaceExpand: true,
            activeItemId: 991
        }

    },
    {
        title: "People Management",
        image: EmployeeMgmt,
        imageAlt: "employee",
        navigateTo: "/management/onboard",
        state: {
            isManagementNodeExpand: true,
            activeItemId: 1001
        }

    },
    {
        title: "Badge Management",
        image: BadgeMgmt,
        imageAlt: "badge",
        navigateTo: "/manage/badges",
        state: {
            isBadgeNodeExpand: true,
            activeItemId: 2001
        }

    },
    {
        title: "Admin Management",
        image: AdminMgmt,
        imageAlt: "admins",
        navigateTo: "/manage/admins",
        state: {
            isAdminManagementNodeExpand: true,
            activeItemId: 3001
        }

    },
    {
        title: "Data Management",
        image: DocumentMgmt,
        imageAlt: "documents",
        navigateTo: "/manage/documents",
        state: {
            isDocumentManagementNodeExpand: true,
            activeItemId: 6001
        }

    },
    {
        title: "Permission Management",
        image: PermissonMgmt,
        imageAlt: "permissions",
        navigateTo: "/manage/permissions",
        state: {
            isPermissionsManagementNodeExpand: true,
            activeItemId: 8001
        }

    },
    {
        title: "Configuration Management",
        image: ConfigurationMgmt,
        imageAlt: "configuration",
        navigateTo: "/manage/configuration",
        state: {
            isConfigManagementNodeExpand: true,
            activeItemId: 4001
        }

    },
    {
        title: "Event Configuration Management",
        image: ConfigurationMgmt,
        imageAlt: "configuration",
        navigateTo: "/manage/event_configuration",
        state: {
            isConfigManagementNodeExpand: true,
            activeItemId: 9001
        }

    },
    {
        title: "Social Management",
        image: SocialMgmt,
        imageAlt: "social",
        navigateTo: "/manage/announcements",
        state: {
            isSocialManagementNodeExpand: true,
            activeItemId: 5001
        }
    },
    {
        title: "Location Management",
        image: LocationMgmt,
        imageAlt: "location",
        navigateTo: "/manage/locations",
        state: {
            isLocationManagementNodeExpand: true,
            activeItemId: 10001
        }
    },
    {
        title: "Billing & Subscription Management",
        image: BillMgmt,
        imageAlt: "location",
        navigateTo: "/manage/billing",
        state: {
            isBillingManagementNodeExpand: true,
            activeItemId: 7001
        }
    }
]
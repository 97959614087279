import React, { useEffect, useState } from "react";
import { Button, Card, Typography, Box, InputAdornment, TextField, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import './Credentials.css';
import SecureLS from "secure-ls";
import { useNavigate } from "react-router-dom";
import { environment } from "../../assets/configurations/configuration";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import Loader from "../loader/Loader";
import CredentialsPaginationTable from "../../molecules/CredentialsPaginationTable";
import CredentialsExternalSources from "../../molecules/CredentialsExternalSources";

const Credentials = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = new SecureLS().get("_secure_user_info");
    const email = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const isPublished = props?.isPublished;

    useEffect(() => {
        if (!tenant) {
            navigate('/workspace/register', {
                state: {
                    message: "You must register domain.",
                    isWorkspaceExpand: true
                }
            });
            return;
        }

    }, [tenant, navigate]);


    const handleOnSubmit = (data) => {
        if (data && data.password) {
            data.email = email;
            setLoading(true);
            axiosInstance.post(`${environment.CONSOLE_CREDENTIALS}/${tenant}/configurationGroup/console_credentials`, data)
                .then(res => {
                    updateCompletionCheck(true);
                    setLoading(false);
                    navigate('/workspace/payment', {
                        state: {
                            isWorkspaceExpand: true,
                            activeItemId: 996
                        }
                    });
                })
                .catch(err => {
                    updateCompletionCheck(false);
                    setLoading(false);
                    setError(err.message);
                });
        }
    };

    const togglePasswordHide = () => {
        setShowPassword(!showPassword);
    };

    const updateCompletionCheck = (flag) => {
        _secureStore.set("isCredentialCompleted", flag);
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required()
            .min(6, 'Password must be at least 6 characters.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
                "Must contain eight characters, one uppercase, one lowercase, one number and one special case character."
            ),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, register, formState } = useForm(formOptions);
    const { errors } = formState;

    return (
        <Box className="kmc-console-credentials-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error">
                {error}
            </Alert> : <></>}
            <Box className="kmc-console-credentials-main-content-box">
                <Card className="kmc-console-credentials-info-card">
                    <Typography variant="h6">Manage console credentials</Typography>
                </Card>
                {
                    !isPublished
                        ? <Card className="kmc-console-credentials-content-card">
                            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-credentials-form-box">
                                <TextField
                                    sx={{ width: "400px" }}
                                    margin="normal"
                                    id="email"
                                    label="Email"
                                    name="email"
                                    size="medium"
                                    autoComplete="email"
                                    autoFocus
                                    placeholder="Email"
                                    disabled
                                    value={email}
                                />
                                <Typography component="h3" style={{ color: "red" }}>{errors.email?.message}</Typography>
                                <TextField
                                    sx={{ width: "400px" }}
                                    type={showPassword ? "text" : "password"}
                                    margin="normal"
                                    id="password"
                                    size="medium"
                                    label="Password"
                                    name="password"
                                    autoComplete="password"
                                    error={errors?.password ? true : false}
                                    helperText={errors.password?.message}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={
                                        {
                                            endAdornment: (
                                                <InputAdornment position="end"> {
                                                    showPassword ? (
                                                        <Visibility className="cursor_pointer"
                                                            onClick={
                                                                togglePasswordHide
                                                            }
                                                        />
                                                    ) : (
                                                        <VisibilityOff onClick={
                                                            togglePasswordHide
                                                        }
                                                        />
                                                    )
                                                }
                                                </InputAdornment>
                                            ),
                                        }
                                    }
                                    {...register("password", { required: true })}
                                />
                                <Box>
                                    <Button variant="outlined"
                                        color="primary"
                                        size="small"
                                        type="submit"
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                        : null
                }
            </Box>
            <Card className="kmc-console-credentials-content-card">
                <Box sx={{ marginTop: "0.5rem" }}>
                    <CredentialsExternalSources />
                </Box>
            </Card>
            {
                isPublished
                    ? <Card className="kmc-console-credentials-content-card">
                        <Box className="kmc-console-box-for-tenant-table"
                            sx={{ marginTop: "1rem" }}>
                            <CredentialsPaginationTable />
                        </Box>
                    </Card>
                    : null
            }
        </Box>
    );
}

export default Credentials;
import './ManageCommonDocuments.css';
import { Alert, Box, Card, Chip, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import DocumentPaginationTable from '../../molecules/DocumentPaginationTable';
import SecureLS from 'secure-ls';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import { environment } from '../../assets/configurations/configuration';
import Loader from '../loader/Loader';

export default function ManageCommonDocuments(props) {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const fileRef = useRef();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [commonDocuments, setCommonDocuments] = useState({});

    useEffect(() => {
        // get existing document of the tenant
        setLoading(true);
        axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/common_documents`)
            .then(res => {
                setLoading(false);
                if (res && res.data) {
                    setCommonDocuments(res.data.documents ? res.data.documents : {});
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
                console.log(err && err.msg);
            })

    }, [tenant]);

    const handleSelectedDocument = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoading(true);
            // hack to replace the spaces in filenames with '_'.
            let fileName = file.name;
            fileName = fileName.replace(/ /g,"_");
            // start uploading the documents to the bulk upload and upon successful
            // the corresponding data should be stored into the private configuration
            let config = {
                headers: {
                    filename: fileName
                }
            }
            let documentInformation = {};
            //let source_path = "";
            axiosInstance.post(`${environment.EMPLOYEE_BULK_IMAGE_UPLOAD_API}/${tenant}/visibility/private/document/console/id_type/documents/id/common_documents`,
                {}, config)
                .then(res => {
                    if (res && res.data) {
                        // needed to save into the private configuration
                        documentInformation.id = res.data.filename;
                        documentInformation.filename = res.data.filename;
                        //documentInformation.created_time = res.data.created_time;

                        // other information if needed
                        documentInformation.filetype = file.type;
                        documentInformation.filesize = file.size;
                        documentInformation.created_time = new Date();
                        documentInformation.file_path = res.data.file_path;

                        const { url } = res.data.presigned_url_details;
                        const { fields } = res.data.presigned_url_details;
                        //source_path = res.data.file_path;
                        //root path and source path - Base64 Encoded URL
                        const formData = new FormData();
                        formData.append("key", fields['key']);
                        formData.append("AWSAccessKeyId", fields['AWSAccessKeyId']);
                        formData.append("x-amz-security-token", fields['x-amz-security-token']);
                        formData.append("policy", fields['policy']);
                        formData.append("signature", fields['signature']);
                        formData.append("file", file);

                        const preSignedHeaders = new Headers();
                        preSignedHeaders.append("Access-Control-Allow-Credentials", "true");
                        preSignedHeaders.append("Content-Type", ' ');
                        const requestOptions = {
                            method: 'POST',
                            headers: preSignedHeaders,
                            body: formData,
                            redirect: 'follow',
                            mode: 'no-cors',
                        };
                        fetch(url, requestOptions)
                            .then(res => {
                                // save the file metadata information to the private configuration
                                // add new file to current documents
                                commonDocuments[documentInformation.filename] = documentInformation;
                                const config = {
                                    documents: commonDocuments
                                }
                                axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/common_documents`, config)
                                    .then(res => {
                                        setLoading(false);
                                        // update table
                                    })
                                    .catch(err => {
                                        setLoading(false);
                                        setError(err && err.message);
                                    })

                            })
                            .catch(err => {
                                setError(err && err.message);
                                setLoading(false);
                            })
                    }
                })
                .catch(err => {
                    setError(err && err.message);
                    setLoading(false);
                })
        }
    }

    const handleAddDocumentClick = (e) => {
        fileRef.current.click();
    }

    return (
        <Box className="kmc-console-manage-common-documents-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity='error'>{error}</Alert> : null}
            <Box className="kmc-console-manage-common-documents-content-box">
                <Card className="kmc-console-manage-common-documents-info-card">
                    <Typography variant="h6">Manage Common Documents</Typography>
                </Card>
                <Card className="kmc-console-manage-common-documents-content-card">
                    <Box className="kmc-console-manage-common-documents-form-box">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: "start" }}>
                                <Chip
                                    label="Add Document"
                                    icon={<Add></Add>}
                                    color="primary"
                                    variant='outlined'
                                    onClick={handleAddDocumentClick}
                                    disabled={loading}
                                >
                                </Chip>
                                <input type={"file"} hidden ref={fileRef}
                                    onChange={handleSelectedDocument}
                                >
                                </input>
                            </Grid>
                            <Grid item xs={12}>
                                <DocumentPaginationTable
                                    documents={commonDocuments}
                                    selected_user_id={tenant}
                                    type={"common"}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}
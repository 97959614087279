import { Domain } from "@mui/icons-material";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { headerCells } from './PublishLinkedSubDomainTableHeadCells';

export default function PublishLinkedSubDomainTableHeader(props) {
    
    return (
        <TableHead sx={{ backgroundColor: "#cdd1dc" }}>
            <TableRow>
                {headerCells.map((headCell, key) => (
                    <TableCell
                        key={key}
                        align={headCell.numeric ? 'right' : 'left'}
                        //padding={headCell.disablePadding ? 'none' : 'normal'}                        
                    >
                        <TableSortLabel hideSortIcon>
                            {headCell.id === "domain" ? <Domain sx={{ paddingRight: "3px" }} /> : null}
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
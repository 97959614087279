import React, { useEffect, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { LocationSearching } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import "./LocationManagementNodeGroup.css";

export default function LocationMangementNodeGroup(props) {

    const navigate = useNavigate();
    const state = useLocation();
    const [active, setActive] = useState();

    useEffect(() => {
        const activeItemId = state && state.state && state.state.activeItemId;
        if(activeItemId) {
            setActive(activeItemId);
        }
    },[state]);

    return (
        <List component="div" disablePadding>
            <ListItemButton id="10001"
                className={(active === 10001) ? "kmc-console-locationmanagement-litem-active" : ""}
                onClick={() => {
                    navigate('/manage/locations', { state: { isLocationManagementNodeExpand: true } });
                    setActive(10001);
                }
                }>
                <ListItemIcon>
                    <LocationSearching fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Manage Locations'} />
            </ListItemButton>
        </List>
    )
}
import GoogleMapContainer from "../../molecules/GoogleMapContainer";
import { Add } from '@mui/icons-material';
import { Alert, Box, Card, Chip, Grid, Typography } from '@mui/material';
import './ManageLocation.css';
import { useEffect, useRef, useState } from 'react';
import RegisterOfficeLocation from "../../molecules/RegisterOfficeLocation";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import SecureLS from "secure-ls";
import { environment } from "../../assets/configurations/configuration";
import Loader from "../loader/Loader";

const ManageLocation = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [addLocationChipDisabled, setAddLocationChipDisabled] = useState(false);
    const [isLocationRowEnabled, setIsLocationRowEnabled] = useState();
    const [locationRow, setLocationRow] = useState({});
    const [address, setAddress] = useState('');
    const [refreshLocations, setRefreshLocations] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [locations, setLocations] = useState([]);
    const [message, setMessage] = useState(null);
    const [seed,setSeed] = useState(1);
    const compRef = useRef();

    useEffect(() => {
        // search all locations
        const searchOptions = {
            prefix: "*",
            pageSize: 500,
            sortByField: "locationName",
            sortBy: "asc"
        }
        const config = {
            headers: {
                company: tenant,
                requestagentid: loggedInUserEmail
            }
        }
        setLoading(true);
        axiosInstance.post(environment.SEARCH_OFFICE_LOCATIONS_API, searchOptions, config)
            .then(res => {
                setLoading(false);
                if (res && res.data) {
                    if (res.data.entityList) {
                        setLocations(res.data.entityList);
                    }
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err?.message);
                console.log(err);
            })
    }, [compRef, refreshLocations, loggedInUserEmail, tenant]);

    const handleAddLocationRow = (e) => {
        setAddLocationChipDisabled(true);
        setIsLocationRowEnabled(true);
    }

    const handleLocationSuccess = (message) => {
        setMessage(message);

        setRefreshLocations(true);
        setIsLocationRowEnabled(false);
        setAddLocationChipDisabled(false);
    }

    const handleEditLocation = (locationRow) => {
        setEditMode(true);
        setAddLocationChipDisabled(true);
        setIsLocationRowEnabled(true);
        setAddress(locationRow.locationName);
        setLocationRow(locationRow.row);
    }

    const handleCloseLocation = () => {
        setEditMode(false);
        setLocationRow(null);
        setAddLocationChipDisabled(false);
        setIsLocationRowEnabled(false);
    }

    const handleChildData = (event) => {
        const searchOptions = {
            prefix: "*",
            pageSize: 500,
            sortByField: "locationName",
            sortBy: "asc"
        }
        const config = {
            headers: {
                company: tenant,
                requestagentid: loggedInUserEmail
            }
        }
        setLoading(true);
        axiosInstance.post(environment.SEARCH_OFFICE_LOCATIONS_API, searchOptions, config)
            .then(res => {
                setLoading(false);
                if (res && res.data) {
                    if (res.data.entityList) {
                        setLocations([]);
                        setLocations(res.data.entityList);
                        setSeed(Math.random())
                    }
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err?.message);
            })

    }

    return (
        <Box className="kmc-console-location-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : null}
            {message ? <Alert severity="success" onClose={() => setMessage(null)}>{message}</Alert> : null}
            <Box className="kmc-console-location-content-box">
                <Card className="kmc-console-location-info-card">
                    <Typography variant="h6">Manage Office Locations</Typography>
                </Card>
                <Card className="kmc-console-location-content-card">
                    <Box className="kmc-console-location-form-box">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: "start" }}>
                                <Chip
                                    label="Register Office Location"
                                    icon={<Add></Add>}
                                    color="primary"
                                    variant='outlined'
                                    onClick={handleAddLocationRow}
                                    disabled={addLocationChipDisabled}
                                >
                                </Chip>
                            </Grid>
                            {
                                isLocationRowEnabled
                                    ? <Grid item xs={12}>
                                        <RegisterOfficeLocation
                                            onLocationSuccess={handleLocationSuccess}
                                            onCloseLocation={handleCloseLocation}
                                            locationRow={locationRow}
                                            locationName={address}
                                            editMode={editMode}
                                            onChildDataChange={handleChildData}
                                        >
                                        </RegisterOfficeLocation>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                {
                                    locations && locations.length > 0 ?
                                        <GoogleMapContainer
                                            key={seed}
                                            initialLocations={locations}
                                            refreshLocations={refreshLocations}
                                            onEditLocationRow={handleEditLocation}
                                        ></GoogleMapContainer>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box >
        </Box >
    )

}

export default ManageLocation;
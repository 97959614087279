import { Chip, Grid, Popover, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

const PopoverMappingsList = (props) => {
    const { el, handleClosePopover, id, mappingsList } = props;
    const popOverOpen = Boolean(el);
    const newId = popOverOpen ? 'kmc-console-mapping-list-popover-' + id : undefined;

    return (
        <Popover
            id={newId}
            open={popOverOpen}
            anchorEl={el}
            onClose={handleClosePopover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Grid container spacing={1} sx={{ padding: "0.5rem" }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "550" }}>Attributes List</Typography>
                </Grid>
                {
                    mappingsList && mappingsList.length > 0
                        ?
                        mappingsList.map((element, index) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 4} key={index}>
                                    <Chip label={element} color={'primary'} variant='outlined' />
                                </Grid>
                            )
                        })
                        : null
                }
            </Grid>
        </Popover>
    )
}

export default PopoverMappingsList;
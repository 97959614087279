import {
    Popover, Card, CardContent, Typography
} from "@mui/material";
import UserPicker from "./UserPicker";

export default function PopoverUserPicker(props) {

    const { el, handleSelectedItem, handleClosePopover, title } = props;
    const popOverOpen = Boolean(el);
    const id = popOverOpen ? 'kmc-console-userpicker-popover' : undefined;

    const { innerHeight, innerWidth } = window;
    const positionLeft = innerWidth / 2;
    const positionTop = innerHeight / 2;


    return (
        <Popover
            id={id}
            open={popOverOpen}
            anchorEl={el}
            onClose={handleClosePopover}
            anchorReference="anchorPosition"
            anchorPosition={{ left: positionLeft, top: positionTop }}
            // anchorPosition={{ top: 300, left: 600 }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{ margin: "1px" }}
        >
            <Card>
                <Typography sx={{
                    textAlign: "center",
                    fontSize: "15px !important", marginTop: "1rem"
                }}>
                    {title}
                </Typography>
                <CardContent>
                    <UserPicker 
                        onSelectSearchItem={handleSelectedItem}
                        onClearSearchItem={handleClosePopover}>                    
                    </UserPicker>
                </CardContent>
            </Card>
        </Popover>
    )
}
import React, { useEffect, useRef, useState } from "react";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Card, Typography, Box, TextField, Alert, Grid, InputAdornment, ListItemText, ListItem } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useForm, Controller } from "react-hook-form";
import './Payment.css';
import SecureLS from "secure-ls";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { environment } from "../../assets/configurations/configuration";
import MuiPhoneNumber from 'material-ui-phone-number';
import valid from 'card-validator';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { isMobile } from "react-device-detect";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

const Payment = (props) => {

  const _secureStore = new SecureLS();
  const tenant = _secureStore.get("tenant_name");
  const _secure_user_info = _secureStore.get("_secure_user_info");
  const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
    && _secure_user_info.attributes['email'];

  const navigate = useNavigate();
  const currentRef = useRef();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [showCvv, setShowCvv] = useState(false);
  const [focused, setFocused] = useState('cardHolderName');

  useEffect(() => {
    if (!tenant) {
      navigate('/workspace/register', {
        state: {
          message: "You must register domain.",
          isWorkspaceExpand: true,
          activeItemId: 991
        }
      });
      return;
    }
    if (currentRef) {
      setLoading(true);
      axiosInstance.get(`${environment.PAYMENT_DETAILS}/${tenant}/configurationGroup/payment_details`)
        .then(res => {
          if (res && res.data) {
            const { cardNumber, cardHolderName, cardExpiration, address1, city, state, country, postal_code, mobile } = res.data;
            setValue("cardNumber", cardNumber);
            setValue("cardHolderName", cardHolderName);
            setValue("cardExpiration", cardExpiration);
            setValue("city", city);
            setValue("state", state);
            setValue("country", country);
            setValue("postal_code", postal_code);
            setValue("address1", address1);
            setValue("mobile", mobile);
          }
          setLoading(false);
        }).catch(err => {
          setLoading(false);
          setError(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRef, tenant]);

  const validationSchema = Yup.object().shape({
    cardNumber: Yup.string()
      .test("test-card-number", "Invalid credit card number",
        value => valid.number(value).isValid).required(),
    cardHolderName: Yup.string().required(),
    cardExpiration: Yup.string()
      .test("test-card-month", "Invalid expiry", function (value) {
        const expiration = value.split('/');
        if (expiration.length === 2) {
          if (valid.expirationDate(value).isValid) {
            return true;
          }
        }
        return false;
      })
      .required(),
    cardSecurityCode: Yup.string().test("test-card-cvv", "Invalid security code",
      value => valid.cvv(value, [3, 4]).isValid).required(),
    address1: Yup.string().required(),
    state: Yup.string().required(),
    postal_code: Yup.string().required()
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { handleSubmit, formState, control, setValue, register, watch } = useForm(formOptions, {
    defaultValues: {
      cardNumber: "",
      cardHolderName: "",
      cardExpiration: "",
      cardSecurityCode: "",
      address1: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      mobile: ""
    }
  });
  const { errors } = formState;
  const cardNumberWatch = watch('cardNumber');
  const cardHolderNameWatch = watch('cardHolderName');
  const cardExpirationWatch = watch('cardExpiration');
  const cardSecurityCodeWatch = watch('cardSecurityCode');

  const handleOnSubmit = (data) => {
    if (data) {
      // validate the card details using the stripe verification      
      setLoading(true);
      const config = {
        email: loggedInUserEmail,
        name: tenant,
        card: {
          number: data?.cardNumber,
          exp_month: data?.cardExpiration.split('/')[0],
          exp_year: data?.cardExpiration.split('/')[1],
          cvc: data?.cardSecurityCode
        },
        address: {
          line1: data?.address1,
          city: data?.city,
          country: data?.country,
          state: data?.state,
          postal_code: data?.postal_code
        }
      };
      axiosInstance.post(environment.STRIPE_CARD_VALIDATION_API, config)
        .then(res => {
          axiosInstance.post(`${environment.PAYMENT_DETAILS}/${tenant}/configurationGroup/payment_details`, data)
            .then(res => {
              updateCompletionCheck(true);
              setLoading(false);
              navigate('/workspace/mapping', {
                state: {
                  isWorkspaceExpand: true,
                  activeItemId: 997
                }
              });
            })
            .catch(err => {
              updateCompletionCheck(false);
              setLoading(false);
              setError(err.message);
            });
        })
        .catch(err => {
          setLoading(false);
          const status = err?.response?.status;
          if (status === 400) {
            setError(err?.response?.data);
          } else {
            setError(err?.message);
          }
        });
    }
  };

  const updateCompletionCheck = (flag) => {
    _secureStore.set("isPaymentCompleted", flag);
  }

  const [address, setAddress] = useState();

  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then(results => {
        const record = results[0];
        // get latitude and longitude
        getLatLng(record)
          .then(latLng => {
            // get locality for employees
            let res = [];
            const address_components = record?.address_components;
            address_components.forEach(address_component => {
              let obj = {
                [address_component.types[0]]: address_component.long_name
              };
              res.push(obj);
            });
            res.forEach(v => {
              const key = Object.keys(v)[0];
              if (key === 'locality') {
                setAddress(Object.values(v));
                setValue('city', Object.values(v)[0]);
              }
              if (key === 'administrative_area_level_1') {
                setValue('state', Object.values(v)[0]);
              }
              if (key === 'country') {
                setValue('country', Object.values(v)[0]);
              }
            })
          })
          .catch(err => {
            console.log(err);
          })
      })
      .catch(error => {
        console.error('Error', error);
      });
  };

  return (
    <Box className="kmc-console-payment-main-box">
      <Loader loading={loading}></Loader>
      {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : <></>}
      <Box className="kmc-console-payment-content-box">
        <Card className="kmc-console-payment-info-card">
          <Typography variant="h6" >Payment details</Typography>
          <Typography variant="h6">Provide the payment details to publish the domain</Typography>
        </Card>
        <Card className="kmc-console-payment-content-card">
          <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-payment-cc-form-box">
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'start' }}>
                <Typography component={'h6'} sx={{ fontSize: '1.30rem', fontWeight: 500, color: '#595a5f' }}>Payment Method</Typography>
              </Grid>
              <Grid item xs sx={{ alignSelf: 'center' }}>
                <Cards
                  name={cardHolderNameWatch || 'IntraCrew'}
                  number={cardNumberWatch || '0123456789101112'}
                  cvc={cardSecurityCodeWatch || '000'}
                  expiry={cardExpirationWatch || 'mm/yy'}
                  focused={focused}
                >
                </Cards>
              </Grid>
              <Grid item xs>
                <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
                  <Grid item xs={12} sm={8.5}>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="cardHolderName"
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          size='medium'
                          {...field}
                          {...register("cardHolderName")}
                          label="Card Holder Name"
                          InputLabelProps={{ shrink: true }}
                          error={errors?.cardHolderName ? true : false}
                          onFocus={(e) => setFocused(e.target.name)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8.5}>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="cardNumber"
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          size='medium'
                          {...register("cardNumber")}
                          label="Card Number"
                          InputLabelProps={{ shrink: true }}
                          error={errors?.cardNumber ? true : false}
                          helperText={errors?.cardNumber?.message}
                          onFocus={(e) => setFocused(e.target.name)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs sm={4}>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="cardExpiration"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size='medium'
                          {...register("cardExpiration")}
                          label="Expiration (mm/yy)"
                          InputLabelProps={{ shrink: true }}
                          error={errors?.cardExpiration ? true : false}
                          helperText={errors?.cardExpiration?.message}
                          placeholder={'mm/yy'}
                          onFocus={(e) => setFocused(e.target.name)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs sm={4}>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name="cardSecurityCode"
                      render={({ field }) => (
                        <TextField
                          type={showCvv ? 'text' : 'password'}
                          {...field}
                          name="cardSecurityCode"
                          size='medium'
                          {...register("cardSecurityCode")}
                          label="Security Code"
                          InputLabelProps={{ shrink: true }}
                          error={errors?.cardSecurityCode ? true : false}
                          helperText={errors?.cardSecurityCode?.message}
                          onFocus={(e) => setFocused('cvc')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                {showCvv ? (
                                  <Visibility
                                    className="cursor_pointer"
                                    onClick={(event) => {
                                      setShowCvv(!showCvv);                                                                      
                                    }
                                    }
                                  />
                                ) : (
                                  <VisibilityOff
                                    onClick={(event) => {
                                      setShowCvv(!showCvv);                                      
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'start', paddingBottom: "1rem" }}>
                <Typography component={'h6'} sx={{ fontSize: '1.30rem', fontWeight: 500, color: '#595a5f' }}>Billing Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="address1"
                  render={({ field }) => (
                    <TextField
                      sx={{ width: isMobile ? '100%' : '50%' }}
                      size='medium'
                      {...field}
                      {...register("address1")}
                      label="Address Line1"
                      InputLabelProps={{ shrink: true }}
                      error={errors?.address1 ? true : false} />
                  )}
                />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'end' }}>
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  searchOptions={{ types: ['locality'] }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <Box>
                      <Controller
                        name={"city"}
                        control={control}
                        defaultValue={''}
                        render={({ field, value }) => (
                          <TextField
                            sx={{ width: isMobile ? '100%' : '50%' }}
                            size='medium'
                            //onChange={(value) => setValue("city", value)}
                            {...field}
                            {...register("city")}
                            label={"City"}
                            InputLabelProps={{ shrink: true }}
                            error={errors?.city ? true : false}
                            {...getInputProps({
                              className: 'location-search-input',
                            })}
                          />
                        )}
                      >
                      </Controller>
                      <Card className="autocomplete-dropdown-container">
                        {loading && <Loader loading={loading}></Loader>}
                        {suggestions.map((suggestion, key) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: 'aliceblue', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <ListItem key={key}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <ListItemText>{suggestion.description}</ListItemText>
                            </ListItem>
                          );
                        })}
                      </Card>
                    </Box>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'start' }}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="state"
                  render={({ field }) => (
                    <TextField
                      sx={{ width: isMobile ? '100%' : '50%' }}
                      size='medium'
                      {...field}
                      {...register("state")}
                      label="State"
                      InputLabelProps={{ shrink: true }}
                      error={errors?.state ? true : false} />
                  )}
                />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'end' }}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="country"
                  render={({ field }) => (
                    <TextField
                      sx={{ width: isMobile ? '100%' : '50%' }}
                      size='medium'
                      {...field}
                      {...register("country")}
                      label="Country"
                      InputLabelProps={{ shrink: true }}
                      error={errors?.country ? true : false} />
                  )}
                />
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'start' }}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="postal_code"
                  render={({ field }) => (
                    <TextField
                      sx={{ width: isMobile ? '100%' : '50%' }}
                      size='medium'
                      {...field}
                      {...register("postal_code")}
                      label="Postal Code"
                      InputLabelProps={{ shrink: true }}
                      error={errors?.postal_code ? true : false} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  defaultValue={''}
                  name="mobile"
                  render={({ field }) => (
                    <MuiPhoneNumber
                      sx={{ width: isMobile ? '100%' : '50%' }}
                      size='medium'
                      defaultCountry={'us'}
                      {...field}
                      {...register("mobile")}
                      label="Mobile"
                      name="mobile"
                      onChange={(value) => setValue("mobile", value)}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button variant="outlined" type="submit" size="small"
                  disabled={loading}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}

export default Payment;
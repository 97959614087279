
import { Dialog, DialogTitle } from "@mui/material";
import FileUpload from '../atoms/upload/FileUpload';

const BulkUploadForm = (props) => {

    const { onClose, open, onError, onSuccess } = props;
    
    const handleClose = () => {
        onClose("bulkCard");
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Bulk Upload Configuration</DialogTitle>
            <FileUpload onError={onError} onClose={handleClose} onSuccess={onSuccess}></FileUpload>
        </Dialog>
    )

}

export default BulkUploadForm;
import {
    Popover, Card, CardContent, Typography, ListItem, Chip, Avatar
} from "@mui/material";
import PrivateEncodedImageUrl from "../misc/PrivateEncodedImageUrl";

export default function PopoverUsersList(props) {

    const { el, users, tenant, handleClosePopover, title, id } = props;
    const popOverOpen = Boolean(el);
    const newId = popOverOpen ? 'kmc-console-userpicker-popover-' + id : undefined;

    return (
        <Popover
            id={newId}
            open={popOverOpen}
            anchorEl={el}
            onClose={handleClosePopover}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{ margin: "1px" }}
        >
            <Card>
                <Typography sx={{
                    textAlign: "center",
                    fontSize: "15px !important", marginTop: "0.5rem"
                }}>
                    {title}
                </Typography>
                <CardContent>
                    {
                        users && users.length > 0 ?
                            users.map((user, key) => {
                                return (
                                    <ListItem sx={{ padding: "0 0 3px 0" }} key={key}>
                                        <Chip label={user && user?.givenName}
                                            key={user && user?.givenName}
                                            avatar={<Avatar src={PrivateEncodedImageUrl(tenant, user?.image_name)}></Avatar>}
                                            color='primary'
                                            variant="outlined"
                                        ></Chip>
                                    </ListItem>
                                )
                            })
                            : null
                    }
                </CardContent>
            </Card>
        </Popover>
    )
}
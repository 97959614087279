import { Add } from '@mui/icons-material';
import { Alert, Box, Card, Chip, Grid, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import './ManageConfigurations.css';
import { useEffect, useState } from 'react';
import ConfigurationRow from '../../molecules/ConfigurationRow';
import ConfigurationPaginationTable from '../../molecules/ConfigurationPaginationTable';
import SecureLS from 'secure-ls';
import { environment } from '../../assets/configurations/configuration';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import Loader from '../loader/Loader';
import { Controller, useForm } from "react-hook-form";

function EventConfiguration(props) {

  const _secureStore = new SecureLS();
  const tenant = _secureStore.get("tenant_name");

  const [addAttributeChipDisabled, setAddAttributeChipDisabled] = useState(false);
  const [isConfigRowEnabled, setIsConfigRowEnabled] = useState(false);
  const [configRow, setConfigRow] = useState({});
  const [configs, setConfigs] = useState({});
  const [refreshConfigs, setRefreshConfigs] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, formState, reset } = useForm();
  const [configValues, setConfigValues] = useState(['']);
  const [savedEvents, setSavedEvents] = useState(['']);
  const [displayEvents, setDisplayEvents] = useState(['']);

  useEffect(() => {
    // setDisplayEvents([''])
    setLoading(true);
    axiosInstance.get(`${environment.PAYMENT_DETAILS}/${tenant}/configurationGroup/event_configuration`)
      .then(res => {
        setDisplayEvents(res.data.events);

        setLoading(false);
      }).catch(err => {
        setLoading(false);
        setError(err.message);
      });
  }, []);
  const handleChangeConfigValue = (value, index) => {
    const updatedValues = [...configValues];
    updatedValues[index] = value;
    setConfigValues(updatedValues);
  };

  const handleAddConfigValue = () => {
    if (configValues.length < 5) {
      setConfigValues([...configValues, '']);
    } else {
      console.log('You can only add up to 5 configuration values.');
    }
  };

  const handleRemoveConfigValue = (index) => {
    const updatedValues = [...configValues];
    updatedValues.splice(index, 1);
    setConfigValues(updatedValues);
  };

  const handleSaveEvents = () => {
    // Validate and filter non-empty values
    const nonEmptyValues = configValues.filter((value) => value.trim() !== '');
    if (nonEmptyValues.length > 0 && nonEmptyValues.length <= 5) {
      // Update saved events
      setSavedEvents([...savedEvents, ...nonEmptyValues]);
      // Clear configuration values

      setLoading(true);
      axiosInstance.get(`${environment.PAYMENT_DETAILS}/${tenant}/configurationGroup/event_configuration`)
        .then(res => {
          setDisplayEvents(res.data.events);

          setLoading(false);
        }).catch(err => {
          setLoading(false);
          setError(err.message);
        });
      const combinedData = [...displayEvents, ...nonEmptyValues];
      const body = { events: combinedData };
      // const body = {events: nonEmptyValues}
      axiosInstance.post(`${environment.PAYMENT_DETAILS}/${tenant}/configurationGroup/event_configuration`, body)
        .then(res => {
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });


      setConfigValues(['']);
      // Reset form state
      reset({ configValue1: '' }); // Reset the first configuration value

    } else {
      console.log('Please provide non-empty values and ensure the total is less than or equal to 5.');
    }
  };

  return (
    <Box className="kmc-console-manage-configuration-main-box">
      <Loader loading={loading}></Loader>
      {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : null}
      <Box className="kmc-console-manage-configuration-content-box">
        <Card className="kmc-console-manage-configuration-info-card">
          <Typography variant="h6">Add Event Configuration</Typography>
        </Card>
        <Card className="kmc-console-manage-configuration-content-card">
          <Box className="kmc-console-manage-configuration-form-box">
            <div>
              {configValues.map((value, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
                  <TextField
                    label={`Configuration Value ${index + 1}`}
                    value={value}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/\s/g, ''); 
                      handleChangeConfigValue(newValue, index);
                    }}
                    size="medium"
                    InputLabelProps={{ shrink: true }}
                    sx={{ textAlign: 'start', width: '80%' }}
                  />

                  {index > 0 && (
                    <Button onClick={() => handleRemoveConfigValue(index)} variant="contained" color="error">
                      Remove
                    </Button>
                  )}
                  <Button onClick={handleAddConfigValue} variant="contained" color="primary" sx={{ marginLeft: 2 }}>
                    Add Configuration
                  </Button>
                </Box>
              ))}
              <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'start' }}>
                 The event name needs to be a single word.
              </Typography>
              <Button onClick={handleSaveEvents} variant="contained" color="success" sx={{ marginTop: 2 }}>
                Save Events
              </Button>
              {displayEvents.length > 0 && (
                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Saved Events</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayEvents.map((event, index) => (
                        <TableRow key={index}>
                          <TableCell>{event}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </Box>
        </Card>
      </Box >
    </Box >
  )
}

export default EventConfiguration;
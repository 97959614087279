import { CheckCircle, Domain, Error, OpenInNew, PendingActions } from "@mui/icons-material";
import { styled, tableCellClasses, Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography, Table, Button } from "@mui/material";

const PublishDNSConfigurationSection = (props) => {

    const { linkStatus, status, tenant } = props;

    return (
        <Grid container spacing={2} sx={{ margin: "1rem 0 1rem 0" }}>
            <Grid item xs={12} sx={{ textAlign: "start" }}>
                <Typography variant='h6'>DNS Configuration</Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "start" }}>
                <Typography sx={{ fontSize: "0.875rem" }}>
                    Recommended: If your DNS provider supports CNAME records, use this recommended option.
                    Create a CNAME record for pointing to our load balancer at {tenant}.intracrew.com.
                </Typography>
            </Grid>
            <Grid item xs sx={{ textAlign: "start" }}>
                <Button
                    type="button"
                    size="medium"
                    sx={{ textTransform: 'none', color: 'inherit', ":hover": { backgroundColor: 'inherit' } }}
                    disableRipple
                    endIcon={
                        status === 'ISSUED' ?
                            <CheckCircle fontSize="small"
                                sx={{ color: "#038a12" }}
                            ></CheckCircle>
                            : status === 'PENDING_VALIDATION' ?
                                <PendingActions fontSize="small"
                                    sx={{ color: "#d39118" }}
                                ></PendingActions>
                                : null
                    }
                >
                    Certificate Status: {status}
                </Button>
            </Grid>
            <Grid item sx={{ paddingRight: '1rem' }}>
                <DomainStatusTable domainStatus={linkStatus} />
            </Grid>
        </Grid >
    )
}

export default PublishDNSConfigurationSection;

export const DomainStatusTable = (props) => {

    const domainStatus = props?.domainStatus;
    console.log(domainStatus);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: "#e6e9ed",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                aria-label="customized table"
                size={'medium'}
            >
                <DomainStatusTableHeader
                />
                <TableBody>
                    {
                        Object.values(domainStatus)
                            .map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const domain = Object.keys(domainStatus)[index];
                                const properties = Object.values(domainStatus)[index];
                                return (
                                    <StyledTableRow
                                        hover
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        <StyledTableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            sx={{ color: "#1271c7", fontWeight: '550' }}
                                        >
                                            <Button
                                                type="button"
                                                size="medium"
                                                sx={{ textTransform: 'none', color: 'inherit', ":hover": { backgroundColor: 'inherit' } }}
                                                disableRipple
                                                endIcon={
                                                    <OpenInNew
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            window.open(`https://${domain}`, "_blank");
                                                        }}
                                                    >
                                                    </OpenInNew>
                                                }
                                            >
                                                {domain}
                                            </Button>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button
                                                type="button"
                                                size="medium"
                                                sx={{ textTransform: 'none', color: 'inherit', ":hover": { backgroundColor: 'inherit' }}}
                                                disableRipple
                                                endIcon={
                                                    properties?.link_status === 'LINKED' ?
                                                        <CheckCircle fontSize="small"
                                                            sx={{ color: "#038a12" }}
                                                        ></CheckCircle>
                                                        : <Error fontSize="small"
                                                            sx={{ color: "#d39118" }}
                                                        ></Error>
                                                }
                                            >
                                                {properties?.link_status}
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export const DomainStatusTableHeader = () => {
    return (
        <TableHead sx={{ backgroundColor: "#cdd1dc" }}>
            <TableRow>
                {domainStatusTableCells.map((headCell, key) => (
                    <TableCell
                        key={key}
                        align={headCell.numeric ? 'right' : 'left'}
                    >
                        <TableSortLabel hideSortIcon sx={{ fontWeight: '550' }}>
                            {headCell.id === "domain" ? <Domain sx={{ paddingRight: "3px" }} /> : null}
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export const domainStatusTableCells = [
    {
        id: "domain",
        numeric: false,
        disablePadding: true,
        label: "DOMAIN",
        sortable: false
    },
    {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: "STATUS",
        sortable: false
    }
]
import { Add } from '@mui/icons-material';
import { Alert, Box, Card, Chip, Grid, Typography } from '@mui/material';
import './ManageConfigurations.css';
import { useEffect, useState } from 'react';
import ConfigurationRow from '../../molecules/ConfigurationRow';
import ConfigurationPaginationTable from '../../molecules/ConfigurationPaginationTable';
import SecureLS from 'secure-ls';
import { environment } from '../../assets/configurations/configuration';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import Loader from '../loader/Loader';

function ManageConfigurations(props) {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [addAttributeChipDisabled, setAddAttributeChipDisabled] = useState(false);
    const [isConfigRowEnabled, setIsConfigRowEnabled] = useState(false);
    const [configRow, setConfigRow] = useState({});
    const [configs, setConfigs] = useState({});
    const [refreshConfigs, setRefreshConfigs] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/application_configuration`)
            .then(res => {
                setRefreshConfigs(false);
                setLoading(false);
                if (res && res.data) {
                    setConfigs(res.data.application_configuration ? res.data.application_configuration : {});
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);                
            })
    }, [refreshConfigs, tenant]);

    const handleAddAttributeClick = (e) => {
        setAddAttributeChipDisabled(true);
        setIsConfigRowEnabled(true);
    }

    const handleConfigSuccess = () => {
        setRefreshConfigs(true);
        setIsConfigRowEnabled(false);
        setAddAttributeChipDisabled(false);
    }

    const handleEditConfig = (configRow) => {
        setEditMode(true);
        setAddAttributeChipDisabled(true);
        setIsConfigRowEnabled(true);
        setConfigRow(configRow);
    }

    const handleCloseConfig = () => {
        setEditMode(false);
        setConfigRow(null);
        setAddAttributeChipDisabled(false);
        setIsConfigRowEnabled(false);
    }

    return (
        <Box className="kmc-console-manage-configuration-main-box">
             <Loader loading={loading}></Loader>
             {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : null}
            <Box className="kmc-console-manage-configuration-content-box">
                <Card className="kmc-console-manage-configuration-info-card">
                    <Typography variant="h6">Manage Application Configuration</Typography>
                </Card>
                <Card className="kmc-console-manage-configuration-content-card">
                    <Box className="kmc-console-manage-configuration-form-box">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: "start" }}>
                                <Chip
                                    label="Add Configuration"
                                    icon={<Add></Add>}
                                    color="primary"
                                    variant='outlined'
                                    onClick={handleAddAttributeClick}
                                    disabled={addAttributeChipDisabled}
                                >
                                </Chip>
                            </Grid>
                            {
                                isConfigRowEnabled
                                    ? <Grid item xs={12}>
                                        <ConfigurationRow
                                            onConfigCreationSuccess={handleConfigSuccess}
                                            onCloseConfig={handleCloseConfig}
                                            configRow={configRow}
                                            editMode={editMode}
                                        >
                                        </ConfigurationRow>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                <ConfigurationPaginationTable
                                    initialConfigs={configs}
                                    refreshConfigs={refreshConfigs}
                                    onEditConfigRow={handleEditConfig}
                                >
                                </ConfigurationPaginationTable>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box >
        </Box >
    )
}

export default ManageConfigurations;
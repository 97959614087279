export const permHeadCells = [
    {
        id: "preview",
        numeric: false,
        disablePadding: true,
        label: "Icon",
        sortable: false
    },
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Permission Name",
        sortable: true
    },
    {
        id: "recipients",
        numeric: false,
        disablePadding: true,
        label: "Recepients",
        sortable: false
    },
    {
        id: "badgeAdmins",
        numeric: false,
        disablePadding: true,
        label: "Administrators",
        sortable: false
    },
    {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Notes",
        sortable: false
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: true,
        label: "Actions",
        sortable: false
    }
]

import { Alert, Box } from "@mui/material";
import { Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import './Branding.css';
import BrandingLogo from "./logo/BrandingLogo";
import Loader from "../loader/Loader";
import BrandingColorPicker from "./color/BrandingColorPicker";

const Branding = (props) => {
    
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const handleComponentState = (loading, error) => {
        setLoading(loading);
        setError(error);
    };

    useEffect(() => {
        if (!tenant) {
            navigate('/workspace/register', {
                state: {
                    message: "You must register domain.",
                    isWorkspaceExpand: true,
                    activeItemId: 991                  
                }
            });
        }
    }, [tenant, navigate]);

    return (
        <Box className="kmc-console-branding-main-content-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error">
                {error}
            </Alert> : <></>}
            <Card className="kmc-console-branding-info-card">
                <Typography variant="h6" >Select the color and logo</Typography>
                <Typography variant="h6">Customize the domain of your choice</Typography>
            </Card>
            <BrandingLogo onComponentChange={handleComponentState}></BrandingLogo>
            <BrandingColorPicker onComponentChange={handleComponentState}></BrandingColorPicker>
        </Box>
    )
}

export default Branding;
import React, { useState } from "react";
import { Typography, Box, TextField, FormGroup, FormControlLabel, Checkbox, Grid, Chip, Avatar, Alert, Card, Button, Stack, CircularProgress } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import './OnBoard.css';
import UserPicker from "../../molecules/UserPicker";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { environment } from "../../assets/configurations/configuration";
import SecureLS from "secure-ls";
import Loader from "../loader/Loader";
import EmployeeDisplayCard from "../../molecules/EmployeeDisplayCard";
import PrivateEncodedImageUrl from "../../misc/PrivateEncodedImageUrl";
import LocationSearchPlaces from "../../molecules/LocationSearchPlaces";
import { useLocation, useNavigate } from "react-router-dom";
import MuiPhoneNumber from 'material-ui-phone-number';
import { FlashOnRounded } from "@mui/icons-material";

const OnBoard = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState(false);
    const [manager, setManager] = useState();
    const [file, setFile] = useState();
    const [geoLocation, setGeoLocation] = useState({});
    const location = useLocation();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        displayName: Yup.string().required(),
        contactEmail: Yup.string().required(),
        //role: Yup.string().required(),
        departments: Yup.string().required(),
        //contactPhone: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState, control, register, reset, watch, setValue } = useForm(formOptions, {
        defaultValues: {
            firstName: "",
            lastName: "",
            displayName: "",
            contactEmail: "",
            role: "",
            departments: "",
            locationName: "",
            contactPhone: "",
            isFullTimeEmployee: false,
            isRemoteWorking:false
        }
    });

    const { errors } = formState;
    const watchingDisplayName = watch("displayName");

    //handle on select manager
    const onSelectManager = (e) => {
        setManager(e);
    }

    const onRemoveManager = () => {
        setManager(null);
    }

    const handleLocation = (location) => {
        setGeoLocation({
            address: location?.address,
            latLng: location?.latLng,
            locality: location?.locality
        });
    }



    const handleOnSubmit = async (data) => {
        setMessage("");
        if (data) {
            // check the presence of user in the system using user's email
            const config_1 = {
                headers: {
                    requestagentid: loggedInUserEmail
                }
            };
            axiosInstance.get(environment.GET_USER_INFO_BY_EMAIL + "/" + data?.contactEmail, config_1)
                .then(res => {
                    if (res && res.data) {
                        setError("User already exists. Go and edit on the edit People page.");
                        return;
                    }
                })
                .catch(err => {
                    data.parentId = manager && manager?.referenceObjectGuid ? manager?.referenceObjectGuid : 'None';
                    // split by ","
                    data.departments = data.departments.split(",");
                    data.entityType = "Employee";
                    data.isActive = true;
                    data.company = tenant;
                    data.referenceObjectGuid = data.contactEmail;
                    data.locationName = geoLocation?.address;
                    data.latitude = (geoLocation?.latLng?.lat)?.toString();
                    data.longitude = (geoLocation?.latLng?.lng)?.toString();
                    data.locality = geoLocation?.locality;
                    data.image_name = data.contactEmail + '.png';
                    data.isFullTimeEmployee = data.isFullTimeEmployee;
                    data.isRemoteWorking = data.isRemoteWorking;
                    const config = {
                        headers: {
                            "changeagentid": loggedInUserEmail
                        }
                    }
                    setLoading(true);
                    axiosInstance.post(environment.CREATE_EMPLOYEE_RECORD_API, data, config)
                        .then(res => {
                            // update profile phto
                            handleProfilePicture(data.contactEmail);
                            setLoading(false);
                            setMessage(true);
                            reset();
                            reset({ contactPhone: "+1" });
                            setFile(null);
                            setManager(null);
                            setError("");
                            setGeoLocation({});
                            if (location && location.state && location.state.navigation_from) {
                                if (location.state?.navigation_from === 'employee_data_page') {
                                    updateCompletionCheck(true);
                                    navigate('/workspace/upload/employee', {
                                        state: {
                                            isWorkspaceExpand: true,
                                            activeItemId: 993
                                        }
                                    });
                                }
                            }
                        })
                        .catch(err => {
                            setLoading(false);
                            setError(err.message);
                        })
                });

        }
    }

    const updateCompletionCheck = (flag) => {
        _secureStore.set("isEmployeeDataCompleted", flag);
    }

    const handleProfilePicture = (email) => {
        if (email && file) {
            const encodedPostImageUrl = PrivateEncodedImageUrl(tenant, email + ".png");
            axiosInstance.post(encodedPostImageUrl, file)
                .then(res => {
                    // do nothing already taken care by the employee creation call.
                    onSelectedAsset(null);
                    console.log(res);
                })
                .catch(err => {
                    // report error here if picture fails to upload
                    setError(err && err.message);
                });
        }
    }

    const onSelectedAsset = (file) => {
        console.log(file);
        setFile(file);
    }

    return (
        <Box className="kmc-console-component-main-box">
            {
                loading ? <Loader loading={loading}></Loader> : <></>
            }
            {
                error ? <Alert color="error" onClose={(e) => setError("")}>{error}</Alert> : <></>
            }
            {
                message ? <Alert color="primary" onClose={(e) => setMessage("")}>People onboard successful</Alert> : <></>
            }
            <Box className="kmc-console-onboard-content-box">
                <Card className="kmc-console-onboard-info-card">
                    <Typography variant="h6">On-board People</Typography>
                </Card>
                <Card className="kmc-console-onboard-content-card">
                    <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-onboard-cc-form-box">
                        <Grid container spacing={3}>
                            <Grid container sx={{ paddingTop: "1rem", alignItems: "center" }}>
                                <Grid item xs>
                                    <EmployeeDisplayCard
                                        employee={{ givenName: watchingDisplayName, locationName: geoLocation?.address }}
                                        requestToUpdateAsset={true} onSelectAsset={onSelectedAsset}></EmployeeDisplayCard>
                                </Grid>
                                <Grid container xs={12} sm spacing={1} sx={{ textAlign: "left" }}>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            defaultValue={''}
                                            name="firstName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    {...register("firstName")}
                                                    label="Firstname"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="standard"
                                                    error={errors?.firstName ? true : false} />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="lastName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    {...register("lastName")}
                                                    label="Lastname"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="standard"
                                                    error={errors?.lastName ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="displayName"
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    {...register("displayName")}
                                                    label="Display Name"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="standard"
                                                    error={errors?.displayName ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="Email"
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            {...field}
                                            {...register("contactEmail")}
                                            label="Email"
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                            error={errors?.contactEmail ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="contactPhone"
                                    render={({ field }) => (
                                        <MuiPhoneNumber
                                            fullWidth
                                            defaultCountry={'us'}
                                            {...field}
                                            {...register("contactPhone")}
                                            label="Mobile"
                                            name="contactPhone"
                                            onChange={(value) => setValue("contactPhone", value)}
                                            InputLabelProps={{ shrink: true }}
                                            variant="standard"
                                            error={errors?.contactPhone ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="role"
                                    render={({ field }) => (
                                        <TextField fullWidth {...field} {...register("role")} size="small" label="Role"
                                            InputLabelProps={{ shrink: true }} variant="standard" error={errors?.role ? true : false} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="departments"
                                    render={({ field }) => (
                                        <TextField fullWidth {...field} {...register("departments")} size="small" label="Department"
                                            InputLabelProps={{ shrink: true }} variant="standard" error={errors?.departments ? true : false} />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <UserPicker label="Assign Facilitator" onSelectSearchItem={onSelectManager} onClearSearchItem={onRemoveManager}></UserPicker>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                        {
                                            manager ?
                                                <Card className="kmc-console-manager-display-card">
                                                    <EmployeeDisplayCard employee={manager}></EmployeeDisplayCard>
                                                </Card>
                                                : <></>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <LocationSearchPlaces
                                    onSelectAddress={handleLocation}
                                    address={geoLocation?.address}
                                    searchType={'employee'}
                                >
                                </LocationSearchPlaces>
                            </Grid>
                            <Grid item xs={12}>
                                            <Controller
                                                control={control}
                                                name={"isFullTimeEmployee"}
                                                render={({ field: { value, ...field } }) => (
                                                    <FormGroup>
                                                            <FormControlLabel label={"Is Full Time Employment"}
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    {...register("isFullTimeEmployee")}
                                                                    checked={!!value}
                                                                    color="primary"
                                                                    size={"small"}
                                                                    disableRipple
                                                                />
                                                            }
                                                        >
                                                        </FormControlLabel>
                                                    </FormGroup>
                                                )}
                                            >
                                            </Controller>
                                        </Grid>
                            <Grid item xs={12} >
                                        <Controller
                                            control={control}
                                            name={"isRemoteWorking"}
                                            render={({ field: { value, ...field } }) => (
                                                <FormGroup>
                                                    <FormControlLabel label={"Is Remote Working"}
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                {...register("isRemoteWorking")}
                                                                checked={!!value}
                                                                color="primary"
                                                                size={"small"}
                                                                disableRipple
                                                            />
                                                        }
                                                    >
                                                    </FormControlLabel>
                                                </FormGroup>
                                            )}
                                        >
                                        </Controller>
                                      </Grid >
                            <Grid item xs={12}>
                                <Button variant="outlined" type="submit" size="small"
                                    disabled={loading}>
                                    Initiate onboard process
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box >
    )
}
export default OnBoard;
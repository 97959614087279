export const credentialsTableHeadCells = [
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email",
        sortable: true
    },
    {
        id: "created_date",
        numeric: false,
        disablePadding: true,
        label: "Created Date",
        sortable: false
    },
    {
        id: "last_login_date",
        numeric: false,
        disablePadding: true,
        label: "Last Login",
        sortable: false
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: true,
        label: "Actions",
        sortable: false
    }
]

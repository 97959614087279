import React, { useEffect, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { PermDataSetting } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import { useLocation, useNavigate } from 'react-router-dom';
import "./ConfigManagementNodeGroup.css";

export default function ConfigManagementNodeGroup(props) {

    const navigate = useNavigate();
    const state = useLocation();
    const [active, setActive] = useState();

    useEffect(() => {
        const activeItemId = state && state.state && state.state.activeItemId;
        if(activeItemId) {
            setActive(activeItemId);
        }
    },[state]);


    return (
        <List component="div" disablePadding>
            <ListItemButton id="4001"
                className={(active === 4001) ? "kmc-console-configmanagement-litem-active" : ""}
                onClick={() => {
                    navigate('/manage/configuration', { state: { isConfigManagementNodeExpand: true } });
                    setActive(4001);
                }
                }>
                <ListItemIcon>
                    <PermDataSetting fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Manage Configurations'} />
            </ListItemButton>
            <ListItemButton id="4002"
                className={(active === 4002) ? "kmc-console-configmanagement-litem-active" : ""}
                onClick={() => {
                    navigate('/manage/event_configuration', { state: { isConfigManagementNodeExpand: true } });
                    setActive(4002);
                }
                }>
                <ListItemIcon>
                    <EventIcon  fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Event Configurations'} />
            </ListItemButton>
        </List>
    )
}
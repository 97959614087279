import { Add } from '@mui/icons-material';
import { Alert, Box, Button, Card, Chip, Grid, Typography } from '@mui/material';
import MappingRow from '../../molecules/MappingRow';
import './Mapping.css';
import { useEffect, useRef, useState } from 'react';
import MappingPaginationTable from '../../molecules/MappingPaginationTable';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import { environment } from '../../assets/configurations/configuration';
import SecureLS from 'secure-ls';
import Loader from '../loader/Loader';
import { useNavigate } from 'react-router-dom';

function Mapping(props) {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [addAttributeChipDisabled, setAddAttributeChipDisabled] = useState(false);
    const [isMappingRowEnabled, setIsMappingRowEnabled] = useState(false);
    const [mappingRow, setMappingRow] = useState({});
    const [refreshMappings, setRefreshMappings] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [mappings, setMappings] = useState({});
    const mappingRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        if (!tenant) {
            navigate('/workspace/register', {
                state: {
                    message: "You must register domain.",
                    isWorkspaceExpand: true
                }
            });
            return;
        }
        setLoading(true);
        axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/mapping_attributes`)
            .then(res => {
                setRefreshMappings(false);
                setLoading(false);
                if (res && res.data) {
                    setMappings(res.data.mapping_attributes ? res.data.mapping_attributes : {});
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mappingRef, refreshMappings, tenant]);

    const handleAddAttributeClick = (e) => {
        setAddAttributeChipDisabled(true);
        setIsMappingRowEnabled(true);
    }

    const handleMappingSuccess = () => {
        setRefreshMappings(true);
        setIsMappingRowEnabled(false);
        setAddAttributeChipDisabled(false);
    }

    const handleEditMapping = (mappingRow) => {
        setEditMode(true);
        setAddAttributeChipDisabled(true);
        setIsMappingRowEnabled(true);
        setMappingRow(mappingRow);
    }

    const handleCloseMapping = () => {
        setEditMode(false);
        setMappingRow(null);
        setAddAttributeChipDisabled(false);
        setIsMappingRowEnabled(false);
    }

    const handleContinue = () => {
        navigate('/workspace/publish', {
            state: {
                isWorkspaceExpand: true,
                activeItemId: 998
            }
        });
        return;
    }

    return (
        <Box className="kmc-console-mapping-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error">{error}</Alert> : null}
            <Box className="kmc-console-mapping-content-box">
                <Card className="kmc-console-mapping-info-card">
                    <Typography variant="h6">Mapping attributes and access to users</Typography>
                </Card>
                <Card className="kmc-console-mapping-content-card">
                    <Box className="kmc-console-mapping-form-box">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ textAlign: "start" }}>
                                <Chip
                                    label="Add Attribute"
                                    icon={<Add></Add>}
                                    color="primary"
                                    variant='outlined'
                                    onClick={handleAddAttributeClick}
                                    disabled={addAttributeChipDisabled}
                                >
                                </Chip>
                            </Grid>
                            {
                                isMappingRowEnabled
                                    ? <Grid item xs={12}>
                                        <MappingRow
                                            onMappingSuccess={handleMappingSuccess}
                                            onCloseMapping={handleCloseMapping}
                                            mappingRow={mappingRow}
                                            editMode={editMode}
                                        >
                                        </MappingRow>
                                    </Grid>
                                    : null
                            }
                            <Grid item xs={12}>
                                {
                                    mappings
                                        ? <MappingPaginationTable
                                            intialMappings={mappings}
                                            refreshMappings={refreshMappings}
                                            onEditMappingRow={handleEditMapping}
                                        >
                                        </MappingPaginationTable>
                                        : null
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    onClick={handleContinue}
                                    size="small"
                                >
                                    Continue
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box >
        </Box >
    )
}

export default Mapping;
import { LinearProgress } from "@mui/material"

const Loader = (props) => {
    const loading = props.loading;
    return (
        <>
            {
                loading ? <LinearProgress color="primary" className="kmc-console-linear-progress"></LinearProgress>
                    : <></>
            }
        </>
    )
}

export default Loader;
import {
    Alert, Button, Card, CardContent, CardHeader,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid, TextField
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import SecureLS from "secure-ls";
import { useEffect, useRef, useState } from 'react';
import Loader from "../atoms/loader/Loader";
import { CloseRounded } from "@mui/icons-material";

const ConfigurationRow = (props) => {

    const { onConfigCreationSuccess, onCloseConfig, configRow, editMode } = props;
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const ref = useRef();
    const [configs, setConfigs] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {       
        axiosInstance.get(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/application_configuration`)
            .then(res => {
                if (res && res.data) {
                    setConfigs(res.data.application_configuration ? res.data.application_configuration : {});
                }
            })
            .catch(err => {
                setError(err && err.message);
                console.log(err && err.msg);
            })
    }, [ref, tenant]);

    const validationSchema = Yup.object().shape({
        configKey: Yup.string().required(),
        configValue: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { formState, control, register, handleSubmit, setValue, reset } = useForm(formOptions, {
        defaultValues: {
            configKey: "",
            configValue: "",
            isSensitive: false
        }
    });
    const { errors } = formState;

    useEffect(() => {
        if (editMode && configRow) {
            console.log(configRow);
            const { configKey, configValue, isSensitive } = configRow;
            setValue("configKey", configKey);
            setValue("configValue", configValue);
            setValue("isSensitive", isSensitive);
        }
    }, [configRow, editMode, setValue]);

    const handleMappingCardClose = (e) => {
        onCloseConfig();
    }

    const handleOnSubmit = (data) => {
        if (editMode && configRow) {
            data.modifiedDate = new Date();
            configs[configRow.configKey] = data;
        } else {
            const isExists = Object.keys(configs).some(key => key === data.configKey);
            if (isExists) {
                setError("Configuration with key " + data.configKey + " already exists.");
                return;
            }
        }
        // add creation time
        data.creationDate = new Date();
        // add new config to current configs
        configs[data.configKey] = data;
        const config = {
            application_configuration: configs
        }
        setLoading(true);
        axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/application_configuration`, config)
            .then(res => {
                setLoading(false);
                onConfigCreationSuccess();
                reset();
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });
    }

    return (
        <Card component="form" onSubmit={handleSubmit(handleOnSubmit)}>
            <Loader loading={loading}></Loader>
            {
                error ? <Alert severity="warning">{error}</Alert> : null
            }
            <CardHeader
                title={<CloseRounded sx={{ color: "darkgray" }} onClick={handleMappingCardClose}></CloseRounded>}
                sx={{ textAlign: "right", padding: "8px" }}
            >
            </CardHeader>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="configKey"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    {...register("configKey")}
                                    label="Configuration Key"
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ textAlign: "start", width: "80%" }}
                                    error={errors?.configKey ? true : false}
                                    disabled={editMode}
                                >
                                </TextField>
                            )}
                        >
                        </Controller>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="configValue"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    {...register("configValue")}
                                    label="Configuration Value"
                                    size="medium"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ textAlign: "start", width: "80%" }}
                                    error={errors?.configValue ? true : false}>
                                </TextField>
                            )}
                        >
                        </Controller>
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name={"isSensitive"}
                            render={({ field: { value, ...field } }) => (
                                <FormGroup>
                                    <FormControlLabel label={"Is Sensitive?"}  sx={{ justifyContent: "end" }}
                                        {...field}
                                        name="isSensitive"
                                        {...register("isSensitive")}
                                        checked={!!value}
                                        control={<Checkbox size="small" />}                                        
                                    />
                                </FormGroup>
                            )}
                        >
                        </Controller>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small" disabled={loading}>
                            Save Configuration
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ConfigurationRow;
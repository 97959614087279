import './CompanyDetails.css';
import { Alert, Box, Button, Card, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import SecureLS from 'secure-ls';
import { axiosInstance } from '../../../interceptors/axios/AxiosInstance';
import { environment } from '../../../assets/configurations/configuration';
import Loader from '../../loader/Loader';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Cancel, EditSharp } from '@mui/icons-material';
import MuiPhoneNumber from 'material-ui-phone-number';
import DefaultLogo from "../../../assets/flight.png";

const CompanyDetails = (props) => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [loading, setLoading] = useState(false);
    const [logoLoader, setLogoLoader] = useState(false);
    const [error, setError] = useState();
    const [preview, setPreview] = useState(DefaultLogo);
    const [editMode, setEditMode] = useState(true);
    const ref = useRef();

    const validationSchema = Yup.object().shape({
        address1: Yup.string().required(),
        address2: Yup.string().required(),
        phone: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { formState, control, register, handleSubmit, setValue } = useForm(formOptions, {
        defaultValues: {
            address1: "",
            address2: "",
            phone: ""
        }
    });
    const { errors } = formState;

    useEffect(() => {
        if (ref) {
            // get company details if available
            setLoading(true);
            axiosInstance.post(
                `${environment.IAM_DETAILS_API}/${tenant}/Alias/console/type/tenant_details`, { email: loggedInUserEmail })
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        if (res.data.company_details) {
                            setValue("address1", res.data.company_details?.address1);
                            setValue("address2", res.data.company_details?.address2);
                            setValue("phone", res.data.company_details?.phone);
                        }
                    }
                }).catch(err => {
                    setLoading(false);
                    setError(err & err.message);
                });
            // get logo for tenant
            setLogoLoader(true);
            axiosInstance
                .get(
                    `${environment.BRANDING_LOGO_API}/${tenant}/visibility/private/document/console/id_type/images/id/logo_image`, {
                    responseType: 'blob'
                })
                .then(res => {
                    const f = new File([res.data], 'preview');
                    setPreview(window.URL.createObjectURL(f));
                    setLogoLoader(false);
                }).catch(err => {
                    setLogoLoader(false);
                    setError(err && err.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    const onSubmit = (data) => {
        if (formState.isDirty) {
            setLoading(true);
            const config = {
                email: loggedInUserEmail,
                company_details: data
            }
            axiosInstance.post(
                `${environment.IAM_DETAILS_API}/${tenant}/Alias/console/type/tenant_details`, config)
                .then(res => {
                    setLoading(false);
                    setEditMode(true);
                }).catch(err => {
                    setLoading(false);
                    setError(err & err.message);
                });
        } else {
            setEditMode(!editMode);
        }
    }

    return (
        <Box className="kmc-console-company-details-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity='error' onClose={() => setError(null)}>{error}</Alert> : null}
            <Box className="kmc-console-company-details-content-box">
                <Card className="kmc-console-company-details-info-card">
                    <Typography variant='h6'>Manage Company Details</Typography>
                </Card>
                <Card className="kmc-console-company-details-content-card">
                    <Box className="kmc-console-company-details-form-box">
                        <Grid container sx={{ alignItems: "center" }}>
                            <Grid item xs>
                                {
                                    logoLoader ? <CircularProgress /> :
                                        // <img src={preview} alt={"preview"} height={100} width={100} style={{ clipPath: "circle()" }}>
                                        // <img src={preview} alt={"preview"} height={100} width={100} style={{ maxWidth: "15rem",maxHeight:"15rem" }}>
                                        <img src={preview} alt={"preview"} style={{ maxWidth: "15rem", maxHeight:"15rem" }}>

                                        </img>
                                }
                            </Grid>
                            <Grid item sm>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled
                                            fullWidth
                                            name="tenant"
                                            value={tenant}
                                            label="Company Name"
                                            InputLabelProps={{ shrink: true }}
                                        >
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="address1"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    {...register("address1")}
                                                    label="Address Line 1"
                                                    sx={{ textAlign: "start" }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={errors?.address1 ? true : false}
                                                    disabled={editMode}
                                                >
                                                </TextField>
                                            )}
                                        >
                                        </Controller>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="address2"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    {...field}
                                                    {...register("address2")}
                                                    label="Address Line 2"
                                                    sx={{ textAlign: "start" }}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={errors?.address2 ? true : false}                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={editMode}
                                                >
                                                </TextField>
                                            )}
                                        >
                                        </Controller>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="phone"
                                            defaultValue={''}
                                            render={({ field }) => (
                                                <MuiPhoneNumber
                                                    fullWidth
                                                    disabled={editMode}
                                                    defaultCountry={'us'}
                                                    {...field}
                                                    {...register("phone")}
                                                    label="Mobile"
                                                    name="phone"
                                                    onChange={(value) => setValue("phone", value)}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={errors?.phone ? true : false}
                                                    variant="outlined"
                                                    
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{ textAlign: "end" }}>
                                        <Button
                                            variant="outlined"
                                            type="submit"
                                            size="small" disabled={loading || editMode}
                                            sx={{ textTransform: 'none' }}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            Save Details
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sx={{ paddingTop: "1rem", paddingLeft: "1rem", textAlign: 'start' }}>
                                        <Button
                                            type="button"
                                            size="small"
                                            color="secondary"
                                            disabled={loading}
                                            sx={{ textTransform: 'none' }}
                                            endIcon={editMode ? <EditSharp></EditSharp> : <Cancel></Cancel>}
                                            onClick={() => setEditMode(!editMode)}
                                            disableRipple
                                        >
                                            {editMode ? 'Edit' : 'Cancel'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box>
    )
}

export default CompanyDetails;
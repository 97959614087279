import React, { useEffect, useState } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Receipt } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import "./BillingManagementNodeGroup.css";

export default function BillingManagementNodeGroup(props) {

    const navigate = useNavigate();
    const state = useLocation();
    const [active, setActive] = useState();

    useEffect(() => {
        const activeItemId = state && state.state && state.state.activeItemId;
        if(activeItemId) {
            setActive(activeItemId);
        }
    },[state]);

    return (
        <List component="div" disablePadding>
            <ListItemButton id="7001"
                className={(active === 7001) ? "kmc-console-billing-management-litem-active" : ""}
                onClick={() => {
                    navigate('/manage/billing', { state: { isBillingManagementNodeExpand: true } });
                    setActive(7001);
                }
                }>
                <ListItemIcon>
                    <Receipt fontSize='medium' />
                </ListItemIcon>
                <ListItemText primary={'Manage Billing'} />
            </ListItemButton>
        </List>
    )
}
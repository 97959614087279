import './ManageAdmin.css';
import { Alert, Box, Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import UserPicker from '../../molecules/UserPicker';
import EmployeeDisplayCard from '../../molecules/EmployeeDisplayCard';
import SecureLS from 'secure-ls';
import { Controller, useForm } from 'react-hook-form';
import { environment } from '../../assets/configurations/configuration';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import Loader from '../loader/Loader';

export default function ManageAdmin() {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [loading, setLoading] = React.useState();
    const [error, setError] = React.useState();
    const [employee, setEmployee] = useState();
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [message, setMessage] = useState(null);

    const { setValue, control, register, handleSubmit, reset } = useForm({
        defaultValues: {
            isAdmin: false,
            hasConsoleAccess: false,
            hasPermissionsAccess: false,
            hasDocumentsAccess: false,
            hasConfigurationsAccess: false
        }
    });

    useEffect(() => {
        if (selectedUser && selectedUser.uniqueId) {
            const config = {
                headers: {
                    "requestagentid": loggedInUserEmail
                }
            }
            setLoading(true);
            axiosInstance
                .get(`${environment.GET_EMPLOYEE_RECORD_API}/${selectedUser.uniqueId}`, config)
                .then(res => {
                    if (res && res.data) {
                        setValue("isAdmin", res.data.isAdmin);
                        setValue("hasConsoleAccess", res.data.hasConsoleAccess);
                        setValue("hasPermissionsAccess", res.data.hasPermissionsAccess);
                        setValue("hasDocumentsAccess", res.data.hasDocumentsAccess);
                        setValue("hasConfigurationsAccess", res.data.hasConfigurationsAccess);
                        setValue("contactEmail", res.data.contactEmail);
                        setEmployee(res.data);
                    }
                    setLoading(false)
                }).catch(err => {
                    setLoading(false);
                    setError(err.message);
                });
        }
    }, [selectedUser, loggedInUserEmail, setValue]);

    function handleSelectUser(user) {
        // reset the current selected user.
        handleClearSearchItem();
        setSelectedUser(user);
    }

    function handleClearSearchItem() {
        setSelectedUser(null);
    }

    function onSubmit(data) {
        const config = {
            headers: {
                "changeagentid": loggedInUserEmail
            }
        }
        data.entityType = "Employee";
        data.isActive = true;
        data.uniqueId = employee.uniqueId;
        data.company = tenant;
        console.log("employee_data",data);

        // update the selected user with the granted roles
        setLoading(true);
        axiosInstance.post(environment.CREATE_EMPLOYEE_RECORD_API, data, config)
            .then(res => {
                  if (res && res.data) {
                      console.log("data",data);
                      const admin_data = {
                                email :data.contactEmail,
                                isAdmin: data.isAdmin,
                                hasConsoleAccess: data.hasConsoleAccess,
                                hasDocumentsAccess: data.hasDocumentsAccess,
                                hasConfigurationsAccess : data.hasConfigurationsAccess
                            }
                      console.log("admin_data",admin_data);
                      setLoading(true);
                      axiosInstance.post(
                        `${environment.IAM_DETAILS_API}/${tenant}/Alias/console/type/tenant`, admin_data)
                        .then(res => {
                            setLoading(false);
                            if (res && res.data) {
                                reset();
                                const message = (data.isAdmin) ? `${employee?.givenName} has been granted with admin access.`
                                    : `Admin access for ${employee?.givenName} has been revoked.`
                                setMessage(message);
                                setLoading(false);
                                setEmployee(null);
                                setSelectedUser(null);
                            }
                        })
                        .catch(err => {
                            setLoading(false);
                            setError(err.message)
                        });
                    }
                }).catch(err => {
                    setError(err && err.message);
                });
}

    return (
        <Box className="kmc-console-manageadmin-main-box">
            <Loader loading={loading}></Loader>
            {error ? <Alert severity='error' onClose={() => setError(null)}>{error}</Alert> : null}
            {message ? <Alert severity="success" onClose={() => setMessage(null)}>{message}</Alert> : null}
            <Box className="kmc-console-manageadmin-content-box">
                <Card className="kmc-console-manageadmin-info-card">
                    <Typography variant="h6">Manage admin access to users</Typography>
                </Card>
                <Card className="kmc-console-manageadmin-content-card">
                    <Box className="kmc-console-manageadmin-form-box">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <UserPicker onSelectSearchItem={handleSelectUser} onClearSearchItem={handleClearSearchItem}
                                    label={"Search User"}
                                ></UserPicker>
                            </Grid>
                            {
                                selectedUser && !loading
                                    ? <>
                                        <Grid item xs={12}>
                                            <Card className="kmc-console-manageadmin-selecteduser-facade">
                                                <CardContent>
                                                    <Grid container sx={{ alignItems: "center" }}>
                                                        <Grid item xs={6}>
                                                            <EmployeeDisplayCard employee={selectedUser}></EmployeeDisplayCard>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{ backgroundColor: "aliceblue", padding: "0.5rem" }}>
                                                            <Typography fontSize={"13px"}>{employee?.title}</Typography>
                                                            <Typography fontSize={"13px"}>{employee?.contactEmail}</Typography>
                                                            <Typography fontSize={"13px"}>
                                                                {employee?.businessPhones && employee.businessPhones.length > 0 ? employee?.businessPhones[0] : ""}
                                                            </Typography>
                                                            <Typography fontSize={"13px"}>{employee?.locationName}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                control={control}
                                                name={"isAdmin"}
                                                render={({ field: { value, ...field } }) => (
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            label={"Grant Admin Access"}
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    {...register("isAdmin")}
                                                                    checked={!!value}
                                                                    color="primary"
                                                                    size={"small"}
                                                                    disableRipple
                                                                />
                                                            }
                                                        >
                                                        </FormControlLabel>
                                                    </FormGroup>
                                                )}
                                            >
                                            </Controller>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Controller
                                                control={control}
                                                name={"hasConsoleAccess"}
                                                render={({ field: { value, ...field } }) => (
                                                    <FormGroup>
                                                        <FormControlLabel label={"Grant Console Access"}
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    {...register("hasConsoleAccess")}
                                                                    checked={!!value}
                                                                    color="primary"
                                                                    size={"small"}
                                                                    disableRipple
                                                                />
                                                            }
                                                        >
                                                        </FormControlLabel>
                                                    </FormGroup>
                                                )}
                                            >
                                            </Controller>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Controller
                                                control={control}
                                                name={"hasPermissionsAccess"}
                                                render={({ field: { value, ...field } }) => (
                                                    <FormGroup>
                                                        <FormControlLabel label={"Grant Permissions Access"}
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    {...register("hasPermissionsAccess")}
                                                                    checked={!!value}
                                                                    color="primary"
                                                                    size={"small"}
                                                                    disableRipple
                                                                />
                                                            }
                                                        >
                                                        </FormControlLabel>
                                                    </FormGroup>
                                                )}
                                            >
                                            </Controller>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Controller
                                                control={control}
                                                name={"hasDocumentsAccess"}
                                                render={({ field: { value, ...field } }) => (
                                                    <FormGroup>
                                                        <FormControlLabel label={"Grant Documents Access"}
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    {...register("hasDocumentsAccess")}
                                                                    checked={!!value}
                                                                    color="primary"
                                                                    size={"small"}
                                                                    disableRipple
                                                                />
                                                            }
                                                        >
                                                        </FormControlLabel>
                                                    </FormGroup>
                                                )}
                                            >
                                            </Controller>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Controller
                                                control={control}
                                                name={"hasConfigurationsAccess"}
                                                render={({ field: { value, ...field } }) => (
                                                    <FormGroup>
                                                        <FormControlLabel label={"Grant Configuration Access"}
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    {...register("hasConfigurationsAccess")}
                                                                    checked={!!value}
                                                                    color="primary"
                                                                    size={"small"}
                                                                    disableRipple
                                                                />
                                                            }
                                                        >
                                                        </FormControlLabel>
                                                    </FormGroup>
                                                )}
                                            >
                                            </Controller>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                size="small"
                                                type="button"
                                                variant='outlined'
                                                disabled={loading}
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Update Access
                                            </Button>
                                        </Grid>
                                    </>
                                    : null
                            }
                        </Grid>
                    </Box>
                </Card>
            </Box >
        </Box >
    )
}
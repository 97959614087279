import { Alert, Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import './ManageAnnouncements.css';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import Loader from '../loader/Loader';
import SecureLS from 'secure-ls';
import { axiosInstance } from '../../interceptors/axios/AxiosInstance';
import { environment } from '../../assets/configurations/configuration';

function ManageSocialAnnouncements(props) {

    const secureLs = new SecureLS();
    const tenant = secureLs.get("tenant_name");
    const _secure_user_info = secureLs.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    const validationSchema = Yup.object().shape({
        post: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState, control, register, reset } = useForm(formOptions, {
        defaultValues: {
            post: " "
        }
    });
    const { errors } = formState;

    const handleSocialPage = (event) => {
        event.preventDefault();
        window.open(`https://${tenant}.intracrew.com/social`);
    }

    const handleFormSubmit = (data) => {
        setSuccess(false);
        const headers = {
            headers: {
                "changeagentid": loggedInUserEmail
            }
        }
        const config = {
            status: data.post,
            tenant_name: tenant,
            on_behalf: tenant,
            role: "HR",
            image_url: "https://intracrew.com/images/intracrew-logo.png"
        };
        setLoading(true);
        axiosInstance.post(environment.SOCIAL_ACTIVITY_POST_API, config, headers)
            .then(res => {
                setLoading(false);
                setSuccess(true);
                reset({ post: "" });
            })
            .catch(err => {                
                setLoading(false);
                setError(err && err?.message);
            });
    }

    return (
        <Box className="kmc-console-manage-announcements-main-box">
            <Loader loading={loading}></Loader>
            {
                error ? <Alert color="error" onClose={(e) => setError("")}>{error}</Alert> : <></>
            }
            {
                success ? <Alert color="info" onClose={(e) => setSuccess(false)}>Your Comment/Post has been submitted successfully.</Alert> : <></>
            }
            <Box className="kmc-console-manage-announcements-content-box">
                <Card className="kmc-console-manage-announcements-info-card">
                    <Typography variant="h6">Manage Company & Special Dates Announcements</Typography>
                </Card>
                <Card className="kmc-console-manage-announcements-content-card">
                    <Box className="kmc-console-manage-announcements-form-box" component="form" onSubmit={handleSubmit(handleFormSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sx={{ textAlign: "end" }}>
                                <Button
                                    sx={{ textTransform: 'none' }}
                                    onClick={handleSocialPage}
                                >
                                    Goto social management page
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="post"
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            {...field}
                                            {...register("post")}
                                            InputLabelProps={{ shrink: true }}
                                            label="Add a Comment/Post"
                                            error={errors?.post ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "start" }}>
                                <Button
                                    type="submit"
                                    variant='outlined'
                                    size="small"
                                    disabled={loading}
                                >
                                    Post
                                </Button>
                            </Grid>                           
                        </Grid>
                    </Box>
                </Card>
            </Box >
        </Box >
    )
}

export default ManageSocialAnnouncements;
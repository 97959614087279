import { Link, LinkOff, Refresh } from "@mui/icons-material";
import { Alert, Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { environment } from "../assets/configurations/configuration";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Loader from "../atoms/loader/Loader";

const PublishDomainSection = (props) => {

    const { tenant, handleGetCNames, handleGetLinkStatus, handleDomainStatus } = props;

    const validationSchema = Yup.object().shape({
        custom_domain_name: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState, control, register, setValue, reset } = useForm(formOptions, {
        defaultValues: {
            custom_domain_name: "",
        }
    });
    const { errors } = formState;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [mount, setMount] = useState(false);
    const [domainFieldDisabled, setDomainFieldDisabled] = useState(false);

    useEffect(() => {
        if (!mount) {
            setLoading(true);
            axiosInstance.get(`${environment.DOMAIN_LINK_UNLINK_GET_API}/${tenant}/config/domain/link`)
                .then(res => {
                    setLoading(false);
                    setMount(true);
                    if (res && res.data) {
                        if (res.data.domain) {
                            setValue("custom_domain_name", res.data.domain);
                            setDomainFieldDisabled(true);
                        }
                        handleGetCNames(res.data?.cnames);
                        handleDomainStatus(res.data?.status);
                        handleGetLinkStatus(res.data?.link_statuses);
                        const link_status = res.data?.link_statuses;
                        const properties = Object.values(link_status)[0];
                        if (properties.link_status === 'LINKED') {
                            setDomainFieldDisabled(true);
                        }
                    }
                })
                .catch(err => {
                    const error = err && err.response && err.response?.data?.error;
                    setError(error === 'NO_CERT_FOR_DOMAIN' ? '' : error);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mount]);

    const handleOnSubmit = (data, type) => {
        const end_point = (type === 'link') ? 'link' : ((type === 'unlink') ? 'unlink' : 'link');
        setLoading(true);
        if (type === 'refresh') {
            // simply do get the status
            axiosInstance.get(`${environment.DOMAIN_LINK_UNLINK_GET_API}/${tenant}/config/domain/${end_point}`, data)
                .then(res => {
                    setLoading(false);
                    handleGetCNames(res.data?.cnames);
                    handleDomainStatus(res.data?.status);
                    handleGetLinkStatus(res.data?.link_statuses);
                    const link_status = res.data?.link_statuses;
                    const properties = Object.values(link_status)[0];
                    if (properties.link_status === 'LINKED') {
                        setDomainFieldDisabled(true);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    const error = err && err.response && err.response?.data.error;
                    setError(error);
                });
            return;
        }
        axiosInstance.post(`${environment.DOMAIN_LINK_UNLINK_GET_API}/${tenant}/config/domain/${end_point}`, data)
            .then((res) => {
                setLoading(false);
                if (res && res.data) {
                    if (end_point === 'link') {
                        axiosInstance.get(`${environment.DOMAIN_LINK_UNLINK_GET_API}/${tenant}/config/domain/${end_point}`, data)
                            .then(res => {
                                setLoading(false);
                                handleGetCNames(res.data?.cnames);
                                handleDomainStatus(res.data?.status);
                                handleGetLinkStatus(res.data?.link_statuses);
                                const link_status = res.data?.link_statuses;
                                const properties = Object.values(link_status)[0];
                                if (properties.link_status === 'LINKED') {
                                    setDomainFieldDisabled(true);
                                }
                            })
                            .catch(err => {
                                setLoading(false);
                                const error = err && err.response && err.response?.data.error;
                                setError(error);
                            });
                    }
                    if (end_point === 'unlink') {
                        reset();
                        setDomainFieldDisabled(false);
                        handleGetCNames([]);
                        handleGetLinkStatus({});
                    }
                }
            })
            .catch(err => {
                const error = err && err.response && err.response?.data.error;
                setError(error);
                setLoading(false);
            })
    }

    return (
        <Box component={'form'} sx={{ width: "100%" }}>
            <Loader loading={loading}></Loader>
            {
                error
                    ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>
                    : null
            }
            <Grid container spacing={2} sx={{ margin: "1rem 0 1rem 0" }}>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="custom_domain_name"
                        defaultValue={''}
                        render={({ field }) => (
                            <TextField
                                sx={{ width: isMobile ? '100%' : '50%' }}
                                {...field}
                                {...register("custom_domain_name")}
                                label="Domain Name"
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                                disabled={domainFieldDisabled || loading}
                                error={errors?.custom_domain_name ? true : false} />
                        )}
                    >

                    </Controller>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <Button
                                startIcon={<Link></Link>}
                                color="primary"
                                disableRipple
                                variant="outlined"
                                size="small"
                                type="submit"
                                disabled={loading}
                                onClick={handleSubmit((data) => handleOnSubmit(data, "link"))}
                            >
                                Link
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                startIcon={<LinkOff></LinkOff>}
                                color="error"
                                disableRipple
                                variant="outlined"
                                size="small"
                                disabled={loading}
                                onClick={handleSubmit((data) => handleOnSubmit(data, "unlink"))}
                            >
                                Unlink
                            </Button>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'left' }}>
                            <Refresh sx={{ paddingBottom: '5px !important', color: '#1976d2', display: loading ? 'none' : 'initial' }}
                                onClick={handleSubmit((data) => handleOnSubmit(data, "refresh"))}
                            >
                            </Refresh>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PublishDomainSection;
import React, { useEffect, useState } from "react";
import { Typography, Box, TextField, FormGroup, FormControlLabel, Checkbox, Grid, Chip, Avatar, Alert, Card, Button, Stack, CircularProgress } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import SecureLS from "secure-ls";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import './EditEmpProfile.css';
import UserPicker from "../../molecules/UserPicker";
import Loader from "../loader/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { isMobile } from 'react-device-detect';
import { Navigation } from "swiper";
import EmployeeDisplayCard from "../../molecules/EmployeeDisplayCard";
import PrivateEncodedImageUrl from "../../misc/PrivateEncodedImageUrl";
import LocationSearchPlaces from "../../molecules/LocationSearchPlaces";
import MuiPhoneNumber from 'material-ui-phone-number';

const EditEmpProfile = () => {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [file, setFile] = useState();
    const [employee, setEmployee] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState(false);
    const [manager, setManager] = useState();
    const [parentId, setParentId] = useState("");
    const [parentName, setParentName] = useState("");
    const [referenceObjectId, setReferenceObjectId] = useState("");
    const [uniqueId, setUniqueId] = useState();
    const [entityDetails, setEntityDetails] = useState([]);
    const [numberOfReportees, setNumberOfReportees] = useState(0);
    const [currentReportees, setCurrentReportees] = useState([]);
    const [children, setChildren] = useState([]);
    const [geoLocation, setGeoLocation] = useState({});

    const [newManager, setNewManager] = useState({});

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        displayName: Yup.string().required(),
        contactEmail: Yup.string().required(),
        //role: Yup.string().required(),
        departments: Yup.string().required()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState, control, setValue, register, reset } = useForm(formOptions, {
        defaultValues: {
            firstName: "",
            lastName: "",
            displayName: "",
            contactEmail: "",
            contactPhone: "",
            role: "",
            departments: ""
        }
    });

    useEffect(() => {
        if (employee && employee.uniqueId) {
            const config = {
                headers: {
                    "requestagentid": loggedInUserEmail
                }
            }
            setLoading(true);
            axiosInstance
                .get(`${environment.GET_EMPLOYEE_RECORD_API}/${employee.uniqueId}`, config)
                .then(res => {
                    if (res && res.data) {
                        setValue("firstName", res.data.firstName);
                        setValue("lastName", res.data.lastName);
                        setValue("displayName", res.data.displayName);
                        setValue("contactEmail", res.data.contactEmail);
                        setValue("contactPhone", res.data.contactPhone);
                        setValue("departments", res.data.departments.toString());
                        setValue("imageUrl", res.data.imageUrl);
                        setValue("role", res.data.role);
                        setValue("isFullTimeEmployee", res.data?.isFullTimeEmployee);
                        setValue("isRemoteWorking", res.data?.isRemoteWorking);
                        setGeoLocation({ address: res.data?.locationName });
                        setParentId(res.data.parentId);
                        setParentName(res.data.parentName);
                        setReferenceObjectId(res.data.referenceObjectGuid);
                        setUniqueId(res.data.uniqueId);
                    }
                    setLoading(false);
                }).catch(err => {
                    setLoading(false);
                    setError(err.message);
                });
            // get the existing reportees if any for the current selected user
            setLoading(true);
            axiosInstance
                .get(`${environment.GET_CHILD_RECORD_API}/${employee.uniqueId}`, config)
                .then((res) => {
                    setLoading(false);
                    if (res && res.data) {
                        setCurrentReportees(res.data.entityDetails);
                        setManager(res.data.parent);
                        setChildren(res.data.children);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setError(err.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employee]);

    console.log(currentReportees)
    const { errors } = formState;

    const handleProfilePicture = (email) => {
        if (email && file) {
            const encodedPostImageUrl = PrivateEncodedImageUrl(tenant, email + ".png");
            axiosInstance.post(encodedPostImageUrl, file)
                .then(res => {
                    // do nothing already taken care by the employee creation call.
                    onSelectedAsset(null);
                    setFile(null);
                })
                .catch(err => {
                    // report error here if picture fails to upload
                    setError(err && err.message);
                });
        }
    }

    const onSelectEmployee = (data) => {
        // fire a get request to get user details with unique Id        
        setEmployee(data);
    }

    const onRemoveEmployee = () => {
        setChildren([]);
        setEntityDetails([]);
        setManager(null);
        setEmployee({});
        setFile(null);
        reset();
    }

    const updateEachReporteeWithNewManager = () => {

        const currentUserReferenceObjectId = referenceObjectId;

        const config = {
            headers: {
                "changeagentid": loggedInUserEmail
            }
        }
        const data = {
            entityType: "Employee",
            isActive: true,
            company: tenant
        }
        setLoading(true);
        if (entityDetails && entityDetails.length > 0) {
            entityDetails.forEach(entity => {
                data.referenceObjectGuid = entity.referenceObjectGuid;
                data.uniqueId = entity.uniqueId;
                data.parentId = currentUserReferenceObjectId;
                axiosInstance.post(environment.CREATE_EMPLOYEE_RECORD_API, data, config)
                    .then(res => {
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        setError(err && err.message);
                    });
            });
        }
    }

    const handleOnSubmit = (data) => {
        // on edit you need to do invoke the rest entites in the following order
        // 1. If there are reportees added to the selected employee, need to call the
        // update api of each reportee to update the parent with new manager referenceObjectGuid
        // 2. If there is a new manager assigned to the selected user, need to update the
        // parentId with the selected manager referenceObjectGuid.
        // Therefore, the order should be in the following way. Do step 2 first, update employee.
        // Do step 1 to update the reportees. 
        updateEachReporteeWithNewManager();
        const config = {
            headers: {
                "changeagentid": loggedInUserEmail
            }
        }
        data.entityType = "Employee";
        data.isActive = true;
        data.departments = data.departments.split(",");
        data.company = tenant;
        if (geoLocation && Object.keys(geoLocation).length > 0) {
            data.locationName = geoLocation?.address;
            data.latitude = (geoLocation?.latLng?.lat)?.toString();
            data.longitude = (geoLocation?.latLng?.lng)?.toString();
            data.locality = geoLocation?.locality;
        }
        // check if there is a new manager selected for current selection
        if (newManager && newManager.referenceObjectGuid) {
            data.parentId = newManager.referenceObjectGuid;
            data.parentName = newManager.givenName;
        } else {
            data.parentId = parentId;
            data.parentName = parentName;
        }
        data.uniqueId = uniqueId;
        data.referenceObjectGuid = referenceObjectId;
        if (file) {
            data.image_name = data.contactEmail + ".png";
        }
        setLoading(true);
        axiosInstance.post(environment.CREATE_EMPLOYEE_RECORD_API, data, config)
            .then(res => {
                // update profile phto
                handleProfilePicture(data.contactEmail);
                setLoading(false);
                setMessage(true);
                //setFile(null);
                reset();
                setManager(null);
                setEmployee({});
                setEntityDetails([]);
                setCurrentReportees([]);
                setNewManager({});
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
            })
    }

    const onSelectReportee = (reportee) => {
        if (numberOfReportees < 10) {
            setNumberOfReportees(numberOfReportees + 1);
            setEntityDetails(current => [...current, reportee]);
        }
    }

    const onSelectNewManager = (e) => {
        setNewManager(e);
    }

    const onRemoveNewManager = (e) => {
        setNewManager(null);
    }

    const handleDelete = reporteeToDelete => () => {
        setEntityDetails(reportees =>
            reportees.filter((reportee) =>
                reportee.uniqueId !== reporteeToDelete.uniqueId));
        setNumberOfReportees(numberOfReportees - 1);
    };

    const Reportees = entityDetails.map((reportee, index) => (
        <Chip
            size="small"
            avatar={<Avatar alt={reportee.givenName} src="./broken.png" />}
            label={reportee.givenName}
            key={index}
            onDelete={handleDelete(reportee)}
            color="primary"
            variant="outlined"
        />
    ));

    const onSelectedAsset = (file) => {
        setFile(file);
    }

    const handleLocation = (location) => {
        setGeoLocation({
            address: location?.address,
            latLng: location?.latLng,
            locality: location?.locality
        });
    }

    const ShowEmployeeDetails = () => {

        return (
            <>
                <Grid container sx={{ paddingTop: "1rem" }}>
                    <Grid item xs>
                        <EmployeeDisplayCard
                            employee={employee}
                            requestToUpdateAsset={true} onSelectAsset={onSelectedAsset} selectedFile={file}></EmployeeDisplayCard>
                    </Grid>
                    <Grid container xs={12} sm spacing={1} sx={{ textAlign: "left" }}>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="firstName"
                                render={({ field }) => (
                                    <TextField fullWidth {...field} {...register("firstName")} label="Firstname"
                                        InputLabelProps={{ shrink: true }} variant="standard" error={errors?.firstName ? true : false} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="lastName"
                                render={({ field }) => (
                                    <TextField fullWidth {...field} {...register("lastName")} label="Lastname"
                                        InputLabelProps={{ shrink: true }} variant="standard" error={errors?.lastName ? true : false} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="displayName"
                                render={({ field }) => (
                                    <TextField fullWidth {...field} {...register("displayName")} label="Display Name"
                                        InputLabelProps={{ shrink: true }} variant="standard" error={errors?.displayName ? true : false} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="contactEmail"
                        render={({ field }) => (
                            <TextField fullWidth {...field} {...register("contactEmail")} label="Email" disabled
                                InputLabelProps={{ shrink: true }} variant="standard" error={errors?.contactEmail ? true : false} />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="contactPhone"
                        render={({ field }) => (
                            <MuiPhoneNumber
                                fullWidth
                                defaultCountry={'us'}
                                {...field}
                                {...register("contactPhone")}
                                label="Mobile"
                                name="contactPhone"
                                onChange={(value) => setValue("contactPhone", value)}
                                InputLabelProps={{ shrink: true }}
                                variant="standard"
                                error={errors?.contactPhone ? true : false}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="role"
                        render={({ field }) => (
                            <TextField fullWidth {...field} {...register("role")} size="small" label="Role"
                                InputLabelProps={{ shrink: true }} variant="standard" error={errors?.role ? true : false} />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="departments"
                        render={({ field }) => (
                            <TextField fullWidth {...field} {...register("departments")} size="small" label="Department"
                                InputLabelProps={{ shrink: true }} variant="standard" error={errors?.departments ? true : false} />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    {
                        loading ? <CircularProgress></CircularProgress> :
                            <>
                                {
                                    children && children.length > 0 ?
                                        <Chip
                                            size="small"
                                            label={children && children.length + " Direct Reportees"}
                                            color="primary"
                                            variant="outlined"
                                            sx={{ marginBottom: "1rem" }}>
                                        </Chip>
                                        : <></>
                                }
                                <Swiper
                                    style={{ height: 'auto' }}
                                    slidesPerView={isMobile ? 1 : 4}
                                    spaceBetween={5}
                                    slidesPerGroup={3}
                                    loop={false}
                                    loopFillGroupWithBlank={false}
                                    navigation={true}
                                    modules={[Navigation]}
                                    centeredSlides={false}
                                    className="swiper-wrapper"
                                >
                                    {children
                                        ?
                                        children.map((item, key) => (
                                            <SwiperSlide className="swiper-slide">
                                                <Card key={key} className="kmc-console-swiperslide-card">
                                                    <EmployeeDisplayCard employee={currentReportees[item.entityId]} key={key}></EmployeeDisplayCard>
                                                </Card>
                                            </SwiperSlide>
                                        ))
                                        :
                                        <></>
                                    }
                                </Swiper>
                            </>

                    }
                </Grid>
                <Grid item xs={12}>
                    <UserPicker label="Assign New Reportee"
                        onSelectSearchItem={onSelectReportee}
                        onClearSearchItem={onRemoveEmployee}
                    >
                    </UserPicker>
                </Grid>
                <Grid item xs={12}>
                    {
                        <Stack direction={"row"} spacing={3}>
                            {
                                numberOfReportees > 0 ? Reportees : ""
                            }
                        </Stack>
                    }
                </Grid>
                <Grid item xs={12}>
                    {
                        manager && manager?.entityId ?
                            <>
                                <Chip
                                    size="small"
                                    label="Current Facilitator"
                                    color="primary"
                                    variant="outlined"
                                    sx={{ marginBottom: "1rem" }}>
                                </Chip>
                                <EmployeeDisplayCard employee={currentReportees[manager?.entityId]}>
                                </EmployeeDisplayCard>
                            </>
                            : <></>

                    }
                </Grid>
                <Grid item xs={12}>
                    <UserPicker label="Assign New Facilitator"
                        onSelectSearchItem={onSelectNewManager}
                        onClearSearchItem={onRemoveNewManager}
                    >
                    </UserPicker>
                </Grid>
                <Grid item xs={12} sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                    {
                        newManager && newManager?.uniqueId ?
                            <Card className="kmc-console-swiperslide-card">
                                <EmployeeDisplayCard employee={newManager}></EmployeeDisplayCard>
                            </Card>
                            : <></>
                    }
                </Grid>
                <Grid item xs={12}>
                    <LocationSearchPlaces onSelectAddress={handleLocation}
                        address={geoLocation?.address} searchType={'employee'}></LocationSearchPlaces>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name={"isFullTimeEmployee"}
                        render={({ field: { value, ...field } }) => (
                            <FormGroup>
                                <FormControlLabel label={"Is Full Time Employment"}
                                    control={
                                        <Checkbox
                                            {...field}
                                            {...register("isFullTimeEmployee")}
                                            checked={!!value}
                                            color="primary"
                                            size={"small"}
                                            disableRipple
                                        />
                                    }
                                >
                                </FormControlLabel>
                            </FormGroup>
                        )}
                    >
                    </Controller>
                </Grid >
                <Grid item xs={12} >
                    <Controller
                        control={control}
                        name={"isRemoteWorking"}
                        render={({ field: { value, ...field } }) => (
                            <FormGroup>
                                <FormControlLabel label={"Is Remote Working"}
                                    control={
                                        <Checkbox
                                            {...field}
                                            {...register("isRemoteWorking")}
                                            checked={!!value}
                                            color="primary"
                                            size={"small"}
                                            disableRipple
                                        />
                                    }
                                >
                                </FormControlLabel>
                            </FormGroup>
                        )}
                    >
                    </Controller>
                </Grid >
            </>
        )
    }

    return (
        <Box className="kmc-console-edit-employee-main-box">
            {
                loading ? <Loader loading={loading}></Loader> : <></>
            }
            {
                error ? <Alert color="error" onClose={(e) => setError(null)}>{error}</Alert> : <></>
            }
            {
                message ? <Alert color="primary" onClose={(e) => setMessage(null)}>People updated successfully</Alert> : <></>
            }
            <Box className="kmc-console-edit-employee-content-box">
                <Card className="kmc-console-edit-employee-info-card">
                    <Typography variant="h6">Edit People Profile</Typography>
                </Card>
                <Card className="kmc-console-edit-employee-content-card">
                    <Box className="kmc-console-edit-employee-form-box">
                        <Grid container spacing={3}>
                            <Grid item xs={11.5}>
                                <UserPicker label="Search People"
                                    onSelectSearchItem={onSelectEmployee}
                                    onClearSearchItem={onRemoveEmployee}
                                >
                                </UserPicker>
                            </Grid>
                            {
                                employee && employee.uniqueId
                                    ? <ShowEmployeeDetails></ShowEmployeeDetails>
                                    : <></>
                            }
                            {
                                employee && employee.uniqueId
                                    ? <Grid item xs={12}>
                                        <Button variant="outlined" type="button" size="small"
                                            disabled={loading}
                                            sx={{ marginTop: "1rem" }}
                                            onClick={handleSubmit(handleOnSubmit)}
                                        >
                                            Update People
                                        </Button>
                                    </Grid>
                                    : <></>
                            }
                        </Grid>
                    </Box>
                </Card>
            </Box>
        </Box >
    )
}
export default EditEmpProfile;
import './CreateBadge.css';
import * as Yup from 'yup';
import { Alert, Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import Loader from '../atoms/loader/Loader';
import { CloseRounded } from '@mui/icons-material';
import SecureLS from 'secure-ls';
import PrivateEncodedPermissionImageUrl from '../misc/PrivateEncodedPermissionImageUrl';
import { axiosInstance } from '../interceptors/axios/AxiosInstance';
import { environment } from '../assets/configurations/configuration';
import AttributePreview from './AttributePreview';
import PermissionMgmt from "../assets/PermissionMgmt.png";
const CreatePermission = (props) => {
    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const { onPermissionSuccess, onClosePermission, permissionRow, editMode } = props;
    const [loading, setLoading] = useState();
    const [error, setError] = useState("");
    const [file, setFile] = useState();
    const [admins, setAdmins] = useState([]);
    const [imageUrl, setImageUrl] = useState(editMode ? permissionRow.logo_url : PermissionMgmt);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required()
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, formState, control, register, watch, reset, setValue } = useForm(formOptions, {
        defaultValues: {
            name: ""
        }
    });
    const { errors } = formState;
    const nameWatch = watch("name");
    useEffect(() => {
        if (editMode && permissionRow) {
            setLoading(true);
            setImageUrl(null);
            const { name, description, logo_url, permission_admins } = permissionRow;
            setImageUrl(logo_url);
            setValue("name", name);
            setValue("description", description);
            setAdmins(permission_admins);
            setLoading(false);
        }
    }, [permissionRow, editMode, setValue, imageUrl]);
    const handleOnSubmit = (data) => {
        // handle rest call for creation
        // create permission using the attribute service
        setLoading(true);
        let permission_image = data.name;
        permission_image = "permission_"+permission_image.replace(/ /g,"_");
        const permissionEncodedUrl = PrivateEncodedPermissionImageUrl(tenant,permission_image);
        const config = {
            name: data.name,
            description: data.description,
            logo_url: permissionEncodedUrl,
            permission_admins: admins,
        }
        axiosInstance.post(`${environment.ATTRIBUTE_SERVICE_API}/${tenant}/associate_type/permission_${data.name}`, config)
            .then(res => {
                setLoading(false);
                onPermissionSuccess();
            })
            .catch(err => {
                setLoading(false);
                setError(err && err?.message);
            });
        // update permission logo using the image encoder utility
        if (file) {
            setLoading(true);
            console.log("coming");
            axiosInstance.post(permissionEncodedUrl, file)
                .then(res => {
                    setLoading(false);
                    reset();
                    setFile(null);
                })
                .catch(err => {
                    setLoading(false);
                    setError(err & err?.message);
                });
        };
        handlePermissionCardClose();
    }
    const handlePermissionCardClose = () => {
        onClosePermission();
    }
    const handleSelectedPermissionLogo = (file) => {
        if (file) {
            setFile(file);
        }
    }
    return (
        <Card component="form" onSubmit={handleSubmit(handleOnSubmit)} className="kmc-console-mapping-row-facade">
            <Loader loading={loading}></Loader>
            {
                error ? <Alert severity="error">{error}</Alert> : null
            }
            <CardHeader
                title={<CloseRounded sx={{ color: "darkgray" }} onClick={handlePermissionCardClose}></CloseRounded>}
                sx={{ textAlign: "right", padding: "8px" }}
            >
            </CardHeader>
            <CardContent>
                <Grid container sx={{ alignItems: "center" }}>
                    <Grid item xs>
                        <AttributePreview isPermission={true}
                            isBadge={false}
                            name={nameWatch}
                            requestToUpdateBadge={true}
                            onSelectAsset={handleSelectedPermissionLogo}
                            editMode={editMode}
                            imageUrl={imageUrl}
                        >
                        </AttributePreview>
                    </Grid>
                    <Grid container xs={12} sm spacing={2} sx={{ textAlign: "left" }}>
                        <Grid item xs={12}>
                            <Controller
                                name='name'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        {...register("name")}
                                        label="Permission Name"
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        error={errors?.name ? true : false}
                                        disabled={editMode}
                                    />
                                )}
                            >
                            </Controller>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name='description'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        {...register("description")}
                                        label="Notes"
                                        InputLabelProps={{ shrink: true }}
                                        variant="standard"
                                        error={errors?.date ? true : false}
                                    />
                                )}
                            >
                            </Controller>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ paddingTop: "1rem" }}>
                    <Grid item xs={12}>
                        <Button variant="outlined" type="submit" size="small"
                            disabled={loading}>
                            {editMode ? 'Update permission' : 'Create permission'}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
export default CreatePermission;
import { Table, Box, Paper, TableBody, TableCell, TableContainer, TablePagination, TableRow, Alert } from "@mui/material";
import { useState } from "react"
import SecureLS from "secure-ls";
import './ConfigurationPaginationTable.css';
import { Delete, Download } from "@mui/icons-material";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import Loader from "../atoms/loader/Loader";
import DocumentTableHead from "./DocumentTableHead";
import { Buffer } from 'buffer';

export default function DocumentPaginationTable(props) {

    const { documents, selected_user_id, type } = props;

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('filename');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDeleteRow = (event, docId) => {
        // make a call to update the announcement in the private configuration
        // check if announcements has such property     
        delete documents[docId];   
        setLoading(true);
        const config = {
            documents: documents
        };
        let id;
        if (type && type === 'common') {
            id = "common_documents";
        } else {
            id = selected_user_id + "_user_documents";
        }
        axiosInstance.post(`${environment.MAPPING_ATTRIBUTES_API}/${tenant}/configurationGroup/${id}`, config)
            .then(res => {                
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err && err.message);
            });

    }

    const handleDownload = (event, row) => {
        const orig_file_path = row.file_path;
        const file_path_split = orig_file_path.split("/");
        let final_file_path = "";
        let file_name;
        file_path_split.forEach((e, idx) => {
            if (idx === file_path_split.length - 1) {
                file_name = e;
                return;
            }
            final_file_path += e + "/";
        });
        const encoded_file_path = new Buffer.from(final_file_path, 'utf-8').toString('base64');
        axiosInstance.get(`${environment.DOCUMENT_DOWNLOAD_LINK}/${tenant}/large_file/root/document-manager-resource/source_path/${encoded_file_path}/${encodeURI(file_name)}`)
            .then(res => {
                // get presigned document link
                if (res && res.data) {
                    const document_link = res.data.get_document_link;
                    const link = document.createElement('a');
                    link.href = document_link;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch(err => {
                setError(err && err?.message);
            });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Object.entries(documents).length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Loader loading={loading}></Loader>
            {error ? <Alert severity="error"></Alert> : null}
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <DocumentTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(Object.values(documents), getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const time = new Date(row.created_time).toLocaleTimeString();
                                    const date = new Date(row.created_time).toLocaleDateString();
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row" >
                                                {row.filename}
                                            </TableCell>
                                            <TableCell>{row.filetype}</TableCell>
                                            <TableCell>{row.filesize}</TableCell>
                                            <TableCell>{date + " " + time}</TableCell>
                                            <TableCell>
                                                <Delete fontSize="medium" sx={{ color: "#a45d5d", cursor: "pointer" }}
                                                    onClick={(event) => handleDeleteRow(event, row.id)}
                                                >
                                                </Delete>
                                                <Download fontSize="medium" sx={{ color: "#00898b", cursor: "pointer" }}
                                                    onClick={(event) => handleDownload(event, row)}>
                                                </Download>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={Object.entries(documents).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}
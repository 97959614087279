export const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortable: true
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    sortable: false
  },
  {
    id: "isStatic",
    numeric: false,
    disablePadding: true,
    label: "Is Static?",
    sortable: false
  },
  {
    id: "isFilterEnabled",
    numeric: false,
    disablePadding: true,
    label: "Is Filter Enabled?",
    sortable: false
  },  
  {
    id: "isUserAccess",
    numeric: false,
    disablePadding: true,
    label: "User Access",
    sortable: false
  }, 
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    sortable: false
  }
]

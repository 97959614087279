export const documentHeadCells = [
    {
        id: "filename",
        numeric: false,
        disablePadding: true,
        label: "Name",
        sortable: true
    },
    {
        id: "filetype",
        numeric: false,
        disablePadding: true,
        label: "Type",
        sortable: false
    },
    {
        id: "filesize",
        numeric: false,
        disablePadding: true,
        label: "Size",
        sortable: false
    },
    {
        id: "creation_date",
        numeric: false,
        disablePadding: true,
        label: "Assigned Date",
        sortable: false
    },
    {
        id: "actions",
        numeric: false,
        disablePadding: true,
        label: "Actions",
        sortable: false
    }
]

import { Table, Box, Paper, TableBody, TableCell, TableContainer, TablePagination, TableRow, Alert, Chip, Button, Typography, Grid } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import SecureLS from "secure-ls";
import CredentialsTableHead from "./CredentialsTableHead";
import { axiosInstance } from "../interceptors/axios/AxiosInstance";
import { environment } from "../assets/configurations/configuration";
import Loader from "../atoms/loader/Loader";
import UserPicker from "./UserPicker";

export default function CredentialsPaginationTable(props) {

    const _secureStore = new SecureLS();
    const tenant = _secureStore.get("tenant_name");
    const _secure_user_info = _secureStore.get("_secure_user_info");
    const loggedInUserEmail = _secure_user_info && _secure_user_info.attributes
        && _secure_user_info.attributes['email'];

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [credAdmins, setCredAdmins] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [cursorId, setCursorId] = useState("");
    const ref = useRef();

    useEffect(() => {
        setLoading(true);
        if (ref) {
            const config = {
                headers: {
                    limit: 60,
                    cursor_id: cursorId
                }
            };
            axiosInstance.get(`${environment.TENANT_USERS_GET_DELETE_API}/${tenant}/users`, config)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        const headers = res.headers;
                        const cursorId = headers['cursor_id'];
                        setCursorId(cursorId ? cursorId : "");
                        setCredAdmins(res.data);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setError(err && err?.message);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, tenant])

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleDeleteRow = (event, record) => {
        setLoading(true);
        axiosInstance.delete(`${environment.TENANT_USERS_GET_DELETE_API}/${tenant}/user/${record.email}`)
            .then(res => {
                setCredAdmins(credAdmins => credAdmins.filter(credAdmin => credAdmin.email !== record.email));
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err?.message);
            });

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - credAdmins.length) : 0;

    const handleSelectedUser = (user) => {
        const config = {
            email: user.contactEmail
        }
        setLoading(true);
        axiosInstance.post(`${environment.TENANT_USERS_POST_API}/${tenant}`, config)
            .then(res => {
                const credAdmin = {
                    email: config.email,
                    username: config.email,
                    UserCreateDate: new Date().toLocaleString()
                }
                setCredAdmins([...credAdmins, credAdmin]);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err && err?.response?.data);
            });
    }

    const handleClearSelectedUser = () => {
        return;
    }

    const handleEmailNavigation = (evnet, email) => {
        if (email) {
            // get user details by email id
            const config = {
                headers: {
                    requestagentid: loggedInUserEmail
                }
            };
            setLoading(true);
            axiosInstance.get(environment.GET_USER_INFO_BY_EMAIL + "/" + email, config)
                .then(res => {
                    setLoading(false);
                    if (res && res.data) {
                        const targetUrl = `https://${tenant}.intracrew.com/general/view/Employee/${res.data?.uniqueId}`;
                        window.open(targetUrl);
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setError(err && err?.message);
                });
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className="kmc-console-emp-datastore-group-title">Configure Tenant Administrators</Typography>
            </Grid>
            <Grid item xs={6}>
                <UserPicker label="Add Tenant User" onSelectSearchItem={handleSelectedUser} onClearSearchItem={handleClearSelectedUser}></UserPicker>
            </Grid>
            <Grid item xs={12}>
                {
                    credAdmins && credAdmins.length > 0
                        ? <Chip
                            color="primary"
                            variant="outlined"
                            label={credAdmins.length + " Tenant Administrators"}
                            size="medium"
                        >
                        </Chip>
                        : <></>
                }
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                    <Loader loading={loading}></Loader>
                    {error ? <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> : null}
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                            >
                                <CredentialsTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(credAdmins, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={index}
                                                >
                                                    <>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                        >
                                                            <Button
                                                                size="small"
                                                                onClick={(event) => handleEmailNavigation(event, row.email)}>
                                                                {row.email}
                                                            </Button>
                                                        </TableCell>
                                                    </>
                                                    <TableCell>
                                                        {row?.UserCreateDate}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row?.UserLastModifiedDate}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Delete fontSize="medium" sx={{ color: "#a45d5d", cursor: "pointer" }}
                                                            onClick={(event) => handleDeleteRow(event, row)}
                                                        >
                                                        </Delete>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={credAdmins.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    )
}
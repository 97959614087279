import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Typography,
  Box,
  TextField,
  Grid,
  Avatar,
  CircularProgress,
  Chip,
  Alert
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { environment } from "../../assets/configurations/configuration";
import { isMobile } from 'react-device-detect';
import SecureLS from "secure-ls";
import { axiosInstance } from "../../interceptors/axios/AxiosInstance";
import "./OffBoard.css";
import UserPicker from "../../molecules/UserPicker";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Loader from "../loader/Loader";
import EmployeeDisplayCard from "../../molecules/EmployeeDisplayCard";
import { useLocation, useNavigate } from "react-router-dom";

const OffBoard = () => {
  const _secureStore = new SecureLS();
  const tenant = _secureStore.get("tenant_name");
  const _secure_user_info = _secureStore.get("_secure_user_info");
  const loggedInUserEmail =
    _secure_user_info &&
    _secure_user_info.attributes &&
    _secure_user_info.attributes["email"];

  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [manager, setManager] = useState();
  const [newManager, setNewManager] = useState();
  const [error, setError] = useState("");
  const [children, setChildren] = useState([]);
  const [entityDetails, setEntityDetails] = useState({});
  const [message, setMessage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (employee && employee.uniqueId) {
      const config = {
        headers: {
          requestagentid: loggedInUserEmail,
        },
      };
      setLoading(true);
      setEntityDetails({});
      setChildren([]);
      // get the user details to confirm the user identity 
      // before removing from the system
      axiosInstance.get(`${environment.GET_EMPLOYEE_RECORD_API}/${employee.uniqueId}`, config)
        .then(res => {
          setLoading(false);
          if (res && res.data) {
            setValue("userName", res.data.givenName);
            setValue("role", res.data.role);
            setValue("contactEmail", res.data.contactEmail);
            setValue("uniqueId", res.data.uniqueId);
            setValue("referenceObjectGuid", res.data.referenceObjectGuid);
          }
        }).catch(err => {
          setLoading(false);
          console.log(err.message);
        });
      // get the user reportees to assign the new manager
      // before removing from the system
      setLoading(true);
      axiosInstance
        .get(`${environment.GET_CHILD_RECORD_API}/${employee.uniqueId}`, config)
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            setManager(res.data.parent);
            setChildren(res.data.children);
            setEntityDetails(res.data.entityDetails);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const onSelectEmployee = (data) => {
    // fire a get request to get user details with unique Id
    setEmployee(data);
  };
  const onRemoveEmployee = (data) => {
    // fire a get request to get user details with unique Id
    setChildren([]);
    setEntityDetails([]);
    setEmployee(null);
    setManager(null);
    reset();
  };

  const { handleSubmit, control, setValue, register, reset } =
    useForm({
      defaultValues: {
        userName: "",
        role: "",
        contactEmail: "",
        exitDescription: ""
      },
    });

  const handleOnSubmit = (data) => {
    // if there are reportees for the user to be deleted and no new manager is selected, 
    // then throw a warning to the user.
    if (children && children.length > 0) {
      if (!newManager) {
        setError("There are " + children.length + " direct reportees reporting to " + data?.userName + ". Please assign them to new manager and initiate exit process.");
        return;
      }
    }
    // first chanage the relation for the current reportees
    setLoading(true);
    let isRelationMapExits = false;
    const relation_entity = {
      "old_manager_unique_id": data.referenceObjectGuid,
      "new_manager_unique_id": newManager && newManager.referenceObjectGuid
    }
    if (relation_entity.old_manager_unique_id && relation_entity.new_manager_unique_id) {
      isRelationMapExits = true;
    }
    const config = {
      headers: {
        "changeagentid": loggedInUserEmail
      }
    }
    if (isRelationMapExits) {
      axiosInstance.post(`${environment.REPLACE_MANAGER_API}/${tenant}`, relation_entity, config)
        .then(res => {
          setLoading(false);
          // if successful, then start deleting the entity
          const config = {
            headers: {
              "requestagentid": loggedInUserEmail
            }
          }
          setLoading(true);
          axiosInstance.post(`${environment.DELETE_EMPLOYEE_RECORD_API}/${data.uniqueId}`, {}, config)
            .then((res) => {
              setLoading(false);
              setMessage(true);
              onSelectEmployee(null);
              setNewManager(null);
              setEntityDetails([]);
              setManager(null);
              if (location && location.state && location.state.navigation_from) {
                if (location.state?.navigation_from === 'employee_data_page') {
                  navigate('/workspace/upload/employee', {
                    state: {
                      isWorkspaceExpand: true,
                      activeItemId: 993
                    }
                  });
                }
              }
            })
            .catch((err) => {
              setLoading(false);
              setError(err?.message);
            });
        })
        .catch(err => {
          setLoading(false);
          setError(err.message);
        });
    } else {
      setLoading(true);
      const config = {
        headers: {
          "requestagentid": loggedInUserEmail
        }
      }
      axiosInstance.post(`${environment.DELETE_EMPLOYEE_RECORD_API}/${data.uniqueId}`, {}, config)
        .then((res) => {
          setLoading(false);
          setMessage(true);
          onSelectEmployee(null);
          setNewManager(null);
          setChildren([]);
          setManager(null);
          setEntityDetails([]);
          if (location && location.state && location.state.navigation_from) {
            if (location.state?.navigation_from === 'employee_data_page') {
              navigate('/workspace/upload/employee', {
                state: {
                  isWorkspaceExpand: true,
                  activeItemId: 993
                }
              });
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err?.message);
        });
    }
  };

  const onSelectNewManager = (e) => {
    setError(null);
    setNewManager(e);
  };

  const onRemoveNewManager = (e) => {
    setNewManager(null);
  }


  const ShowEmployeeDetails = () => {
    return (
      <>
        <Grid container sx={{ paddingTop: "1rem" }}>
          <Grid item xs sx={{ justifyItems: "center" }}>
            <EmployeeDisplayCard employee={employee}></EmployeeDisplayCard>
          </Grid>
          <Grid container xs={12} sm spacing={1} sx={{ textAlign: "left" }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="role"
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    disabled
                    fullWidth
                    {...field}
                    {...register("role")}
                    label="Current Role"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="contactEmail"
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    disabled
                    fullWidth
                    {...field}
                    {...register("contactEmail")}
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {
            manager && manager.entityId ?
              <>
                <Chip
                  size="small"
                  label="Current Facilitator"
                  color="primary"
                  variant="outlined"
                  sx={{ marginBottom: "1rem" }}>
                </Chip>
                <EmployeeDisplayCard employee={entityDetails[manager?.entityId]}>
                </EmployeeDisplayCard>
              </>
              : <></>
          }
        </Grid>
        <Grid item xs={12}>
          {
            loading ? <CircularProgress></CircularProgress> :
              <>
                {
                  children && children.length > 0 ?
                    <Chip
                      size="small"
                      label={children && children.length + " Direct Reportees"}
                      color="primary"
                      variant="outlined"
                      sx={{ marginBottom: "1rem" }}>
                    </Chip>
                    : <></>
                }
                <Swiper
                  style={{ height: 'auto' }}
                  slidesPerView={isMobile ? 1 : 4}
                  spaceBetween={3}
                  slidesPerGroup={3}
                  loop={false}
                  loopFillGroupWithBlank={false}
                  navigation={true}
                  modules={[Navigation]}
                  centeredSlides={false}
                  className="swiper-wrapper"
                >
                  {children && children.length > 0
                    ?
                    children.map((item, key) => {
                      return (
                        <SwiperSlide className="swiper-slide">
                          <Card key={key} className="kmc-console-swiperslide-card">
                            <EmployeeDisplayCard employee={entityDetails[item.entityId]} key={key}></EmployeeDisplayCard>
                          </Card>
                        </SwiperSlide>
                      )
                    })
                    :
                    <></>
                  }
                </Swiper>
              </>
          }
        </Grid>
        {
          children && children.length > 0
            ? <Grid item xs={12}>
              <UserPicker label="Assign to new Facilitator"
                onSelectSearchItem={onSelectNewManager}
                onClearSearchItem={onRemoveNewManager}
              >
              </UserPicker>
            </Grid>
            : null
        }
        {
          newManager
            ?
            <>
              <Grid item xs={12} sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <Card className="kmc-console-swiperslide-card">
                  <EmployeeDisplayCard employee={newManager}></EmployeeDisplayCard>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Chip
                  color="primary"
                  label={"Assign all the reportees to " + newManager.givenName}
                  avatar={<Avatar alt={newManager.givenName} src="./broken.png" />}
                  variant="outlined"
                >
                </Chip>
              </Grid>
            </>
            : null
        }        
      </>
    );
  };

  return (
    <Box className="kmc-console-offboard-content-box">
      {
        loading ? <Loader loading={loading}></Loader> : <></>
      }
      {
        error ? <Alert color="error" onClose={() => setError(null)}>{error}</Alert> : <></>
      }
      {
        message ? <Alert color="primary" onClose={() => setMessage(null)}>People is disabled in the current org {tenant}</Alert> : <></>
      }
      <Card className="kmc-console-offboard-info-card">
        <Typography variant="h6">Off-board People</Typography>
      </Card>
      <Card className="kmc-console-offboard-content-card">
        <Box
          className="kmc-console-offboard-form-box"
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserPicker
                label="Search People"
                onSelectSearchItem={onSelectEmployee}
                onClearSearchItem={onRemoveEmployee}
              ></UserPicker>
            </Grid>
            {employee && employee.uniqueId ? (
              <ShowEmployeeDetails></ShowEmployeeDetails>
            ) : (
              <></>
            )}
            {employee && employee.uniqueId ? (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  type="button"
                  size="small"
                  disabled={loading}
                  sx={{ marginTop: "1rem" }}
                  onClick={handleSubmit(handleOnSubmit)}
                >
                  Assign & Initiate exit process
                </Button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};
export default OffBoard;
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Typography,
  CardHeader,
  Box,
  CardContent,
} from "@mui/material";
import { SketchPicker } from "react-color";
import "../Branding.css";
import "../../../App.css";
import { useNavigate } from "react-router-dom";
import ColorPicker from "../../../assets/color-picker.png";
import { environment } from "../../../assets/configurations/configuration";
import { axiosInstance } from "../../../interceptors/axios/AxiosInstance";
import SecureLS from "secure-ls";
import { CloseRounded } from "@mui/icons-material";

const BrandingColorPicker = (props) => {

  const ref = useRef();

  const [primaryPreset, setPrimaryPreset] = useState();
  const [secondaryPreset, setSecondaryPreset] = useState();
  const [teritaryPreset, setTeritaryPreset] = useState();
  const [quaternaryPreset, setQuaternaryPreset] = useState();
  const [pColorPicker, setPColorPicker] = useState(false);
  const [sColorPicker, setSColorPicker] = useState(false);
  const [tColorPicker, setTColorPicker] = useState(false);
  const [qColorPicker, setQColorPicker] = useState(false);

  const _secureStore = new SecureLS();
  const tenant = _secureStore.get("tenant_name");
  const { onComponentChange } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (ref) {
      onComponentChange(true, "");
      axiosInstance
        .get(`${environment.BRANDING_COLOR_API}/${tenant}/configurationGroup/color`)
        .then(res => {
          if (res && res.data) {
            const { primary_color, secondary_color, third_color, fourth_color } = res.data;
            setPrimaryPreset(primary_color);
            setSecondaryPreset(secondary_color);
            setTeritaryPreset(third_color);
            setQuaternaryPreset(fourth_color);
            onComponentChange(false, "");
          }
        }).catch(err => {
          onComponentChange(false, err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const handlePresetSubmit = (event) => {
    event.preventDefault();
    const data = {
      primary_color: primaryPreset,
      secondary_color: secondaryPreset,
      third_color: teritaryPreset,
      fourth_color: quaternaryPreset
    }
    onComponentChange(true, "");
    axiosInstance
      .post(`${environment.BRANDING_COLOR_API}/${tenant}/configurationGroup/color`, data)
      .then(res => {
        if (res && res.data) {
          onComponentChange(false, "");
          navigate('/workspace/upload/employee', {
            state: {
              isWorkspaceExpand: true,
              activeItemId: 993
            }
          });
        }
      }).catch(err => {
        onComponentChange(false, err.message);
      });
  }

  const handlePicker = (event) => {
    event.preventDefault();
    const value = event.target.name;
    switch (value) {
      case 'primary': {
        setPColorPicker(true);
        setSColorPicker(false);
        setTColorPicker(false);
        setQColorPicker(false);
        break;
      }
      case 'secondary': {
        setPColorPicker(false);
        setSColorPicker(true);
        setTColorPicker(false);
        setQColorPicker(false);
        break;
      }
      case 'teritary': {
        setPColorPicker(false);
        setSColorPicker(false);
        setTColorPicker(true);
        setQColorPicker(false);
        break;
      }
      case 'quaternary': {
        setPColorPicker(false);
        setSColorPicker(false);
        setTColorPicker(false);
        setQColorPicker(true);
        break;
      }
      default: {
        console.err("Invalid picker");
      }
    }
  };

  return (
    <Card className="kmc-console-branding-content-card">
      <CardHeader title="Choose the color pattern">
        Custom colors
      </CardHeader>
      <Box className="kmc-console-branding-color-box">
        <Box className="kmc-console-branding-color-image-box">
          <img src={ColorPicker}></img>
        </Box>
        <Box className="kmc-console-branding-color-selection-box">
          <Box className="kmc-console-branding-color-group">
            <Typography variant="h6" className="kmc-console-branding-color-picker-text" sx={{ fontSize: '14px !important' }}>
              Choose background color
            </Typography>
            <input
              name="primary"
              onClick={handlePicker}
              style={{
                backgroundColor: `${primaryPreset}`,
                marginLeft: "15px",
                padding: "10px 12px",
                width: "46px",
              }}
            ></input>
            {pColorPicker ?
              <Box style={{
                position: "absolute",
                zIndex: 999
              }}
              >
                <Card>
                  <CardHeader
                    title={<CloseRounded sx={{ color: "darkgray" }} onClick={() => setPColorPicker(false)}></CloseRounded>}
                    sx={{ textAlign: "right", padding: "6px" }}
                  >
                  </CardHeader>
                  <CardContent sx={{ padding: "0.5rem !important" }}>
                    <SketchPicker
                      name="primarySketch"
                      style={{ marginLeft: 20 }}
                      color={primaryPreset}
                      onChange={(color) => {
                        setPrimaryPreset(color?.hex);
                      }}
                    >
                    </SketchPicker>
                  </CardContent>
                </Card>
              </Box>
              : <></>
            }
          </Box>
          <Box className="kmc-console-branding-color-group">
            <Typography variant="h6" className="kmc-console-branding-color-picker-text" sx={{ fontSize: '14px !important' }}>
              Choose theme color
            </Typography>
            <input
              name="secondary"
              onClick={handlePicker}
              style={{
                backgroundColor: `${secondaryPreset}`,
                marginLeft: "15px",
                padding: "10px 12px",
                width: "46px",
              }}
            ></input>
            {sColorPicker ?
              <Box style={{
                position: "absolute",
                zIndex: 999
              }}>
                <Card>
                  <CardHeader
                    title={<CloseRounded sx={{ color: "darkgray" }} onClick={() => setSColorPicker(false)}></CloseRounded>}
                    sx={{ textAlign: "right", padding: "6px" }}
                  >
                  </CardHeader>
                  <CardContent sx={{ padding: "0.5rem !important" }}>
                    <SketchPicker
                      name="secondarySketch"
                      style={{ marginLeft: 20 }}
                      color={secondaryPreset}
                      onChange={(color) => {
                        setSecondaryPreset(color.hex);
                      }}
                    />
                  </CardContent>
                </Card>
              </Box>
              : <></>
            }
          </Box>
          <Box className="kmc-console-branding-color-group">
            <Typography variant="h6" className="kmc-console-branding-color-picker-text" sx={{ fontSize: '14px !important' }}>
              Choose text color
            </Typography>
            <input
              onClick={handlePicker}
              name="teritary"
              style={{
                backgroundColor: `${teritaryPreset}`,
                marginLeft: "15px",
                padding: "10px 12px",
                width: "46px",
              }}
            ></input>
            {tColorPicker ?
              <Box style={{
                position: "absolute",
                zIndex: 999
              }}>
                <Card>
                  <CardHeader
                    title={<CloseRounded sx={{ color: "darkgray" }} onClick={() => setTColorPicker(false)}></CloseRounded>}
                    sx={{ textAlign: "right", padding: "6px" }}
                  >
                  </CardHeader>
                  <CardContent sx={{ padding: "0.5rem !important" }}>
                    <SketchPicker
                      name="teritarySketch"
                      style={{ marginLeft: 20 }}
                      color={teritaryPreset}
                      onChange={(color) => {
                        setTeritaryPreset(color.hex);
                      }}
                    />
                  </CardContent>
                </Card>
              </Box>
              : <></>
            }
          </Box>
          <Box className="kmc-console-branding-color-group">
            <Typography variant="h6" className="kmc-console-branding-color-picker-text" sx={{ fontSize: '14px !important' }}>
              Choose header and bold color
            </Typography>
            <input
              name="quaternary"
              onClick={handlePicker}
              style={{
                backgroundColor: `${quaternaryPreset}`,
                marginLeft: "15px",
                padding: "10px 12px",
                width: "46px"
              }}
            ></input>
            {qColorPicker ?
              <Box style={{
                position: "absolute",
                zIndex: 999
              }}>
                <Card>
                  <CardHeader
                    title={<CloseRounded sx={{ color: "darkgray" }} onClick={() => setQColorPicker(false)}></CloseRounded>}
                    sx={{ textAlign: "right", padding: "6px" }}
                  >
                  </CardHeader>
                  <CardContent sx={{ padding: "0.5rem !important" }}>
                    <SketchPicker
                      name="quaternarySketch"
                      style={{ marginLeft: 20 }}
                      color={quaternaryPreset}
                      onChange={(color) => {
                        setQuaternaryPreset(color.hex);
                      }}
                    />
                  </CardContent>
                </Card>
              </Box>
              : <></>
            }
          </Box>
        </Box>
      </Box>
      <Button
        onClick={handlePresetSubmit}
        variant="outlined"
        size="small"
        sx={{ marginRight: "1rem", marginTop: "1rem", marginBottom: "1rem" }}
      >
        Continue
      </Button>
    </Card>
  )
}

export default BrandingColorPicker;

import axios from 'axios';
import SecureLS from 'secure-ls';

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        const _secure_user_info = new SecureLS().get("_secure_user_info");
        const token = _secure_user_info && _secure_user_info.signInUserSession.accessToken.jwtToken;
        const company = new SecureLS().get("tenant_name");
        config.headers.common = {
            ...config.headers.common,
            'authorization': `Bearer ${token}`,
            'tenant': company,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        return config;
    },
    error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
//        if (error.message === 'Network Error') {
//        if (error.response.status === 403) {
//            localStorage.clear();
//            window.location.reload();
//        }
        return Promise.reject(error);
    }
);

export const mappings = [
    {
        name: 'firstName',
        label: 'Firstname'
    },
    {
        name: 'lastName',
        label: 'Lastname'
    },
    {
        name: 'contactEmail',
        label: 'Email'
    },
    {
        name: 'locationName',
        label: 'Location'
    },
    {
        name: 'parentId',
        label: 'Facilitator People Id'
    },
    {
        name: 'referenceObjectGuid',
        label: 'People Id'
    }
]